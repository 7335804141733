import { useState, useEffect, useRef } from "react";
export const truncateAddress = (address) => {
  address = ("" + address).toLowerCase();
  if (address.length > 12) {
    let start = address.substring(0, 5);
    let end = address.substring(address.length - 4, address.length);
    address = start + "..." + end;
  }
  return address;
};

export const epochFromDate = (date) => {
  const epochMilliseconds = date.getTime();
  const epochSeconds = Math.floor(epochMilliseconds / 1000);
  return epochSeconds; // or epochMilliseconds if you want it in milliseconds
};

export const epochToDateObj = (epochTime) => {
  if (!epochTime) {
    return new Date();
  }
  return new Date(epochTime * 1000);
};

export const epochToDate = (epochTime, options = { removeDay: false }) => {
  if (!epochTime) {
    return new Date();
  }
  const date = new Date(epochTime * 1000); // convert seconds to milliseconds
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  if (options.removeDay) {
    return `${month} ${year}`;
  }
  const day = date.getDate();
  // add the appropriate ordinal suffix to the day (e.g. "1st", "2nd", "3rd")
  let dayStr;
  if (day % 10 === 1 && day !== 11) {
    dayStr = day + "st";
  } else if (day % 10 === 2 && day !== 12) {
    dayStr = day + "nd";
  } else if (day % 10 === 3 && day !== 13) {
    dayStr = day + "rd";
  } else {
    dayStr = day + "th";
  }
  return `${dayStr} ${month} ${year}`;
};

export const addOrdinalSuffix = (number) => {
  // get the last two digits of the number (to handle numbers above 100)
  const lastTwoDigits = number % 100;
  // determine the appropriate suffix based on the last two digits
  let suffix;
  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    suffix = "th";
  } else if (lastTwoDigits % 10 === 1) {
    suffix = "st";
  } else if (lastTwoDigits % 10 === 2) {
    suffix = "nd";
  } else if (lastTwoDigits % 10 === 3) {
    suffix = "rd";
  } else {
    suffix = "th";
  }
  return `${number}${suffix}`;
};

export const isValidEthAddress = (address) => {
  return /^0x[a-fA-F0-9]{40}$/.test(address);
};

export const addCommasToNum = (number) => {
  try {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch (error) {
    console.log(`addCommasToNum() - Error`, error);
  }
  return number;
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideAlerter = (ref, outsideCb) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        outsideCb();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, outsideCb]);
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export const useDocumentTitle = (title, prevailOnUnmount = false) => {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    []
  );
};

export const copyTextToClipboard = (text) => {
  // Create a temporary textarea to hold the text
  const textarea = document.createElement("textarea");
  textarea.value = text;
  document.body.appendChild(textarea);

  // Select the text in the textarea
  textarea.select();

  // Copy the selected text to the clipboard
  document.execCommand("copy");

  // Remove the temporary textarea
  document.body.removeChild(textarea);

  // Log the message to indicate successful copy
  console.log("Text copied to clipboard:", text);
};

export const timeNow = () => {
  return Math.round(Date.now() / 1000);
};

// export const uniqueString = (length) => {
//   return crypto
//     .randomBytes(Math.max(length, 64))
//     .toString("hex")
//     .substring(0, length);
// };

export const uniqueGuid = () => {
  // const inputString = uniqueString(32);
  // const formattedUUID = `${inputString.substr(0, 8)}-${inputString.substr(
  //   8,
  //   4
  // )}-4${inputString.substr(12, 3)}-a${inputString.substr(
  //   15,
  //   3
  // )}-${inputString.substr(18, 12)}`;
  // return formattedUUID;
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const areSetsEqual = (a, b) =>
  a.size === b.size && [...a].every((value) => b.has(value));
