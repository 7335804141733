/// Menu
import React, {
  Component,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
//import Metismenu from "metismenujs";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";

/// Link
import { Link, NavLink, useLocation } from "react-router-dom";

import { MenuList } from "./Menu";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

import logotext1 from "./../../../images/logo/logo-text.png";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const SideBar = () => {
  const location = useLocation();
  // console.log(`location`, location);
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);

  // let handleheartBlast = document.querySelector('.heart');
  // function heartBlast(){
  //   return handleheartBlast.classList.toggle("heart-blast");
  // }

  useEffect(() => {}, []);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  // Menu dropdown list
  //const [active , setActive] = useState('');
  //const [activeSubmenu , setActiveSubmenu] = useState('');

  const handleMenuActive = (status) => {
    setState({ active: status });

    if (state.active === status) {
      setState({ active: "" });
    }
  };
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };
  // Menu dropdown list End

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  return (
    <div
      className={`deznav  border-right ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <div className="nav-header sidebar-logo">
        <a href="https://betterblocks.io" className="brand-logo">
          <img src={logotext1} className="brand-title sidebar-logo" alt="" />
        </a>
      </div>
      <PerfectScrollbar className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {MenuList.map((data, index) => {
            let menuClass = data.classChange;
            if (!data.to) {
              data.to = "/";
            }
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index}>
                  {data.title}
                </li>
              );
            } else {
              return (
                <li
                  className={` ${
                    location.pathname === data.to ? "mm-active" : ""
                  }`}
                  key={index}
                >
                  {data.content && data.content.length > 0 ? (
                    <Link
                      to={data.to}
                      className="has-arrow"
                      onClick={() => {
                        handleMenuActive(data.title);
                      }}
                    >
                      {data.iconStyle}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                  ) : (
                    <NavLink to={data.to}>
                      {data.iconStyle}
                      <span className="nav-text">{data.title}</span>
                    </NavLink>
                  )}
                  {data.content && (
                    <Collapse
                      in={location.pathname.startsWith(data.to) ? true : false}
                    >
                      <ul
                        className={`${
                          menuClass === "mm-collapse" ? "mm-show" : ""
                        }`}
                      >
                        {data.content &&
                          data.content.map((data, index) => {
                            return (
                              <li
                                key={index}
                                className={`${
                                  location.pathname.startsWith(data.to)
                                    ? "mm-active sidebar-sub-active"
                                    : ""
                                }`}
                              >
                                {data.content && data.content.length > 0 ? (
                                  <>
                                    <NavLink
                                      to={data.to}
                                      className={
                                        data.hasMenu ? "has-arrow" : ""
                                      }
                                      onClick={() => {
                                        handleSubmenuActive(data.title);
                                      }}
                                    >
                                      {data.title}
                                    </NavLink>
                                    <Collapse
                                      in={
                                        location.pathname === data.to
                                          ? true
                                          : false
                                      }
                                    >
                                      <ul
                                        className={`${
                                          menuClass === "mm-collapse"
                                            ? "mm-show"
                                            : ""
                                        }`}
                                      >
                                        {data.content &&
                                          data.content.map((data, index) => {
                                            return (
                                              <>
                                                <li key={index}>
                                                  <Link
                                                    className={`${
                                                      path === data.to
                                                        ? "mm-active"
                                                        : ""
                                                    }`}
                                                    to={data.to}
                                                  >
                                                    {data.title}
                                                  </Link>
                                                </li>
                                              </>
                                            );
                                          })}
                                      </ul>
                                    </Collapse>
                                  </>
                                ) : (
                                  <Link to={data.to}>
                                    <div className="d-flex align-items-center">
                                      {data.img && (
                                        <img
                                          className="nav-submenu-icon"
                                          width={20}
                                          src={data.img}
                                        ></img>
                                      )}
                                      <div>{data.title}</div>
                                    </div>
                                  </Link>
                                )}
                              </li>
                            );
                          })}
                      </ul>
                    </Collapse>
                  )}
                </li>
              );
            }
          })}
        </ul>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
