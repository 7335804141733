import React from "react";
import { Link } from "react-router-dom";

const PageHeaderBar = ({ pages }) => {
  return (
    <>
      <div className="row page-titles mx-0">
        <ol className="breadcrumb">
          {pages.map((page, index) => (
            <li
              className={`breadcrumb-item ${index === 0 ? "active" : ""}`}
              key={index}
            >
              <Link to={page.url}>{page.title}</Link>
            </li>
          ))}
        </ol>
      </div>
    </>
  );
};

export default PageHeaderBar;
