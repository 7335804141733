import spotlightBotImg from "../../../images/blocks/bots/spotlight-bot.png";
import salesBotImg from "../../../images/blocks/bots/sales-bot.png";
import flexBotImg from "../../../images/blocks/bots/flex-bot.png";
import rewardsBotImg from "../../../images/blocks/bots/rewards-bot.png";
import snapshotToolImg from "../../../images/blocks/bots/snapshot-tool.png";

export const Categories = {
  Twitter: { title: `Twitter`, class: `bg-info` },
  Discord: { title: `Discord`, class: `bg-discord` },
  ContentGeneration: { title: `Content Generation`, class: `bg-warning` },
  Data: { title: `Data`, class: `bg-data` },
  // Promotion: { title: `Promotion`, class: `bg-primary` },
};

export const LandmarkCategories = {
  LeadGeneration: { title: `Lead Generation`, class: `bg-info` },
  C2P: { title: `C2P`, class: `bg-discord` },
  Backend: { title: `Backend`, class: `bg-discord` },
  // ContentGeneration: { title: `Content Generation`, class: `bg-warning` },
  DataProcessing: { title: `Data Processing`, class: `bg-data` },
  // Promotion: { title: `Promotion`, class: `bg-primary` },
};

export const Blocks = {
  NFTSpotlightBot: {
    type: "Bot",
    title: `NFT Spotlight Bot`,
    url: `/blocks/spotlightbot`,
    img: spotlightBotImg,
    display: true,
    bullets: [
      `Create the perfect tweet to highlight your NFT's rarest features`,
      `Automate daily or weekly tweets showcasing random NFTs from your
    collection`,
      `Attract more attention to your collection`,
    ],
    categories: [
      Categories.Twitter,
      Categories.ContentGeneration,
      // Categories.Promotion,
    ],
  },
  NFTFlexBot: {
    type: "Bot",
    title: `NFT Flex Bot`,
    url: `/blocks/flexbot`,
    img: flexBotImg,
    display: true,
    bullets: [
      `Create the perfect tweet to flex your NFT's rarest features`,
      `Automate daily or weekly tweets flexing your owned NFTs on social media`,
      `Attract more attention to your owned NFTs`,
    ],
    categories: [
      Categories.Twitter,
      Categories.ContentGeneration,
      // Categories.Promotion,
    ],
  },
  NFTSalesBot: {
    type: "Bot",
    title: `NFT Sales Bot`,
    url: `/blocks/salesbot`,
    img: salesBotImg,
    display: true,
    bullets: [
      `Automatically post whenever an NFT in your collection is sold`,
      `Works seamlessly with Twitter and Discord`,
      `Attract more attention to your collection`,
    ],
    categories: [
      Categories.Twitter,
      Categories.Discord,
      Categories.ContentGeneration,
      // Categories.Promotion,
    ],
  },
  RewardsBot: {
    type: "Bot",
    title: `Rewards Bot`,
    url: `/blocks/rewardsbot`,
    img: rewardsBotImg,
    display: true,
    bullets: [
      `Reward Twitter users in your community`,
      `Works seamlessly with Twitter`,
      `Automate your rewards`,
    ],
    categories: [
      Categories.Twitter,
      Categories.ContentGeneration,
      // Categories.Promotion,
    ],
  },
  NFTSnapshotTool: {
    type: "Tool",
    title: `NFT Snapshot Tool`,
    url: `/tools/snapshot`,
    img: snapshotToolImg,
    display: true,
    bullets: [
      `Create a snapshot of an NFT collection at any point in time`,
      `Download the snapshot data in a variety of formats`,
      `Your history is saved so you can easily view previous snapshots`,
    ],
    categories: [Categories.Data],
  },
};

export const LandmarkBlocks = {
  KeywordBot: {
    type: "Bot",
    title: `LinkedIn Keyword Bot`,
    url: `/landmark/keywordbot`,
    img: spotlightBotImg,
    display: true,
    bullets: [
      `Create the perfect tweet to highlight your NFT's rarest features`,
      `Automate daily or weekly tweets showcasing random NFTs from your
    collection`,
      `Attract more attention to your collection`,
    ],
    categories: [LandmarkCategories.LeadGeneration],
  },
  SalesBot: {
    type: "Bot",
    title: `LinkedIn Sales Nav Bot`,
    url: `/landmark/salesnavbot`,
    img: flexBotImg,
    display: true,
    bullets: [
      `Create the perfect tweet to highlight your NFT's rarest features`,
      `Automate daily or weekly tweets showcasing random NFTs from your
    collection`,
      `Attract more attention to your collection`,
    ],
    categories: [LandmarkCategories.LeadGeneration],
  },
  EmailAutomation: {
    type: "Tool",
    title: `Email Automation`,
    url: `/landmark/campaign/dashboard`,
    img: salesBotImg,
    display: true,
    bullets: [
      `Create the perfect tweet to highlight your NFT's rarest features`,
      `Automate daily or weekly tweets showcasing random NFTs from your
    collection`,
      `Attract more attention to your collection`,
    ],
    categories: [LandmarkCategories.LeadGeneration],
  },
  TweetSearch: {
    type: "Tool",
    title: `Tweet Searcher`,
    url: `/landmark/tweetsearch`,
    img: salesBotImg,
    display: true,
    bullets: [
      `Create the perfect tweet to highlight your NFT's rarest features`,
      `Automate daily or weekly tweets showcasing random NFTs from your
    collection`,
      `Attract more attention to your collection`,
    ],
    categories: [LandmarkCategories.LeadGeneration],
  },
  DeDuper: {
    type: "Tool",
    title: `DeDuper`,
    url: `/landmark/deduper`,
    img: rewardsBotImg,
    display: true,
    bullets: [
      `Automatically post whenever an NFT in your collection is sold`,
      `Works seamlessly with Twitter and Discord`,
      `Attract more attention to your collection`,
    ],
    categories: [LandmarkCategories.DataProcessing],
  },
  GlobalDashboard: {
    type: "Backend",
    title: `Global Events Dashboard`,
    url: `/landmark/global/dashboard`,
    img: snapshotToolImg,
    display: true,
    bullets: [
      `Create a snapshot of an NFT collection at any point in time`,
      `Download the snapshot data in a variety of formats`,
      `Your history is saved so you can easily view previous snapshots`,
    ],
    categories: [LandmarkCategories.Backend, LandmarkCategories.C2P],
  },
  EventsDashboard: {
    type: "Backend",
    title: `Events Dashboard`,
    url: `/landmark/events/dashboard`,
    img: snapshotToolImg,
    display: true,
    bullets: [
      `Create a snapshot of an NFT collection at any point in time`,
      `Download the snapshot data in a variety of formats`,
      `Your history is saved so you can easily view previous snapshots`,
    ],
    categories: [LandmarkCategories.Backend, LandmarkCategories.C2P],
  },
  IndustrialTechDashboard: {
    type: "Backend",
    title: `Industrial Tech Dashboard`,
    url: `/landmark/industrialtech/dashboard`,
    img: snapshotToolImg,
    display: true,
    bullets: [
      `Create a snapshot of an NFT collection at any point in time`,
      `Download the snapshot data in a variety of formats`,
      `Your history is saved so you can easily view previous snapshots`,
    ],
    categories: [LandmarkCategories.Backend, LandmarkCategories.C2P],
  },
  SISDelegateDashboard: {
    type: "Backend",
    title: `SIS Delegate Dashboard`,
    url: `/landmark/sisdelegates/dashboard`,
    img: snapshotToolImg,
    display: true,
    bullets: [
      `Create a snapshot of an NFT collection at any point in time`,
      `Download the snapshot data in a variety of formats`,
      `Your history is saved so you can easily view previous snapshots`,
    ],
    categories: [LandmarkCategories.Backend, LandmarkCategories.C2P],
  },
  SISOmniDashboard: {
    type: "Backend",
    title: `SIS Omni Dashboard`,
    url: `/landmark/sisomni/dashboard`,
    img: snapshotToolImg,
    display: true,
    bullets: [
      `Create a snapshot of an NFT collection at any point in time`,
      `Download the snapshot data in a variety of formats`,
      `Your history is saved so you can easily view previous snapshots`,
    ],
    categories: [LandmarkCategories.Backend, LandmarkCategories.C2P],
  },
  DealmakersIBDashboard: {
    type: "Backend",
    title: `Dealmakers IB Dashboard`,
    url: `/landmark/dealmakersib/dashboard`,
    img: snapshotToolImg,
    display: true,
    bullets: [
      `Create a snapshot of an NFT collection at any point in time`,
      `Download the snapshot data in a variety of formats`,
      `Your history is saved so you can easily view previous snapshots`,
    ],
    categories: [LandmarkCategories.Backend, LandmarkCategories.C2P],
  },

  GlobalC2P: {
    type: "C2P",
    title: `CES 2024 C2P`,
    url: `https://ces.landmarkventures.com`,
    img: snapshotToolImg,
    display: true,
    bullets: [
      `Create a snapshot of an NFT collection at any point in time`,
      `Download the snapshot data in a variety of formats`,
      `Your history is saved so you can easily view previous snapshots`,
    ],
    categories: [LandmarkCategories.C2P],
  },
  EventsC2P: {
    type: "C2P",
    title: `Events C2P`,
    url: `https://events.landmarkventures.com`,
    img: snapshotToolImg,
    display: true,
    bullets: [
      `Create a snapshot of an NFT collection at any point in time`,
      `Download the snapshot data in a variety of formats`,
      `Your history is saved so you can easily view previous snapshots`,
    ],
    categories: [LandmarkCategories.C2P],
  },
  IndustrialTechC2P: {
    type: "C2P",
    title: `Industrial Tech C2P`,
    url: `https://industrialtech.landmarkventures.com`,
    img: snapshotToolImg,
    display: true,
    bullets: [
      `Create a snapshot of an NFT collection at any point in time`,
      `Download the snapshot data in a variety of formats`,
      `Your history is saved so you can easily view previous snapshots`,
    ],
    categories: [LandmarkCategories.C2P],
  },
  SISDelegateC2P: {
    type: "C2P",
    title: `SIS Delegate C2P`,
    url: `https://sisdelegate.landmarkventures.com`,
    img: snapshotToolImg,
    display: true,
    bullets: [
      `Create a snapshot of an NFT collection at any point in time`,
      `Download the snapshot data in a variety of formats`,
      `Your history is saved so you can easily view previous snapshots`,
    ],
    categories: [LandmarkCategories.C2P],
  },
  SISOmniC2P: {
    type: "C2P",
    title: `SIS Omni C2P`,
    url: `https://sisomni.landmarkventures.com`,
    img: snapshotToolImg,
    display: true,
    bullets: [
      `Create a snapshot of an NFT collection at any point in time`,
      `Download the snapshot data in a variety of formats`,
      `Your history is saved so you can easily view previous snapshots`,
    ],
    categories: [LandmarkCategories.C2P],
  },
  DealmakersIBC2P: {
    type: "C2P",
    title: `Dealmakers IB C2P`,
    url: `https://dealmakersib.landmarkventures.com`,
    img: snapshotToolImg,
    display: true,
    bullets: [
      `Create a snapshot of an NFT collection at any point in time`,
      `Download the snapshot data in a variety of formats`,
      `Your history is saved so you can easily view previous snapshots`,
    ],
    categories: [LandmarkCategories.C2P],
  },
};
