import { Fragment } from "react";
import { motion } from "framer-motion";

function BlockCard({
  block,
  compactMode = false,
  withoutMotion = false,
  landmark = false,
}) {
  if (!block) {
    return;
  }

  let typeClass = "type-bot";
  if (block.type === "Tool") {
    typeClass = "type-tool";
  }

  if (landmark) {
    typeClass += " landmark";
  }

  const blockCard = () => (
    <div className={`card h-fc block-card ${typeClass}`}>
      <div className="card-header border-0 flex-row justify-content-start">
        <img className="img-fluid block-card-img" width={90} src={block.img} />
        <div className="mb-2" style={{ paddingLeft: "1rem" }}>
          <div className="d-flex align-items-center">
            <h4 className="heading m-0 orbitron">{block.title}</h4>
            <div className="beta-badge">BETA</div>
          </div>
          {!compactMode && (
            <span className="fs-16">
              {block.bullets.map((bullet, index) => (
                <Fragment key={index}>
                  {`• `}
                  {bullet.trim()}
                  <br />
                </Fragment>
              ))}
            </span>
          )}
          <div className="bot-header-badges">
            {block.categories.map((category, index) => (
              <div key={index} className={`badge ${category.class} mt-04`}>
                {category.title}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  if (withoutMotion) {
    return blockCard();
  }
  // with motion animation
  return (
    <motion.div
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0, opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      {blockCard()}
    </motion.div>
  );
}

export default BlockCard;
