import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { Stepper, Step } from "react-form-stepper";
import { Link } from "react-router-dom";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import toast from "react-hot-toast";
import { Col, Card, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import { AlchemyContext } from "../../../Providers/AlchemyProvider";
import CollectionWidget from "../../nft/CollectionWidget";
import { TwitterTweetEmbed } from "react-twitter-embed";
import blueCircleImg from "../../../../images/misc/blue-circle.png";
import blueWaveAnim from "../../../../images/misc/green-wave-anim.svg";
import ethGreenImg from "../../../../images/misc/eth-green.png";

import AuthOnly from "../../AuthOnly";
import ApiService from "../../../../services/ApiService";
import { AuthContext } from "../../../Providers/AuthProvider";
import { isValidEthAddress } from "../../../utils";
import TwitterAuthSelectDropdown from "../TwitterAuthSelectDropdown";

import { Blocks } from "../blocks";
import BlockCard from "../BlockCard";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import { TypeAnimation } from "react-type-animation";
import DiscordWebhookSelectDropdown from "../DiscordWebhookSelectDropdown";
import BlockEventsTable from "../BlockEventsTable";

const BotContext = createContext({});
const StatsContext = createContext({});

const CollectionEditor = ({ handleContractAddressChange }) => {
  const [bot, setBot] = useContext(BotContext);

  const getRandomExampleContract = () => {
    const exampleContracts = [
      "0xb159f1a0920a7f1d336397a52d92da94b1279838".toLowerCase(), // rsop
      "0x5a91f86c4eb5f113575770f99ba8241371961c85".toLowerCase(), // e1
      "0x3e69baab7a742c83499661c5db92386b2424df11".toLowerCase(), // rw
      "0x4819dab28d11de83c20c75c7fa2a6eac9dc948d4".toLowerCase(), // mvp
      "0x9b0674fE9c91414378a5dFAFB3376775A65CDEba".toLowerCase(), // bib
    ];
    const randomIndex = Math.floor(Math.random() * exampleContracts.length);
    return exampleContracts[randomIndex];
  };

  return (
    <motion.div //updated the div tag
      animate={{ x: 0 }}
      initial={{ x: -150 }}
    >
      <form>
        <div className="row">
          <div
            className={`form-group mb-3 col-12 d-flex justify-content-center ${
              bot?.contractAddress
                ? bot.contractAddress
                  ? "is-invalid"
                  : "is-valid"
                : ""
            }`}
          >
            <div className="w-100 max-w-400px">
              <label className="text-label">
                Contract address of collection
              </label>
              <div className="input-group">
                <span className="input-group-text">
                  <i className="fa fa-file-contract col-g" />{" "}
                </span>
                <input
                  type="text"
                  className="form-control"
                  id="val-contractAddress"
                  placeholder=""
                  name="contractAddress"
                  onChange={handleContractAddressChange}
                  // onBlur={handleBlur}
                  value={bot?.contractAddress}
                />
                <div
                  id="val-contractAddress-error"
                  className="invalid-feedback animated fadeInUp"
                  style={{ display: "block" }}
                >
                  {!bot?.collectionName &&
                    `Must be a valid Ethereum contract address (starts with 0x...)`}
                </div>

                <div
                  id="val-contractAddress-error"
                  className="invalid-feedback animated fadeInUp"
                  style={{ display: "block" }}
                />
              </div>
              {!bot?.contractAddress && (
                <div className="w-100 text-center">
                  <label
                    className="hvr-shrink text-label use-example-collection-btn m-auto mt-2"
                    onClick={() =>
                      handleContractAddressChange(getRandomExampleContract())
                    }
                  >
                    Use example collection
                  </label>
                </div>
              )}
            </div>
          </div>
          {/* <CollectionWidget contractAddress={values.contractAddress} /> */}
          <CollectionWidget
            contractAddress={bot?.contractAddress}
            bot={bot}
            setFromBot={true}
          />
          {/* <button
        type="submit"
        className="btn me-2 btn-primary"
        disabled={isSubmitting}
      >
        Submit
      </button> */}
          {/* <button  className="btn btn-danger">
        Cancel
      </button> */}
        </div>
      </form>
    </motion.div>
  );
};

const AutomationControlsDiscord = ({ discordWebhooks }) => {
  const [bot, setBot] = useContext(BotContext);

  const [automationDiscordEnabled, setAutomationDiscordEnabled] = useState(
    bot?.automationDiscordEnabled || false
  );
  const [automationDiscordWebhookId, setAutomationDiscordWebhookId] = useState(
    bot?.automationDiscordWebhookId || null
  );

  const handleWebhookSelect = (webhook) => {
    const newWebhookId = webhook.discordWebhookId;
    if (bot.automationDiscordWebhookId !== newWebhookId) {
      setBot({
        ...bot,
        automationDiscordWebhookId: newWebhookId,
      });
    }
    setAutomationDiscordWebhookId(newWebhookId);
  };

  const handleSetAutomationDiscordEnabled = (isEnabled) => {
    if (bot.automationDiscordEnabled !== isEnabled) {
      setBot({
        ...bot,
        automationDiscordEnabled: isEnabled,
      });
    }
    setAutomationDiscordEnabled(isEnabled);
  };

  return (
    <motion.div //updated the div tag
      animate={{ x: 0 }}
      initial={{ x: -150 }}
    >
      <div className="row tweet-automation position-relative z-100">
        <div className="col-12 mb-3 pt-3 pl-2 pr-2">
          <h2 className="heading">Discord Automation</h2>
          <div className="row mt-3 d-flex align-items-center justify-content-center">
            <div className="col-12 mb-2">
              <div className="max-w-1000px text-center m-auto">
                When a sale occurs we'll automatically post in a Discord channel
                of your choice.
                <br></br>It's as simple as set and forget - we'll take care of
                everything else.
              </div>
              {discordWebhooks.length === 0 && (
                <motion.div
                  animate={{
                    scale: [0, 1.07, 1],
                    opacity: [0, 1],
                  }}
                >
                  <div className="col-12">
                    <div className="not-authed-twitter">
                      <div>
                        You must have added a Discord Webhook to use this
                        feature{" "}
                      </div>
                      <div>
                        Visit the <Link to={"/settings"}>settings</Link> page to
                        add a Webhook
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </div>
          </div>
        </div>
        {discordWebhooks.length > 0 && (
          <motion.div
            animate={{
              scale: [0, 1.07, 1],
              opacity: [0, 1],
            }}
          >
            <div className="row">
              <div className="col-12 z-100 text-center">
                <div className="basic-form">
                  <form>
                    <div className="form-group mb-3 pb-1">
                      <label>Discord Webhook to post from:</label>
                      <DiscordWebhookSelectDropdown
                        discordWebhooks={discordWebhooks}
                        onSelect={handleWebhookSelect}
                        selectedDiscordWebhook={discordWebhooks.find(
                          (w) =>
                            w.discordWebhookId === automationDiscordWebhookId
                        )}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-center text-center pb-2">
                  <AnimatePresence>
                    {automationDiscordEnabled && (
                      <motion.div
                        animate={{
                          scale: [0, 1.07, 1],
                          opacity: [0, 1],
                        }}
                      >
                        <div className="d-flex flex-column">
                          <div className="automation-status-msg enabled">
                            Automation Enabled
                          </div>
                          <img
                            className="automation-status-img"
                            src={blueWaveAnim}
                            width={100}
                          />
                          <div className="hvr-shrink">
                            <button
                              type="button"
                              className="btn btn-danger btn-xs"
                              onClick={() =>
                                handleSetAutomationDiscordEnabled(false)
                              }
                            >
                              Pause
                            </button>
                          </div>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <AnimatePresence>
                    {!automationDiscordEnabled && (
                      <motion.div
                        animate={{
                          scale: [0, 1.07, 1],
                          opacity: [0, 1],
                        }}
                      >
                        <div className="d-flex flex-column">
                          <div className="automation-status-msg disabled">
                            Automation Paused
                          </div>
                          {!automationDiscordWebhookId && (
                            <div className="automation-status-msg">
                              Select Discord Webhook to post from
                            </div>
                          )}
                          {automationDiscordWebhookId && (
                            <div className="hvr-shrink">
                              <button
                                type="button"
                                className="btn btn-success btn-sm"
                                onClick={() =>
                                  handleSetAutomationDiscordEnabled(true)
                                }
                              >
                                Enable
                              </button>
                            </div>
                          )}
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

const AutomationControlsTwitter = ({ twitterAuths }) => {
  const [bot, setBot] = useContext(BotContext);
  const [stats] = useContext(StatsContext);

  const [automationTwitterEnabled, setAutomationTwitterEnabled] = useState(
    bot?.automationTwitterEnabled || false
  );
  const [automationTwitterAuthId, setAutomationTwitterAuthId] = useState(
    bot?.automationTwitterAuthId || null
  );

  const handleAuthSelect = (auth) => {
    const newAuthId = auth.twitterAuthId;
    if (bot.automationTwitterAuthId !== newAuthId) {
      setBot({
        ...bot,
        automationTwitterAuthId: newAuthId,
      });
    }
    setAutomationTwitterAuthId(newAuthId);
  };

  const handleSetAutomationTwitterEnabled = (isEnabled) => {
    if (bot.automationTwitterEnabled !== isEnabled) {
      setBot({
        ...bot,
        automationTwitterEnabled: isEnabled,
      });
    }
    setAutomationTwitterEnabled(isEnabled);
  };

  let twitterBotLimitReached = false;
  // limit to how many active twitter automated bots can be running
  if (
    stats &&
    stats.botsActiveTwitterAutomationCount >=
      stats.constants.maxTwitterEnabledBots
  ) {
    twitterBotLimitReached = true;
  }

  return (
    <motion.div //updated the div tag
      animate={{ x: 0 }}
      initial={{ x: -150 }}
    >
      <div className="row tweet-automation position-relative z-100">
        <div className="col-12 mb-3 pt-3 pl-2 pr-2">
          <h2 className="heading">Twitter Automation</h2>
          <div className="row mt-3 d-flex align-items-center justify-content-center">
            <div className="col-12 mb-2">
              <div className="max-w-1000px text-center m-auto">
                When a sale occurs we'll automatically send a Tweet out from
                your account.
                <br></br>It's as simple as set and forget - we'll take care of
                everything else.
                <br></br>
                <br></br>Note: Due to Twitter API restrictions you will be
                limited to 50 Sales Bot Tweets per day.<br></br>This restriction
                doesn't effect Discord Automation.
              </div>
              {twitterAuths.length === 0 && (
                <motion.div
                  animate={{
                    scale: [0, 1.07, 1],
                    opacity: [0, 1],
                  }}
                >
                  <div className="col-12">
                    <div className="not-authed-twitter">
                      <div>
                        You must have an authenticated Twitter account to use
                        this feature{" "}
                      </div>
                      <div>
                        Visit the{" "}
                        {/* <a target="_blank" href="/settings">
                          settings
                        </a>{" "} */}
                        <Link className="col-g" to={"/settings"}>
                          settings
                        </Link>{" "}
                        page to add an account
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </div>
          </div>
        </div>
        {twitterAuths.length > 0 && (
          <motion.div
            animate={{
              scale: [0, 1.07, 1],
              opacity: [0, 1],
            }}
          >
            <div className="row">
              <div className="col-12 z-100 text-center">
                <div className="basic-form">
                  <form>
                    <div className="form-group mb-3 pb-1">
                      <label>Twitter account to post from:</label>
                      <TwitterAuthSelectDropdown
                        twitterAuths={twitterAuths}
                        onSelect={handleAuthSelect}
                        selectedTwitterAuth={twitterAuths.find(
                          (auth) =>
                            auth.twitterAuthId === automationTwitterAuthId
                        )}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-center text-center pb-2">
                  <AnimatePresence>
                    {automationTwitterEnabled && (
                      <motion.div
                        animate={{
                          scale: [0, 1.07, 1],
                          opacity: [0, 1],
                        }}
                      >
                        <div className="d-flex flex-column">
                          <div className="automation-status-msg enabled">
                            Automation Enabled
                          </div>
                          <img
                            className="automation-status-img"
                            src={blueWaveAnim}
                            width={100}
                          />
                          <div className="hvr-shrink">
                            <button
                              type="button"
                              className="btn btn-danger btn-xs"
                              onClick={() =>
                                handleSetAutomationTwitterEnabled(false)
                              }
                            >
                              Pause
                            </button>
                          </div>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <AnimatePresence>
                    {!automationTwitterEnabled && (
                      <motion.div
                        animate={{
                          scale: [0, 1.07, 1],
                          opacity: [0, 1],
                        }}
                      >
                        <div className="d-flex flex-column">
                          <div className="automation-status-msg disabled">
                            Automation Paused
                          </div>
                          {!automationTwitterAuthId && (
                            <div className="automation-status-msg">
                              Select Twitter account to post from
                            </div>
                          )}
                          {!twitterBotLimitReached &&
                          automationTwitterAuthId ? (
                            <div className="hvr-shrink">
                              <button
                                type="button"
                                className="btn btn-success btn-sm"
                                onClick={() =>
                                  handleSetAutomationTwitterEnabled(true)
                                }
                              >
                                Enable
                              </button>
                            </div>
                          ) : null}
                          {/* user cannot enable automation because limit reached */}
                          {twitterBotLimitReached && automationTwitterAuthId ? (
                            <div className="automation-disabled-msg">
                              Sorry our platform limit for Twitter sales bots
                              has been reached.
                              <br />
                              <a
                                href="https://discord.gg/vPSuTs3qz4"
                                target="_blank"
                                className="col-g"
                              >
                                Contact us
                              </a>{" "}
                              to discuss removing this limit.
                            </div>
                          ) : null}
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

const CollectionImage = ({ collectionImage }) => {
  return (
    <>
      <AnimatePresence>
        {collectionImage && (
          <motion.div
            animate={{
              scale: [0, 1.07, 1],
              rotate: [-90, 3, 0],
              opacity: [0, 1],
            }}
            exit={{
              scale: [1, 1.07, 0],
              rotate: [0, 3, -90],
            }}
          >
            <img
              className="img-fluid mr-05 br-50  border-green"
              width={42}
              src={collectionImage}
            />
          </motion.div>
        )}
      </AnimatePresence>
      {!collectionImage && (
        <img
          className="img-fluid mr-05 br-50  border-green opacity-0"
          width={42}
          src={blueCircleImg}
        />
      )}
    </>
  );
};

const BotControls = () => {
  const [bot, setBot] = useState(null);
  const [stats, setStats] = useState(null);
  const [selectedBotId, setSelectedBotId] = useState(null);
  const [contractAddress, setContractAddress] = useState("");
  const [formStep, setFormStep] = useState(0);
  const { isAuth, userId } = useContext(AuthContext);
  const { alchemySDK } = useContext(AlchemyContext);
  const [userBots, setUserBots] = useState([]);
  const [twitterAuths, setTwitterAuths] = useState([]);
  const [discordWebhooks, setDiscordWebhooks] = useState([]);
  const [blockEvents, setBlockEvents] = useState([]);
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    // Clear the timeout when the component unmounts
    return () => clearTimeout(timeoutIdRef.current);
  }, []);

  const saveBot = async () => {
    console.log(`saveBot`, bot);
    delayedSaveBot(); // waits 1.9 sec to exec (so new request isn't overloaded with fast input changes)
  };

  const actualSaveBot = async () => {
    try {
      const api = new ApiService();
      const response = await api.post(`blocks/salesBot/saveBot`, {
        userId,
        bot,
      });
      console.log(`saveBot()`, response);
      if (response.error || !response.userBots) {
        toast.error(response.error);
      } else {
        toast.success("Changes saved");
        setUserBots(response.userBots);
        setStats(response.stats);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // waits 1.9 secs to display 'Changes Saved' toast
  const delayedSaveBot = () => {
    // Clear any existing timeouts
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    // Set a timeout to call a function after 1.9 seconds
    const newTimeoutId = setTimeout(async () => {
      actualSaveBot();
    }, 1900);

    // Update the timeout ID ref
    timeoutIdRef.current = newTimeoutId;
  };

  useEffect(() => {
    if (bot && Object.keys(bot).length > 0) {
      const existing = userBots.find((b) => b.salesBotId === bot.salesBotId);
      // only save if bot has changed from stored ones
      if (existing && existing !== bot) {
        saveBot();
      }
      // fetch any missing collection info
      if (isValidEthAddress(bot.contractAddress) && !bot.collectionName) {
        if (contractAddress !== bot.contractAddress) {
          setContractAddress(bot.contractAddress.toLowerCase());
        }
      }
    }
  }, [bot]);

  const fetchCollectionInfo = async () => {
    if (!bot || !bot.contractAddress) {
      console.log(
        `fetchCollectionInfo() - returning because (!bot || !bot.contractAddress)`
      );
      return;
    }
    console.log(`fetchCollectionInfo()`);
    // valid eth address - refresh collection info
    if (isValidEthAddress(bot.contractAddress)) {
      const api = new ApiService();
      const data = await api.get(
        `collection/info/${bot.contractAddress.trim()}`
      );
      if (data) {
        // valid collection
        console.log(`collectionInfo`, data);
        setBot({
          ...bot,
          collectionImage: data.image,
          collectionName: data.name,
          collectionSymbol: data.symbol,
          collectionTokenType: data.tokenType,
          collectionTotalSupply: data.totalSupply,
        });
      }
      // no collection info for contract
      else if (bot.collectionName) {
        setBot({
          ...bot,
          collectionImage: null,
          collectionName: null,
          collectionSymbol: null,
          collectionTokenType: null,
          collectionTotalSupply: null,
        });
      }
    }
    // invalid eth address
    else {
      // only set to null if bot has these values set
      if (bot.collectionName) {
        setBot({
          ...bot,
          collectionImage: null,
          collectionName: null,
          collectionSymbol: null,
          collectionTokenType: null,
          collectionTotalSupply: null,
        });
      }
    }
  };

  const fetchTwitterAuths = () => {
    const api = new ApiService();
    api.get(`user/twitter/getAuths/${userId}`).then((data) => {
      setTwitterAuths(data);
      console.log(`twitterAuths`, data);
    });
  };

  useEffect(() => {
    if (isAuth && userId && !twitterAuths.length) {
      fetchTwitterAuths();
    }
  }, [userId, isAuth, twitterAuths.length]);

  const fetchDiscordWebhooks = () => {
    const api = new ApiService();
    api.get(`user/discord/webhook/get/${userId}`).then((data) => {
      setDiscordWebhooks(data);
      console.log(`discordWebhooks`, data);
    });
  };

  useEffect(() => {
    if (isAuth && userId && !discordWebhooks.length) {
      fetchDiscordWebhooks();
    }
  }, [userId, isAuth, discordWebhooks.length]);

  useEffect(() => {
    console.log(`selectedBotId changed`, selectedBotId);
    // set form stage based on whether contract address is set
    if (selectedBotId && bot && bot.collectionName) {
      setFormStep(1); // go to the tweet designer stage
    } else {
      setFormStep(0); // go to the collection edit
      if (bot && !bot.collectionName) {
        // console.log(
        //   `fetchCollectionInfo() - called from: selectedBotId changed`
        // );
        // fetchCollectionInfo(); // fetch missing collection info
      }
    }
  }, [selectedBotId]);

  const handleSelectBot = (bot) => {
    if (bot.salesBotId === selectedBotId) {
      // bot already selected so unselect
      setBot(null);
      setSelectedBotId(null);
    } else {
      // select bot
      setBot(bot);
      setSelectedBotId(bot.salesBotId);
    }
  };

  const fetchUserBots = async () => {
    try {
      const api = new ApiService();
      const response = await api.get(`blocks/salesBot/getBots/${userId}`);
      console.log(`fetchUserBots()`, response);
      setUserBots(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isAuth || !userId) {
      setUserBots([]);
      return;
    }
    fetchUserBots();
  }, [isAuth, userId]);

  const fetchStats = async () => {
    try {
      const api = new ApiService();
      const response = await api.get(`blocks/salesBot/stats`);
      console.log(`fetchStats()`, response);
      setStats(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!stats) {
      fetchStats();
    }
  }, []);

  const fetchBlockEvents = async () => {
    try {
      const api = new ApiService();
      const response = await api.get(`blocks/salesBot/getEvents/${userId}`);
      console.log(`fetchBlockEvents()`, response);
      setBlockEvents(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isAuth || !userId) {
      setBlockEvents([]);
      return;
    }
    fetchBlockEvents();
  }, [isAuth, userId]);

  const addNewBot = async () => {
    try {
      const api = new ApiService();
      const response = await api.post(`blocks/salesBot/addBot`, { userId });
      console.log(`addNewBot()`, response);
      if (response.error || !response.userBots) {
        toast.error(response.error);
      } else {
        toast.success("New bot created");
        setUserBots(response.userBots);
        setStats(response.stats);
        // select new bot
        setBot(response.bot);
        setSelectedBotId(response.bot.salesBotId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const confirmDeleteBotAlert = (botId) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "Any bots using this Twitter will be disabled",
      showCancelButton: true,
      confirmButtonColor: "#EB5757",
      cancelButtonColor: "#adadad",
      confirmButtonText: "Delete Bot",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBot(botId);
      }
    });
  };

  const deleteBot = async (botId) => {
    try {
      const api = new ApiService();
      const response = await api.post(`blocks/salesBot/deleteBot`, {
        userId,
        botId,
      });
      console.log(`deleteBot() - ${botId}`, response);
      if (response.error || !response.userBots) {
        toast.error(response.error);
      } else {
        toast.success("Bot deleted");
        // unselect bot
        setBot(null);
        setSelectedBotId(null);
        // set new user bots & stats
        setUserBots(response.userBots);
        setStats(response.stats);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const UserBots = () => {
    return (
      <Col lg={12}>
        <Card className="mb-3 h-fc bot-table">
          <Card.Header>
            <Card.Title>Your Bots</Card.Title>
            <div className="hvr-shrink">
              <button className="btn btn-primary" onClick={addNewBot}>
                <span className="me-2">
                  {" "}
                  <i className="fa fa-plus"></i>{" "}
                </span>
                NEW BOT
              </button>
            </div>
          </Card.Header>
          <Card.Body>
            {userBots.length === 0 && (
              <div className="no-userBots-msg">
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={[
                    "You have no bots",
                    2600,
                    "You are botless",
                    2600,
                    "The total supply of your bots is 0",
                    2600,
                    "You are in a bot-free zone",
                    2600,
                    "Bot inventory critically low",
                    2600,
                    "Zero bots, infinite potential",
                    2600,
                    "Fresh out of bots",
                    2600,
                    "Bot count: 0",
                    2600,
                    "Bots? Not here",
                    2600,
                    "You still have no bots",
                    2600,
                    "Bots. Get some.",
                    2600,
                  ]}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </div>
            )}
            {userBots.length > 0 && (
              <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <strong>Collection</strong>
                    </th>
                    <th>
                      <strong>Token Type</strong>
                    </th>
                    <th>
                      <strong>Discord Automation</strong>
                    </th>
                    <th>
                      <strong>Twitter Automation</strong>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {userBots.map((bot, key) => {
                    return (
                      <tr
                        className={
                          selectedBotId && selectedBotId === bot.salesBotId
                            ? `selected`
                            : ``
                        }
                        key={`userBot_${key}`}
                        onClick={() => handleSelectBot(bot)}
                      >
                        <td>
                          <div className="d-flex align-items-center">
                            {bot.collectionName && (
                              <>
                                <img
                                  src={bot.collectionImage}
                                  className="me-2 br-50  border-green"
                                  width="28"
                                  alt=""
                                />{" "}
                                <span className="w-space-no">
                                  {bot.collectionName}
                                </span>
                              </>
                            )}
                            {!bot.collectionName && (
                              <span className="w-space-no">Not Set</span>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            {bot.collectionName &&
                            bot.collectionChain === "ethereum" ? (
                              <img
                                className="eth-logo"
                                width={11}
                                src={ethGreenImg}
                              />
                            ) : (
                              ""
                            )}
                            {bot.collectionTokenType === "ERC721" && (
                              <div className="badge bg-light">ERC 721</div>
                            )}
                            {bot.collectionTokenType === "ERC1155" && (
                              <div className="badge bg-warning">ERC 1155</div>
                            )}
                            {bot.collectionTokenType === "Other" && (
                              <div className="badge bg-dark">N/A</div>
                            )}
                          </div>
                        </td>
                        <td>
                          {bot.automationDiscordEnabled && (
                            <div className="d-flex align-items-center">
                              <i className="fa fa-circle text-success me-1"></i>{" "}
                              Enabled
                            </div>
                          )}
                          {!bot.automationDiscordEnabled && (
                            <div className="d-flex align-items-center">
                              <i className="fa fa-circle text-danger me-1"></i>{" "}
                              Paused
                            </div>
                          )}
                        </td>
                        <td>
                          {bot.automationTwitterEnabled && (
                            <div className="d-flex align-items-center">
                              <i className="fa fa-circle text-success me-1"></i>{" "}
                              Enabled
                            </div>
                          )}
                          {!bot.automationTwitterEnabled && (
                            <div className="d-flex align-items-center">
                              <i className="fa fa-circle text-danger me-1"></i>{" "}
                              Paused
                            </div>
                          )}
                        </td>
                        <td>
                          <div className="d-flex">
                            {/* <Link
                href="#"
                className="btn btn-primary shadow btn-xs sharp me-1"
              >
                <i className="fas fa-pencil-alt"></i>
              </Link> */}
                            <div className="hvr-shrink">
                              <div
                                onClick={() =>
                                  confirmDeleteBotAlert(bot.salesBotId)
                                }
                                className="btn btn-danger btn-xs sharp"
                              >
                                <i className="fa fa-trash"></i>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
      </Col>
    );
  };
  return (
    <StatsContext.Provider value={[stats, setStats]}>
      <BotContext.Provider value={[bot, setBot]}>
        <UserBots />
        <BotEditor
          formStep={formStep}
          setFormStep={setFormStep}
          alchemySDK={alchemySDK}
          twitterAuths={twitterAuths}
          discordWebhooks={discordWebhooks}
          fetchCollectionInfo={fetchCollectionInfo}
          contractAddress={contractAddress}
          setContractAddress={setContractAddress}
        />
        <BlockEventsTable events={blockEvents} />
      </BotContext.Provider>
    </StatsContext.Provider>
  );
};

const BotEditor = ({
  formStep,
  setFormStep,
  contractAddress,
  setContractAddress,
  discordWebhooks,
  twitterAuths,
  fetchCollectionInfo,
}) => {
  const [bot, setBot] = useContext(BotContext);

  console.log(`BotEditor Refresh - bot`, bot);
  useEffect(() => {
    if (!bot || !bot.contractAddress) {
      return; // might cause error if contractAddy had been set previously then deleted
    }
    console.log(
      `fetchCollectionInfo() - called from: contract address changed`
    );
    fetchCollectionInfo();
  }, [contractAddress]);

  // const handlbotEdit = (event) => {
  //   setData({
  //     ...bot,
  //     [event.target.name]: event.target.value,
  //   });
  // }
  // accompanied w/ :
  // <input name="name" value={data.name} onChange={handleChange} />

  const handleContractAddressChange = (event) => {
    const newContractAddress = event.target ? event.target.value.trim() : event;
    console.log(`newContractAddress`, newContractAddress);
    if (bot.contractAddress !== newContractAddress) {
      setBot({
        ...bot,
        contractAddress: newContractAddress,
      });
    }
    setContractAddress(newContractAddress);
  };

  return (
    <AnimatePresence>
      {bot !== null && (
        <motion.div
          animate={{
            x: [-500, 0],
            // scale: [0, 1],
            opacity: [0, 1],
          }}
        >
          <div className="card mb-3 h-fc">
            <div className="card-header d-flex justify-content-start">
              <CollectionImage collectionImage={bot?.collectionImage} />
              <h4 className="card-title">Edit Bot</h4>
            </div>
            <div className="card-body">
              <div className="form-wizard">
                <Stepper className="nav-wizard mb-1" activeStep={formStep}>
                  <Step
                    label="Collection"
                    className="nav-link"
                    onClick={() => setFormStep(0)}
                  />
                  <Step
                    label="Discord Automation"
                    className="nav-link"
                    onClick={() => setFormStep(1)}
                  />
                  <Step
                    label="Twitter Automation"
                    className="nav-link"
                    onClick={() => setFormStep(2)}
                  />
                </Stepper>

                {formStep === 0 && (
                  <>
                    <CollectionEditor
                      handleContractAddressChange={handleContractAddressChange}
                    />
                    {bot?.collectionName && (
                      <div className="text-end toolbar toolbar-bottom p-2">
                        <div className="hvr-shrink">
                          <button
                            className="btn btn-primary sw-btn-next"
                            onClick={() => setFormStep(1)}
                          >
                            Discord Automation{" "}
                            <i className="fa fa-arrow-right ml-05"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {formStep === 1 && (
                  <>
                    <AutomationControlsDiscord
                      discordWebhooks={discordWebhooks}
                    />
                    <div className="text-end toolbar toolbar-bottom p-2 pl-0 pr-0 d-flex justify-content-between">
                      <div className="hvr-shrink">
                        <button
                          className="btn btn-secondary sw-btn-prev"
                          onClick={() => {
                            setFormStep(0);
                          }}
                        >
                          <i className="fa fa-arrow-left mr-05"></i> Collection
                        </button>
                      </div>
                      <div className="hvr-shrink">
                        <button
                          className="btn btn-primary sw-btn-next"
                          onClick={() => setFormStep(2)}
                        >
                          Twitter Automation{" "}
                          <i className="fa fa-arrow-right ml-05"></i>
                        </button>
                      </div>

                      {/* <div className="d-flex">
                        <div className="hvr-shrink">
                          <button
                            className="btn btn-success sw-btn-prev me-1 mr-05"
                            onClick={() => {}}
                          >
                            Save <i className="fa fa-check ml-05"></i>
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </>
                )}
                {formStep === 2 && (
                  <>
                    <AutomationControlsTwitter twitterAuths={twitterAuths} />
                    <div className="text-end toolbar toolbar-bottom p-2 pl-0 pr-0 d-flex justify-content-between">
                      <div className="hvr-shrink">
                        <button
                          className="btn btn-secondary sw-btn-prev"
                          onClick={() => {
                            setFormStep(1);
                          }}
                        >
                          <i className="fa fa-arrow-left mr-05"></i> Discord
                          Automation
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const SalesBot = (props) => {
  const BotHeading = () => {
    const [animate, setAnimate] = useState(false);
    const tweetLoadAnim = useAnimation();
    const tweetLoaded = () => {};

    async function startAnimation() {
      await tweetLoadAnim.start({
        opacity: 1,
        scale: 1,
        transition: { duration: 0.2 },
      });
    }

    return (
      <div className="row">
        <div className="col-lg-8">
          <BlockCard block={Blocks.NFTSalesBot} />
          <AuthOnly>
            <motion.div animate={{ x: 0 }} initial={{ x: -1000 }}>
              <BotControls />
            </motion.div>
          </AuthOnly>
        </div>
        <div className="col-lg-4">
          <div className="spottie-tweet-example-card position-relative">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={tweetLoadAnim}
            >
              <div className="bot-example-card-badge">
                Sales Bot Example Tweet
              </div>
              <TwitterTweetEmbed
                onLoad={function noRefCheck() {
                  console.log(`setShowExampleTweet()`);
                  startAnimation();
                }}
                tweetId={"1618922778933043208"}
                options={{ theme: "dark" }}
              />
            </motion.div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {/* <button disabled={isLoading} onClick={() => signMessage()}>
        Sign message
      </button>
      {isSuccess && <div>Signature: {data}</div>}
      {isError && <div>Error signing message</div>} */}
      <PageHeaderBar
        pages={[
          { title: "Blocks", url: `/blocks` },
          { title: "NFT Sales Bot", url: `/blocks/salesbot` },
        ]}
      />
      <BotHeading />
    </Fragment>
  );
};

export default SalesBot;
