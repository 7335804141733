import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { Stepper, Step } from "react-form-stepper";
import { Link } from "react-router-dom";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import toast from "react-hot-toast";
import { Row, Col, Card, Table, Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { AlchemyContext } from "../../../Providers/AlchemyProvider";
import { TwitterTweetEmbed, TwitterShareButton } from "react-twitter-embed";
import blueWaveAnim from "../../../../images/misc/green-wave-anim.svg";
import BlockEventsTable from "../BlockEventsTable";

import {
  calculateTwitterCharacters,
  convertStringToHtml,
  convertStringToTwitterHtml,
  fileExtensionIsImage,
  getRandomIntInRange,
  makeReplacementsToString,
  stripNonNumeric,
  timeUntil,
  truncateAddress,
} from "../../../../utils";

import avatar1 from "../../../../images/avatar/1.jpg";
import AuthOnly from "../../AuthOnly";
import ApiService from "../../../../services/ApiService";
import { AuthContext } from "../../../Providers/AuthProvider";
import { isValidEthAddress } from "../../../utils";
import TwitterAuthSelectDropdown from "../TwitterAuthSelectDropdown";
import EmojiPicker from "../EmojiPicker";

import { Blocks } from "../blocks";
import BlockCard from "../BlockCard";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import { TypeAnimation } from "react-type-animation";
import { MultiSelect } from "primereact/multiselect";

const dailyTimeOptions = [
  {
    name: "00:00",
    value: "0",
  },
  {
    name: "01:00",
    value: "1",
  },
  {
    name: "02:00",
    value: "2",
  },
  {
    name: "03:00",
    value: "3",
  },
  {
    name: "04:00",
    value: "4",
  },
  {
    name: "05:00",
    value: "5",
  },
  {
    name: "06:00",
    value: "6",
  },
  {
    name: "07:00",
    value: "7",
  },
  {
    name: "08:00",
    value: "8",
  },
  {
    name: "09:00",
    value: "9",
  },
  {
    name: "10:00",
    value: "10",
  },
  {
    name: "11:00",
    value: "11",
  },
  {
    name: "12:00",
    value: "12",
  },
  {
    name: "13:00",
    value: "13",
  },
  {
    name: "14:00",
    value: "14",
  },
  {
    name: "15:00",
    value: "15",
  },
  {
    name: "16:00",
    value: "16",
  },
  {
    name: "17:00",
    value: "17",
  },
  {
    name: "18:00",
    value: "18",
  },
  {
    name: "19:00",
    value: "19",
  },
  {
    name: "20:00",
    value: "20",
  },
  {
    name: "21:00",
    value: "21",
  },
  {
    name: "22:00",
    value: "22",
  },
  {
    name: "23:00",
    value: "23",
  },
];

const automationIntervalOptions = [
  {
    name: "24 hours",
    value: "24 hours",
  },
  {
    name: "48 hours",
    value: "48 hours",
  },
  {
    name: "72 hours",
    value: "72 hours",
  },
  {
    name: "Weekly",
    value: "Weekly",
  },
];

const traitCountOptions = [
  {
    name: "1",
    value: "1",
  },
  {
    name: "2",
    value: "2",
  },
  {
    name: "3",
    value: "3",
  },
  {
    name: "4",
    value: "4",
  },
  {
    name: "5",
    value: "5",
  },
  {
    name: "All",
    value: "All",
  },
];

const BotContext = createContext({});
//const [selectedCollections, setSelectedCollections] = useState(null);

const CollectionSelector = ({ alchemySDK }) => {
  const [bot, setBot] = useContext(BotContext);
  const [selectedCollections, setSelectedCollections] = useState(
    bot?.selectedCollections || []
  );
  const [allCollections, setAllCollections] = useState([]);
  const { address } = useContext(AuthContext);

  const getCollectionsFromUser = () => {
    let uniqueCollections = [];
    alchemySDK.nft.getContractsForOwner(bot?.walletAddress).then((data) => {
      //const ownedNfts = data.ownedNfts;
      console.log(data);
      data.contracts.forEach((element) => {
        uniqueCollections.push({
          address: element.address,
          image: element.openSea.imageUrl,
          name: element.name,
          noOwned: element.totalBalance,
        });
      });
      setAllCollections(uniqueCollections); // update state with unique collections
    });
  };

  const getCollectionMetadata = () => {};

  // const handleCheckboxChange = (event) => {
  //   setSelectedCollections({
  //     ...selectedCollections,
  //     [event.target.name]: event.target.checked,
  //   });
  // };

  useEffect(() => {
    if (isValidEthAddress(bot?.walletAddress)) {
      console.log("Changed Address");
      getCollectionsFromUser(); // call getCollectionsFromUser to update allCollections state
    } else {
      setAllCollections([]);
    }
  }, [bot?.walletAddress]); // re-run the effect when the address changes

  useEffect(() => {
    console.log("Setting Selected Collections");
    if (selectedCollections !== null) {
      setBot({
        ...bot,
        selectedCollections: selectedCollections,
      });
    }

    console.log(bot);
  }, [selectedCollections]); // re-run the effect when the address changes

  const collectionImageDisplay = (option) => {
    return (
      <div className="d-flex align-items-center flexbot-multi-select-col-info">
        <img
          alt={option.name}
          src={option.image}
          className="col-img br-50 border-green"
          style={{ width: "38px", marginRight: "8px" }}
        />
        <div className="d-flex flex-column">
          <div className="col-name">{option.name}</div>
          <div className="col-no-owned">
            {option.noOwned} NFT{option.noOwned === 1 ? "" : "s"}
          </div>
        </div>
      </div>
    );
  };
  const panelFooterTemplate = () => {
    const length = selectedCollections ? selectedCollections.length : 0;

    return (
      <div className="py-2 px-3 flexbot-multi-select-footer">
        <b>{length}</b> collection{length === 1 ? "" : "s"} selected
      </div>
    );
  };
  console.log("allCollections", allCollections);
  console.log("selectedCollections", selectedCollections);
  return (
    <motion.div animate={{ x: 0 }} initial={{ x: -150 }}>
      <div className="card flex justify-content-center text-center multi-select">
        <div style={{ marginBottom: "8px" }}>Select Collections</div>
        {allCollections && (
          <MultiSelect
            placeholder="Select Collections"
            value={selectedCollections}
            onChange={(e) => setSelectedCollections(e.value)}
            options={allCollections.sort((a, b) => b.noOwned - a.noOwned)}
            optionLabel="name"
            itemTemplate={collectionImageDisplay}
            filter
            // display="chip"
            // maxSelectedLabels={3}
            panelFooterTemplate={panelFooterTemplate}
            className="w-full md:w-20rem collectionMultiSelect multi-select"
          />
        )}

        {/* <div className="container collectionContainer">
        <div className="row">
        {allCollections.map((item) => (
          <div className="col-sm-4 collectionSelector">
          <label key={item.address}>
            <img className="collectionImage" src={item.image} alt="Contract Image"></img>                  
            {item.name}
            <input
              type="checkbox"
              name={item.address}
              checked={selectedCollections[item]}
              onChange={handleCheckboxChange}
            /> 
            <span className="checkmark"></span>
          </label>
          </div>
        ))}
        </div>
      </div> */}
      </div>
    </motion.div>
  );
};

const TweetDesigner = ({ alchemySDK }) => {
  const [bot, setBot] = useContext(BotContext);

  const [tweetHeader, setTweetHeader] = useState(bot?.tweetHeader || "");
  const [tweetFooter, setTweetFooter] = useState(bot?.tweetFooter || "");
  const [tweetShowTraits, setTweetShowTraits] = useState(
    bot?.tweetShowTraits || true
  );
  const [tweetTraitsToShow, setTweetTraitsToShow] = useState(
    bot?.tweetTraitsToShow || "3"
  );
  const [tokenRarity, setTokenRarity] = useState(null);
  const [tokenMedia, setTokenMedia] = useState(null);
  const [tokenId, setTokenId] = useState(0);
  const [ownedIds, setOwnedIds] = useState(0);
  const initialCollection =
    bot && bot.selectedCollections && bot.selectedCollections.length
      ? bot?.selectedCollections[0].address
      : null;
  const [currentCollection, setCurrentCollection] = useState(initialCollection);
  //const [allOwnedCollections,setAllOwnedCollections] = useState(getAllOwnedCollections())
  const mediaIsImage = tokenMedia
    ? fileExtensionIsImage(tokenMedia.format)
    : true; // image by default
  // if its not image we assume its a video
  // TODO: Handle other cases

    // getAllOwnedCollections = async () => {
    //   let allNfts = [];
    //   let {nfts, pageKey} = await alchemy[chain].nft.getNftsForContract(contractAddr)
    //   allNfts = allNfts.concat(nfts);
    //   // console.log(allNfts.length, pageKey)
    //   while(pageKey) {
    //     // console.log(`more NFTs`);
    //     const {nfts: _nfts, pageKey: _pageKey} = await alchemy[chain].nft.getNftsForContract(contractAddr, {pageKey})
    //     pageKey = _pageKey
    //     allNfts = allNfts.concat(_nfts);
    //     // console.log(`pageKey`, pageKey);
    //   }
    //   console.log(`${DEBUG_PREFIX}getNFTsForCollection() - ${contractAddr} (chain: ${chain}) - ${allNfts.length} NFTs found`);
    //   return allNfts;
    // };




  useEffect(() => {
    try {
      console.log("Current Collection - " + currentCollection);
      try {
        alchemySDK.nft
          .computeRarity(currentCollection, tokenId)
          .then((data) => {
            console.log(`setTokenRarity`, data);
            // sort by rarity
            data.sort((a, b) => {
              if (a.prevalence > b.prevalence) {
                return 1;
              } else if (b.prevalence > a.prevalence) {
                return -1;
              }
              return 0;
            });
            setTokenRarity(data);
          });
      } catch (error) {}

      alchemySDK.nft.getNftMetadata(currentCollection, tokenId).then((data) => {
        console.log(`setTokenMedia`, data);
        // get media url (relies on alchemy sdk cacheing it)
        const mediaUrl = data.media[0]; // we are after format (file type) and gateway (url)
        setTokenMedia(mediaUrl);
      });
    } catch (error) {
      console.log(`error`, error);
    }
  }, [tokenId]);

  const handleTokenIdChange = (event) => {
    const newTokenId = stripNonNumeric(event.target.value.trim());
    if (tokenId !== newTokenId) {
      setTokenId(newTokenId);
    }
  };

  const getOwnedIdsForCollection = async (collectionAddress) => {
    let ownedIds = [];
    let response = await alchemySDK.nft.getNftsForOwner(bot?.walletAddress, {
      contractAddresses: [collectionAddress],
    });
    response.ownedNfts.forEach((contract) => {
      console.log(contract);
      ownedIds.push(contract.tokenId);
    });
    setOwnedIds(ownedIds);
    setTokenId(ownedIds[0]);
  };

  const generateRandomNFT = async () => {
    if (!bot) {
      return;
    }
    let allData = [];
      let response = await alchemySDK.nft.getNftsForOwner(bot?.walletAddress, {
        contractAddresses: bot?.selectedCollections.map(a => a.address),
        withMetadata:false
      });
      console.log(response);
      response.ownedNfts.forEach((contract) => {
        allData.push({
          contractAddress: contract.address,
          tokenId: contract.tokenId
        });
      });
      console.log(allData);
    const min = 0;
    const max = parseInt(allData.length) - 1;
    const random = getRandomIntInRange(min, max);
    setTokenId(allData[random].tokenId);
    setCurrentCollection(allData[random].contractAddress);
  };

  useEffect(() => {
    getOwnedIdsForCollection(currentCollection);
  }, [currentCollection]);


  const handleTweetHeaderEmojiClick = (emoji) => {
    handleTweetHeaderChange(`${tweetHeader}${emoji.native}`);
  };

  const handleTweetFooterEmojiClick = (emoji) => {
    handleTweetFooterChange(`${tweetFooter}${emoji.native}`);
  };

  const handleTweetHeaderChange = (event) => {
    const newTweetHeader = event.target ? event.target.value : event; // so we can feed emoji into this function
    if (bot.tweetHeader !== newTweetHeader) {
      setBot({
        ...bot,
        tweetHeader: newTweetHeader,
      });
    }
    setTweetHeader(newTweetHeader);
  };

  const handleTweetFooterChange = (event) => {
    const newtweetFooter = event.target ? event.target.value : event; // so we can feed emoji into this function
    if (bot.tweetFooter !== newtweetFooter) {
      setBot({
        ...bot,
        tweetFooter: newtweetFooter,
      });
    }
    setTweetFooter(newtweetFooter);
  };

  const handleTweetTraitsToShowChange = (toShow) => {
    if (bot.tweetTraitsToShow !== toShow) {
      setBot({
        ...bot,
        tweetTraitsToShow: toShow,
      });
    }
    setTweetTraitsToShow(toShow);
  };

  const handleTweetShowTraitsChange = () => {
    const newTweetShowTraits = !tweetShowTraits;
    if (bot.tweetShowTraits !== newTweetShowTraits) {
      setBot({
        ...bot,
        tweetShowTraits: newTweetShowTraits,
      });
    }
    setTweetShowTraits(newTweetShowTraits);
  };

  const traitsText = () => {
    if (!tweetShowTraits || !tokenRarity || tokenRarity.length === 0) {
      return ``;
    }
    let traitCount = tokenRarity.length; // default (all)
    if (tweetTraitsToShow && tweetTraitsToShow !== "All") {
      traitCount = parseInt(tweetTraitsToShow);
    }
    const traitsArr = tokenRarity.slice(0, traitCount);
    return `${traitsArr
      .map(
        (element) =>
          `${element.traitType}: ${element.value} (${(
            element.prevalence * 100
          ).toFixed(2)}%)`
      )
      .join("\n")}`;
  };
  const tweetReplacements = {
    "[tokenId]": tokenId,
    "[collectionName]": bot?.selectedCollections.find(
      (x) => x.address === currentCollection
    ).name,
    "[collectionSymbol]": bot?.collectionSymbol,
    "[artworkName]": bot?.name,
  };
  const traitsStr = traitsText();
  const traitsHtml = convertStringToHtml(traitsStr);
  const tweetHeaderStr =
    tweetHeader && tweetHeader.length
      ? `\n${makeReplacementsToString(tweetHeader, tweetReplacements)}`
      : "";
  const traitsFinalStr =
    traitsStr.length > 0
      ? `${tweetHeaderStr.length > 0 ? `\n` : ``}${traitsStr}`
      : "";
  const tweetFooterStr =
    tweetFooter && tweetFooter.length
      ? `\n${makeReplacementsToString(tweetFooter, tweetReplacements)}`
      : "";
  const openseaUrl = `https://opensea.io/assets/ethereum/${currentCollection}/${tokenId}`;
  const tweetContent = `${tweetHeaderStr}${traitsFinalStr}${tweetFooterStr}`;
  const characterCount = calculateTwitterCharacters(
    `${tweetContent}\n${openseaUrl}`
  );
  // console.log(`tweetContent`, tweetContent);

  const SaveNftFileButton = ({ tokenMedia }) => {
    if (!tokenMedia || !tokenMedia.gateway) {
      return;
    }

    return (
      <div>
        <motion.div
          animate={{
            scale: [0, 1.07, 1],
            opacity: [0, 1],
          }}
        >
          <div className="download-btn d-flex align-items-center w-fc ">
            <div className=" hvr-shrink">
              <a href={tokenMedia.gateway} target="_blank" rel="noreferrer">
                <i className="fa fa-file-arrow-down mr-05"></i>Save Image
              </a>
            </div>
          </div>
        </motion.div>
      </div>
    );
  };

  const TwitterShareButtonComponent = ({
    tweetContent,
    url = `https://betterblocks.io`,
  }) => {
    return (
      <div className="twitter-share-btn-sm">
        <motion.div
          animate={{
            scale: [0, 1.07, 1],
            opacity: [0, 1],
          }}
        >
          <TwitterShareButton
            onLoad={function noRefCheck() {}}
            options={{
              // size: "large",
              text: tweetContent,
              // via: 'saurabhnemade'
            }}
            url={url}
          />
        </motion.div>
      </div>
    );
  };

  // const handleSaveNftFile = () => {
  //   const url = tokenMedia.gateway;
  //   if (!url || !tokenMedia || !collectionInfo) {
  //     return;
  //   }
  //   const collectionSymbol = collectionInfo.symbol;
  //   const extension = tokenMedia.format;
  //   const fileName = `${collectionSymbol}-${tokenId}.${extension}`;
  //   downloadFileFromUrl(url, fileName);
  // };

  return (
    <motion.div //updated the div tag
      animate={{ x: 0 }}
      initial={{ x: -150 }}
    >
      <div className="row tweet-designer">
        <div className="col-12 mb-5 pt-3 pl-2 pr-2">
          <h2 className="heading">Tweet Designer</h2>
          <div className="row mt-6 d-flex align-items-center justify-content-center">
            <div className="input-group max-w-500px">
              <span className="input-group-text">Collection</span>
              <select
                className="form-control lh-1"
                onChange={(e) => setCurrentCollection(e.target.value)}
                value={currentCollection}
              >
                {bot?.selectedCollections?.map((item, key) => (
                  <option key={`sel_col_${key}`} value={item.address}>
                    {item.name}
                  </option>
                ))}
              </select>
              <span className="input-group-text">Token ID</span>
              <select
                className="form-control lh-1"
                onChange={(e) => handleTokenIdChange(e)}
                value={tokenId}
              >
                {ownedIds &&
                  ownedIds.map((item, key) => (
                    <option key={`owned_id_${key}`} value={item}>
                      {item}
                    </option>
                  ))}
              </select>
              {/* <input
                type="text"
                className="form-control"
                id="val-tokenId"
                placeholder=""
                name="tokenId"
                onChange={handleTokenIdChange}
                value={tokenId}
              ></input> */}
            </div>
            <div
              className="btn btn-primary light btn-xs mb-1 me-1 w-fc"
              onClick={() => generateRandomNFT()}
            >
              Random NFT
            </div>
          </div>
        </div>
        <div className="col-md-6 z-100">
          <div className="basic-form">
            <form>
              <div className="form-group mb-3">
                <div className="d-flex justify-content-between position-relative">
                  <label>Tweet Header:</label>
                  <EmojiPicker onEmojiSelect={handleTweetHeaderEmojiClick} />
                </div>
                <textarea
                  className="form-txtarea form-control"
                  rows="4"
                  id="tweetHeader"
                  onChange={handleTweetHeaderChange}
                  value={bot?.tweetHeader}
                ></textarea>
              </div>
              <div className="form-group mb-3">
                {/* <label>Traits:</label> */}
                <div className="form-group mb-3 ml-1">
                  <Row>
                    <Col
                      xl={12}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <div className="form-check custom-checkbox checkbox-info ml-1">
                        <input
                          type="checkbox"
                          checked={bot?.tweetShowTraits}
                          className="form-check-input"
                          id="showTraits"
                          onChange={handleTweetShowTraitsChange}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="showTraits"
                        >
                          Show Traits
                        </label>
                      </div>
                      <div className="d-flex align-items-center traits-to-show-container">
                        <div className="mr-05 traits-to-display-label">
                          Traits to display:
                          {/* <br />
                          (sorted by highest rarity) */}
                        </div>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant=""
                            className="ps-0 mt-1 mb-2"
                          >
                            <span className="traits-to-display-value">
                              {bot?.tweetTraitsToShow}
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {traitCountOptions.map((o, key) => {
                              return (
                                <Dropdown.Item
                                  key={`dropdown_item_${key}`}
                                  onClick={() =>
                                    handleTweetTraitsToShowChange(o.name)
                                  }
                                >
                                  {o.name}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col>
                  </Row>
                  {/* <div className="input-group">
                    <span className="input-group-text">
                      <i className="fa fa-clock" />{" "}
                    </span>
                    <select
                      defaultValue={"3"}
                      className="form-control rmono"
                      id="val-tweetTraitsToShow"
                      name="tweetTraitsToShow"
                      onChange={handleTweetTraitsToShowChange}
                      value={tweetTraitsToShow}
                    >
                      {traitCountOptions.map((o) => {
                        return <option>{o.name}</option>;
                      })}
                    </select>
                  </div> */}
                </div>
              </div>
              <div className="form-group mb-3">
                <div className="d-flex justify-content-between position-relative">
                  <label>Tweet Footer:</label>
                  <EmojiPicker onEmojiSelect={handleTweetFooterEmojiClick} />
                </div>
                <textarea
                  className="form-txtarea form-control"
                  rows="4"
                  id="tweetFooter"
                  onChange={handleTweetFooterChange}
                  value={bot?.tweetFooter}
                ></textarea>
                <div className="character-count d-flex align-items-center">
                  Characters:{" "}
                  <span
                    className={`char-val ${
                      characterCount > 280 ? " col-danger" : ""
                    }`}
                  >
                    {characterCount}
                  </span>{" "}
                  / 280
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-end justify-content-between">
            <label>Tweet Preview:</label>
            <div className="d-flex align-items-center">
              <SaveNftFileButton tokenMedia={tokenMedia} />
              <TwitterShareButtonComponent
                tweetContent={tweetContent}
                url={openseaUrl}
              />
            </div>
          </div>
          <div className="tweet-preview">
            <div className="head-container d-flex align-items-center">
              <div className="d-flex align-items-center">
                <img
                  src={avatar1}
                  className="me-2 br-50  border-green"
                  width="28"
                />
                <div className="user-info">
                  <div className="name">Example User</div>
                  <div className="username">@ExampleUser</div>
                </div>
              </div>
              <div className="icon">
                <i className="fa fa-brands fa-twitter col-twitter-blue"></i>
              </div>
            </div>
            <div className="body-container">
              <>
                <div>
                  {convertStringToTwitterHtml(tweetHeader, tweetReplacements)}
                </div>
                {traitsHtml && <div>{traitsHtml}</div>}
                <div>
                  {convertStringToTwitterHtml(tweetFooter, tweetReplacements)}
                </div>
                <div>{convertStringToTwitterHtml(openseaUrl)}</div>
                <br />
                <AnimatePresence>
                  <motion.div
                    animate={{
                      scale: [0, 1.07, 1],
                      opacity: [0, 1],
                    }}
                    exit={{
                      scale: [1, 1.07, 0],
                      rotate: [0, 3, -90],
                    }}
                  >
                    {tokenMedia && mediaIsImage && (
                      <img className="img-fluid" src={tokenMedia.gateway} />
                    )}
                    {tokenMedia && !mediaIsImage && (
                      <video
                        autoPlay
                        muted
                        controls={false}
                        className="img-fluid"
                      >
                        <source src={tokenMedia.gateway} type="video/mp4" />
                      </video>
                    )}
                  </motion.div>
                </AnimatePresence>
              </>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const CollectionEditor = ({ handleWalletAddressChange, walletAddress }) => {
  const [bot, setBot] = useContext(BotContext);
  const { address } = useContext(AuthContext);

  const getConnectedAddress = () => {
    return address;
  };

  return (
    <motion.div //updated the div tag
      animate={{ x: 0 }}
      initial={{ x: -150 }}
    >
      <form>
        <div className="row">
          <div
            className={`form-group mb-3 col-12 d-flex justify-content-center ${
              bot?.contractAddress
                ? bot.contractAddress
                  ? "is-invalid"
                  : "is-valid"
                : ""
            }`}
          >
            <div className="w-100 max-w-400px">
              <label className="text-label">Wallet address</label>
              <div className="input-group">
                <span className="input-group-text">
                  <i className="fa fa-file-contract col-g" />{" "}
                </span>
                <input
                  type="text"
                  className="form-control"
                  id="val-walletAddress"
                  placeholder=""
                  name="walletAddress"
                  onChange={handleWalletAddressChange}
                  // onBlur={handleBlur}
                  value={walletAddress}
                />
                <div
                  id="val-walletAddress-error"
                  className="invalid-feedback animated fadeInUp"
                  style={{ display: "block" }}
                >
                  {!bot ||
                  !bot.walletAddress ||
                  !isValidEthAddress(bot.walletAddress)
                    ? `Must be a valid Ethereum address (starts with 0x...)`
                    : null}
                </div>

                <div
                  id="val-contractAddress-error"
                  className="invalid-feedback animated fadeInUp"
                  style={{ display: "block" }}
                />
              </div>
              {!bot?.contractAddress && (
                <div className="w-100 text-center">
                  <label
                    className="hvr-shrink text-label use-example-collection-btn m-auto mt-2"
                    onClick={() =>
                      handleWalletAddressChange(getConnectedAddress())
                    }
                  >
                    Use Connected Wallet
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </motion.div>
  );
};

const AutomationControls = ({ twitterAuths }) => {
  const [bot, setBot] = useContext(BotContext);

  const [automationEnabled, setAutomationEnabled] = useState(
    bot?.automationEnabled || false
  );
  const [automationInterval, setAutomationInterval] = useState(
    bot?.automationInterval || "24 hours"
  );
  const [automationDailyTime, setAutomationDailyTime] = useState(
    bot?.automationDailyTime || "13:00"
  );
  const [automationTwitterAuthId, setAutomationTwitterAuthId] = useState(
    bot?.automationTwitterAuthId || null
  );
  // const [automationIdSelectionMode, setAutomationIdSelectionMode] = useState(
  //   bot?.automationIdSelectionMode || "Random"
  // );

  const handleAuthSelect = (auth) => {
    const newAuthId = auth.twitterAuthId;
    if (bot.automationTwitterAuthId !== newAuthId) {
      setBot({
        ...bot,
        automationTwitterAuthId: newAuthId,
      });
    }
    setAutomationTwitterAuthId(newAuthId);
  };

  const handleSetDailyTime = (event) => {
    const newDailyTime = event.target.value;
    if (bot.automationDailyTime !== newDailyTime) {
      setBot({
        ...bot,
        automationDailyTime: newDailyTime,
      });
    }
    setAutomationDailyTime(newDailyTime);
  };

  const handleSetAutomationInterval = (event) => {
    const newAutomationInterval = event.target.value;
    if (bot.automationInterval !== newAutomationInterval) {
      setBot({
        ...bot,
        automationInterval: newAutomationInterval,
      });
    }
    setAutomationInterval(newAutomationInterval);
  };

  const handleSetAutomationEnabled = (isEnabled) => {
    if (bot.automationEnabled !== isEnabled) {
      setBot({
        ...bot,
        automationEnabled: isEnabled,
      });
    }
    setAutomationEnabled(isEnabled);
  };

  return (
    <motion.div //updated the div tag
      animate={{ x: 0 }}
      initial={{ x: -150 }}
    >
      <div className="row tweet-automation position-relative z-100">
        <div className="col-12 mb-5 pt-3 pl-2 pr-2">
          <h2 className="heading">Tweet Automation</h2>
          <div className="row mt-3 d-flex align-items-center justify-content-center">
            <div className="col-12 mb-2">
              <div className="max-w-1000px text-center m-auto">
                Keep flexing by scheduling daily automated tweets to showcase
                random NFTs from your wallet.
                <br></br>It's as simple as set and forget - we'll take care of
                everything else.
              </div>
              {twitterAuths.length === 0 && (
                <motion.div
                  animate={{
                    scale: [0, 1.07, 1],
                    opacity: [0, 1],
                  }}
                >
                  <div className="col-12">
                    <div className="not-authed-twitter">
                      <div>
                        You must have an authenticated Twitter account to use
                        this feature{" "}
                      </div>
                      <div>
                        Visit the{" "}
                        {/* <a target="_blank" href="/settings">
                          settings
                        </a>{" "} */}
                        <Link className="col-g" to={"/settings"}>
                          settings
                        </Link>{" "}
                        page to add an account
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </div>
          </div>
        </div>
        {twitterAuths.length > 0 && (
          <motion.div
            animate={{
              scale: [0, 1.07, 1],
              opacity: [0, 1],
            }}
          >
            <div className="row">
              <div className="col-md-6 z-100">
                <div className="basic-form">
                  <form>
                    <div className="form-group mb-3 pb-1">
                      <label>Twitter account to post from:</label>
                      <TwitterAuthSelectDropdown
                        twitterAuths={twitterAuths}
                        onSelect={handleAuthSelect}
                        selectedTwitterAuth={twitterAuths.find(
                          (auth) =>
                            auth.twitterAuthId === automationTwitterAuthId
                        )}
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-6">
                <label>Time of day (UTC) to post the Tweet</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">
                    <i className="fa fa-clock col-g" />{" "}
                  </span>
                  <select
                    defaultValue={dailyTimeOptions[0].name}
                    className="form-control rmono"
                    id="val-dailyTime"
                    name="automationDailyTime"
                    onChange={handleSetDailyTime}
                    value={automationDailyTime}
                  >
                    {dailyTimeOptions.map((dt, key) => {
                      return <option key={`dt_${key}`}>{dt.name}</option>;
                    })}
                  </select>
                </div>
                <label>Time between Tweets</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">
                    <i className="fa fa-clock col-g" />{" "}
                  </span>
                  <select
                    defaultValue={automationIntervalOptions[0].name}
                    className="form-control rmono"
                    id="val-interval"
                    name="automationInterval"
                    onChange={handleSetAutomationInterval}
                    value={automationInterval}
                  >
                    {automationIntervalOptions.map((dt, key) => {
                      return <option key={`dt_${key}`}>{dt.name}</option>;
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-center text-center pb-2">
                  <AnimatePresence>
                    {automationEnabled && (
                      <motion.div
                        animate={{
                          scale: [0, 1.07, 1],
                          opacity: [0, 1],
                        }}
                      >
                        <div className="d-flex flex-column">
                          <div className="automation-status-msg enabled">
                            Automation Enabled
                          </div>
                          <img
                            className="automation-status-img"
                            src={blueWaveAnim}
                            width={100}
                          />
                          <div className="hvr-shrink">
                            <button
                              type="button"
                              className="btn btn-danger btn-xs"
                              onClick={() => handleSetAutomationEnabled(false)}
                            >
                              Pause
                            </button>
                          </div>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <AnimatePresence>
                    {!automationEnabled && (
                      <motion.div
                        animate={{
                          scale: [0, 1.07, 1],
                          opacity: [0, 1],
                        }}
                      >
                        <div className="d-flex flex-column">
                          <div className="automation-status-msg disabled">
                            Automation Paused
                          </div>
                          {!automationTwitterAuthId && (
                            <div className="automation-status-msg">
                              Select Twitter account to post from
                            </div>
                          )}
                          {automationTwitterAuthId && (
                            <div className="hvr-shrink">
                              <button
                                type="button"
                                className="btn btn-success btn-sm"
                                onClick={() => handleSetAutomationEnabled(true)}
                              >
                                Enable
                              </button>
                            </div>
                          )}
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

const CollectionImage = ({ collectionImage }) => {
  const { ensAvatar } = useContext(AuthContext);

  return (
    <>
      <AnimatePresence>
        {collectionImage && (
          <motion.div
            animate={{
              scale: [0, 1.07, 1],
              rotate: [-90, 3, 0],
              opacity: [0, 1],
            }}
            exit={{
              scale: [1, 1.07, 0],
              rotate: [0, 3, -90],
            }}
          >
            <img
              className="img-fluid mr-05 br-50  border-green"
              width={42}
              src={collectionImage}
            />
          </motion.div>
        )}
      </AnimatePresence>
      {!collectionImage && ensAvatar && (
        <img
          className="img-fluid mr-05 br-50 border-green"
          width={42}
          src={ensAvatar}
        />
      )}
    </>
  );
};

const BotControls = () => {
  const [bot, setBot] = useState(null);
  const [selectedBotId, setSelectedBotId] = useState(null);
  const [formStep, setFormStep] = useState(0);
  const { isAuth, userId, address } = useContext(AuthContext);
  const { alchemySDK } = useContext(AlchemyContext);
  const [selectedCollections, setSelectedCollections] = useState(null);
  const [userBots, setUserBots] = useState([]);
  const [userBotsLoading, setUserBotsLoading] = useState(false);
  const [twitterAuths, setTwitterAuths] = useState([]);
  const timeoutIdRef = useRef(null);
  const [blockEvents, setBlockEvents] = useState([]);

  useEffect(() => {
    // Clear the timeout when the component unmounts
    return () => clearTimeout(timeoutIdRef.current);
  }, []);

  const saveBot = async () => {
    console.log(`saveBot`, bot);
    delayedSaveBot(); // waits 1.9 sec to exec (so new request isn't overloaded with fast input changes)
  };

  const actualSaveBot = async () => {
    console.log("Address Testing - " + address);
    try {
      const api = new ApiService();
      const response = await api.post(`blocks/flexBot/saveBot`, {
        userId,
        bot,
        address,
      });
      console.log(`saveBot()`, response);
      if (response.error || !response.userBots) {
        toast.error(response.error);
      } else {
        toast.success("Changes saved");
        setUserBots(response.userBots);
      }
    } catch (error) {
      console.error(error);
    } finally {
      // setUserBotsLoading(false);
    }
  };

  // waits 1.9 secs to display 'Changes Saved' toast
  const delayedSaveBot = () => {
    // Clear any existing timeouts
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    // Set a timeout to call a function after 1.9 seconds
    const newTimeoutId = setTimeout(async () => {
      actualSaveBot();
    }, 1900);

    // Update the timeout ID ref
    timeoutIdRef.current = newTimeoutId;
  };

  useEffect(() => {
    if (bot && Object.keys(bot).length > 0) {
      const existing = userBots.find((b) => b.flexBotId === bot.flexBotId);
      // only save if bot has changed from stored ones
      if (existing && existing !== bot) {
        saveBot();
      }
      // fetch any missing collection info
      if (isValidEthAddress(bot.contractAddress) && !bot.collectionName) {
        fetchCollectionInfo();
      }
    }
  }, [bot]);

  const fetchCollectionInfo = () => {
    if (!bot || !bot.contractAddress) {
      console.log(
        `fetchCollectionInfo() - returning because (!bot || !bot.contractAddress)`
      );
      return;
    }
    console.log(`fetchCollectionInfo()`);
    // valid eth address - refresh collection info
    if (isValidEthAddress(bot.contractAddress)) {
      alchemySDK.nft.getContractMetadata(bot.contractAddress).then((data) => {
        if (data && data.openSea) {
          // valid collection
          console.log(`collectionInfo`, data);
          setBot({
            ...bot,
            walletAddress: address,
          });
        }
        // no collection info for contract
        else {
          // only set to null if bot has these values set
          if (bot.walletAddress) {
            setBot({
              ...bot,
              walletAddress: null,
            });
          }
        }
      });
    }
    // invalid eth address
    else {
      // only set to null if bot has these values set
      if (bot.collectionName) {
        setBot({
          ...bot,
          collectionImage: null,
          collectionName: null,
          collectionSymbol: null,
          collectionTokenType: null,
          collectionTotalSupply: null,
        });
      }
    }
  };

  const fetchTwitterAuths = () => {
    const api = new ApiService();
    api.get(`user/twitter/getAuths/${userId}`).then((data) => {
      setTwitterAuths(data);
      console.log(`twitterAuths`, data);
    });
  };

  useEffect(() => {
    if (isAuth && userId && !twitterAuths.length) {
      fetchTwitterAuths();
    }
  }, [userId, isAuth, twitterAuths.length]);

  useEffect(() => {
    // set form stage based on whether wallet address is set
    if (bot && bot.walletAddress && isValidEthAddress(bot.walletAddress)) {
      setTimeout(() => {
        // slight delay for ux
        setFormStep(1); // go to the tweet designer stage
      }, 500);
    } else {
      setFormStep(0); // go to the collection edit
      // if (bot && !bot.collectionName) {
      //   fetchCollectionInfo(); // fetch missing collection info
      // }
    }
  }, [bot?.walletAddress]);

  const handleSelectBot = (bot) => {
    if (bot.flexBotId === selectedBotId) {
      // bot already selected so unselect
      setBot(null);
      setSelectedBotId(null);
    } else {
      // select bot
      setBot(bot);
      setSelectedBotId(bot.flexBotId);
    }
  };

  const fetchUserBots = async () => {
    setUserBotsLoading(true);
    try {
      const api = new ApiService();
      const response = await api.get(`blocks/flexBot/getBots/${userId}`);
      console.log(`fetchUserBots()`, response);
      setUserBots(response);
    } catch (error) {
      console.error(error);
    } finally {
      setUserBotsLoading(false);
    }
  };

  useEffect(() => {
    if (!isAuth || !userId) {
      setUserBots([]);
      return;
    }
    fetchUserBots();
  }, [isAuth, userId, address]);

  const fetchBlockEvents = async () => {
    try {
      const api = new ApiService();
      const response = await api.get(`blocks/flexBot/getEvents/${userId}`);
      console.log(`fetchBlockEvents()`, response);
      setBlockEvents(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isAuth || !userId) {
      setBlockEvents([]);
      return;
    }
    fetchBlockEvents();
  }, [isAuth, userId]);

  const addNewBot = async () => {
    try {
      const api = new ApiService();
      const response = await api.post(`blocks/flexBot/addBot`, { userId });
      console.log(`addNewBot()`, response);
      if (response.error || !response.userBots) {
        toast.error(response.error);
      } else {
        toast.success("New bot created");
        setUserBots(response.userBots);
        // select new bot
        setBot(response.bot);
        setSelectedBotId(response.bot.flexBotId);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setUserBotsLoading(false);
    }
  };

  const confirmDeleteBotAlert = (botId) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "Any bots using this Twitter will be disabled",
      showCancelButton: true,
      confirmButtonColor: "#EB5757",
      cancelButtonColor: "#adadad",
      confirmButtonText: "Delete Bot",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBot(botId);
      }
    });
  };

  const deleteBot = async (botId) => {
    try {
      const api = new ApiService();
      const response = await api.post(`blocks/flexBot/deleteBot`, {
        userId,
        botId,
      });
      console.log(`deleteBot() - ${botId}`, response);
      if (response.error || !response.userBots) {
        toast.error(response.error);
      } else {
        toast.success("Bot deleted");
        // unselect bot
        setBot(null);
        setSelectedBotId(null);
        // set new user bots
        setUserBots(response.userBots);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setUserBotsLoading(false);
    }
  };

  const UserBots = () => {
    return (
      <Col lg={12}>
        <Card className="mb-3 h-fc bot-table">
          <Card.Header>
            <Card.Title>Your Bots</Card.Title>
            <div className="hvr-shrink">
              <button className="btn btn-primary" onClick={addNewBot}>
                <span className="me-2">
                  {" "}
                  <i className="fa fa-plus"></i>{" "}
                </span>
                NEW BOT
              </button>
            </div>
          </Card.Header>
          <Card.Body>
            {userBots.length === 0 && (
              <div className="no-userBots-msg">
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={[
                    "You have no bots",
                    2600,
                    "You are botless",
                    2600,
                    "The total supply of your bots is 0",
                    2600,
                    "You are in a bot-free zone",
                    2600,
                    "Bot inventory critically low",
                    2600,
                    "Zero bots, infinite potential",
                    2600,
                    "Fresh out of bots",
                    2600,
                    "Bot count: 0",
                    2600,
                    "Bots? Not here",
                    2600,
                    "You still have no bots",
                    2600,
                    "Bots. Get some.",
                    2600,
                  ]}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </div>
            )}
            {userBots.length > 0 && (
              <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <strong>Wallet Address</strong>
                    </th>
                    <th>
                      <strong>Collections</strong>
                    </th>
                    <th>
                      <strong>Automation</strong>
                    </th>
                    <th>
                      <strong>Next Scheduled Tweet</strong>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {userBots.map((bot, key) => {
                    return (
                      <tr
                        className={
                          selectedBotId && selectedBotId === bot.flexBotId
                            ? `selected`
                            : ``
                        }
                        key={`userBot_${key}`}
                        onClick={() => handleSelectBot(bot)}
                      >
                        <td>
                          <div className="d-flex align-items-center">
                            {bot.walletAddress && (
                              <>
                                <span className="w-space-no">
                                  {truncateAddress(bot.walletAddress)}
                                </span>
                              </>
                            )}
                            {!bot.walletAddress && (
                              <span className="w-space-no">Not Set</span>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            {bot.selectedCollections &&
                              bot.selectedCollections.map((item, index) => {
                                console.log(index);
                                if (index < 3) {
                                  return (
                                    <img
                                      className="collectionImage border-green"
                                      src={item.image}
                                      alt="Contract Image"
                                      key={`bot_sel_col_${index}`}
                                    ></img>
                                  );
                                } else if (index == 3) {
                                  return (
                                    <div
                                      className="collectionStacker"
                                      key={`bot_sel_col_${index}`}
                                    >
                                      +{bot.selectedCollections.length - 3}
                                    </div>
                                  );
                                }
                                //return (null);
                              })}
                            {!bot.selectedCollections && (
                              <span className="w-space-no">Not Set</span>
                            )}
                          </div>
                        </td>
                        <td>
                          {bot.automationEnabled && (
                            <div className="d-flex align-items-center">
                              <i className="fa fa-circle text-success me-1"></i>{" "}
                              Enabled
                            </div>
                          )}
                          {!bot.automationEnabled && (
                            <div className="d-flex align-items-center">
                              <i className="fa fa-circle text-danger me-1"></i>{" "}
                              Paused
                            </div>
                          )}
                        </td>
                        <td>
                          {bot.automationEnabled && (
                            <span>
                              {timeUntil({
                                secondsUntil:
                                  bot.automationDailyTimeSecondsUntil,
                              })}
                            </span>
                          )}
                          {!bot.automationEnabled && <span>N/A</span>}
                        </td>
                        <td>
                          <div className="d-flex">
                            {/* <Link
                href="#"
                className="btn btn-primary shadow btn-xs sharp me-1"
              >
                <i className="fas fa-pencil-alt"></i>
              </Link> */}
                            <div className="hvr-shrink">
                              <div
                                onClick={() =>
                                  confirmDeleteBotAlert(bot.flexBotId)
                                }
                                className="btn btn-danger btn-xs sharp"
                              >
                                <i className="fa fa-trash"></i>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
      </Col>
    );
  };
  return (
    <BotContext.Provider value={[bot, setBot]}>
      <UserBots />
      <BotEditor
        formStep={formStep}
        setFormStep={setFormStep}
        alchemySDK={alchemySDK}
        twitterAuths={twitterAuths}
        fetchCollectionInfo={fetchCollectionInfo}
      />
      <BlockEventsTable events={blockEvents} />
    </BotContext.Provider>
  );
};

const BotEditor = ({
  formStep,
  setFormStep,
  alchemySDK,
  twitterAuths,
  fetchCollectionInfo,
  setSelectedCollections,
  selectedCollections,
}) => {
  const [bot, setBot] = useContext(BotContext);
  const [walletAddress, setWalletAddress] = useState(bot?.walletAddress || "");

  console.log(`BotEditor Refresh - bot`, bot);
  useEffect(() => {
    if (!bot || !bot.walletAddress) {
      return; // might cause error if contractAddy had been set previously then deleted
    }
    fetchCollectionInfo();
  }, [walletAddress]);

  useEffect(() => {
    if (!walletAddress || !walletAddress.length) {
      if (bot && bot.walletAddress) {
        setWalletAddress(bot.walletAddress);
      }
    }
  }, [bot]);

  // const handlbotEdit = (event) => {
  //   setData({
  //     ...bot,
  //     [event.target.name]: event.target.value,
  //   });
  // }
  // accompanied w/ :
  // <input name="name" value={data.name} onChange={handleChange} />

  const handleWalletAddressChange = (event) => {
    const newWalletAddress = event.target ? event.target.value.trim() : event;
    console.log(`newWalletAddress`, newWalletAddress);
    if (bot.add !== newWalletAddress) {
      setBot({
        ...bot,
        walletAddress: newWalletAddress,
        selectedCollections: null,
      });
    }
    setWalletAddress(newWalletAddress);
  };

  return (
    <AnimatePresence>
      {bot !== null && (
        <motion.div
          animate={{
            x: [-500, 0],
            // scale: [0, 1],
            opacity: [0, 1],
          }}
        >
          <div className="card mb-3 h-fc">
            <div className="card-header d-flex justify-content-start">
              <CollectionImage collectionImage={bot?.collectionImage} />
              <h4 className="card-title">Edit Bot</h4>
            </div>
            <div className="card-body">
              <div className="form-wizard">
                <Stepper className="nav-wizard mb-1" activeStep={formStep}>
                  <Step
                    label="Wallet"
                    className="nav-link"
                    onClick={() => setFormStep(0)}
                  />
                  <Step
                    label="Collection"
                    className="nav-link"
                    onClick={() => setFormStep(1)}
                  />
                  <Step
                    label="Tweet Designer"
                    className="nav-link"
                    onClick={() => setFormStep(2)}
                  />
                  <Step
                    label="Automation"
                    className="nav-link"
                    onClick={() => setFormStep(3)}
                  />
                </Stepper>

                {formStep === 0 && (
                  <>
                    <CollectionEditor
                      handleWalletAddressChange={handleWalletAddressChange}
                      walletAddress={walletAddress}
                    />
                    {bot?.walletAddress &&
                    isValidEthAddress(bot.walletAddress) ? (
                      <div className="text-end toolbar toolbar-bottom p-2">
                        <div className="hvr-shrink">
                          <button
                            className="btn btn-primary sw-btn-next"
                            onClick={() => setFormStep(1)}
                          >
                            Select Collections{" "}
                            <i className="fa fa-arrow-right ml-05"></i>
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </>
                )}
                {formStep === 1 && (
                  <>
                    <CollectionSelector alchemySDK={alchemySDK} />

                    <div className="text-end toolbar toolbar-bottom p-2 pl-0 pr-0 d-flex justify-content-between">
                      <div className="hvr-shrink">
                        <button
                          className="btn btn-secondary sw-btn-prev"
                          onClick={() => {
                            setFormStep(0);
                          }}
                        >
                          <i className="fa fa-arrow-left mr-05"></i> Wallet
                          Address
                        </button>
                      </div>
                      {bot?.selectedCollections &&
                      bot?.selectedCollections.length > 0 ? (
                        <div className="d-flex">
                          <div className="hvr-shrink">
                            <button
                              className="btn btn-secondary sw-btn-next me-1"
                              onClick={() => {
                                setFormStep(2);
                              }}
                            >
                              Tweet Designer{" "}
                              <i className="fa fa-arrow-right ml-05"></i>
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
                {formStep === 2 && (
                  <>
                    <TweetDesigner alchemySDK={alchemySDK} />
                    <div className="text-end toolbar toolbar-bottom p-2 pl-0 pr-0 d-flex justify-content-between">
                      <div className="hvr-shrink">
                        <button
                          className="btn btn-secondary sw-btn-prev"
                          onClick={() => {
                            setFormStep(1);
                          }}
                        >
                          <i className="fa fa-arrow-left mr-05"></i> Collection
                        </button>
                      </div>
                      <div className="d-flex">
                        <div className="hvr-shrink">
                          <button
                            className="btn btn-secondary sw-btn-next me-1"
                            onClick={() => {
                              setFormStep(3);
                            }}
                          >
                            Automation{" "}
                            <i className="fa fa-arrow-right ml-05"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {formStep === 3 && (
                  <>
                    <AutomationControls twitterAuths={twitterAuths} />
                    <div className="text-end toolbar toolbar-bottom p-2 pl-0 pr-0 d-flex justify-content-between">
                      <div className="hvr-shrink">
                        <button
                          className="btn btn-secondary sw-btn-prev"
                          onClick={() => {
                            setFormStep(2);
                          }}
                        >
                          <i className="fa fa-arrow-left mr-05"></i> Tweet
                          Designer
                        </button>
                      </div>
                      {/* <div className="d-flex">
                        <div className="hvr-shrink">
                          <button
                            className="btn btn-success sw-btn-prev me-1 mr-05"
                            onClick={() => {}}
                          >
                            Save <i className="fa fa-check ml-05"></i>
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const FlexBot = (props) => {
  const BotHeading = () => {
    const [animate, setAnimate] = useState(false);
    const tweetLoadAnim = useAnimation();
    const tweetLoaded = () => {};

    async function startAnimation() {
      await tweetLoadAnim.start({
        opacity: 1,
        scale: 1,
        transition: { duration: 0.2 },
      });
    }

    return (
      <div className="flexbot row">
        <div className="col-lg-8">
          <BlockCard block={Blocks.NFTFlexBot} />
          <AuthOnly>
            <motion.div animate={{ x: 0 }} initial={{ x: -1000 }}>
              <BotControls />
            </motion.div>
          </AuthOnly>
        </div>
        <div className="col-lg-4">
          <div className="spottie-tweet-example-card position-relative">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={tweetLoadAnim}
            >
              <div className="bot-example-card-badge">
                Flex Bot Example Tweet
              </div>
              <TwitterTweetEmbed
                onLoad={function noRefCheck() {
                  console.log(`setShowExampleTweet()`);
                  startAnimation();
                }}
                tweetId={"1646175388937117700"}
                options={{ theme: "dark" }}
              />
            </motion.div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {/* <button disabled={isLoading} onClick={() => signMessage()}>
        Sign message
      </button>
      {isSuccess && <div>Signature: {data}</div>}
      {isError && <div>Error signing message</div>} */}
      <PageHeaderBar
        pages={[
          { title: "Blocks", url: `/blocks` },
          { title: "NFT Flex Bot", url: `/blocks/flex` },
        ]}
      />
      <BotHeading />
    </Fragment>
  );
};

export default FlexBot;
