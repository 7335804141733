import React, { useState } from "react";
import { toast } from "react-hot-toast";
// import ApiService from "../services/ApiService";
import {
  facebookIcon,
  flickrIcon,
  linkedIcon,
  twitterIcon,
  youtubeIcon,
} from "./SVG";

export default function Footer({ alternate = false }) {
  const [email, setEmail] = useState("");

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleEmailSubmit = async () => {};
  if (alternate) {
    return (
      <footer className="footer">
        <div className="auto__container">
          <div className="footer__inner alt">
            <div className="footer__inner-top alt">
              <div className="footer__inner-logo alt">
                <img
                  src={
                    "https://events.landmarkventures.com" +
                    "/images/footer-logo.png"
                  }
                  alt="logo"
                />
              </div>
              <h5>At Landmark Ventures we build exceptional relationships</h5>
            </div>
            <div className="footer__inner-row">
              <div className="footer__inner-col">
                <a href="https://landmarkventures.com/about/">About</a>
                <a href="https://landmarkventures.com/people/">People</a>
                <a href="https://landmarkventures.com/services/">Services</a>
                <a href="https://landmarkventures.com/expertise/">Expertise</a>
                <a href="https://landmarkventures.com/portfolio/">Portfolio</a>
                <a href="https://landmarkventures.com/newsroom/">Newsroom</a>
                <a href="https://landmarkventures.com/careers/">Careers</a>
                <a href="https://landmarkventures.com/case-studies/">
                  Case Studies
                </a>
              </div>
              <div className="footer__inner-col">
                <a href="https://landmarkventures.com/newsroom/">Newsroom</a>
                <a href="https://landmarkventures.com/careers/">Careers</a>
                <a href="https://landmarkventures.com/#">Contact</a>
                <a href="https://landmarkventures.com/case-studies/">
                  Case Studies
                </a>
              </div>
              <div className="footer__inner-main">
                <h6>Subscribe to our newsletter</h6>
                <p className="sm">
                  Stay connected with Landmark and up to date on the latest
                  news, trends, and ideas in our sectors.
                </p>
                <div className="input">
                  <input
                    type="email"
                    placeholder="youremail@mail.com"
                    value={email}
                    onChange={handleInputChange}
                  />
                </div>
                <button
                  type="button"
                  className="button"
                  onClick={handleEmailSubmit}
                >
                  Subscribe
                </button>
              </div>
              <div className="footer__inner-col social">
                <a href="https://twitter.com/Landmark">
                  <span>{twitterIcon}</span>
                  Twitter
                </a>
                <a href="https://facebook.com/Landmark-Ventures-138752542808379/">
                  <span>{facebookIcon}</span>
                  Facebook
                </a>
                <a href="https://linkedin.com/company/landmark-ventures">
                  <span>{linkedIcon}</span>
                  Linkedin
                </a>
                <a href="https://youtube.com/channel/UCu-H9YnQi63vrHLtQnIA3ww">
                  <span>{youtubeIcon}</span>
                  YouTube
                </a>
                <a href="https://flickr.com/photos/landmarkventures/">
                  <span>{flickrIcon}</span>
                  Flickr
                </a>
              </div>
            </div>
            <div className="footer__inner-copy">
              <div className="footer__inner-copy-links">
                <a href="https://landmarkventures.com/terms-of-use/">
                  Terms of use
                </a>
                <hr />
                <a href="https://landmarkventures.com/privacy-policy/">
                  Privacy Policy
                </a>
              </div>
              <p className="sm">
                © {new Date().getFullYear()} Landmark Ventures. All Rights
                Reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
  return (
    <footer className="footer">
      <div className="auto__container">
        <div className="footer__inner">
          <div className="footer__inner-top">
            <div className="footer__inner-logo">
              <img
                src={
                  "https://events.landmarkventures.com" +
                  "/images/footer-logo.png"
                }
                alt="logo"
              />
            </div>
            <h5>At Landmark Ventures we build exceptional relationships</h5>
          </div>
          <div className="footer__inner-row">
            <div className="footer__inner-col">
              <a href="https://landmarkventures.com/about/">About</a>
              <a href="https://landmarkventures.com/people/">People</a>
              <a href="https://landmarkventures.com/services/">Services</a>
              <a href="https://landmarkventures.com/expertise/">Expertise</a>
              <a href="https://landmarkventures.com/portfolio/">Portfolio</a>
              <a href="https://landmarkventures.com/newsroom/">Newsroom</a>
              <a href="https://landmarkventures.com/careers/">Careers</a>
              <a href="https://landmarkventures.com/case-studies/">
                Case Studies
              </a>
            </div>
            <div className="footer__inner-col">
              <a href="https://landmarkventures.com/newsroom/">Newsroom</a>
              <a href="https://landmarkventures.com/careers/">Careers</a>
              <a href="https://landmarkventures.com/#">Contact</a>
              <a href="https://landmarkventures.com/case-studies/">
                Case Studies
              </a>
            </div>
            <div className="footer__inner-main">
              <h6>Subscribe to our newsletter</h6>
              <p className="sm">
                Stay connected with Landmark and up to date on the latest news,
                trends, and ideas in our sectors.
              </p>
              <div className="input">
                <input
                  type="email"
                  placeholder="youremail@mail.com"
                  value={email}
                  onChange={handleInputChange}
                />
              </div>
              <button
                type="button"
                className="button"
                onClick={handleEmailSubmit}
              >
                Subscribe
              </button>
            </div>
            <div className="footer__inner-col social">
              <a href="https://twitter.com/Landmark">
                <span>{twitterIcon}</span>
                Twitter
              </a>
              <a href="https://facebook.com/Landmark-Ventures-138752542808379/">
                <span>{facebookIcon}</span>
                Facebook
              </a>
              <a href="https://linkedin.com/company/landmark-ventures">
                <span>{linkedIcon}</span>
                Linkedin
              </a>
              <a href="https://youtube.com/channel/UCu-H9YnQi63vrHLtQnIA3ww">
                <span>{youtubeIcon}</span>
                YouTube
              </a>
              <a href="https://flickr.com/photos/landmarkventures/">
                <span>{flickrIcon}</span>
                Flickr
              </a>
            </div>
          </div>
          <div className="footer__inner-copy">
            <div className="footer__inner-copy-links">
              <a href="https://landmarkventures.com/terms-of-use/">
                Terms of use
              </a>
              <hr />
              <a href="https://landmarkventures.com/privacy-policy/">
                Privacy Policy
              </a>
            </div>
            <p className="sm">
              © {new Date().getFullYear()} Landmark Ventures. All Rights
              Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
