import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import clipboard from "clipboardy";
import loadingCircleImg from "../../../../images/misc/loading-circle-green.svg";
import landmarkImg from "../../../../images/landmark/logo-text-dark.png";
import ApiService from "../../../../services/ApiService";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { utils } from "ethers";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { FileCopyOutlined as FileCopyOutlinedIcon } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { darken } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TypeAnimation } from "react-type-animation";
import {
  copyTextToClipboard,
  uniqueGuid,
  uniqueString,
  useDocumentTitle,
} from "../../../utils";
import { ThemeContext } from "../../../../context/ThemeContext";
import {
  addCommas,
  epochToDateString,
  epochToDateTimeString,
  stringToRGB,
  toTitleCase,
} from "../../../../utils";

const PasswordPrompt = ({ password, setPassword, submitPassword }) => {
  const handleInputChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submitPassword(password);
  };

  return (
    <div className="d-flex">
      <input
        onChange={handleInputChange}
        type="password"
        className="form-control w-100 mt-1 inline-input"
        placeholder="Password"
        value={password}
      ></input>
      <div
        className="btn btn-primary light btn-xs mt-1 me-1 w-fc inline-btn"
        onClick={(e) => handleSubmit(e)}
      >
        Submit
      </div>
    </div>
  );
};

const getTodaysDateFormatted = () => {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(now.getDate()).padStart(2, "0");

  return `${month}/${day}/${year}`;
};

const getEndDateFormatted = () => {
  const now = new Date();
  now.setDate(now.getDate() + 5); // Add 5 days
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(now.getDate()).padStart(2, "0");
  const hours = "16"; // noon EST
  const minutes = "00";
  const seconds = "00";
  const milliseconds = "000";

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
};

const AddDonationForm = ({ password, fetchDonations }) => {
  const [formData, setFormData] = useState({
    amountUsd: "",
    amountEth: "",
    date: getTodaysDateFormatted(),
    recipientName: "",
    recipientUrl: "",
    imageUrl: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (getButtonDisabledClass() === "disabled") {
      return;
    }

    // fill out the missing data
    const options = JSON.parse(JSON.stringify(formData));

    const api = new ApiService({ password });
    const { success, error, info } = await api.post(
      `landmark/bets/donations/add`,
      {
        options,
      }
    );
    if (!success && !info) {
      toast.error(`An error occurred: ${error}`);
      return false;
    } else if (success) {
      toast.success(`${success}`);
      // refresh marketplace items
      fetchDonations();
      return true;
    } else if (info) {
      toast(`${info}`);
      return true;
    }
  };

  const getButtonDisabledClass = () => {
    let isDisabled = true;
    const {
      amountUsd,
      amountEth,
      date,
      recipientName,
      recipientUrl,
      imageUrl,
    } = formData;
    try {
      if (amountUsd && amountEth && date && recipientName && recipientUrl) {
        isDisabled = false;
      }
    } catch (error) {
      console.log(`error`, error);
      isDisabled = true;
    }
    return isDisabled ? "disabled" : "";
  };

  return (
    <div className="marketplace-container">
      <div className="marketplace-form-container">
        <form onSubmit={handleSubmit}>
          {/*<div className="mb-3">
            <label htmlFor="id" className="form-label">
              ID
            </label>
            <input
              type="text"
              className="form-control"
              id="id"
              name="id"
              value={formData.id}
              onChange={handleChange}
            />
          </div> */}
          <div className="mb-3">
            <label htmlFor="recipientName" className="form-label">
              Recipient Name
              <br />
              <span className="example">e.g. Polaris Project</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="recipientName"
              name="recipientName"
              value={formData.recipientName}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="recipientUrl" className="form-label">
              Recipient Website
              <br />
              <span className="example">e.g. https://polarisproject.org</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="recipientUrl"
              name="recipientUrl"
              value={formData.recipientUrl}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="amountUsd" className="form-label">
              USD Amount
              <br />
              <span className="example">e.g. 5000</span>
            </label>
            <input
              type="number"
              className="form-control"
              id="amountUsd"
              name="amountUsd"
              value={formData.amountUsd}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="amountEth" className="form-label">
              ETH Amount
              <br />
              <span className="example">e.g. 2.5</span>
            </label>
            <input
              type="number"
              className="form-control"
              id="amountEth"
              name="amountEth"
              value={formData.amountEth}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="date" className="form-label">
              Donation Date <span className="example">(MM/DD/YYYY)</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="imageUrl" className="form-label">
              Recipient Logo Image URL (optional)<br></br>
              <span className="example">
                If you dont have a URL, upload the image to a site like{" "}
                <a href="https://imgbb.com/" target="_blank">
                  ImgBB
                </a>
                , right click on image and open in new tab, copy and paste URL
                here (URL should end in .jpg or .png) <br></br>
                <i>Images should be square dimensions for best results</i>
              </span>
            </label>
            <input
              type="text"
              className="form-control"
              id="imageUrl"
              name="imageUrl"
              value={formData.imageUrl}
              onChange={handleChange}
            />
          </div>
          <button
            type="submit"
            className={`btn btn-primary ${getButtonDisabledClass()}`}
          >
            Add Donation
          </button>
        </form>
      </div>
    </div>
  );
};

const DonationsTable = ({ password, fetchDonations, donations }) => {
  const handleDeleteDonation = async (donationId) => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this donation?"
    );
    if (!userConfirmed) {
      return;
    }
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/bets/donations/delete`,
        {
          donationId,
        }
      );
      if (!success) {
        toast.error(`${error}`);
        return false;
      } else {
        toast.success(`${success}`);
        fetchDonations();
        return true;
      }
    } catch (error) {
      console.error(error);
      toast.error(`${error}`);
      return false;
    }
  };

  const copyText = (text, desc = null) => {
    copyTextToClipboard(text);
    desc = desc ? desc : "text";
    toast.success(`Copied ${desc} to clipboard`);
  };

  return (
    <>
      <div class="outputHeader">Donations</div>
      <div className="outputBody">
        <div className="table-wrapper">
          <Table responsive className="header-border ">
            <thead>
              <tr>
                <th>Recipient Name</th>
                <th>Recipient Image</th>
                <th>Recipient URL</th>
                <th>Amount USD</th>
                <th>Amount ETH</th>
                <th>Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {donations.map((donation, i) => {
                const {
                  donationId,
                  amountUsd,
                  amountEth,
                  date,
                  recipientName,
                  recipientUrl,
                  imageUrl,
                } = donation;
                return (
                  <tr key={`tr_row_${i}`}>
                    <td>
                      <b>{recipientName}</b>
                    </td>
                    <td>
                      <div className="img-container">
                        <div className="item-img">
                          <img src={imageUrl} className="img-fluid" />
                        </div>
                      </div>
                    </td>
                    <td>
                      <a
                        href={recipientUrl}
                        target="_blank"
                        className="blue-link"
                      >
                        {recipientUrl}
                      </a>
                    </td>
                    <td>{`$${addCommas(amountUsd)}`}</td>
                    <td>{`${amountEth} ETH`}</td>
                    <td>{date}</td>
                    <td>
                      {" "}
                      <div
                        className={`sign-up-btn btn del btn-sm delete-text-btn`}
                        onClick={() => handleDeleteDonation(donationId)}
                      >
                        {`Delete Donation`}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

const AddTeamMemberForm = ({ password, fetchTeamMembers }) => {
  const [formData, setFormData] = useState({
    name: "",
    socialUrl: "",
    jobTitle: "",
    description: "",
    type: "Member",
    imageUrl: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (getButtonDisabledClass() === "disabled") {
      return;
    }

    // fill out the missing data
    const options = JSON.parse(JSON.stringify(formData));

    const api = new ApiService({ password });
    const { success, error, info } = await api.post(`landmark/bets/team/add`, {
      options,
    });
    if (!success && !info) {
      toast.error(`An error occurred: ${error}`);
      return false;
    } else if (success) {
      toast.success(`${success}`);
      // refresh marketplace items
      fetchTeamMembers();
      return true;
    } else if (info) {
      toast(`${info}`);
      return true;
    }
  };

  const getButtonDisabledClass = () => {
    let isDisabled = true;
    const { name, socialUrl, jobTitle, description, type, imageUrl } = formData;
    try {
      if (name && socialUrl && jobTitle && description && type && imageUrl) {
        isDisabled = false;
      }
    } catch (error) {
      console.log(`error`, error);
      isDisabled = true;
    }
    return isDisabled ? "disabled" : "";
  };

  return (
    <div className="marketplace-container">
      <div className="marketplace-form-container">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Name
              <br />
              <span className="example">e.g. John Doe</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="socialUrl" className="form-label">
              Social URL
              <br />
              <span className="example">e.g. https://twitter.com/johndoe</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="socialUrl"
              name="socialUrl"
              value={formData.socialUrl}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="jobTitle" className="form-label">
              Job Title
              <br />
              <span className="example">e.g. CEO</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="jobTitle"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">
              Description
              <br />
              <span className="example">e.g. Brief bio</span>
              <br />
              <span className="example">
                To insert a link: [URL=https://x.com/johndoe]John's
                Twitter[/URL]
              </span>
            </label>
            <textarea
              className="form-control"
              id="description"
              name="description"
              value={formData.description}
              rows="4"
              onChange={handleChange}
              style={{ height: "65px", lineHeight: "1.3" }}
            ></textarea>
          </div>
          <div className="mb-3">
            <label htmlFor="type" className="form-label">
              Type
              <br />
              <span className="example">Select "Member" or "Founder"</span>
            </label>
            <select
              className="form-control"
              id="type"
              name="type"
              value={formData.type}
              onChange={handleChange}
            >
              <option value="Member">Member</option>
              <option value="Founder">Founder</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="imageUrl" className="form-label">
              Image URL<br></br>
              <span className="example">
                If you don't have a URL, upload the image to a site like{" "}
                <a href="https://imgbb.com/" target="_blank">
                  ImgBB
                </a>
                , right click on image and open in new tab, copy and paste URL
                here (URL should end in .jpg or .png) <br></br>
              </span>
              <span className="example">
                Recommended dimensions: 800 x 1236 px
              </span>
            </label>
            <input
              type="text"
              className="form-control"
              id="imageUrl"
              name="imageUrl"
              value={formData.imageUrl}
              onChange={handleChange}
            />
          </div>
          <button
            type="submit"
            className={`btn btn-primary ${getButtonDisabledClass()}`}
          >
            Add Team Member
          </button>
        </form>
      </div>
    </div>
  );
};

const TeamMembersTable = ({ password, fetchTeamMembers, teamMembers }) => {
  const handleDeleteTeamMember = async (teamMemberId) => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this team member?"
    );
    if (!userConfirmed) {
      return;
    }
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(`landmark/bets/team/delete`, {
        teamMemberId,
      });
      if (!success) {
        toast.error(`${error}`);
        return false;
      } else {
        toast.success(`${success}`);
        fetchTeamMembers();
        return true;
      }
    } catch (error) {
      console.error(error);
      toast.error(`${error}`);
      return false;
    }
  };

  const copyText = (text, desc = null) => {
    copyTextToClipboard(text);
    desc = desc ? desc : "text";
    toast.success(`Copied ${desc} to clipboard`);
  };

  return (
    <>
      <div className="outputHeader">Team Members</div>
      <div className="outputBody">
        <div className="table-wrapper">
          <Table responsive className="header-border">
            <thead>
              <tr>
                <th>Name</th>
                <th>Image</th>
                <th>Social URL</th>
                <th>Job Title</th>
                <th>Description</th>
                <th>Type</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {teamMembers.map((member, i) => {
                const {
                  teamMemberId,
                  name,
                  socialUrl,
                  jobTitle,
                  description,
                  type,
                  imageUrl,
                } = member;
                return (
                  <tr key={`tr_row_${i}`}>
                    <td>
                      <b>{name}</b>
                    </td>
                    <td>
                      <div className="img-container">
                        <div className="item-img">
                          <img
                            src={imageUrl}
                            className="img-fluid"
                            alt={name}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href={socialUrl} target="_blank" className="blue-link">
                        {socialUrl}
                      </a>
                    </td>
                    <td>{jobTitle}</td>
                    <td className="tr-description wrap-text">{description}</td>
                    <td>{type}</td>
                    <td>
                      <div
                        className={`sign-up-btn btn del btn-sm delete-text-btn`}
                        onClick={() => handleDeleteTeamMember(teamMemberId)}
                      >
                        {`Delete Team Member`}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

const Dashboard = (props) => {
  const { changeBackground } = useContext(ThemeContext);
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [donations, setDonations] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);

  async function fetchDonations() {
    const api = new ApiService({ password });
    try {
      const response = await api.get(`landmark/bets/donations/get`);
      setDonations(response);
      console.log("donations", response);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchDonations();
  }, []);

  async function fetchTeamMembers() {
    const api = new ApiService({ password });
    try {
      const response = await api.get(`landmark/bets/team/get`);
      setTeamMembers(response);
      console.log("setTeamMembers", response);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  // set light theme
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);

  async function submitPassword(password) {
    if (!password) {
      toast.error("Invalid Password");
      return;
    }
    try {
      const api = new ApiService();
      const { success, error } = await api.post(`landmark/bets/auth`, {
        password,
      });
      if (!success) {
        toast.error(`${error}`);
        setIsAuthenticated(false);
        return false;
      } else {
        toast.success(`${success}`);
        setIsAuthenticated(true);
        return true;
      }
    } catch (error) {
      console.error(error);
      setIsAuthenticated(false);
      return false;
    }
  }

  useDocumentTitle("BETS - Dashboard");

  return (
    <Fragment>
      <div className="global-dash-wrapper marketplace-wrapper bets-dashboard-wrapper">
        {/* <button disabled={isLoading} onClick={() => signMessage()}>
        Sign message
      </button>
      {isSuccess && <div>Signature: {data}</div>}
      {isError && <div>Error signing message</div>} */}
        <div className="d-flex align-items-center justify-content-center">
          <img
            src="https://i.ibb.co/cb0VxH4/logo-big-bg.jpg"
            className="img-fluid landmark-img"
          />
        </div>
        <PageHeaderBar
          pages={[
            { title: "BETS", url: `` },
            {
              title: "Dashboard",
              url: `/bets/dashboard`,
            },
          ]}
        />
        {/* show dashboard */}
        {isAuthenticated ? (
          <>
            {/* donations */}
            <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
              <Card className="mb-3 h-fc bot-table">
                <div className="row">
                  <DonationsTable
                    password={password}
                    donations={donations}
                    fetchDonations={fetchDonations}
                  />
                </div>
              </Card>
            </motion.div>
            <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
              <Card className="mb-3 h-fc bot-table">
                <div className="row">
                  <div className="outputHeader">Add Donation</div>
                </div>
                <AddDonationForm
                  password={password}
                  fetchDonations={fetchDonations}
                />
              </Card>
            </motion.div>
            <div style={{ height: "100px" }}></div>
            {/* team members */}
            <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
              <Card className="mb-3 h-fc bot-table">
                <div className="row">
                  <TeamMembersTable
                    password={password}
                    teamMembers={teamMembers}
                    fetchTeamMembers={fetchTeamMembers}
                  />
                </div>
              </Card>
            </motion.div>
            <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
              <Card className="mb-3 h-fc bot-table">
                <div className="row">
                  <div className="outputHeader">Add Team Member</div>
                </div>
                <AddTeamMemberForm
                  password={password}
                  fetchTeamMembers={fetchTeamMembers}
                />
              </Card>
            </motion.div>
          </>
        ) : null}
        {/* show password entry */}
        {!isAuthenticated ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div className="not-authed-msg">
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={["Enter password to access dashboard", 4600]}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <PasswordPrompt
                password={password}
                setPassword={setPassword}
                submitPassword={submitPassword}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default Dashboard;
