import React, { useState, useEffect, useContext } from "react";
import { isValidEthAddress, addCommasToNum } from "../../utils";
import { motion, AnimatePresence } from "framer-motion";
import ethGreenImg from "../../../images/misc/eth-green.png";
import ApiService from "../../../services/ApiService";

function CollectionWidget({ contractAddress, bot, setFromBot }) {
  const [collectionInfo, setCollectionInfo] = useState(null);

  useEffect(() => {
    if (!setFromBot) {
      return;
    }
    // collection info already set
    if (
      collectionInfo?.contractAddress &&
      bot?.contractAddress &&
      collectionInfo.contractAddress.toLowerCase() ===
        bot.contractAddress.toLowerCase()
    ) {
      return;
    }
    //collection info passed in via a bot - bypass making an api call
    if (bot && bot.collectionName) {
      setCollectionInfo({
        address: bot.contractAddress,
        image: bot.collectionImage,
        name: bot.collectionName,
        symbol: bot.collectionSymbol,
        tokenType: bot.collectionTokenType,
        totalSupply: bot.collectionTotalSupply,
      });
    }
    // bot or bot collection info is not valid
    else {
      setCollectionInfo(null);
    }
  }, [bot, setFromBot]);

  useEffect(() => {
    if (setFromBot) {
      return;
    }
    // collection info already set
    if (
      collectionInfo?.contractAddress &&
      contractAddress &&
      collectionInfo.contractAddress.toLowerCase() ===
        contractAddress.toLowerCase()
    ) {
      return;
    }
    //collection info passed in via a bot - bypass making an api call
    if (bot && bot.collectionName) {
      setCollectionInfo({
        address: bot.contractAddress,
        image: bot.collectionImage,
        name: bot.collectionName,
        symbol: bot.collectionSymbol,
        tokenType: bot.collectionTokenType,
        totalSupply: bot.collectionTotalSupply,
      });
    }
    // bot doesnt have collection info - make api call
    else if (
      isValidEthAddress(contractAddress) &&
      (!collectionInfo ||
        collectionInfo.address !== contractAddress.toLowerCase().trim())
    ) {
      const api = new ApiService();
      api.get(`collection/info/${contractAddress.trim()}`).then((data) => {
        if (data) {
          console.log(`CollectionWidget: collectionInfo`, data);
          setCollectionInfo(data);
        } else {
          setCollectionInfo(null);
        }
      });
    }
    // contractAddress is not valid
    else {
      setCollectionInfo(null);
    }
  }, [contractAddress]);

  return (
    <>
      <AnimatePresence>
        {collectionInfo && (
          <motion.div
            animate={{
              scale: [0, 1.04, 1],
              opacity: [0, 1],

              // scale: [0, 1.07, 1],
              // rotate: [-90, 3, 0],
              // opacity: [0, 1],
            }}
            exit={{
              scale: [1, 1.07, 0],
              rotate: [0, 3, -45],
              // scale: [0, 1.07, 1],
              // rotate: [-90, 3, 0],
              opacity: [1, 1, 0],
            }}
          >
            <div className="card collection-widget border-green">
              <div className="card-body">
                <div className="students d-flex align-items-center justify-content-between flex-wrap">
                  <div>
                    <h5 className="mb-5px">{collectionInfo.name}</h5>
                    <span className="mb-5px">{`Total Supply: ${addCommasToNum(
                      collectionInfo.totalSupply
                    )}`}</span>
                    <div className="bot-header-badges mt-2 d-flex align-items-center">
                      <img className="eth-logo" width={11} src={ethGreenImg} />
                      {collectionInfo.tokenType === "ERC721" && (
                        <div className="badge bg-light">ERC 721</div>
                      )}
                      {collectionInfo.tokenType === "ERC1155" && (
                        <div className="badge bg-warning">ERC 1155</div>
                      )}
                    </div>
                  </div>
                  <div>
                    <img
                      className="br-50  border-green collection-img"
                      src={collectionInfo.image}
                    />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {/* {(!collectionInfo || (collectionInfo && !collectionInfo.openSea)) && (
        <motion.div
          animate={{
            scale: [1, 1.03, 1.03, 1, 1],
            opacity: [0, 0.33, 0.66, 1],
          }}
        >
          <div className="invalid-collection-msg text-warning">
            Invalid collection address
          </div>
        </motion.div>
      )} */}
    </>
  );
}

export default CollectionWidget;
