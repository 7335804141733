import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Papa from "papaparse";

import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import loadingCircleImg from "../../../../images/misc/loading-circle-green.svg";
import landmarkImg from "../../../../images/landmark/logo-text-dark.png";
import ApiService from "../../../../services/ApiService";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { utils } from "ethers";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { FileCopyOutlined as FileCopyOutlinedIcon } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { darken } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TypeAnimation } from "react-type-animation";
import { copyTextToClipboard, timeNow, useDocumentTitle } from "../../../utils";
import { ThemeContext } from "../../../../context/ThemeContext";
import {
  addCommas,
  epochToDateString,
  epochToDateTimeString,
  stringToRGB,
  timeAgo,
  toTitleCase,
} from "../../../../utils";

import DeDuperImg from "../../../../images/misc/deduper.png";
import BlueLoading from "../../../../images/misc/blue-goo-loading.svg";
import { Parser } from "@json2csv/plainjs";
// import { timeNow, useDocumentTitle } from "../../../utils";

function mapEmails(arr, emailKey, setEmailKey = null) {
  const emailsSet = new Set();

  arr.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (
        typeof obj[key] === "string" &&
        /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b/.test(obj[key])
      ) {
        const email = obj[key].toLowerCase().trim();
        emailsSet.add(email);
        if (setEmailKey && emailKey !== key) {
          setEmailKey(key);
        }
      }
    });
  });

  return Array.from(emailsSet);
}

const DeDuperForm = ({ password }) => {
  const [originalData, setOriginalData] = useState([]);
  const [emailKey, setEmailKey] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [csvFileName, setCsvFileName] = useState("");
  const [masterCsvData, setMasterCsvData] = useState([]);
  const [masterCsvFileName, setMasterCsvFileName] = useState("");

  const [masterLoading, setMasterLoading] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [duplicateCount, setDuplicateCount] = useState(0);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setCsvFileName(file.name);

    Papa.parse(file, {
      complete: (result) => {
        const emailsFromCsv = mapEmails(result.data, emailKey, setEmailKey);
        setOriginalData(result.data);
        setCsvData(emailsFromCsv);
        console.log(`csvData`, emailsFromCsv);
      },
      header: true,
    });
  };

  const handleMasterFileUpload = (e) => {
    const file = e.target.files[0];
    setMasterCsvFileName(file.name);

    setMasterLoading(true);

    setTimeout(() => {
      Papa.parse(file, {
        complete: (result) => {
          const emailsFromCsv = mapEmails(result.data);
          setMasterCsvData(emailsFromCsv);
          console.log(`masterCsvData`, emailsFromCsv);
          setMasterLoading(false);
        },
        header: true,
      });
    }, 500);
  };

  const handleCsvUploadBtnClick = () => {
    document.getElementById("csv-file-input").click();
  };

  const handleMasterCsvUploadBtnClick = () => {
    document.getElementById("master-csv-file-input").click();
  };

  async function getSalesforceContacts() {
    try {
      const api = new ApiService({ password });
      const result = await api.get(`landmark/salesforce/getContacts`);
      console.log(`getSalesforceContacts() - result`, result);
      setMasterLoading(false);
      if (!result || !result.meta || !result.contactsEmails) {
        toast.error("An error occurred when retrieving Salesforce contacts");
        return;
      }
      // success
      setMasterCsvData(result.contactsEmails);
      setMasterCsvFileName(
        `Salesforce Contacts (updated ${timeAgo(
          null,
          result.meta.secondsSinceUpdate
        )})`
      );
    } catch (error) {
      toast.error("An error occurred when retrieving Salesforce contacts");
      console.error(error);
    }
  }

  const handleSalesforceBtn = () => {
    setMasterLoading(true);
    // reset master csv state
    setMasterCsvData([]);
    setMasterCsvFileName("");
    setTimeout(() => {
      getSalesforceContacts();
    }, 800);
  };

  const generateFinalData = () => {
    setGenerateLoading(true);
    setTimeout(() => {
      const masterSet = new Set(masterCsvData);
      const finalEmails = csvData.filter((email) => !masterSet.has(email));
      const finalEmailsSet = new Set(finalEmails);
      const duplicateCount = csvData.length - finalEmails.length;

      // now we have a final list of emails filter the original data
      const filteredOriginal = originalData.filter((od) =>
        finalEmailsSet.has(od[emailKey].toLowerCase().trim())
      );
      setDuplicateCount(duplicateCount);
      setFinalData(filteredOriginal);
      setGenerateLoading(false);
      setTimeout(() => {
        toast.success("DeDupe Successful", { duration: 5000 });
      }, 750);
    }, 1500);
  };

  // const downloadFinalDataEmailsOnly = () => {
  //   const fileName = `deduped-emails-${timeNow()}.csv`;
  //   const csv = finalData.join("\n");
  //   const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  //   const link = document.createElement("a");
  //   if (link.download !== undefined) {
  //     const url = URL.createObjectURL(blob);
  //     link.setAttribute("href", url);
  //     link.setAttribute("download", fileName);
  //     link.style.visibility = "hidden";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     toast.success("File Downloaded");
  //   }
  // };

  const downloadFinalData = () => {
    const fileName = `deduped-emails-${timeNow()}.csv`;
    try {
      const parser = new Parser();
      const csv = parser.parse(finalData);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success("File Downloaded");
      }
    } catch (err) {
      console.error(err);
    }
  };

  // async function getEvents() {
  //   try {
  //     const api = new ApiService({ password });
  //     const tag = "test";
  //     const eventsResponse = await api.get(`landmark/events/getEvents/${tag}`);
  //     console.log(`OwnerAssign - events`, eventsResponse);
  //     setEvents(eventsResponse);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // useEffect(() => {
  //   getEvents();
  // }, []);

  return (
    <>
      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
        <Card className="mb-3 h-fc bot-table">
          <div className="logo-container">
            <img src={DeDuperImg} className="img-fluid" />
          </div>
          <div className="row">
            <>
              <div className="dedupe-form-wrapper">
                <div className="form-section">
                  <div className="events-owners-subheading">
                    Upload a CSV file containing emails
                  </div>
                  <button
                    className={`upload-csv-btn btn btn-sm btn-primary`}
                    onClick={handleCsvUploadBtnClick}
                  >
                    {`Upload CSV`}
                    <i className="event-owner-add fa-sharp fa-solid fa-upload ml-05"></i>
                  </button>
                  <input
                    type="file"
                    accept=".csv"
                    id="csv-file-input"
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                  />
                  {csvData && csvData.length > 0 ? (
                    <div>
                      <div className="file-name">{csvFileName}</div>
                      <div className="file-name">
                        Emails: {addCommas(csvData.length)}
                      </div>
                      <div className="file-name sm">{`${JSON.stringify(csvData)
                        .replace(/[\[\]"]/g, "")
                        .substring(0, 120)}...`}</div>
                    </div>
                  ) : null}
                </div>

                {csvData.length > 0 && (
                  <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
                    <div className="form-section">
                      <div className="events-owners-subheading">
                        Choose Reference List
                      </div>
                      <div className="csv-option-container">
                        <button
                          className={`upload-csv-btn btn btn-sm btn-primary`}
                          onClick={handleSalesforceBtn}
                        >
                          {`Use Salesforce List`}
                          <i className="event-owner-add fa-sharp fa-solid fa-user ml-05"></i>
                        </button>
                        <button
                          className={`upload-csv-btn btn btn-sm btn-primary`}
                          onClick={handleMasterCsvUploadBtnClick}
                        >
                          {`Upload Master CSV`}
                          <i className="event-owner-add fa-sharp fa-solid fa-upload ml-05"></i>
                        </button>
                      </div>
                      <input
                        type="file"
                        accept=".csv"
                        id="master-csv-file-input"
                        style={{ display: "none" }}
                        onChange={handleMasterFileUpload}
                      />
                      {masterLoading ? (
                        <div className="loading-container">
                          <img src={BlueLoading} className="img-fluid" />
                        </div>
                      ) : null}
                      {masterCsvData && masterCsvData.length > 0 ? (
                        <div>
                          <div className="file-name">{masterCsvFileName}</div>
                          <div className="file-name">
                            Emails: {addCommas(masterCsvData.length)}
                          </div>
                          <div className="file-name sm">{`${JSON.stringify(
                            masterCsvData
                          )
                            .replace(/[\[\]"]/g, "")
                            .substring(0, 120)}...`}</div>
                        </div>
                      ) : null}
                    </div>
                  </motion.div>
                )}
                {masterCsvData.length > 0 && (
                  <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
                    <div className="form-section">
                      <div className="csv-option-container">
                        <button
                          className={`upload-csv-btn btn btn-sm btn-primary`}
                          onClick={generateFinalData}
                        >
                          {`DeDupe`}
                          <i className="event-owner-add fa-sharp fa-solid fa-layer-group ml-05"></i>
                        </button>
                      </div>

                      {generateLoading ? (
                        <div className="loading-container">
                          <img src={BlueLoading} className="img-fluid" />
                        </div>
                      ) : null}
                      {finalData && finalData.length > 0 ? (
                        <div>
                          <div className="file-name">
                            Emails: {addCommas(finalData.length)}
                          </div>
                          <div className="file-name">
                            {`(${duplicateCount} duplicates removed)`}
                          </div>
                          <motion.div
                            animate={{ scale: 1 }}
                            initial={{ scale: 0 }}
                          >
                            <button
                              className={`upload-csv-btn dl-btn btn btn-sm btn-primary`}
                              onClick={downloadFinalData}
                            >
                              {`Download`}
                              <i className="event-owner-add fa-sharp fa-solid fa-download ml-05"></i>
                            </button>
                          </motion.div>
                        </div>
                      ) : null}
                    </div>
                  </motion.div>
                )}
              </div>
            </>
          </div>
        </Card>
      </motion.div>
    </>
  );
};

const PasswordPrompt = ({ password, setPassword, submitPassword }) => {
  const handleInputChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submitPassword(password);
  };

  return (
    <div className="d-flex">
      <input
        onChange={handleInputChange}
        type="password"
        className="form-control w-100 mt-1 inline-input"
        placeholder="Password"
        value={password}
      ></input>
      <div
        className="btn btn-primary light btn-xs mt-1 me-1 w-fc inline-btn"
        onClick={(e) => handleSubmit(e)}
      >
        Submit
      </div>
    </div>
  );
};

const Dashboard = (props) => {
  const { changeBackground } = useContext(ThemeContext);
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // set light theme
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);

  async function submitPassword(password) {
    if (!password) {
      toast.error("Invalid Password");
      return;
    }
    try {
      const api = new ApiService();
      const { success, error } = await api.post(`landmark/events/auth`, {
        password,
      });
      if (!success) {
        toast.error(`${error}`);
        setIsAuthenticated(false);
        return false;
      } else {
        toast.success(`${success}`);
        setIsAuthenticated(true);
        return true;
      }
    } catch (error) {
      console.error(error);
      setIsAuthenticated(false);
      return false;
    }
  }

  useDocumentTitle("DeDupe - Landmark Ventures");

  return (
    <Fragment>
      <div className="global-dash-wrapper dedupe">
        {/* <button disabled={isLoading} onClick={() => signMessage()}>
        Sign message
      </button>
      {isSuccess && <div>Signature: {data}</div>}
      {isError && <div>Error signing message</div>} */}
        <div className="d-flex align-items-center justify-content-center">
          <img src={landmarkImg} className="img-fluid landmark-img" />
        </div>
        <PageHeaderBar
          pages={[
            { title: "Landmark", url: `` },
            { title: "DeDuper", url: `/landmark/deduper` },
          ]}
        />
        {/* show dashboard */}
        {isAuthenticated ? (
          <>
            <DeDuperForm password={password} />
          </>
        ) : null}
        {/* show password entry */}
        {!isAuthenticated ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div className="not-authed-msg">
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={["Enter password to access tool", 4600]}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <PasswordPrompt
                password={password}
                setPassword={setPassword}
                submitPassword={submitPassword}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default Dashboard;
