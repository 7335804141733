import React, { useState, useRef } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useOutsideAlerter } from "../../utils";
// import "emoji-mart/css/emoji-mart.css";

function EmojiPicker({ onEmojiSelect }) {
  const wrapperRef = useRef(null);
  const [popupVisible, setPopupVisible] = useState(false);

  const togglePopupVisible = () => {
    if (popupVisible) {
      // clicked on toggle button but already visible
      // do nothing because useOutsideAlerter() will close the popup
      return;
    }
    setPopupVisible(!popupVisible);
  };

  const close = () => {
    setPopupVisible(false);
  };

  useOutsideAlerter(wrapperRef, close);

  const positioningClass = "down"; // or up

  return (
    <div className="position-relative emoji-toggle">
      <div onClick={togglePopupVisible} className="hvr-shrink">
        <i className="fa-regular fa-face-smile"></i>
      </div>
      {popupVisible && (
        <div className={`emoji-popup ${positioningClass}`} ref={wrapperRef}>
          <Picker
            theme="light"
            data={data}
            onEmojiSelect={(e) => onEmojiSelect(e)}
          />
        </div>
      )}
    </div>
  );
}

export default EmojiPicker;
