import React from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import toast from "react-hot-toast";
import urlRegex from "url-regex";

export const addOneMonth = (dateString) => {
  // Split the date string into month, day, and year components
  const [month, day, year] = dateString.split("/").map(Number);

  // Create a new Date object
  const date = new Date(year, month - 1, day); // Month is zero-indexed, so subtract 1

  // Add one month
  date.setMonth(date.getMonth() + 1);

  // Get the new month, day, and year
  const newMonth = date.getMonth() + 1; // Month is zero-indexed, so add 1
  const newDay = date.getDate();
  const newYear = date.getFullYear();

  // Return the new date in the format MM/DD/YYYY
  return `${newMonth}/${newDay}/${newYear}`;
};

export const truncateAddress = (address) => {
  if (!address) return "No Account";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{3})[a-zA-Z0-9]+([a-zA-Z0-9]{3})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};
export const unixToDate = (epochTime, options = { removeDay: false }) => {
  const date = new Date(epochTime * 1000); // convert seconds to milliseconds
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  if (options.removeDay) {
    return `${month} ${year}`;
  }
  const day = date.getDate();
  // add the appropriate ordinal suffix to the day (e.g. "1st", "2nd", "3rd")
  let dayStr;
  if (day % 10 === 1 && day !== 11) {
    dayStr = day + "st";
  } else if (day % 10 === 2 && day !== 12) {
    dayStr = day + "nd";
  } else if (day % 10 === 3 && day !== 13) {
    dayStr = day + "rd";
  } else {
    dayStr = day + "th";
  }
  return `${dayStr} ${month} ${year} - ${("0" + date.getHours()).slice(-2)}:${(
    "0" + date.getMinutes()
  ).slice(-2)}:${("0" + date.getSeconds()).slice(-2)}`;
};

export const toHex = (num) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};

// always assumes white text overlay
export const stringToRGB = (str) => {
  // Simple hashing function to generate consistent RGB values
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  // Calculate RGB values based on the hash
  const r = (hash & 0xff0000) >> 16;
  const g = (hash & 0x00ff00) >> 8;
  const b = hash & 0x0000ff;
  // Calculate luminance (YIQ formula) to determine if the text should be black or white
  const luminance = (r * 0.299 + g * 0.587 + b * 0.114) / 255;
  // If the luminance is too low, adjust the RGB values to increase luminance
  const minLuminance = 0.5; // Minimum acceptable luminance
  if (luminance < minLuminance) {
    // Increase the RGB values while keeping the luminance above the minimum
    const adjustmentFactor = (minLuminance - luminance) / luminance;
    const adjustedR = Math.min(Math.round(r * adjustmentFactor), 255);
    const adjustedG = Math.min(Math.round(g * adjustmentFactor), 255);
    const adjustedB = Math.min(Math.round(b * adjustmentFactor), 255);
    // Return the adjusted RGB value and the text color
    return `rgb(${adjustedR},${adjustedG},${adjustedB})`;
  }
  // Return the original RGB value and the text color
  return `rgb(${r},${g},${b})`;
};

// checks each word individually.
// If a word is in all capital letters, it leaves it as is. Otherwise, it converts the word to title case.
export const toTitleCaseByWord = (str) => {
  // Function to check if a word is all uppercase
  function isAllCaps(word) {
    return word === word.toUpperCase();
  }

  // Function to convert a word to title case
  function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  // Split the string into words, process each word, and join them back together
  return str
    .split(" ")
    .map((word) => {
      if (isAllCaps(word)) {
        return word;
      } else {
        return capitalize(word);
      }
    })
    .join(" ");
};

export const toTitleCase = (str) => {
  if (!str || typeof str !== "string") {
    return "";
  }

  // Split the input string into words using whitespace as the separator
  const words = str.split(/\s+/);

  // Capitalize the first letter of each word and convert the rest to lowercase
  const titleCaseWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  // Join the words back together to form the title case string
  const titleCaseString = titleCaseWords.join(" ");

  return titleCaseString;
};

export const stripNonNumeric = (str) => {
  return str.replace(/[^0-9]/g, "");
};

export const stripNonTwitterHandleChars = (str) => {
  // Replace any non-alphanumeric characters and underscores with an empty string
  return str.replace(/[^a-zA-Z0-9_]/g, "");
};

export const timeUntil = ({ secondsUntil }) => {
  const msUntil = secondsUntil * 1000; // convert seconds to milliseconds
  const now = new Date();
  //const untilDate = new Date(now.getTime() + msUntil);

  const hoursUntil = Math.floor(msUntil / (1000 * 60 * 60));
  const minutesUntil = Math.floor(msUntil / (1000 * 60)) % 60;
  const secondsLeft = Math.floor(msUntil / 1000) % 60;

  if (hoursUntil > 0) {
    return `in ${hoursUntil} hours`;
  } else if (minutesUntil > 0) {
    return `in ${minutesUntil} minutes`;
  } else {
    if (secondsLeft < 5) {
      return `any second now`;
    }
    return `in ${secondsLeft} seconds`;
  }
};

export const timeAgo = (epochTime, deltaOverride = null) => {
  const now = Date.now();
  let delta = (now - epochTime * 1000) / 1000; // Convert to seconds

  if (deltaOverride !== null) {
    delta = deltaOverride;
  }

  let interval;

  if (delta < 5) {
    return "Just now";
  }

  if (delta < 60) {
    interval = Math.floor(delta);
    return interval === 1 ? "1 second ago" : `${interval} seconds ago`;
  }

  if (delta < 3600) {
    interval = Math.floor(delta / 60);
    return interval === 1 ? "1 minute ago" : `${interval} minutes ago`;
  }

  if (delta < 86400) {
    interval = Math.floor(delta / 3600);
    return interval === 1 ? "1 hour ago" : `${interval} hours ago`;
  }

  if (delta < 604800) {
    interval = Math.floor(delta / 86400);
    return interval === 1 ? "1 day ago" : `${interval} days ago`;
  }

  interval = Math.floor(delta / 604800);
  return interval === 1 ? "1 week ago" : `${interval} weeks ago`;
};

// The formula below returns a random integer between min and max (inclusive of both).
export const getRandomIntInRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const fileExtensionIsImage = (extension) => {
  try {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "svg", "svg+xml"];
    return imageExtensions.includes(extension.toLowerCase());
  } catch (error) {
    console.log(
      `fileExtensionIsImage() - extension is likely undefined - extension:`,
      extension
    );
    return true; // returning true
  }
};

// preserves new-line chars
export const convertStringToHtml = (str) => {
  if (!str || (str && str.length === 0)) {
    return <></>;
  }
  const lines = str.split("\n");
  return (
    <div>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </div>
  );
};

export const makeReplacementsToString = (str, replacements) => {
  if (replacements) {
    for (const [strToReplace, replacementStr] of Object.entries(replacements)) {
      str = str.replaceAll(strToReplace, replacementStr);
    }
  }
  return str;
};

// preserves new-line chars & highlights in blue any # @ or urls
export const convertStringToTwitterHtml = (str, replacements) => {
  if (!str || str.length === 0) {
    return null;
  }
  const lines = str.split("\n");
  let html = (
    <div>
      {lines.map((line, index) => {
        const words = line.split(/\s+/);
        return (
          <React.Fragment key={index}>
            {words.map((word, i) => {
              if (word.match(/^(https?:\/\/|www\.|mailto:)/i)) {
                const displayUrl = word.replace(/^(https?:\/\/)?(www\.)?/i, "");
                return (
                  <a
                    key={i}
                    href={word}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="col-g"
                  >
                    {displayUrl}&nbsp;
                  </a>
                );
              } else if (word.match(/^(@|#)\w+/)) {
                return (
                  <span key={i} className="col-g">
                    {word}&nbsp;
                  </span>
                );
              } else {
                return (
                  <React.Fragment key={i}>
                    {makeReplacementsToString(word, replacements)}&nbsp;
                  </React.Fragment>
                );
              }
            })}
            <br />
          </React.Fragment>
        );
      })}
    </div>
  );
  return html;
};

export const calculateTwitterCharacters = (contentStr) => {
  if (typeof contentStr !== "string") {
    return 0;
  }
  // console.log(`contentStr`, contentStr);
  const urls = `${contentStr}`.match(urlRegex()) || [];
  // console.log(`urls`, urls);
  let urlsLength = 0;
  urls.forEach((u) => (urlsLength += u.length));

  const contentLengthWithoutUrls = contentStr.length - urlsLength;
  const finalLength = contentLengthWithoutUrls + urls.length * 23;
  return finalLength;
};

export const downloadFileFromUrl = (url, fileName) => {
  try {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, fileName);
      });
  } catch (error) {
    toast.error("An error occurred when saving");
  }
  return;
  // text content
  const texts = ["line 1", "line 2", "line 3"];

  // file object
  const file = new Blob(texts, { type: "text/plain" });

  // anchor link
  const element = document.createElement("a");
  element.href = URL.createObjectURL(file);
  element.download = "100ideas-" + Date.now() + ".txt";

  // simulate link click
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

export const epochToDateString = (epochTime, options = {}) => {
  const date = new Date(epochTime * 1000);

  // Get the day, month, and year from the date
  const day = date.getDate();
  const month = date.getMonth() + 1; // JavaScript months are 0-indexed
  const fullYear = date.getFullYear();
  const year = fullYear.toString().substring(2); // Get the last two digits

  // Simple US format: MM/DD/YY
  if (options.simpleUS) {
    return `${month.toString().padStart(2, "0")}/${day
      .toString()
      .padStart(2, "0")}/${year}`;
  }

  // Simple UK format: DD/MM/YY
  if (options.simpleUK) {
    return `${day.toString().padStart(2, "0")}/${month
      .toString()
      .padStart(2, "0")}/${year}`;
  }

  // Define an array of suffixes for the day of the month
  const suffixes = ["th", "st", "nd", "rd"];
  let suffix =
    suffixes[
      day % 10 === 1 && day !== 11
        ? 1
        : day % 10 === 2 && day !== 12
        ? 2
        : day % 10 === 3 && day !== 13
        ? 3
        : 0
    ];

  const dayWithSuffix = `${day}${suffix}`;

  // Get the shortened month name
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthName = monthNames[date.getMonth()];

  // Concatenate the date components into a verbose string
  return `${dayWithSuffix} ${monthName} ${fullYear}`;
};

export const epochToDateTimeString = (epochTime) => {
  const date = new Date(epochTime * 1000);
  // Define an array of suffixes for the day of the month
  const suffixes = ["th", "st", "nd", "rd"];
  // Get the day of the month and add the appropriate suffix
  let day = date.getDate();
  let suffix =
    suffixes[
      day % 10 === 1 && day !== 11
        ? 1
        : day % 10 === 2 && day !== 12
        ? 2
        : day % 10 === 3 && day !== 13
        ? 3
        : 0
    ];
  day += suffix;
  // Get the shortened month name
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()];
  // Get the year
  const year = date.getFullYear();
  // Get the hours and minutes
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  // Concatenate the date components and time into a string
  const dateTimeString = `${day} ${month} ${year} ${hours}:${minutes}`;
  return dateTimeString;
};

export const addCommas = (num) => {
  return num.toLocaleString();
};

export const arraysAreEqual = (arr1, arr2) => {
  // Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Sort the arrays
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  // Compare the sorted arrays
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  // If all elements are equal, the arrays are identical
  return true;
};
