import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

import discordHookImg from "../../../images/misc/discord-hook.png";

function DiscordWebhookSelectDropdown({
  discordWebhooks,
  onSelect,
  selectedDiscordWebhook,
}) {
  const [selectedWebhook, setSelectedWebhook] = useState(
    selectedDiscordWebhook || null
  );
  const handleSelect = (eventKey) => {
    const selected = discordWebhooks.find(
      (w) => w.discordWebhookId === eventKey
    );
    setSelectedWebhook(selected);
    onSelect(selected);
  };

  return (
    <Dropdown onSelect={handleSelect} className="twitter-auth-select-dropdown">
      <Dropdown.Toggle
        className="basic-dropdown"
        variant="primary"
        id="dropdown-basic"
      >
        {selectedWebhook ? (
          <>
            {selectedWebhook.channelName}{" "}
            <span>{`(${selectedWebhook.serverName})`}</span>
          </>
        ) : (
          "Select Webhook"
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {discordWebhooks.map((webhook) => (
          <Dropdown.Item
            key={webhook.discordWebhookId}
            eventKey={webhook.discordWebhookId}
          >
            <div className="d-flex align-items-center twitter-auth-dropdown-item">
              <img
                className="br-50  border-green mr-1"
                src={discordHookImg}
                alt="Discord Webhook Logo"
              />
              <div className="d-flex flex-column">
                <div className="name">{webhook.channelName}</div>
                <span className="username">{webhook.serverName}</span>
              </div>
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DiscordWebhookSelectDropdown;
