import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Papa from "papaparse";

import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import loadingCircleImg from "../../../../images/misc/loading-circle-green.svg";
import landmarkImg from "../../../../images/landmark/logo-text-dark.png";
import ApiService from "../../../../services/ApiService";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { utils } from "ethers";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { FileCopyOutlined as FileCopyOutlinedIcon } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { darken } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TypeAnimation } from "react-type-animation";
import { copyTextToClipboard, timeNow, useDocumentTitle } from "../../../utils";
import { ThemeContext } from "../../../../context/ThemeContext";
import {
  addCommas,
  epochToDateString,
  epochToDateTimeString,
  stringToRGB,
  timeAgo,
  toTitleCase,
  toTitleCaseByWord,
} from "../../../../utils";

import SeamlessBotImg from "../../../../images/misc/ceo-sniper-logo.png";
import BlueLoading from "../../../../images/misc/blue-goo-loading.svg";
import { Parser } from "@json2csv/plainjs";
// import { timeNow, useDocumentTitle } from "../../../utils";

const SeamlessAccounts = [
  "ihaq@landmarkventures.com",
  "ibrown@landmarkventures.com",
  "dsoukup@landmarkventures.com",
  "Sniessl@landmarkventures.com",
  "ptemplet@landmarkventures.com",
];

const BatchIntervals = [
  "6 hours",
  "12 hours",
  "24 hours",
  "48 hours",
  "72 hours",
  "Weekly",
];

function mapEmails(arr, emailKey, setEmailKey = null) {
  const emailsSet = new Set();

  arr.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (
        typeof obj[key] === "string" &&
        /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b/.test(obj[key])
      ) {
        const email = obj[key].toLowerCase().trim();
        emailsSet.add(email);
        if (setEmailKey && emailKey !== key) {
          setEmailKey(key);
        }
      }
    });
  });

  return Array.from(emailsSet);
}

const SeamlessBotForm = ({ employee, password }) => {
  const [notes, setNotes] = useState("");
  const [sourceOfLead, setSourceOfLead] = useState("");
  const [referencePersonName, setReferencePersonName] = useState("");
  const [referencePersonTitle, setReferencePersonTitle] = useState("");
  const [referenceCompany, setReferenceCompany] = useState("");
  const [companyUrl, setCompanyUrl] = useState("");
  const [allTasks, setAllTasks] = useState([]);

  async function fetchAllTasks() {
    try {
      const api = new ApiService({ password, botsApiUrl: true });
      const taskArr = await api.get(`landmark/ceosniper/getAllTasks`);
      setAllTasks(taskArr.reverse());
      console.log(`allTasks`, taskArr);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchAllTasks();
  }, []);

  const handleRunBotBtn = async () => {
    if (!companyUrl) {
      toast.error("Please enter a valid Company URL");
      return;
    }
    if (!referenceCompany || !referenceCompany.length) {
      toast.error("Please enter a Reference Company");
      return;
    }
    if (!isRunBtnEnabled) {
      return;
    }
    try {
      const api = new ApiService({ botsApiUrl: true });
      const defaultLinkedInEmail = "mjonezdev@gmail.com";
      const { success, error } = await api.post(
        `landmark/ceosniper/createTask`,
        {
          employee,
          password,
          referencePersonName,
          referencePersonTitle,
          referenceCompany,
          companyUrl,
          notes,
          linkedInEmail: defaultLinkedInEmail,
        }
      );
      if (!success) {
        toast.error(`${error}`);
        return false;
      } else {
        toast.success(`${success}`, { duration: "6000" });
        // toast.success(`Results will be emailed to ${resultsEmail}`, {
        //   duration: "6000",
        // });
        // clear fields
        // setSearchTitle("");
        setCompanyUrl("");
        setReferencePersonName("");
        setReferencePersonTitle("");
        setReferenceCompany("");
        setNotes("");
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const setTaskHandled = async (taskId, handled) => {
    try {
      const api = new ApiService({ botsApiUrl: true });
      const { success, error } = await api.post(
        `landmark/ceosniper/setTaskHandled`,
        {
          employee,
          password,
          taskId,
          handled,
        }
      );
      if (!success) {
        toast.error(`${error}`);
        return false;
      } else {
        toast.success(`${success}`, { duration: "6000" });
        // refresh list
        fetchAllTasks();
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  let isRunBtnEnabled = false;

  if (companyUrl && referenceCompany && referenceCompany.length) {
    isRunBtnEnabled = true;
  }

  return (
    <>
      {/* <div className="bot-downtime-msg">
        03/13/24 - Bot is experiencing downtime due to LinkedIn/Seamless
        changing the way contacts are displayed
      </div> */}
      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
        <Card className="mb-3 h-fc bot-table">
          <div className="logo-container">
            <img src={SeamlessBotImg} className="img-fluid" />
          </div>
          <div className="sub-logo-text-container">
            <div>
              • Provide the bot with the website of the company
              <br />
              • The bot will attempt to find the CEO of the company, and add
              them to a Sales Navigator list
              <br />
            </div>
          </div>
          <div className="row">
            <>
              <div className="seamless-bot-form-wrapper">
                <div className="form-section text-left">
                  {/* <div className="events-owners-subheading text-center">
                    Enter your LinkedIn credentials
                    <br />
                    (account must have Sales access)
                  </div>
                  <div className="form-group mb-3 col-12">
                    <label className="form-label">
                      Email <span className="example-text"></span>
                    </label>
                    <input
                      type="text"
                      value={linkedInUsername}
                      className="form-control"
                      onChange={(e) =>
                        handleSetLinkedInUsername(e.target.value)
                      }
                      autoComplete="email"
                    />
                  </div>
                  <div className="form-group mb-3 col-12">
                    <label className="form-label">
                      Password <span className="example-text"></span>
                    </label>
                    <input
                      type="password"
                      value={linkedInPassword}
                      className="form-control"
                      onChange={(e) => setLinkedInPassword(e.target.value)}
                      autoComplete="linkedInPassword"
                    />
                  </div> */}
                  {/* <div className="events-owners-subheading text-center">
                    Enter LinkedIn URLs
                    <br />
                  </div> */}
                  <div className="form-group mb-3 col-12">
                    <label className="form-label">
                      Company Website <br />
                      <span className="example-text">
                        e.g. https://www.bastille.net/
                      </span>
                    </label>
                    <input
                      type="text"
                      value={companyUrl}
                      className="form-control"
                      onChange={(e) => setCompanyUrl(e.target.value)}
                    />
                    <br></br>
                    <label className="form-label">
                      Reference Company <br />
                      <span className="example-text">
                        The company of the person who gave you this lead
                      </span>{" "}
                      <br />
                      <span className="example-text">
                        {/* If provided, will be used to create a personalized
                        outreach message.
                        <br /> */}
                        e.g. "HIG Capital" or "Dyson"
                      </span>
                    </label>
                    <input
                      type="text"
                      value={referenceCompany}
                      className="form-control"
                      onChange={(e) =>
                        setReferenceCompany(toTitleCaseByWord(e.target.value))
                      }
                    />
                    <br></br>{" "}
                    <label className="form-label">
                      Reference Person Title{" "}
                      <span className="example-text">(optional)</span> <br />
                      <span className="example-text">
                        The job title of the person who gave you this lead
                      </span>{" "}
                      <br />
                      <span className="example-text">
                        {/* If provided, will be used to create a personalized
                        outreach message.
                        <br /> */}
                        e.g. "CMO" or "Marketing Director"
                      </span>
                    </label>
                    <input
                      type="text"
                      value={referencePersonTitle}
                      className="form-control"
                      onChange={(e) =>
                        setReferencePersonTitle(
                          toTitleCaseByWord(e.target.value)
                        )
                      }
                    />
                    <br></br>
                    <label className="form-label">
                      Reference Person Name{" "}
                      <span className="example-text">(optional)</span> <br />
                      <span className="example-text">
                        The name of the person who gave you this lead
                      </span>{" "}
                      <br />
                      <span className="example-text">
                        {/* If provided, will be used to create a personalized
                        outreach message.
                        <br /> */}
                        e.g. "Mark Fiske" or "Amy Adams"
                      </span>
                    </label>
                    <input
                      type="text"
                      value={referencePersonName}
                      className="form-control"
                      onChange={(e) =>
                        setReferencePersonName(toTitleCase(e.target.value))
                      }
                    />
                    <br></br>
                    <label className="form-label">
                      Note <span className="example-text">(optional)</span>{" "}
                      <br />
                      <span className="example-text">
                        Anything else you'd like to mention. For our reference
                        only.
                        <br />
                        e.g. "from a tap with jon guy at apple"
                      </span>
                    </label>
                    <input
                      type="text"
                      value={notes}
                      className="form-control"
                      onChange={(e) => setNotes(e.target.value)}
                    />
                    <br></br>
                  </div>

                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      className={`run-btn btn btn-primary ${
                        isRunBtnEnabled ? "" : "disabled-soft"
                      }`}
                      onClick={handleRunBotBtn}
                    >
                      {`RUN BOT`}
                      <i className="event-owner-add fa-sharp fa-solid fa-robot ml-05"></i>
                    </button>
                  </div>
                </div>
              </div>
            </>
          </div>
        </Card>
        {allTasks && allTasks.length ? (
          <div className="ceo-sniper-tasks-section">
            <div className="header-text">Recent Jobs</div>
            <div className="tasks-container">
              {allTasks && allTasks.length
                ? allTasks.map((task) => {
                    const {
                      taskId,
                      employeeName,
                      employeeEmail,
                      notes,
                      companyUrl,
                      companyLinkedInUrl,
                      linkedInEmail,
                      ceoInfo: {
                        name: ceoName,
                        bio: ceoBio,
                        img: ceoImg,
                        jobSummary: ceoJobSummary,
                        jobTitle: ceoJobTitle,
                        companyUrl: ceoCompanyUrl,
                        salesNavUrl: ceoSalesNavUrl,
                      } = {},
                      companyInfo: {
                        name: companyName,
                        summary: companySummary,
                        img: companyImg,
                        companyUrl: companyInfoUrl,
                        ceoUrl: companyCeoUrl,
                      } = {},
                      ceoAddedToSalesNav,
                      completed,
                      createdTime,
                      errorMsg,
                      handled,
                    } = task;

                    // used for display logic
                    let linkedInFound =
                      companyLinkedInUrl &&
                      companyLinkedInUrl
                        .toLowerCase()
                        .includes("linkedin.com/company/");
                    let linkedInParseSuccess =
                      task.companyInfo && task.companyInfo.name;
                    let ceoFound =
                      companyCeoUrl &&
                      companyCeoUrl.toLowerCase().includes("linkedin.com/in/");
                    let ceoParseSuccess = task.ceoInfo && task.ceoInfo.name;

                    // linkedInFound = true;
                    // linkedInParseSuccess = false;
                    // ceoFound = true;
                    // ceoParseSuccess = false;

                    function getLinkedInShortUrl(url) {
                      return url.replace("https://www.", "");
                    }

                    return (
                      <div className="task-outer-container">
                        <div className="task-container">
                          <a href={companyUrl} target="_blank" className="info">
                            {companyUrl}
                          </a>
                          {notes ? (
                            <div className="info">
                              <b>{notes}</b>
                            </div>
                          ) : null}
                          {employeeEmail ? (
                            <div className="employee-name-container">
                              <div className="employee-name">
                                <b>
                                  {employeeEmail
                                    .toLowerCase()
                                    .replace("@landmarkventures.com", "")}
                                </b>{" "}
                                -{" "}
                                {epochToDateString(createdTime, {
                                  simpleUS: true,
                                })}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="arrow">
                          <i className="fa-solid fa-arrow-right"></i>
                        </div>
                        <div className="task-container">
                          {linkedInFound ? (
                            linkedInParseSuccess ? (
                              <div className="inner-container">
                                <img className="pfp" src={companyImg} />
                                <div>
                                  <div className="info highlighted">
                                    {companyName}
                                  </div>
                                  <div className="info">{companySummary}</div>
                                  <a
                                    href={companyLinkedInUrl}
                                    target="_blank"
                                    className="info"
                                  >
                                    {getLinkedInShortUrl(companyLinkedInUrl)}
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <div className="error-msg">
                                Error scraping LinkedIn company data
                              </div>
                            )
                          ) : (
                            <div className="error-msg">
                              No company LinkedIn found on website
                            </div>
                          )}
                        </div>
                        <div className="arrow">
                          <i className="fa-solid fa-arrow-right"></i>
                        </div>
                        <div className="task-container">
                          {ceoFound ? (
                            ceoParseSuccess ? (
                              <>
                                <div className="inner-container">
                                  <img className="pfp" src={ceoImg} />
                                  <div>
                                    <div className="info highlighted">
                                      {ceoName}
                                    </div>
                                    <div className="info">{ceoJobTitle}</div>
                                    <a
                                      href={companyCeoUrl}
                                      target="_blank"
                                      className="info"
                                    >
                                      {getLinkedInShortUrl(companyCeoUrl)}
                                    </a>
                                  </div>
                                </div>
                                {ceoAddedToSalesNav ? (
                                  <div className="employee-name-container sales-nav">
                                    <div className="employee-name">
                                      <i class="fa-solid fa-check"></i> Saved in
                                      Sales Navigator
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              <div className="error-msg">
                                Error scraping LinkedIn CEO data
                              </div>
                            )
                          ) : (
                            <div className="error-msg">
                              Company CEO could not be found
                            </div>
                          )}
                        </div>
                        {!ceoFound ? (
                          <div className="handled-container">
                            <div className="form-check custom-checkbox checkbox-success check-lg handled-outer">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={`handledCheckbox-${taskId}`}
                                required
                                defaultChecked={false}
                                checked={handled}
                                onChange={() =>
                                  setTaskHandled(taskId, !handled)
                                }
                              />
                              <label
                                className="handled-label"
                                htmlFor={`handledCheckbox-${taskId}`}
                              >
                                Handled
                              </label>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        ) : null}
      </motion.div>
    </>
  );
};

const PasswordPrompt = ({ password, setPassword, submitPassword }) => {
  const handleInputChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submitPassword(password);
  };

  return (
    <div className="d-flex">
      <input
        onChange={handleInputChange}
        type="password"
        className="form-control w-100 mt-1 inline-input"
        placeholder="Password"
        value={password}
        autoComplete="password"
      ></input>
      <div
        className="btn btn-primary light btn-xs mt-1 me-1 w-fc inline-btn"
        onClick={(e) => handleSubmit(e)}
      >
        Submit
      </div>
    </div>
  );
};

const EmployeeInfo = ({
  landmarkEmployee,
  handleOnClick = () => {},
  disableHover = false,
}) => {
  if (!landmarkEmployee) {
    return null;
  }
  const { firstName, lastName, email, employeeId } = landmarkEmployee;
  const disableHoverClass = disableHover ? "disable-hover" : "";
  return (
    <div
      className={`account ${disableHoverClass}`}
      onClick={() => handleOnClick(landmarkEmployee)}
    >
      <div
        className="user-icon"
        style={{
          backgroundColor: stringToRGB(employeeId),
        }}
      >
        <PersonIcon className="icon" />
      </div>
      <div>
        <div className="name">
          {firstName} {lastName}
        </div>
        <div className="email">{email}</div>
      </div>
    </div>
  );
};

const EmployeeSignIn = ({ password, signInAsEmployee }) => {
  const [employees, setEmployees] = useState([]);
  const [showNewAccount, setShowNewAccount] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  async function getEmployees() {
    try {
      const api = new ApiService({ password });
      const employeesResponse = await api.get(
        `landmark/employees/getEmployees`
      );
      console.log(`employees`, employeesResponse);
      setEmployees(employeesResponse);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getEmployees();
  }, []);

  const btnDisabled =
    firstName.length && lastName.length && email.length ? false : true;
  const btnDisabledClass = btnDisabled ? "disabled" : "";

  const handleSignUpBtnClicked = async () => {
    if (btnDisabled) {
      return;
    }
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/employees/addEmployee/`,
        {
          firstName,
          lastName,
          email,
        }
      );
      if (!success) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else {
        // toast.success(`Account Created`);
        const employee = success;
        signInAsEmployee(employee);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <div className="landmark-sign-in">
      <div className="card">
        <div className="card-body">
          <div className="header-text">Log in as</div>
          <div className="existing-accounts-container">
            {employees
              .sort((a, b) => {
                // If both have 'lastSeenTime', compare by 'lastSeenTime' (descending)
                if (a.lastSeenTime && b.lastSeenTime) {
                  return b.lastSeenTime - a.lastSeenTime;
                }

                // If one has 'lastSeenTime', prioritize that one
                if (a.lastSeenTime && !b.lastSeenTime) {
                  return -1;
                }
                if (!a.lastSeenTime && b.lastSeenTime) {
                  return 1;
                }

                // If neither have 'lastSeenTime', compare by 'firstName' alphabetically
                return a.firstName.localeCompare(b.firstName);
              })
              .map((employee, i) => (
                <EmployeeInfo
                  key={i}
                  landmarkEmployee={employee}
                  handleOnClick={signInAsEmployee}
                />
              ))}
          </div>
        </div>
      </div>
      {!showNewAccount && (
        <div
          className="new-account-btn"
          onClick={() => setShowNewAccount(true)}
        >
          <div className="hvr-shrink text">
            First time here? Create New Account
          </div>
        </div>
      )}
      {showNewAccount && (
        <motion.div
          animate={{
            scale: [0, 1.04, 1],
          }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <div className="card">
            <div className="card-body">
              <div className="header-text">New Account</div>
              <div className="settings-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        value={firstName}
                        className="form-control"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        value={lastName}
                        className="form-control"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="form-label">Email</label>
                    <div className="input-group input-primary">
                      <input
                        type="text"
                        value={email}
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <span className="input-group-text">
                        @landmarkventures.com
                      </span>
                    </div>
                  </div>
                  <div className="sign-up-btn-container">
                    <button
                      className={`sign-up-btn btn btn-primary btn-sm ${btnDisabledClass}`}
                      type="submit"
                      onClick={handleSignUpBtnClicked}
                    >
                      Sign up
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

const Dashboard = (props) => {
  const { changeBackground } = useContext(ThemeContext);
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [employee, setEmployee] = useState(null);

  // set light theme
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);

  const signInAsEmployee = (employee) => {
    if (!employee) {
      toast.error("Cannot sign in as employee");
      return;
    }
    setIsSignedIn(true);
    setEmployee(employee);

    try {
      const api = new ApiService({ employee });
      // sets lastSeenTime
      api.get(`landmark/employees/login`);
    } catch (error) {
      console.error(`signInAsEmployee() - employee login error`, error);
    }

    toast.success(`Logged in as ${employee.firstName} ${employee.lastName}`);
  };

  async function submitPassword(password) {
    if (!password) {
      toast.error("Invalid Password");
      return;
    }
    try {
      const api = new ApiService();
      const { success, error } = await api.post(`landmark/events/auth`, {
        password,
      });
      if (!success) {
        toast.error(`${error}`);
        setIsAuthenticated(false);
        return false;
      } else {
        toast.success(`${success}`);
        setIsAuthenticated(true);
        return true;
      }
    } catch (error) {
      console.error(error);
      setIsAuthenticated(false);
      return false;
    }
  }

  useDocumentTitle("CEO Sniper - Landmark Ventures");

  return (
    <Fragment>
      <div className="global-dash-wrapper seamless-bot">
        {/* <button disabled={isLoading} onClick={() => signMessage()}>
        Sign message
      </button>
      {isSuccess && <div>Signature: {data}</div>}
      {isError && <div>Error signing message</div>} */}
        <div className="d-flex align-items-center justify-content-center">
          <img src={landmarkImg} className="img-fluid landmark-img" />
        </div>
        {isSignedIn && (
          <div className="landmark-sign-in" style={{ marginBottom: "18px" }}>
            <div className="existing-accounts-container">
              <EmployeeInfo landmarkEmployee={employee} disableHover={true} />
            </div>
          </div>
        )}
        <PageHeaderBar
          pages={[
            { title: "Landmark", url: `` },
            { title: "LinkedIn CEO Sniper Bot", url: `/landmark/ceosniper` },
          ]}
        />
        {/* show dashboard */}
        {isAuthenticated && isSignedIn ? (
          <>
            <SeamlessBotForm employee={employee} password={password} />
          </>
        ) : null}
        {/* show sign in */}
        {isAuthenticated && !isSignedIn ? (
          <>
            <motion.div
              animate={{
                scale: [0, 1.04, 1],
              }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <EmployeeSignIn
                password={password}
                signInAsEmployee={signInAsEmployee}
              />
            </motion.div>
          </>
        ) : null}
        {/* show password entry */}
        {!isAuthenticated ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div className="not-authed-msg">
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={["Enter password to access tool", 4600]}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <PasswordPrompt
                password={password}
                setPassword={setPassword}
                submitPassword={submitPassword}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default Dashboard;
