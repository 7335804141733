import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import toast from "react-hot-toast";
import { Card } from "react-bootstrap";
import loadingCircleImg from "../../../../images/misc/loading-circle-green.svg";
import landmarkImg from "../../../../images/landmark/logo-text.png";
import ApiService from "../../../../services/ApiService";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { utils } from "ethers";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DoneIcon from "@mui/icons-material/Done";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { darken } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TypeAnimation } from "react-type-animation";
import { useDocumentTitle } from "../../../utils";
import { epochToDateString, epochToDateTimeString } from "../../../../utils";

let contractOwnedData = [];

const TableDataContext = createContext({});

const tag = "israeldealportal";

const OutputWindow = ({ password }) => {
  const [outputData, setOutputData] = useState([]);

  console.log(outputData);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  async function RenderTable() {
    const api = new ApiService({ password });

    try {
      const response = await api.get(
        `landmark/portfolio/getCompanyRsvps/${tag}`
      );
      setOutputData(response);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    RenderTable();
  }, []);

  // Avoid a layout jump when reaching the last page with empty rows.

  const getRowBackgroundColor = (row) => {
    if (row.original.handled) {
      return "#23a912";
    }
    return "#022238";
  };

  const arrayStringFilterFn = (row, _columnIds, filterValue) => {
    return row
      .getValue(_columnIds)
      .join(" ")
      .toLowerCase()
      .includes(filterValue.toLowerCase());
  };

  const eventTitleFilterFn = (row, _columnIds, filterValue) => {
    return row
      .getValue(_columnIds)
      .map((c) => c.eventTitle)
      .join(" ")
      .toLowerCase()
      .includes(filterValue.toLowerCase());
  };

  const companyTitleFilterFn = (row, _columnIds, filterValue) => {
    return row
      .getValue(_columnIds)
      .map((c) => c.companyTitle)
      .join(" ")
      .toLowerCase()
      .includes(filterValue.toLowerCase());
  };

  var columns = React.useMemo(
    () => [
      // {
      //   header: "Event ID",
      //   accessorKey: "eventId",
      // },
      {
        header: "Email",
        accessorKey: "email",
      },
      {
        header: "Name",
        accessorKey: "fullName",
      },
      {
        header: "Requested Companies",
        accessorKey: "requestedCompanies",
        filterFn: companyTitleFilterFn,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value.map((o, i) => {
            return (
              <div key={i} className="option-value">
                {o.companyTitle}
              </div>
            );
          });
        },
      },
      {
        header: "Company",
        accessorKey: "company",
      },
      {
        header: "Job Title",
        accessorKey: "jobTitle",
      },
      {
        header: "Notes",
        accessorKey: "notes",
      },
      {
        header: "Created Time",
        accessorKey: "createdTime",
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return epochToDateTimeString(value);
        },
      },
    ],
    []
  );
  // allCompanies.forEach(element => {
  //   columns.push({
  //     header: element.eventTitle,
  //     accessorKey: outputdata[0].
  //   })
  // });
  const handleExportRows = (rows) => {
    const purified = rows
      .map((row) => row.original)
      .map((o) => {
        const {
          firstName,
          lastName,
          fullName,
          company,
          jobTitle,
          email,
          notes,
          requestedCompaniesStr,
          createdTime,
        } = o;
        return {
          firstName,
          lastName,
          fullName,
          company,
          jobTitle,
          email,
          notes,
          requestedCompaniesStr,
          createdTime,
        };
      });
    csvExporter.generateCsv(purified);
  };

  const handleExportData = () => {
    const purified = outputData.map((o) => {
      const {
        firstName,
        lastName,
        fullName,
        company,
        jobTitle,
        email,
        notes,
        requestedCompaniesStr,
        createdTime,
      } = o;
      return {
        firstName,
        lastName,
        fullName,
        company,
        jobTitle,
        email,
        notes,
        requestedCompaniesStr,
        createdTime,
      };
    });
    csvExporter.generateCsv(purified);
  };

  const handleMarkRsvpsAsHandled = async (rows) => {
    const companyRsvpIds = rows.map((row) => row.original.rsvpCompanyId);
    // mark all as handled
    const handledArr = companyRsvpIds.map((e) => true);
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/portfolio/setCompanyRsvpsHandled/`,
        {
          companyRsvpIds,
          handledArr,
        }
      );
      if (!success) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else {
        toast.success(`${success}`);
        // refresh table
        RenderTable();
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const deleteRsvps = async (rows) => {
    const companyRsvpIds = rows.map((row) => row.original.rsvpCompanyId);
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/portfolio/removeCompanyRsvps`,
        {
          companyRsvpIds,
        }
      );
      if (!success) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else {
        toast.success(`${success}`);
        // refresh table
        RenderTable();
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  // const darkTheme = createTheme({
  //   palette: {
  //     mode: "dark",
  //     primary: {
  //       main: "#05558d", // Set your desired primary color
  //     },
  //     secondary: {
  //       main: "#28587a", // Set your desired secondary color
  //     },
  //   },
  // });

  const darkTheme = createTheme({
    palette: {
      mode: "dark", //let's use the same dark/light mode as the global theme
      primary: {
        main: "#17d2ff", // Set your desired primary color
      },
      info: {
        main: "#022238", //add in a custom color for the toolbar alert background stuff
      },
      background: {
        default: "#022238", //pure black table in dark mode for fun
      },
    },
  });

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    headers: columns.map((c) => c.accessorKey),
  };

  const csvExporter = new ExportToCsv(csvOptions);
  console.log(`render Companies table`);

  return (
    <>
      {/* <div>Test</div> */}
      {outputData && outputData.length ? (
        <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
          <Card className="mb-3 h-fc bot-table">
            <div className="row">
              <div className="outputHeader">Responses</div>
              <div className="events-owners-subheading">
                Browse and export form submissions for Israel Deal Portal
                <br></br>
                <br></br>
                Recommended workflow:
                <ul>
                  <li>• Filter the data based on your criteria</li>
                  <li>
                    • Click 'Export Current' to export the filtered data to a
                    spreadsheet
                  </li>
                  <li>• Once handled, click 'Mark Current as Handled'</li>
                </ul>
              </div>
              <>
                <div className="outputBody">
                  <Card.Body className="pt-0">
                    <div className="snapshot-output-container">
                      <ThemeProvider theme={darkTheme}>
                        <MaterialReactTable
                          columns={columns}
                          data={outputData}
                          enableRowSelection
                          muiTableContainerProps={{
                            sx: (theme) => ({
                              "&::-webkit-scrollbar": {
                                width: 20,
                              },
                              "&::-webkit-scrollbar-track": {
                                backgroundColor: `#091f2d`,
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: `#8fa9ba`,
                                borderRadius: "10px",
                              },
                            }),
                          }}
                          muiTableBodyRowProps={({ row }) => ({
                            style: {
                              backgroundColor: getRowBackgroundColor(row),
                            },
                          })}
                          muiTableBodyProps={{
                            sx: (theme) => ({
                              // "& tr:nth-of-type(odd)": {
                              //   backgroundColor: darken(
                              //     theme.palette.background.default,
                              //     0.05
                              //   ),
                              // },
                            }),
                          }}
                          initialState={{
                            density: "compact",
                            showColumnFilters: true,
                            expanded: false, //expand all groups by default
                            // grouping: ["email"], //an array of columns to group by by default (can be multiple)
                            pagination: { pageIndex: 0, pageSize: 20 },
                            // sorting: [{ id: "createdTime", desc: false }], //sort by state by default
                          }}
                          renderTopToolbarCustomActions={({ table }) => (
                            <Box
                              sx={{
                                display: "flex",
                                gap: "1rem",
                                p: "0.5rem",
                                flexWrap: "wrap",
                              }}
                            >
                              <Button
                                color="primary"
                                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                onClick={handleExportData}
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                              >
                                Export All
                              </Button>
                              <Button
                                disabled={
                                  table.getPrePaginationRowModel().rows
                                    .length === 0
                                }
                                //export all rows, including from the next page, (still respects filtering and sorting)
                                onClick={() =>
                                  handleExportRows(
                                    table.getPrePaginationRowModel().rows
                                  )
                                }
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                              >
                                Export Current
                              </Button>
                              <Button
                                className="handled-btn"
                                disabled={
                                  table.getPrePaginationRowModel().rows
                                    .length === 0
                                }
                                //export all rows, including from the next page, (still respects filtering and sorting)
                                onClick={() =>
                                  handleMarkRsvpsAsHandled(
                                    table.getPrePaginationRowModel().rows
                                  )
                                }
                                startIcon={<DoneIcon />}
                                variant="contained"
                              >
                                Mark Current as Handled
                              </Button>
                              {/* <Button
                                disabled={table.getRowModel().rows.length === 0}
                                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                onClick={() =>
                                  handleExportRows(table.getRowModel().rows)
                                }
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                              >
                                Export Page Rows
                              </Button> */}
                              <Button
                                disabled={
                                  !table.getIsSomeRowsSelected() &&
                                  !table.getIsAllRowsSelected()
                                }
                                //only export selected rows
                                onClick={() =>
                                  handleExportRows(
                                    table.getSelectedRowModel().rows
                                  )
                                }
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                              >
                                Export Selected
                              </Button>
                              <Button
                                className="handled-btn"
                                disabled={
                                  !table.getIsSomeRowsSelected() &&
                                  !table.getIsAllRowsSelected()
                                }
                                //only export selected rows
                                onClick={() =>
                                  handleMarkRsvpsAsHandled(
                                    table.getSelectedRowModel().rows
                                  )
                                }
                                startIcon={<DoneIcon />}
                                variant="contained"
                              >
                                Mark Selected as Handled
                              </Button>
                              <Button
                                className="delete-btn"
                                disabled={
                                  !table.getIsSomeRowsSelected() &&
                                  !table.getIsAllRowsSelected()
                                }
                                //only export selected rows
                                onClick={() =>
                                  deleteRsvps(
                                    table.getSelectedRowModel().rows
                                  ) && table.toggleAllRowsSelected(false)
                                }
                                startIcon={<DeleteForeverIcon />}
                                variant="contained"
                              >
                                Delete Selected
                              </Button>
                            </Box>
                          )}
                        />
                      </ThemeProvider>
                    </div>
                  </Card.Body>
                </div>
              </>
              {!outputData ? (
                <div className="text-center">
                  <img
                    className="m-auto mt-3 mb-3"
                    width={74}
                    src={loadingCircleImg}
                  />
                </div>
              ) : null}
            </div>
          </Card>
        </motion.div>
      ) : null}
    </>
  );
};
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const PasswordPrompt = ({ password, setPassword, submitPassword }) => {
  const handleInputChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submitPassword(password);
  };

  return (
    <div className="d-flex">
      <input
        onChange={handleInputChange}
        type="password"
        className="form-control w-100 mt-1 inline-input"
        placeholder="Password"
        value={password}
      ></input>
      <div
        className="btn btn-primary light btn-xs mt-1 me-1 w-fc inline-btn"
        onClick={(e) => handleSubmit(e)}
      >
        Submit
      </div>
    </div>
  );
};

const OwnerEmailPromptCompany = ({
  companyId,
  submitOwnerAssignment,
  setShowEmailCompanyId,
}) => {
  const [email, setEmail] = useState("");

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await submitOwnerAssignment(companyId, email);
    if (result) {
      // reset and hide the email input on success
      setEmail("");
      setShowEmailCompanyId("");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        onChange={handleInputChange}
        type="email"
        className="form-control w-100 mt-1"
        placeholder="Owner Email"
        value={email}
      ></input>
      <div
        className="btn btn-primary light btn-xs mt-1 me-1 w-fc"
        onClick={(e) => handleSubmit(e)}
      >
        Add
      </div>
    </form>
  );
};

const OwnerEmailPrompt = ({ submitOwnerAssignment, setShowEmailForm }) => {
  const [email, setEmail] = useState("");

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await submitOwnerAssignment(email);
    if (result) {
      // reset and hide the email input on success
      setEmail("");
      setShowEmailForm(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        onChange={handleInputChange}
        type="email"
        className="form-control w-100 mt-1"
        placeholder="Owner Email"
        value={email}
      ></input>
      <div
        className="btn btn-primary light btn-xs mt-1 me-1 w-fc"
        onClick={(e) => handleSubmit(e)}
      >
        Add
      </div>
    </form>
  );
};

const OwnerAssignCompanies = ({ password }) => {
  const [companies, setCompanies] = useState([]);
  const [showEmailCompanyId, setShowEmailCompanyId] = useState("");

  async function getCompanies() {
    try {
      const api = new ApiService({ password });
      const companiesResponse = await api.get(
        `landmark/portfolio/getCompanies/${tag}`
      );
      console.log(`OwnerAssign - companies`, companiesResponse);
      setCompanies(companiesResponse);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getCompanies();
  }, []);

  async function submitOwnerAssignment(companyId, email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    if (!isValidEmail) {
      toast.error("Please enter a valid email");
      return false;
    }
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/portfolio/assignOwnerToCompany/`,
        {
          companyId,
          email,
        }
      );
      if (!success) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else {
        toast.success(`${success}`);
        // refresh owners
        getCompanies();
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async function removeOwnerAssignment(companyId, email) {
    if (!companyId || !email) {
      toast.error("Error occurred");
      return false;
    }
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/portfolio/removeOwnerFromCompany/`,
        {
          companyId,
          email,
        }
      );
      if (!success) {
        toast.error(`An error occurred: ${error}`);
      } else {
        toast.success(`${success}`);
        // refresh owners
        getCompanies();
        return true;
      }
    } catch (error) {
      console.error(error);
    }
  }

  if (!companies || !companies.length) {
    return <div>Loading Companies...</div>;
  }

  return (
    <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
      <Card className="mb-3 p-3 h-fc bot-table">
        <div className="row">
          <div className="outputHeader">Company Owners</div>
        </div>
        <div className="events-owners-subheading">
          Owners assigned to companies will receive email notifications when new
          meeting requests for those companies are detected
        </div>
        <div className="event-owners-wrapper">
          {companies.map((company) => {
            return (
              <div>
                <div className="event-owner-container">
                  <img src={company.imageUrl} className="img-fluid event-img" />
                  <div className="event-title">{company.title}</div>
                  {company.owners ? (
                    <>
                      {company.owners.map((o) => (
                        <div className="event-owner-outer">
                          <div className="event-owner">{o.email}</div>
                          <i
                            onClick={() =>
                              removeOwnerAssignment(company.companyId, o.email)
                            }
                            className="event-owner-remove fa-sharp fa-solid fa-delete-left ml-05"
                          ></i>
                        </div>
                      ))}
                    </>
                  ) : null}
                  {showEmailCompanyId === company.companyId ? (
                    <motion.div
                      animate={{ opacity: 1 }}
                      initial={{ opacity: 0 }}
                    >
                      <OwnerEmailPrompt
                        companyId={company.companyId}
                        submitOwnerAssignment={submitOwnerAssignment}
                        setShowEmailCompanyId={setShowEmailCompanyId}
                      />
                    </motion.div>
                  ) : (
                    <i
                      onClick={() => setShowEmailCompanyId(company.companyId)}
                      className="event-owner-add fa-sharp fa-solid fa-plus ml-05"
                    ></i>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Card>
    </motion.div>

    // <Box>
    //   Assign Event
    //   <Select
    //     options={companiesOptions}
    //     onChange={(values) => setSelectedEventId(values)}
    //     color="#fff"
    //     styles={{ backgroundColor: "#000" }}
    //   ></Select>
    //   <input className="form-control" onKeyUp={changeInputText}></input>
    //   <Button className="form-control" onClick={submitOwnerAssignment}>
    //     Submit
    //   </Button>
    // </Box>
  );
};

const OwnerAssign = ({ password }) => {
  const [globalOwners, setGlobalOwners] = useState([]);
  const [showEmailForm, setShowEmailForm] = useState(false);
  async function getGlobalOwners() {
    try {
      const api = new ApiService({ password });
      const ownersResponse = await api.get(
        `landmark/emails/getGlobalOwners/${tag}`
      );
      console.log(`getGlobalOwners() - response`, ownersResponse);
      setGlobalOwners(ownersResponse);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getGlobalOwners();
  }, []);

  async function submitOwnerAssignment(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    if (!isValidEmail) {
      toast.error("Please enter a valid email");
      return false;
    }
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/emails/addGlobalOwner/`,
        {
          email,
          tag,
        }
      );
      if (!success) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else {
        toast.success(`${success}`);
        // refresh owners
        getGlobalOwners();
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async function removeOwnerAssignment(email) {
    if (!email) {
      toast.error("Error occurred");
      return false;
    }
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/emails/removeGlobalOwner/`,
        {
          email,
          tag,
        }
      );
      if (!success) {
        toast.error(`An error occurred: ${error}`);
      } else {
        toast.success(`${success}`);
        // refresh owners
        getGlobalOwners();
        return true;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
      <Card className="mb-3 p-3 h-fc bot-table">
        <div className="row">
          <div className="outputHeader">Owners</div>
        </div>
        <div className="events-owners-subheading">
          Owners will receive email notifications when new form responses are
          received
        </div>
        <div className="event-owners-wrapper">
          <div>
            <div className="event-owner-container">
              {/* <img src={company.imageUrl} className="img-fluid event-img" /> */}
              {/* <div className="event-title">Industrial Tech Owners</div> */}
              {globalOwners && globalOwners.length ? (
                <>
                  {globalOwners.map((o, i) => (
                    <div className="event-owner-outer" key={i}>
                      <div className="event-owner">{o.email}</div>
                      <i
                        onClick={() => removeOwnerAssignment(o.email)}
                        className="event-owner-remove fa-sharp fa-solid fa-delete-left ml-05"
                      ></i>
                    </div>
                  ))}
                </>
              ) : (
                <div className="event-owner">No Owners Assigned</div>
              )}
              {showEmailForm ? (
                <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
                  <OwnerEmailPrompt
                    submitOwnerAssignment={submitOwnerAssignment}
                    setShowEmailForm={setShowEmailForm}
                  />
                </motion.div>
              ) : (
                <i
                  onClick={() => setShowEmailForm(true)}
                  className="event-owner-add fa-sharp fa-solid fa-plus ml-05"
                ></i>
              )}
            </div>
          </div>
        </div>
      </Card>
    </motion.div>

    // <Box>
    //   Assign Event
    //   <Select
    //     options={companiesOptions}
    //     onChange={(values) => setSelectedEventId(values)}
    //     color="#fff"
    //     styles={{ backgroundColor: "#000" }}
    //   ></Select>
    //   <input className="form-control" onKeyUp={changeInputText}></input>
    //   <Button className="form-control" onClick={submitOwnerAssignment}>
    //     Submit
    //   </Button>
    // </Box>
  );
};

TablePaginationActions2.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function TablePaginationActions2(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const Dashboard = (props) => {
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  async function submitPassword(password) {
    if (!password) {
      toast.error("Invalid Password");
      return;
    }
    try {
      const api = new ApiService();
      const { success, error } = await api.post(`landmark/portfolio/auth`, {
        password,
      });
      if (!success) {
        toast.error(`${error}`);
        setIsAuthenticated(false);
        return false;
      } else {
        toast.success(`${success}`);
        setIsAuthenticated(true);
        return true;
      }
    } catch (error) {
      console.error(error);
      setIsAuthenticated(false);
      return false;
    }
  }

  useDocumentTitle("Israel Deal Portal Dashboard - Landmark Ventures");

  return (
    <Fragment>
      {/* <button disabled={isLoading} onClick={() => signMessage()}>
        Sign message
      </button>
      {isSuccess && <div>Signature: {data}</div>}
      {isError && <div>Error signing message</div>} */}
      <div className="d-flex align-items-center justify-content-center">
        <img src={landmarkImg} className="img-fluid landmark-img" />
      </div>
      <PageHeaderBar
        pages={[
          { title: "Landmark", url: `` },
          {
            title: "Israel Deal Portal Dashboard",
            url: `/landmark/israeldealportal/dashboard`,
          },
        ]}
      />
      {isAuthenticated ? (
        <>
          <OwnerAssign password={password} />
          <OutputWindow password={password} />
        </>
      ) : (
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <div className="not-authed-msg">
              <TypeAnimation
                // Same String at the start will only be typed once, initially
                sequence={["Enter password to access dashboard", 4600]}
                speed={40} // Custom Speed from 1-99 - Default Speed: 40
                wrapper="span" // Animation will be rendered as a <span>
                repeat={Infinity} // Repeat this Animation Sequence infinitely
              />
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center align-items-center">
            <PasswordPrompt
              password={password}
              setPassword={setPassword}
              submitPassword={submitPassword}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Dashboard;
