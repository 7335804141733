import React, { createContext, useState, useEffect, useCallback } from "react";
import jwt_decode from "jwt-decode";
import { useAccount, useSignMessage, useDisconnect, useProvider } from "wagmi";
import ApiService from "../../services/ApiService";
import defaultEnsAvatar from "../../images/misc/rising-bubbles-green.svg";

const AuthContext = createContext();

function getAuthToken() {
  return localStorage.getItem("token");
}

function getDecodedAuthToken() {
  try {
    const token = getAuthToken();
    return jwt_decode(token);
  } catch (error) {
    return null;
  }
}

function authTokenHasExpired() {
  try {
    const decodedToken = getDecodedAuthToken();
    if (decodedToken) {
      const expirationTime = decodedToken.exp * 1000;
      console.log(`expirationTime`, expirationTime);
      console.log(`Date.now()`, Date.now());
      const isExpired = Date.now() > expirationTime;
      if (isExpired) {
        console.log(`authTokenHasExpired() - Token Expired`);
      } else {
        console.log(`authTokenHasExpired() - Token Valid`);
      }
      return isExpired;
    }
    throw new Error("Error");
  } catch (error) {
    console.log(`authTokenHasExpired() - Error`, error);
    return true;
  }
}

const AuthProvider = ({ children }) => {
  const provider = useProvider();
  const [auth, setAuth] = useState(null);

  const [ensName, setEnsName] = useState("");
  const [ensAvatar, setEnsAvatar] = useState(defaultEnsAvatar);
  const { address, isConnected } = useAccount();
  const [hasRequestedSignature, setHasRequestedSignature] = useState(false);
  const { disconnect } = useDisconnect();
  // const [msgToSign, setMsgToSign] = useState(`Better Blocks Login`);

  const loginWithSignature = async (signed) => {
    try {
      console.log(`loginWithSignature()`);
      async function fetchData() {
        const api = new ApiService();
        const response = await api.post(`user/users/login`, {
          address,
          signed,
        });
        if (response.user && response.authToken) {
          setAuth(response.user);
          // store token locally
          localStorage.setItem("token", response.authToken);
        } else {
          disconnect();
          setAuth(null);
          localStorage.setItem("token", null);
        }
      }
      fetchData();
    } catch (error) {
      console.log(`AuthProvider - Login error`, error);
    }
  };

  const { signMessage } = useSignMessage({
    onSuccess(data, variables) {
      setHasRequestedSignature(false);
      loginWithSignature(data);
    },
    onError(error) {
      console.log(`AuthProvider - Login error`, error);
      if (address && isConnected) {
        disconnect();
        setAuth(null);
        localStorage.setItem("token", null);
      }
      setHasRequestedSignature(false);
    },
  });

  const requestAuth = async () => {
    try {
      const loginWithToken = async () => {
        try {
          console.log(`loginWithToken()`);
          const api = new ApiService(); // token is inserted into the header
          const response = await api.post(`user/users/tokenLogin`, { address });
          if (response.user && response.authToken) {
            // store token locally
            localStorage.setItem("token", response.authToken);
            // login success
            setAuth(response.user);
            console.log(`Login Success`);
          } else {
            localStorage.setItem("token", null);
            setAuth(null);
            disconnect();
            console.log(`Login Failed`);
          }
        } catch (error) {
          console.log(`AuthProvider - Login error`, error);
          console.log(`Login Failed`);
        }
      };

      console.log(`requestAuth()`);
      if (address && isConnected && !auth && !hasRequestedSignature) {
        // does the user have a valid auth token
        const decodedToken = getDecodedAuthToken();
        const tokenExpired = authTokenHasExpired();
        console.log(`decodedToken`, decodedToken);
        console.log(`tokenExpired`, tokenExpired);
        // user has a non-expired token and the address matches their connected wallet
        if (
          decodedToken &&
          !tokenExpired &&
          decodedToken.address === address.toLowerCase()
        ) {
          // attempt login via token (bypassing signature)
          await loginWithToken();
        }
        // user does not have a valid auth token
        else {
          // request signature
          const api = new ApiService();
          const nonce = await api.get(`user/sigNonce/${address}`);
          signMessage({
            message: `Better Blocks Login\n${address.toLowerCase()}\nNonce: ${nonce}`,
          });
          setHasRequestedSignature(true);
        }
      } else {
        setAuth(null);
        setHasRequestedSignature(false);
      }
    } catch (error) {
      console.log(`AuthProvider - Login error`, error);
      setAuth(null);
      setHasRequestedSignature(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      console.log(`useEffect() - triggered from change of address`, address);
      const getEnsName = async (_address) => {
        return await provider.lookupAddress(_address);
      };

      const getEnsAvatar = async (_ensName) => {
        return await provider.getAvatar(_ensName);
      };
      const getAndSetEns = async () => {
        console.log(`getAndSetEns()`);
        let _ensName, _ensAvatar;
        if (!ensName && provider) {
          _ensName = await getEnsName(address);
        }
        if (!ensAvatar && provider && ensName) {
          _ensAvatar = await getEnsAvatar(ensName);
        }
        console.log(`STATE - setEnsName()`, _ensName);
        setEnsName(_ensName);
        if (_ensAvatar) {
          console.log(`STATE - setEnsAvatar()`, _ensAvatar);
          setEnsAvatar(_ensAvatar);
        }
      };
      // retrieve ens if not yet set
      if (address && isConnected && !ensName) {
        await getAndSetEns();
      }

      // address connected and user hasn't already authenticated
      if (address && isConnected && !auth) {
        await requestAuth();
      }
      // when user disconnects or wallet - clear auth
      else if (!address) {
        console.log(`STATE - setAuth()`, null);
        setAuth(null);
        console.log(`STATE - setHasRequestedSignature()`, false);
        setHasRequestedSignature(false);
        console.log(`STATE - setEnsName()`, "");
        setEnsName("");
        console.log(`STATE - setEnsAvatar()`, defaultEnsAvatar);
        setEnsAvatar(defaultEnsAvatar);
        disconnect();
      }
    };
    fetchData();
  }, [address]);

  const value = {
    address: address?.toLowerCase(),
    ensName,
    ensAvatar,
    isAuth:
      auth && address && auth.address === address.toLowerCase() ? true : false,
    hasRequestedSignature,
    requestAuth,
    auth,
    userId: auth?.userId,
  };

  console.log(`value`, value);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
