import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { Stepper, Step } from "react-form-stepper";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import { Formik } from "formik";
import * as Yup from "yup";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import toast from "react-hot-toast";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  ButtonGroup,
} from "react-bootstrap";
import { useAccount, useSignMessage } from "wagmi";
import Swal from "sweetalert2";
import { AlchemyContext } from "../../../Providers/AlchemyProvider";
import CollectionWidget from "../../nft/CollectionWidget";
import { TwitterTweetEmbed, TwitterShareButton } from "react-twitter-embed";
import spottieExampleImg from "../../../../images/blocks/spottie/spottie-tweet-example.png";
import spottibotImg from "../../../../images/blocks/spottie/bot-icon.png";
import blueCircleImg from "../../../../images/misc/blue-circle.png";
import blueWaveAnim from "../../../../images/misc/green-wave-anim.svg";
import ethGreenImg from "../../../../images/misc/eth-green.png";

import {
  convertStringToHtml,
  convertStringToTwitterHtml,
  downloadFileFromUrl,
  fileExtensionIsImage,
  getRandomIntInRange,
  makeReplacementsToString,
  stripNonNumeric,
  timeUntil,
  unixToDate,
  truncateAddress,
} from "../../../../utils";

import avatar1 from "../../../../images/avatar/1.jpg";
import avatar2 from "../../../../images/avatar/2.jpg";
import avatar3 from "../../../../images/avatar/3.jpg";
import loadingCircleImg from "../../../../images/misc/loading-circle-green.svg";
import AuthOnly from "../../AuthOnly";
import ApiService from "../../../../services/ApiService";
import { AuthContext, AuthProvider } from "../../../Providers/AuthProvider";
import { isValidEthAddress } from "../../../utils";
import TwitterAuthSelectDropdown from "../TwitterAuthSelectDropdown";
import EmojiPicker from "../EmojiPicker";

import { Blocks } from "../blocks";
import BlockCard from "../BlockCard";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import { TypeAnimation } from "react-type-animation";
import { MultiSelect } from "primereact/multiselect";
import Nav from "react-bootstrap/Nav";
import { Alchemy } from "alchemy-sdk";

import Table2 from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { utils } from "ethers";

let contractOwnedData = [];

const ContractDataContext = createContext({});

const InfoCollection = ({ getUserSnapshots }) => {
  const { address } = useContext(AuthContext);
  const [contractAddress, setContractAddress] = useState("");
  const [contractOwnerData, setContractOwnerData] =
    useContext(ContractDataContext);
  const { isAuth, userId } = useContext(AuthContext);

  const { alchemySDK } = useContext(AlchemyContext);

  const [snapshotTime, setSnapshotTime] = useState(new Date());
  const [placeHolderText, setPlaceHolderText] = useState("Please enter a date");
  const [isLiveDate, setIsLiveDate] = useState(false);
  const [liveDateStyle, setLiveDateStyle] = useState("btn btn-primary");
  const [readOnlyDate, setReadOnlyDate] = useState(false);

  const [date, setDate] = React.useState(new Date());
  //Replaces componentDidMount and componentWillUnmount
  React.useEffect(() => {
    var timerID = setInterval(() => tick(), 500);
    return function cleanup() {
      const hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      const minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      const seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      setPlaceHolderText(
        date.toLocaleDateString() +
          " - " +
          hours +
          ":" +
          minutes +
          ":" +
          seconds
      );
      clearInterval(timerID);
    };
  });
  function tick() {
    setDate(new Date());
  }

  const getRandomExampleContract = () => {
    const exampleContracts = [
      "0xb159f1a0920a7f1d336397a52d92da94b1279838".toLowerCase(), // rsop
      "0x5a91f86c4eb5f113575770f99ba8241371961c85".toLowerCase(), // e1
      "0x3e69baab7a742c83499661c5db92386b2424df11".toLowerCase(), // rw
      "0x4819dab28d11de83c20c75c7fa2a6eac9dc948d4".toLowerCase(), // mvp
      "0x9b0674fE9c91414378a5dFAFB3376775A65CDEba".toLowerCase(), // bib
    ];
    const randomIndex = Math.floor(Math.random() * exampleContracts.length);
    return exampleContracts[randomIndex];
  };

  const SetLiveDate = () => {
    if (isLiveDate) {
      setIsLiveDate(false);
      setSnapshotTime(new Date());
      setPlaceHolderText("Please enter a date");
      setLiveDateStyle("btn btn-primary");
      setReadOnlyDate(false);
    } else {
      setIsLiveDate(true);
      setSnapshotTime(null);
      setPlaceHolderText(date);
      setLiveDateStyle("btn btn-danger");
      setReadOnlyDate(true);
    }
  };

  const RenderSnapshot = async () => {
    let ownerData = [];
    let timestamp = 0;
    const api = new ApiService();

    if (isLiveDate) {
      timestamp = Date.parse(new Date()) / 1000;
    } else {
      timestamp = Date.parse(snapshotTime) / 1000;
    }
    let blockNumber = 0;
    try {
      const response = await api.get(
        `blocks/snapshotTool/getBlockNumberByTimestamp/${timestamp}`
      );
      blockNumber = response;
    } catch (error) {
      console.error(error);
    }
    console.log("Block Number - " + blockNumber);
    const data = await alchemySDK.nft.getOwnersForContract(contractAddress, {
      withTokenBalances: true,
      block: blockNumber.toString(),
    });
    console.log(data);
    // Get metadata
    const metadata = await alchemySDK.nft.getContractMetadata(contractAddress);
    console.log(metadata);
    data.owners.forEach((element) => {
      const tokenAddress = element.ownerAddress;
      const ownedIds = element.tokenBalances.map((x) =>
        parseInt(x.tokenId, 16)
      );

      ownerData.push({
        address: tokenAddress,
        ownedIds: ownedIds,
      });
    });
    setContractOwnerData(ownerData);

    // setOutputSearch({
    //   contractAddress: contractAddress,
    //   name: metadata.name,
    //   outputDate: timestamp,
    // });

    try {
      console.log("timestamp - " + timestamp);
      const response = await api.post(`blocks/snapshotTool/postEvents`, {
        userId,
        contractAddress,
        snapshotTime: timestamp,
        contractImage: metadata.openSea.imageUrl,
        contractName: metadata.name,
        blockNumber: blockNumber,
      });
      console.log(`postEvent()`, response);
      if (response.error) {
        toast.error(response.error);
      } else {
        toast.success("Snapshot taken successfully");
        getUserSnapshots();
      }
    } catch (error) {
      console.error(error);
    } finally {
      // setUserBotsLoading(false);
    }
  };

  const contractAddressChange = (event) => {
    setContractAddress(event.target.value.trim());
  };

  return (
    <motion.div //updated the div tag
      animate={{ x: 0 }}
      initial={{ x: -150 }}
    >
      <Card className="mb-3 h-fc bot-table">
        <div className="row">
          <div className="w-100">
            <Card.Header>
              <h3>New Snapshot</h3>
            </Card.Header>
            <Card.Body>
              <label className="text-label">Contract address</label>
              <div className="input-group">
                <span className="input-group-text">
                  <i className="fa fa-file-contract col-g" />{" "}
                </span>
                <input
                  type="text"
                  className="form-control"
                  id="val-contractAddress"
                  placeholder=""
                  name="contractAddress"
                  // onBlur={handleBlur}
                  onChange={contractAddressChange}
                  value={contractAddress}
                />
                <div
                  id="val-contractAddress-error"
                  className="invalid-feedback animated fadeInUp"
                  style={{ display: "block" }}
                >
                  {!contractAddress || !isValidEthAddress(contractAddress)
                    ? `Must be a valid Ethereum Contract address (starts with 0x...)`
                    : null}
                </div>
                <div
                  id="val-contractAddress-error"
                  className="invalid-feedback animated fadeInUp"
                  style={{ display: "block" }}
                />
              </div>
              {!contractAddress && (
                <div className="w-100 text-center">
                  <label
                    className="hvr-shrink text-label use-example-collection-btn m-auto mt-2"
                    onClick={() =>
                      setContractAddress(getRandomExampleContract())
                    }
                  >
                    Use example collection
                  </label>
                </div>
              )}
              <CollectionWidget contractAddress={contractAddress} />
              {contractAddress && isValidEthAddress(contractAddress) ? (
                <div>
                  <label className="text-label">Date</label>
                  <div className="input-group">
                    <div className="col col-8">
                      <DatePicker
                        readOnly={readOnlyDate}
                        placeholderText={placeHolderText}
                        className="form-control datePickerSnapshot"
                        selected={snapshotTime}
                        onChange={(date) => setSnapshotTime(date)}
                        showTimeSelect
                        dateFormat="MMMM d, yyyy h:mm aa"
                      />
                    </div>
                    <div className="col col-4 liveDateContainer">
                      <button className={liveDateStyle} onClick={SetLiveDate}>
                        Live Date
                      </button>
                    </div>
                  </div>
                  <div className="generateBtn">
                    <button
                      className="btn btn-primary w-fc m-auto mt-3"
                      onClick={RenderSnapshot}
                    >
                      Generate Snapshot
                    </button>
                  </div>
                </div>
              ) : null}
            </Card.Body>
          </div>
        </div>
      </Card>
    </motion.div>
  );
};

const OutputWindow = ({ outputSearch }) => {
  const textareaRef = useRef(null);
  const [pillActiveKey, setPillActiveKey] = useState("1");
  const [contractOwnerData, setContractOwnerData] =
    useContext(ContractDataContext);
  console.log(`OutputWindow() - contractOwnerData`, contractOwnerData);

  const [outputData, setOutputData] = useState(
    !contractOwnerData ? "" : JSON.stringify(contractOwnerData, null, 1)
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [isNavDisabled, setIsNavDisabled] = useState(!outputData);

  useEffect(() => {
    setIsNavDisabled(!outputData);
  }, [outputData]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - contractOwnerData.length)
      : 0;

  const handleCopyClick = () => {
    textareaRef.current.select();
    document.execCommand("copy");
    toast.success("Text copied to clipboard");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const downloadOutput = () => {
    let filename =
      outputSearch.name + "-" + unixToDate(outputSearch.outputDate);
    filename +=
      pillActiveKey === "1"
        ? "-uniqueownerslist.txt"
        : pillActiveKey === "2"
        ? ".json"
        : pillActiveKey === "3"
        ? ".csv"
        : pillActiveKey === "4"
        ? ".txt"
        : "";

    download(filename, outputData);

    function download(filename, text) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#022238",
      color: theme.palette.common.white,
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#010b13",
      color: theme.palette.common.white,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#010b13",
      color: theme.palette.common.white,
      border: "1px solid #00e3b4",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: "white",
    },
  }));

  const columns = React.useMemo(
    () => [
      {
        Header: "Wallet Address",
        accessor: "walletAddress",
      },
      {
        Header: "Owned Ids",
        accessor: "ownedIds",
      },
      {
        Header: "Number Owned",
        accessor: "noOwned",
      },
    ],
    []
  );

  useEffect(() => {
    console.log(contractOwnerData);
  }, [contractOwnerData]);

  useEffect(() => {
    switch (pillActiveKey) {
      case "1":
        let ownerList = "";
        if (!contractOwnerData) {
          setOutputData("Click on snapshot to generate...");
          break;
        }
        contractOwnerData.forEach((element) => {
          ownerList += element.address + "\n";
        });
        setOutputData(ownerList);
        break;
      case "2":
        setOutputData(JSON.stringify(contractOwnerData, null, 1));
        break;
      case "4":
        let text = "";
        contractOwnerData.forEach((element) => {
          text += element.address + "-" + element.ownedIds.join(",") + "\n";
        });
        setOutputData(text);
      default:
        break;
    }
  }, [contractOwnerData, pillActiveKey]);

  return (
    <>
      {outputSearch && outputData ? (
        <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
          <Card className="mb-3 h-fc bot-table">
            <div className="row">
              <div className="outputHeader">SNAPSHOT</div>
              {contractOwnerData ? (
                <>
                  {" "}
                  <div className="text-center">
                    <div className="snapshot-collection-name">
                      {outputSearch.name}
                    </div>
                    <div className="snapshot-collection-time">
                      {unixToDate(outputSearch.outputDate)}
                    </div>
                  </div>
                  <div className="outputBody">
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <Nav
                          variant="pills"
                          activeKey={pillActiveKey}
                          onSelect={setPillActiveKey}
                          style={{ display: "flex" }}
                        >
                          <Nav.Item className="snapshot-pill">
                            <Nav.Link eventKey="1" disabled={isNavDisabled}>
                              Unique Holder List
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="snapshot-pill">
                            <Nav.Link eventKey="2" disabled={isNavDisabled}>
                              JSON
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="snapshot-pill">
                            <Nav.Link eventKey="3" disabled={isNavDisabled}>
                              CSV
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="snapshot-pill">
                            <Nav.Link eventKey="4" disabled={isNavDisabled}>
                              TXT
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                        {!isNavDisabled && (
                          <div className="d-flex">
                            {pillActiveKey !== "3" && (
                              <div
                                className="btn btn-primary shadow btn-xs sharp me-2"
                                onClick={handleCopyClick}
                                style={{ marginRight: "8px" }}
                              >
                                <i className="fa-solid fa-clipboard"></i>
                              </div>
                            )}
                            <div
                              className="btn btn-primary shadow btn-xs sharp me-2"
                              onClick={downloadOutput}
                            >
                              <i className="fa-solid fa-download"></i>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* <div className="outputSaveButton">
                Download
                </div>     */}
                      <div className="snapshot-output-container">
                        {(pillActiveKey === "1" ||
                          pillActiveKey === "2" ||
                          pillActiveKey === "4") && (
                          <textarea
                            ref={textareaRef}
                            className="snapshot-output-textbox"
                            value={outputData}
                            readOnly
                            rows={50}
                          ></textarea>
                        )}
                        {pillActiveKey === "3" && (
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>
                                  <strong>Wallet Address</strong>
                                </th>
                                <th>
                                  <strong>Owned Ids</strong>
                                </th>
                                <th>
                                  <strong>Total</strong>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {contractOwnerData.map((row, key) => (
                                <tr
                                  className="snapshot-csv-row"
                                  key={`csv_row_${key}`}
                                >
                                  <td>{row.address}</td>
                                  <td>{row.ownedIds.join(", ")}</td>
                                  <td>{row.ownedIds.length}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </div>
                    </Card.Body>
                  </div>
                </>
              ) : null}
              {!contractOwnerData ? (
                <div className="text-center">
                  <img
                    className="m-auto mt-3 mb-3"
                    width={74}
                    src={loadingCircleImg}
                  />
                </div>
              ) : null}
            </div>
          </Card>
        </motion.div>
      ) : null}
    </>
  );
};
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const RecentSearches = ({
  setOutputSearch,
  selectedSnapshot,
  setSelectedSnapshot,
  userSnapshots,
}) => {
  const { alchemySDK } = useContext(AlchemyContext);
  const [contractOwnerData, setContractOwnerData] =
    useContext(ContractDataContext);

  const handleSelectSnapshot = async (snapshot) => {
    console.log(`handleSelectSnapshot() - snapshot`, snapshot);
    if (
      snapshot &&
      (!selectedSnapshot ||
        selectedSnapshot.snapshotToolEventId !== snapshot.snapshotToolEventId)
    ) {
      setSelectedSnapshot(JSON.parse(JSON.stringify(snapshot)));
    }
  };

  useEffect(() => {
    setContractOwnerData(null);
    if (selectedSnapshot) {
      regenerateSnapshot(selectedSnapshot);
    }
  }, [selectedSnapshot]);

  const regenerateSnapshot = async (snapshot) => {
    let ownerData = [];
    console.log(snapshot);
    const data = await alchemySDK.nft.getOwnersForContract(
      snapshot.contractAddress,
      {
        withTokenBalances: true,
        block: snapshot.blockNumber.toString(),
      }
    );
    console.log(data);
    // Get metadata
    const metadata = await alchemySDK.nft.getContractMetadata(
      snapshot.contractAddress
    );
    console.log(metadata);
    data.owners.forEach((element) => {
      const tokenAddress = element.ownerAddress;
      const ownedIds = element.tokenBalances.map((x) =>
        parseInt(x.tokenId, 16)
      );

      ownerData.push({
        address: tokenAddress,
        ownedIds: ownedIds,
      });
    });
    setContractOwnerData(ownerData);

    setOutputSearch({
      contractAddress: snapshot.contractAddress,
      name: snapshot.contractName,
      outputDate: snapshot.snapshotTime,
    });
  };

  console.log(`selectedSnapshot`, selectedSnapshot);
  return (
    <>
      <Card className="mb-3 h-fc bot-table">
        <Card.Header>
          <h3>Your Snapshots</h3>
        </Card.Header>
        <div className="card-body">
          {!userSnapshots || userSnapshots.length === 0 ? (
            <div className="no-userBots-msg">
              <TypeAnimation
                // Same String at the start will only be typed once, initially
                sequence={["You have no snapshots", 7000]}
                speed={40} // Custom Speed from 1-99 - Default Speed: 40
                wrapper="span" // Animation will be rendered as a <span>
                repeat={Infinity} // Repeat this Animation Sequence infinitely
              />
            </div>
          ) : null}
          {userSnapshots && userSnapshots.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <strong>Collection</strong>
                  </th>
                  <th>
                    <strong>Contract Address</strong>
                  </th>
                  <th>
                    <strong>Snapshot Date</strong>
                  </th>
                </tr>
              </thead>
              {/* <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Contract Address</TableCell>
              <TableCell>Snapshot Date</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead> */}
              <tbody>
                {userSnapshots.map((row, key) => (
                  <tr
                    key={`recent_snapshots_${key}`}
                    onClick={() => handleSelectSnapshot(row)}
                    className={
                      row.snapshotToolEventId ===
                      selectedSnapshot?.snapshotToolEventId
                        ? `selected`
                        : ""
                    }
                  >
                    <td>
                      <div className="d-flex align-items-center">
                        {row.contractName && (
                          <>
                            <img
                              src={row.contractImage}
                              className="me-2 br-50  border-green"
                              width="28"
                              alt=""
                            />{" "}
                            <span className="w-space-no">
                              {row.contractName}
                            </span>
                          </>
                        )}
                        {!row.contractName && (
                          <span className="w-space-no">Not Set</span>
                        )}
                      </div>
                    </td>
                    <td>{truncateAddress(row.contractAddress)}</td>
                    <td>{unixToDate(row.snapshotTime)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : null}
        </div>
      </Card>
    </>
  );
};

const CollectionImage = ({ collectionImage }) => {
  return (
    <>
      <AnimatePresence>
        {collectionImage && (
          <motion.div
            animate={{
              scale: [0, 1.07, 1],
              rotate: [-90, 3, 0],
              opacity: [0, 1],
            }}
            exit={{
              scale: [1, 1.07, 0],
              rotate: [0, 3, -90],
            }}
          >
            <img
              className="img-fluid mr-05 br-50  border-green"
              width={42}
              src={collectionImage}
            />
          </motion.div>
        )}
      </AnimatePresence>
      {!collectionImage && (
        <img
          className="img-fluid mr-05 br-50  border-green opacity-0"
          width={42}
          src={blueCircleImg}
        />
      )}
    </>
  );
};

const BotHeading = () => {
  const { isAuth, userId } = useContext(AuthContext);
  const [contractOwnerData, setContractOwnerData] = useState(null);
  const [outputSearch, setOutputSearch] = useState(null);
  const [selectedSnapshot, setSelectedSnapshot] = useState(null);
  const [userSnapshots, setUserSnapshots] = useState([]);
  const [animate, setAnimate] = useState(false);
  const tweetLoadAnim = useAnimation();
  const tweetLoaded = () => {};

  const getUserSnapshots = async () => {
    const api = new ApiService();
    const data = await api.get(`blocks/snapshotTool/getEvents/${userId}`);
    console.log(data);
    setUserSnapshots(data);
    if (data && data.length) {
      setSelectedSnapshot(data[0]);
    }
  };

  useEffect(() => {
    if (userId) {
      getUserSnapshots();
    }
  }, [userId]);

  async function startAnimation() {
    await tweetLoadAnim.start({
      opacity: 1,
      scale: 1,
      transition: { duration: 0.2 },
    });
  }

  return (
    <ContractDataContext.Provider
      value={[contractOwnerData, setContractOwnerData]}
    >
      <div className="row">
        <div className="col-lg-6">
          <BlockCard block={Blocks.NFTSnapshotTool} />
          <AuthOnly>
            <motion.div animate={{ x: 0 }} initial={{ x: -1000 }}>
              <InfoCollection getUserSnapshots={getUserSnapshots} />
            </motion.div>
          </AuthOnly>
        </div>
        <div className="col-lg-6">
          <AuthOnly hideMsg={true}>
            <RecentSearches
              userSnapshots={userSnapshots}
              selectedSnapshot={selectedSnapshot}
              setSelectedSnapshot={setSelectedSnapshot}
              setOutputSearch={setOutputSearch}
            />
            <OutputWindow outputSearch={outputSearch} />
          </AuthOnly>
        </div>
      </div>
    </ContractDataContext.Provider>
  );
};
const SnapshotTool = (props) => {
  return (
    <Fragment>
      {/* <button disabled={isLoading} onClick={() => signMessage()}>
        Sign message
      </button>
      {isSuccess && <div>Signature: {data}</div>}
      {isError && <div>Error signing message</div>} */}
      <PageHeaderBar
        pages={[
          { title: "Tools", url: `/blocks` },
          { title: "NFT Snapshot Tool", url: `/tools/snapshottool` },
        ]}
      />
      <BotHeading />
    </Fragment>
  );
};

export default SnapshotTool;
