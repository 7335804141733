import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { Stepper, Step } from "react-form-stepper";
import { Link } from "react-router-dom";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import toast from "react-hot-toast";
import { Row, Col, Card, Table, Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { AlchemyContext } from "../../../Providers/AlchemyProvider";
import CollectionWidget from "../../nft/CollectionWidget";
import { TwitterTweetEmbed, TwitterShareButton } from "react-twitter-embed";
import blueCircleImg from "../../../../images/misc/blue-circle.png";
import blueWaveAnim from "../../../../images/misc/green-wave-anim.svg";
import ethGreenImg from "../../../../images/misc/eth-green.png";
import BlockEventsTable from "../BlockEventsTable";

import {
  convertStringToHtml,
  convertStringToTwitterHtml,
  fileExtensionIsImage,
  getRandomIntInRange,
  makeReplacementsToString,
  stripNonNumeric,
  timeUntil,
  calculateTwitterCharacters,
} from "../../../../utils";

import avatar1 from "../../../../images/avatar/1.jpg";
import AuthOnly from "../../AuthOnly";
import ApiService from "../../../../services/ApiService";
import { AuthContext } from "../../../Providers/AuthProvider";
import { isValidEthAddress } from "../../../utils";
import TwitterAuthSelectDropdown from "../TwitterAuthSelectDropdown";
import EmojiPicker from "../EmojiPicker";

import { Blocks } from "../blocks";
import BlockCard from "../BlockCard";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import { TypeAnimation } from "react-type-animation";

const dailyTimeOptions = [
  {
    name: "00:00",
    value: "0",
  },
  {
    name: "01:00",
    value: "1",
  },
  {
    name: "02:00",
    value: "2",
  },
  {
    name: "03:00",
    value: "3",
  },
  {
    name: "04:00",
    value: "4",
  },
  {
    name: "05:00",
    value: "5",
  },
  {
    name: "06:00",
    value: "6",
  },
  {
    name: "07:00",
    value: "7",
  },
  {
    name: "08:00",
    value: "8",
  },
  {
    name: "09:00",
    value: "9",
  },
  {
    name: "10:00",
    value: "10",
  },
  {
    name: "11:00",
    value: "11",
  },
  {
    name: "12:00",
    value: "12",
  },
  {
    name: "13:00",
    value: "13",
  },
  {
    name: "14:00",
    value: "14",
  },
  {
    name: "15:00",
    value: "15",
  },
  {
    name: "16:00",
    value: "16",
  },
  {
    name: "17:00",
    value: "17",
  },
  {
    name: "18:00",
    value: "18",
  },
  {
    name: "19:00",
    value: "19",
  },
  {
    name: "20:00",
    value: "20",
  },
  {
    name: "21:00",
    value: "21",
  },
  {
    name: "22:00",
    value: "22",
  },
  {
    name: "23:00",
    value: "23",
  },
];

const automationIntervalOptions = [
  {
    name: "24 hours",
    value: "24 hours",
  },
  {
    name: "48 hours",
    value: "48 hours",
  },
  {
    name: "72 hours",
    value: "72 hours",
  },
  {
    name: "Weekly",
    value: "Weekly",
  },
];

const traitCountOptions = [
  {
    name: "1",
    value: "1",
  },
  {
    name: "2",
    value: "2",
  },
  {
    name: "3",
    value: "3",
  },
  {
    name: "4",
    value: "4",
  },
  {
    name: "5",
    value: "5",
  },
  {
    name: "All",
    value: "All",
  },
];

const BotContext = createContext({});

const TweetDesigner = ({ alchemySDK }) => {
  const [bot, setBot] = useContext(BotContext);

  const [tweetHeader, setTweetHeader] = useState(bot?.tweetHeader || "");
  const [tweetFooter, setTweetFooter] = useState(bot?.tweetFooter || "");
  const [tweetShowTraits, setTweetShowTraits] = useState(
    bot?.tweetShowTraits || true
  );
  const [tweetTraitsToShow, setTweetTraitsToShow] = useState(
    bot?.tweetTraitsToShow || "3"
  );
  const [tokenRarity, setTokenRarity] = useState(null);
  const [tokenMedia, setTokenMedia] = useState(null);
  const [tokenId, setTokenId] = useState(1);

  const mediaIsImage = tokenMedia
    ? fileExtensionIsImage(tokenMedia.format)
    : true; // image by default
  // if its not image we assume its a video
  // TODO: Handle other cases

  useEffect(() => {
    try {
      alchemySDK.nft
        .computeRarity(bot?.contractAddress, tokenId)
        .then((data) => {
          console.log(`setTokenRarity`, data);
          // sort by rarity
          data.sort((a, b) => {
            if (a.prevalence > b.prevalence) {
              return 1;
            } else if (b.prevalence > a.prevalence) {
              return -1;
            }
            return 0;
          });
          setTokenRarity(data);
        });
      alchemySDK.nft
        .getNftMetadata(bot?.contractAddress, tokenId)
        .then((data) => {
          console.log(`setTokenMedia`, data);
          // get media url (relies on alchemy sdk cacheing it)
          const mediaUrl = data.media[0]; // we are after format (file type) and gateway (url)
          setTokenMedia(mediaUrl);
        });
    } catch (error) {
      console.log(`error`, error);
    }
  }, [tokenId]);

  const handleTokenIdChange = (event) => {
    const newTokenId = stripNonNumeric(event.target.value.trim());
    if (tokenId !== newTokenId) {
      setTokenId(newTokenId);
    }
  };

  const generateRandomTokenId = () => {
    if (!bot || !bot.collectionTotalSupply) {
      return;
    }
    const min = 0;
    const max = parseInt(bot.collectionTotalSupply) - 1;
    const random = getRandomIntInRange(min, max);
    setTokenId(random);
  };

  const handleTweetHeaderEmojiClick = (emoji) => {
    handleTweetHeaderChange(`${tweetHeader}${emoji.native}`);
  };

  const handleTweetFooterEmojiClick = (emoji) => {
    handleTweetFooterChange(`${tweetFooter}${emoji.native}`);
  };

  const handleTweetHeaderChange = (event) => {
    const newTweetHeader = event.target ? event.target.value : event; // so we can feed emoji into this function
    if (bot.tweetHeader !== newTweetHeader) {
      setBot({
        ...bot,
        tweetHeader: newTweetHeader,
      });
    }
    setTweetHeader(newTweetHeader);
  };

  const handleTweetFooterChange = (event) => {
    const newtweetFooter = event.target ? event.target.value : event; // so we can feed emoji into this function
    if (bot.tweetFooter !== newtweetFooter) {
      setBot({
        ...bot,
        tweetFooter: newtweetFooter,
      });
    }
    setTweetFooter(newtweetFooter);
  };

  const handleTweetTraitsToShowChange = (toShow) => {
    if (bot.tweetTraitsToShow !== toShow) {
      setBot({
        ...bot,
        tweetTraitsToShow: toShow,
      });
    }
    setTweetTraitsToShow(toShow);
  };

  const handleTweetShowTraitsChange = () => {
    const newTweetShowTraits = !tweetShowTraits;
    if (bot.tweetShowTraits !== newTweetShowTraits) {
      setBot({
        ...bot,
        tweetShowTraits: newTweetShowTraits,
      });
    }
    setTweetShowTraits(newTweetShowTraits);
  };

  useEffect(() => {
    if (bot) {
      setTweetShowTraits(bot.tweetShowTraits);
    }
  }, [bot]);

  const traitsText = () => {
    if (!tweetShowTraits || !tokenRarity || tokenRarity.length === 0) {
      return ``;
    }
    let traitCount = tokenRarity.length; // default (all)
    if (tweetTraitsToShow && tweetTraitsToShow !== "All") {
      traitCount = parseInt(tweetTraitsToShow);
    }
    const traitsArr = tokenRarity.slice(0, traitCount);
    return `${traitsArr
      .map(
        (element) =>
          `${element.traitType}: ${element.value} (${(
            element.prevalence * 100
          ).toFixed(2)}%)`
      )
      .join("\n")}`;
  };

  const tweetReplacements = {
    "[tokenId]": tokenId,
    "[collectionName]": bot?.collectionName,
    "[collectionSymbol]": bot?.collectionSymbol,
  };
  const traitsStr = traitsText();
  const traitsHtml = convertStringToHtml(traitsStr);
  const tweetHeaderStr =
    tweetHeader && tweetHeader.length
      ? `\n${makeReplacementsToString(tweetHeader, tweetReplacements)}`
      : "";
  const traitsFinalStr =
    traitsStr.length > 0
      ? `${tweetHeaderStr.length > 0 ? `\n` : ``}${traitsStr}`
      : "";
  const tweetFooterStr =
    tweetFooter && tweetFooter.length
      ? `\n${makeReplacementsToString(tweetFooter, tweetReplacements)}`
      : "";
  const openseaUrl = `https://opensea.io/assets/ethereum/${bot?.contractAddress}/${tokenId}`;
  const tweetContent = `${tweetHeaderStr}${traitsFinalStr}${tweetFooterStr}`;
  const characterCount = calculateTwitterCharacters(
    `${tweetContent}\n${openseaUrl}`
  );
  // console.log(`tweetContent`, tweetContent);

  const SaveNftFileButton = ({ tokenMedia }) => {
    if (!tokenMedia || !tokenMedia.gateway) {
      return;
    }

    return (
      <div>
        <motion.div
          animate={{
            scale: [0, 1.07, 1],
            opacity: [0, 1],
          }}
        >
          <div className="download-btn d-flex align-items-center w-fc">
            <div className=" hvr-shrink">
              <a href={tokenMedia.gateway} target="_blank" rel="noreferrer">
                <i className="fa fa-file-arrow-down mr-05 col-g"></i>
                <span className="col-white">Save Image</span>
              </a>
            </div>
          </div>
        </motion.div>
      </div>
    );
  };

  const TwitterShareButtonComponent = ({
    tweetContent,
    url = `https://betterblocks.io`,
  }) => {
    return (
      <div className="twitter-share-btn-sm">
        <motion.div
          animate={{
            scale: [0, 1.07, 1],
            opacity: [0, 1],
          }}
        >
          <TwitterShareButton
            onLoad={function noRefCheck() {}}
            options={{
              // size: "large",
              text: tweetContent,
              // via: 'saurabhnemade'
            }}
            url={url}
          />
        </motion.div>
      </div>
    );
  };

  // const handleSaveNftFile = () => {
  //   const url = tokenMedia.gateway;
  //   if (!url || !tokenMedia || !collectionInfo) {
  //     return;
  //   }
  //   const collectionSymbol = collectionInfo.symbol;
  //   const extension = tokenMedia.format;
  //   const fileName = `${collectionSymbol}-${tokenId}.${extension}`;
  //   downloadFileFromUrl(url, fileName);
  // };

  return (
    <motion.div //updated the div tag
      animate={{ x: 0 }}
      initial={{ x: -150 }}
    >
      <div className="row tweet-designer">
        <div className="col-12 mb-5 pt-3 pl-2 pr-2">
          <h2 className="heading">Tweet Designer</h2>
          <div className="row mt-3 d-flex align-items-center justify-content-center">
            <div className="input-group max-w-300px">
              <span className="input-group-text">Token ID</span>
              <input
                type="text"
                className="form-control"
                id="val-tokenId"
                placeholder=""
                name="tokenId"
                onChange={handleTokenIdChange}
                value={tokenId}
              ></input>
            </div>
            <div
              className="btn btn-primary light btn-xs mb-1 me-1 w-fc"
              onClick={() => generateRandomTokenId()}
            >
              Random ID
            </div>
          </div>
        </div>
        <div className="col-md-6 z-100">
          <div className="basic-form">
            <form>
              <div className="form-group mb-3">
                <div className="d-flex justify-content-between position-relative">
                  <label>Tweet Header:</label>
                  <EmojiPicker onEmojiSelect={handleTweetHeaderEmojiClick} />
                </div>
                <textarea
                  className="form-txtarea form-control"
                  rows="4"
                  id="tweetHeader"
                  onChange={handleTweetHeaderChange}
                  value={bot?.tweetHeader}
                ></textarea>
              </div>
              <div className="form-group mb-3">
                {/* <label>Traits:</label> */}
                <div className="form-group mb-3 ml-1">
                  <Row>
                    <Col
                      xl={12}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <div className="form-check custom-checkbox checkbox-info ml-1">
                        <input
                          type="checkbox"
                          defaultChecked={bot?.tweetShowTraits}
                          checked={bot?.tweetShowTraits}
                          className="form-check-input"
                          id="showTraits"
                          onChange={handleTweetShowTraitsChange}
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="showTraits"
                        >
                          Show Traits
                        </label>
                      </div>
                      <div className="d-flex align-items-center traits-to-show-container">
                        <div className="mr-05 traits-to-display-label">
                          Traits to display:
                          {/* <br />
                          (sorted by highest rarity) */}
                        </div>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant=""
                            className="ps-0 mt-1 mb-2"
                          >
                            <span className="traits-to-display-value">
                              {bot?.tweetTraitsToShow}
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {traitCountOptions.map((o) => {
                              return (
                                <Dropdown.Item
                                  onClick={() =>
                                    handleTweetTraitsToShowChange(o.name)
                                  }
                                >
                                  {o.name}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Col>
                  </Row>
                  {/* <div className="input-group">
                    <span className="input-group-text">
                      <i className="fa fa-clock" />{" "}
                    </span>
                    <select
                      defaultValue={"3"}
                      className="form-control rmono"
                      id="val-tweetTraitsToShow"
                      name="tweetTraitsToShow"
                      onChange={handleTweetTraitsToShowChange}
                      value={tweetTraitsToShow}
                    >
                      {traitCountOptions.map((o) => {
                        return <option>{o.name}</option>;
                      })}
                    </select>
                  </div> */}
                </div>
              </div>
              <div className="form-group mb-3">
                <div className="d-flex justify-content-between position-relative">
                  <label>Tweet Footer:</label>
                  <EmojiPicker onEmojiSelect={handleTweetFooterEmojiClick} />
                </div>
                <textarea
                  className="form-txtarea form-control mb-3"
                  rows="4"
                  id="tweetFooter"
                  onChange={handleTweetFooterChange}
                  value={bot?.tweetFooter}
                ></textarea>
                <div className="character-count d-flex align-items-center">
                  Characters:{" "}
                  <span
                    className={`char-val ${
                      characterCount > 280 ? " col-danger" : ""
                    }`}
                  >
                    {characterCount}
                  </span>{" "}
                  / 280
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-end justify-content-between">
            <label>Tweet Preview:</label>
            <div className="d-flex align-items-center">
              <SaveNftFileButton tokenMedia={tokenMedia} />
              <TwitterShareButtonComponent
                tweetContent={tweetContent}
                url={openseaUrl}
              />
            </div>
          </div>
          <div className="tweet-preview border-green">
            <div className="head-container d-flex align-items-center">
              <div className="d-flex align-items-center">
                <img
                  src={avatar1}
                  className="me-2 br-50  border-green"
                  width="28"
                />
                <div className="user-info">
                  <div className="name">Example User</div>
                  <div className="username">@ExampleUser</div>
                </div>
              </div>
              <div className="icon">
                <i className="fa fa-brands fa-twitter col-g"></i>
              </div>
            </div>
            <div className="body-container">
              <>
                <div>
                  {convertStringToTwitterHtml(tweetHeader, tweetReplacements)}
                </div>
                {traitsHtml && <div>{traitsHtml}</div>}
                <div>
                  {convertStringToTwitterHtml(tweetFooter, tweetReplacements)}
                </div>
                <div>{convertStringToTwitterHtml(openseaUrl)}</div>
                <br />
                <AnimatePresence>
                  <motion.div
                    animate={{
                      scale: [0, 1.07, 1],
                      opacity: [0, 1],
                    }}
                    exit={{
                      scale: [1, 1.07, 0],
                      rotate: [0, 3, -90],
                    }}
                  >
                    {tokenMedia && mediaIsImage && (
                      <img className="img-fluid" src={tokenMedia.gateway} />
                    )}
                    {tokenMedia && !mediaIsImage && (
                      <video
                        autoPlay
                        muted
                        controls={false}
                        className="img-fluid"
                      >
                        <source src={tokenMedia.gateway} type="video/mp4" />
                      </video>
                    )}
                  </motion.div>
                </AnimatePresence>
              </>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const CollectionEditor = ({ handleContractAddressChange }) => {
  const [bot, setBot] = useContext(BotContext);

  const getRandomExampleContract = () => {
    const exampleContracts = [
      "0xb159f1a0920a7f1d336397a52d92da94b1279838".toLowerCase(), // rsop
      "0x5a91f86c4eb5f113575770f99ba8241371961c85".toLowerCase(), // e1
      "0x3e69baab7a742c83499661c5db92386b2424df11".toLowerCase(), // rw
      "0x4819dab28d11de83c20c75c7fa2a6eac9dc948d4".toLowerCase(), // mvp
      "0x9b0674fE9c91414378a5dFAFB3376775A65CDEba".toLowerCase(), // bib
    ];
    const randomIndex = Math.floor(Math.random() * exampleContracts.length);
    return exampleContracts[randomIndex];
  };

  return (
    <motion.div //updated the div tag
      animate={{ x: 0 }}
      initial={{ x: -150 }}
    >
      <form>
        <div className="row">
          <div
            className={`form-group mb-3 col-12 d-flex justify-content-center ${
              bot?.contractAddress
                ? bot.contractAddress
                  ? "is-invalid"
                  : "is-valid"
                : ""
            }`}
          >
            <div className="w-100 max-w-400px">
              <label className="text-label">
                Contract address of collection
              </label>
              <div className="input-group">
                <span className="input-group-text">
                  <i className="fa fa-file-contract col-g" />{" "}
                </span>
                <input
                  type="text"
                  className="form-control"
                  id="val-contractAddress"
                  placeholder=""
                  name="contractAddress"
                  onChange={handleContractAddressChange}
                  // onBlur={handleBlur}
                  value={bot?.contractAddress}
                />
                <div
                  id="val-contractAddress-error"
                  className="invalid-feedback animated fadeInUp"
                  style={{ display: "block" }}
                >
                  {!bot?.collectionName &&
                    `Must be a valid Ethereum contract address (starts with 0x...)`}
                </div>

                <div
                  id="val-contractAddress-error"
                  className="invalid-feedback animated fadeInUp"
                  style={{ display: "block" }}
                />
              </div>
              {!bot?.contractAddress && (
                <div className="w-100 text-center">
                  <label
                    className="hvr-shrink text-label use-example-collection-btn m-auto mt-2"
                    onClick={() =>
                      handleContractAddressChange(getRandomExampleContract())
                    }
                  >
                    Use example collection
                  </label>
                </div>
              )}
            </div>
          </div>
          {/* <CollectionWidget contractAddress={values.contractAddress} /> */}
          <CollectionWidget
            contractAddress={bot?.contractAddress}
            bot={bot}
            setFromBot={true}
          />
          {/* <button
        type="submit"
        className="btn me-2 btn-primary"
        disabled={isSubmitting}
      >
        Submit
      </button> */}
          {/* <button  className="btn btn-danger">
        Cancel
      </button> */}
        </div>
      </form>
    </motion.div>
  );
};

const AutomationControls = ({ twitterAuths }) => {
  const [bot, setBot] = useContext(BotContext);

  const [automationEnabled, setAutomationEnabled] = useState(
    bot?.automationEnabled || false
  );
  const [automationInterval, setAutomationInterval] = useState(
    bot?.automationInterval || "24 hours"
  );
  const [automationDailyTime, setAutomationDailyTime] = useState(
    bot?.automationDailyTime || "13:00"
  );
  const [automationTwitterAuthId, setAutomationTwitterAuthId] = useState(
    bot?.automationTwitterAuthId || null
  );
  // const [automationIdSelectionMode, setAutomationIdSelectionMode] = useState(
  //   bot?.automationIdSelectionMode || "Random"
  // );

  const handleAuthSelect = (auth) => {
    const newAuthId = auth.twitterAuthId;
    if (bot.automationTwitterAuthId !== newAuthId) {
      setBot({
        ...bot,
        automationTwitterAuthId: newAuthId,
      });
    }
    setAutomationTwitterAuthId(newAuthId);
  };

  const handleSetDailyTime = (event) => {
    const newDailyTime = event.target.value;
    if (bot.automationDailyTime !== newDailyTime) {
      setBot({
        ...bot,
        automationDailyTime: newDailyTime,
      });
    }
    setAutomationDailyTime(newDailyTime);
  };

  const handleSetAutomationInterval = (event) => {
    const newAutomationInterval = event.target.value;
    if (bot.automationInterval !== newAutomationInterval) {
      setBot({
        ...bot,
        automationInterval: newAutomationInterval,
      });
    }
    setAutomationInterval(newAutomationInterval);
  };

  const handleSetAutomationEnabled = (isEnabled) => {
    if (bot.automationEnabled !== isEnabled) {
      setBot({
        ...bot,
        automationEnabled: isEnabled,
      });
    }
    setAutomationEnabled(isEnabled);
  };

  return (
    <motion.div //updated the div tag
      animate={{ x: 0 }}
      initial={{ x: -150 }}
    >
      <div className="row tweet-automation position-relative z-100">
        <div className="col-12 mb-5 pt-3 pl-2 pr-2">
          <h2 className="heading">Tweet Automation</h2>
          <div className="row mt-3 d-flex align-items-center justify-content-center">
            <div className="col-12 mb-2">
              <div className="max-w-1000px text-center m-auto">
                Keep the spotlight on by scheduling daily automated tweets to
                showcase random NFTs from your collection.
                <br></br>It's as simple as set and forget - we'll take care of
                everything else.
              </div>
              {twitterAuths.length === 0 && (
                <motion.div
                  animate={{
                    scale: [0, 1.07, 1],
                    opacity: [0, 1],
                  }}
                >
                  <div className="col-12">
                    <div className="not-authed-twitter">
                      <div>
                        You must have an authenticated Twitter account to use
                        this feature{" "}
                      </div>
                      <div>
                        Visit the{" "}
                        {/* <a target="_blank" href="/settings">
                          settings
                        </a>{" "} */}
                        <Link className="col-g" to={"/settings"}>
                          settings
                        </Link>{" "}
                        page to add an account
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </div>
          </div>
        </div>
        {twitterAuths.length > 0 && (
          <motion.div
            animate={{
              scale: [0, 1.07, 1],
              opacity: [0, 1],
            }}
          >
            <div className="row">
              <div className="col-md-6 z-100">
                <div className="basic-form">
                  <form>
                    <div className="form-group mb-3 pb-1">
                      <label>Twitter account to post from:</label>
                      <TwitterAuthSelectDropdown
                        twitterAuths={twitterAuths}
                        onSelect={handleAuthSelect}
                        selectedTwitterAuth={twitterAuths.find(
                          (auth) =>
                            auth.twitterAuthId === automationTwitterAuthId
                        )}
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-6">
                <label>Time of day (UTC) to post the Tweet</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">
                    <i className="fa fa-clock col-g" />{" "}
                  </span>
                  <select
                    defaultValue={dailyTimeOptions[0].name}
                    className="form-control rmono"
                    id="val-dailyTime"
                    name="automationDailyTime"
                    onChange={handleSetDailyTime}
                    value={automationDailyTime}
                  >
                    {dailyTimeOptions.map((dt) => {
                      return <option>{dt.name}</option>;
                    })}
                  </select>
                </div>
                <label>Time between Tweets</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">
                    <i className="fa fa-clock col-g" />{" "}
                  </span>
                  <select
                    defaultValue={automationIntervalOptions[0].name}
                    className="form-control rmono"
                    id="val-interval"
                    name="automationInterval"
                    onChange={handleSetAutomationInterval}
                    value={automationInterval}
                  >
                    {automationIntervalOptions.map((dt) => {
                      return <option>{dt.name}</option>;
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-center text-center pb-2">
                  <AnimatePresence>
                    {automationEnabled && (
                      <motion.div
                        animate={{
                          scale: [0, 1.07, 1],
                          opacity: [0, 1],
                        }}
                      >
                        <div className="d-flex flex-column">
                          <div className="automation-status-msg enabled">
                            Automation Enabled
                          </div>
                          <img
                            className="automation-status-img"
                            src={blueWaveAnim}
                            width={100}
                          />
                          <div className="hvr-shrink">
                            <button
                              type="button"
                              className="btn btn-danger btn-xs"
                              onClick={() => handleSetAutomationEnabled(false)}
                            >
                              Pause
                            </button>
                          </div>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <AnimatePresence>
                    {!automationEnabled && (
                      <motion.div
                        animate={{
                          scale: [0, 1.07, 1],
                          opacity: [0, 1],
                        }}
                      >
                        <div className="d-flex flex-column">
                          <div className="automation-status-msg disabled">
                            Automation Paused
                          </div>
                          {!automationTwitterAuthId && (
                            <div className="automation-status-msg">
                              Select Twitter account to post from
                            </div>
                          )}
                          {automationTwitterAuthId && (
                            <div className="hvr-shrink">
                              <button
                                type="button"
                                className="btn btn-success btn-sm"
                                onClick={() => handleSetAutomationEnabled(true)}
                              >
                                Enable
                              </button>
                            </div>
                          )}
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

const CollectionImage = ({ collectionImage }) => {
  return (
    <>
      <AnimatePresence>
        {collectionImage && (
          <motion.div
            animate={{
              scale: [0, 1.07, 1],
              rotate: [-90, 3, 0],
              opacity: [0, 1],
            }}
            exit={{
              scale: [1, 1.07, 0],
              rotate: [0, 3, -90],
            }}
          >
            <img
              className="img-fluid mr-05 br-50  border-green"
              width={42}
              src={collectionImage}
            />
          </motion.div>
        )}
      </AnimatePresence>
      {!collectionImage && (
        <img
          className="img-fluid mr-05 br-50  border-green opacity-0"
          width={42}
          src={blueCircleImg}
        />
      )}
    </>
  );
};

const BotControls = () => {
  const [bot, setBot] = useState(null);
  const [selectedBotId, setSelectedBotId] = useState(null);
  const [contractAddress, setContractAddress] = useState("");
  const [formStep, setFormStep] = useState(0);
  const { isAuth, userId } = useContext(AuthContext);
  const { alchemySDK } = useContext(AlchemyContext);
  const [userBots, setUserBots] = useState([]);
  const [userBotsLoading, setUserBotsLoading] = useState(false);
  const [twitterAuths, setTwitterAuths] = useState([]);
  const timeoutIdRef = useRef(null);
  const [blockEvents, setBlockEvents] = useState([]);

  useEffect(() => {
    // Clear the timeout when the component unmounts
    return () => clearTimeout(timeoutIdRef.current);
  }, []);

  const saveBot = async () => {
    console.log(`saveBot`, bot);
    delayedSaveBot(); // waits 1.9 sec to exec (so new request isn't overloaded with fast input changes)
  };

  const actualSaveBot = async () => {
    try {
      const api = new ApiService();
      const response = await api.post(`blocks/spotlightBot/saveBot`, {
        userId,
        bot,
      });
      console.log(`saveBot()`, response);
      if (response.error || !response.userBots) {
        toast.error(response.error);
      } else {
        toast.success("Changes saved");
        setUserBots(response.userBots);
      }
    } catch (error) {
      console.error(error);
    } finally {
      // setUserBotsLoading(false);
    }
  };

  // waits 1.9 secs to display 'Changes Saved' toast
  const delayedSaveBot = () => {
    // Clear any existing timeouts
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    // Set a timeout to call a function after 1.9 seconds
    const newTimeoutId = setTimeout(async () => {
      actualSaveBot();
    }, 1900);

    // Update the timeout ID ref
    timeoutIdRef.current = newTimeoutId;
  };

  useEffect(() => {
    if (bot && Object.keys(bot).length > 0) {
      const existing = userBots.find(
        (b) => b.spotlightBotId === bot.spotlightBotId
      );
      // only save if bot has changed from stored ones
      if (existing && existing !== bot) {
        saveBot();
      }
      // fetch any missing collection info
      if (isValidEthAddress(bot.contractAddress) && !bot.collectionName) {
        if (contractAddress !== bot.contractAddress) {
          setContractAddress(bot.contractAddress.toLowerCase());
        }
      }
    }
  }, [bot]);

  const fetchCollectionInfo = async () => {
    if (!bot || !bot.contractAddress) {
      console.log(
        `fetchCollectionInfo() - returning because (!bot || !bot.contractAddress)`
      );
      return;
    }
    // valid eth address - refresh collection info
    if (isValidEthAddress(bot.contractAddress)) {
      const api = new ApiService();
      const data = await api.get(
        `collection/info/${bot.contractAddress.trim()}`
      );
      console.log(`fetchCollectionInfo()`, data);
      if (data && data.isRarityCompatible) {
        // valid collection
        console.log(`collectionInfo`, data);
        setBot({
          ...bot,
          collectionImage: data.image,
          collectionName: data.name,
          collectionSymbol: data.symbol,
          collectionTokenType: data.tokenType,
          collectionTotalSupply: data.totalSupply,
        });
      }
      // no collection info for contract
      else {
        if (data && !data.isRarityCompatible) {
          toast.error("Sorry. This collection is incompatible with this bot.");
        }
        // only set to null if bot has these values set
        if (bot.collectionName) {
          setBot({
            ...bot,
            collectionImage: null,
            collectionName: null,
            collectionSymbol: null,
            collectionTokenType: null,
            collectionTotalSupply: null,
          });
        }
      }
    }
    // invalid eth address
    else {
      // only set to null if bot has these values set
      if (bot.collectionName) {
        setBot({
          ...bot,
          collectionImage: null,
          collectionName: null,
          collectionSymbol: null,
          collectionTokenType: null,
          collectionTotalSupply: null,
        });
      }
    }
  };

  const fetchTwitterAuths = () => {
    const api = new ApiService();
    api.get(`user/twitter/getAuths/${userId}`).then((data) => {
      setTwitterAuths(data);
      console.log(`twitterAuths`, data);
    });
  };

  useEffect(() => {
    if (isAuth && userId && !twitterAuths.length) {
      fetchTwitterAuths();
    }
  }, [userId, isAuth, twitterAuths.length]);

  useEffect(() => {
    console.log(`selectedBotId changed`, selectedBotId);
    // set form stage based on whether contract address is set
    if (selectedBotId && bot && bot.collectionName) {
      setFormStep(1); // go to the tweet designer stage
    } else {
      setFormStep(0); // go to the collection edit
      if (bot && !bot.collectionName) {
        // console.log(
        //   `fetchCollectionInfo() - called from: selectedBotId changed`
        // );
        // fetchCollectionInfo(); // fetch missing collection info
      }
    }
  }, [selectedBotId]);

  const handleSelectBot = (bot) => {
    if (bot.spotlightBotId === selectedBotId) {
      // bot already selected so unselect
      setBot(null);
      setSelectedBotId(null);
    } else {
      // select bot
      setBot(bot);
      setSelectedBotId(bot.spotlightBotId);
    }
  };

  const fetchUserBots = async () => {
    setUserBotsLoading(true);
    try {
      const api = new ApiService();
      const response = await api.get(`blocks/spotlightBot/getBots/${userId}`);
      console.log(`fetchUserBots()`, response);
      setUserBots(response);
    } catch (error) {
      console.error(error);
    } finally {
      setUserBotsLoading(false);
    }
  };

  useEffect(() => {
    if (!isAuth || !userId) {
      setUserBots([]);
      return;
    }
    fetchUserBots();
  }, [isAuth, userId]);

  const fetchBlockEvents = async () => {
    try {
      const api = new ApiService();
      const response = await api.get(`blocks/spotlightBot/getEvents/${userId}`);
      console.log(`fetchBlockEvents()`, response);
      setBlockEvents(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isAuth || !userId) {
      setBlockEvents([]);
      return;
    }
    fetchBlockEvents();
  }, [isAuth, userId]);

  const addNewBot = async () => {
    try {
      const api = new ApiService();
      const response = await api.post(`blocks/spotlightBot/addBot`, { userId });
      console.log(`addNewBot()`, response);
      if (response.error || !response.userBots) {
        toast.error(response.error);
      } else {
        toast.success("New bot created");
        setUserBots(response.userBots);
        // select new bot
        setBot(response.bot);
        setSelectedBotId(response.bot.spotlightBotId);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setUserBotsLoading(false);
    }
  };

  const confirmDeleteBotAlert = (botId) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "Any bots using this Twitter will be disabled",
      showCancelButton: true,
      confirmButtonColor: "#EB5757",
      cancelButtonColor: "#adadad",
      confirmButtonText: "Delete Bot",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBot(botId);
      }
    });
  };

  const deleteBot = async (botId) => {
    try {
      const api = new ApiService();
      const response = await api.post(`blocks/spotlightBot/deleteBot`, {
        userId,
        botId,
      });
      console.log(`deleteBot() - ${botId}`, response);
      if (response.error || !response.userBots) {
        toast.error(response.error);
      } else {
        toast.success("Bot deleted");
        // unselect bot
        setBot(null);
        setSelectedBotId(null);
        // set new user bots
        setUserBots(response.userBots);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setUserBotsLoading(false);
    }
  };

  const UserBots = () => {
    return (
      <Col lg={12}>
        <Card className="mb-3 h-fc bot-table">
          <Card.Header>
            <Card.Title>Your Bots</Card.Title>
            <div className="hvr-shrink">
              <button className="btn btn-primary" onClick={addNewBot}>
                <span className="me-2">
                  {" "}
                  <i className="fa fa-plus"></i>{" "}
                </span>
                NEW BOT
              </button>
            </div>
          </Card.Header>
          <Card.Body>
            {userBots.length === 0 && (
              <div className="no-userBots-msg">
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={[
                    "You have no bots",
                    2600,
                    "You are botless",
                    2600,
                    "The total supply of your bots is 0",
                    2600,
                    "You are in a bot-free zone",
                    2600,
                    "Bot inventory critically low",
                    2600,
                    "Zero bots, infinite potential",
                    2600,
                    "Fresh out of bots",
                    2600,
                    "Bot count: 0",
                    2600,
                    "Bots? Not here",
                    2600,
                    "You still have no bots",
                    2600,
                    "Bots. Get some.",
                    2600,
                  ]}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </div>
            )}
            {userBots.length > 0 && (
              <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <strong>Collection</strong>
                    </th>
                    <th>
                      <strong>Token Type</strong>
                    </th>
                    <th>
                      <strong>Automation</strong>
                    </th>
                    <th>
                      <strong>Next Scheduled Tweet</strong>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {userBots.map((bot, key) => {
                    return (
                      <tr
                        className={
                          selectedBotId && selectedBotId === bot.spotlightBotId
                            ? `selected`
                            : ``
                        }
                        key={`userBot_${key}`}
                        onClick={() => handleSelectBot(bot)}
                      >
                        <td>
                          <div className="d-flex align-items-center">
                            {bot.collectionName && (
                              <>
                                <img
                                  src={bot.collectionImage}
                                  className="me-2 br-50  border-green"
                                  width="28"
                                  alt=""
                                />{" "}
                                <span className="w-space-no">
                                  {bot.collectionName}
                                </span>
                              </>
                            )}
                            {!bot.collectionName && (
                              <span className="w-space-no">Not Set</span>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            {bot.collectionName &&
                            bot.collectionChain === "ethereum" ? (
                              <img
                                className="eth-logo"
                                width={11}
                                src={ethGreenImg}
                              />
                            ) : (
                              ""
                            )}
                            {bot.collectionTokenType === "ERC721" && (
                              <div className="badge bg-light">ERC 721</div>
                            )}
                            {bot.collectionTokenType === "ERC1155" && (
                              <div className="badge bg-warning">ERC 1155</div>
                            )}
                            {bot.collectionTokenType === "Other" && (
                              <div className="badge bg-dark">N/A</div>
                            )}
                          </div>
                        </td>
                        <td>
                          {bot.automationEnabled && (
                            <div className="d-flex align-items-center">
                              <i className="fa fa-circle text-success me-1"></i>{" "}
                              Enabled
                            </div>
                          )}
                          {!bot.automationEnabled && (
                            <div className="d-flex align-items-center">
                              <i className="fa fa-circle text-danger me-1"></i>{" "}
                              Paused
                            </div>
                          )}
                        </td>
                        <td>
                          {bot.automationEnabled && (
                            <span>
                              {timeUntil({
                                secondsUntil:
                                  bot.automationDailyTimeSecondsUntil,
                              })}
                            </span>
                          )}
                          {!bot.automationEnabled && <span>N/A</span>}
                        </td>
                        <td>
                          <div className="d-flex">
                            {/* <Link
                href="#"
                className="btn btn-primary shadow btn-xs sharp me-1"
              >
                <i className="fas fa-pencil-alt"></i>
              </Link> */}
                            <div className="hvr-shrink">
                              <div
                                onClick={() =>
                                  confirmDeleteBotAlert(bot.spotlightBotId)
                                }
                                className="btn btn-danger btn-xs sharp"
                              >
                                <i className="fa fa-trash"></i>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
      </Col>
    );
  };
  return (
    <BotContext.Provider value={[bot, setBot]}>
      <UserBots />
      <BotEditor
        formStep={formStep}
        setFormStep={setFormStep}
        alchemySDK={alchemySDK}
        twitterAuths={twitterAuths}
        fetchCollectionInfo={fetchCollectionInfo}
        contractAddress={contractAddress}
        setContractAddress={setContractAddress}
      />
      <BlockEventsTable events={blockEvents} />
    </BotContext.Provider>
  );
};

const BotEditor = ({
  formStep,
  setFormStep,
  contractAddress,
  setContractAddress,
  alchemySDK,
  twitterAuths,
  fetchCollectionInfo,
}) => {
  const [bot, setBot] = useContext(BotContext);

  console.log(`BotEditor Refresh - bot`, bot);
  useEffect(() => {
    if (!bot || !bot.contractAddress) {
      return; // might cause error if contractAddy had been set previously then deleted
    }
    console.log(
      `fetchCollectionInfo() - called from: contract address changed`
    );
    fetchCollectionInfo();
  }, [contractAddress]);

  // const handlbotEdit = (event) => {
  //   setData({
  //     ...bot,
  //     [event.target.name]: event.target.value,
  //   });
  // }
  // accompanied w/ :
  // <input name="name" value={data.name} onChange={handleChange} />

  const handleContractAddressChange = (event) => {
    const newContractAddress = event.target ? event.target.value.trim() : event;
    console.log(`newContractAddress`, newContractAddress);
    if (bot.contractAddress !== newContractAddress) {
      setBot({
        ...bot,
        contractAddress: newContractAddress,
      });
    }
    setContractAddress(newContractAddress);
  };

  return (
    <AnimatePresence>
      {bot !== null && (
        <motion.div
          animate={{
            x: [-500, 0],
            // scale: [0, 1],
            opacity: [0, 1],
          }}
        >
          <div className="card mb-3 h-fc">
            <div className="card-header d-flex justify-content-start">
              <CollectionImage collectionImage={bot?.collectionImage} />
              <h4 className="card-title">Edit Bot</h4>
            </div>
            <div className="card-body">
              <div className="form-wizard">
                <Stepper className="nav-wizard mb-1" activeStep={formStep}>
                  <Step
                    label="Collection"
                    className="nav-link"
                    onClick={() => setFormStep(0)}
                  />
                  <Step
                    label="Tweet Designer"
                    className="nav-link"
                    onClick={() => setFormStep(1)}
                  />
                  <Step
                    label="Automation"
                    className="nav-link"
                    onClick={() => setFormStep(2)}
                  />
                </Stepper>

                {formStep === 0 && (
                  <>
                    <CollectionEditor
                      handleContractAddressChange={handleContractAddressChange}
                    />
                    {bot?.collectionName && (
                      <div className="text-end toolbar toolbar-bottom p-2">
                        <div className="hvr-shrink">
                          <button
                            className="btn btn-primary sw-btn-next"
                            onClick={() => setFormStep(1)}
                          >
                            Tweet Designer{" "}
                            <i className="fa fa-arrow-right ml-05"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {formStep === 1 && (
                  <>
                    <TweetDesigner alchemySDK={alchemySDK} />
                    <div className="text-end toolbar toolbar-bottom p-2 pl-0 pr-0 d-flex justify-content-between">
                      <div className="hvr-shrink">
                        <button
                          className="btn btn-secondary sw-btn-prev"
                          onClick={() => {
                            setFormStep(0);
                          }}
                        >
                          <i className="fa fa-arrow-left mr-05"></i> Collection
                        </button>
                      </div>
                      <div className="d-flex">
                        <div className="hvr-shrink">
                          <button
                            className="btn btn-secondary sw-btn-next me-1"
                            onClick={() => {
                              setFormStep(2);
                            }}
                          >
                            Automation{" "}
                            <i className="fa fa-arrow-right ml-05"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {formStep === 2 && (
                  <>
                    <AutomationControls twitterAuths={twitterAuths} />
                    <div className="text-end toolbar toolbar-bottom p-2 pl-0 pr-0 d-flex justify-content-between">
                      <div className="hvr-shrink">
                        <button
                          className="btn btn-secondary sw-btn-prev"
                          onClick={() => {
                            setFormStep(1);
                          }}
                        >
                          <i className="fa fa-arrow-left mr-05"></i> Tweet
                          Designer
                        </button>
                      </div>
                      {/* <div className="d-flex">
                        <div className="hvr-shrink">
                          <button
                            className="btn btn-success sw-btn-prev me-1 mr-05"
                            onClick={() => {}}
                          >
                            Save <i className="fa fa-check ml-05"></i>
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const SpotlightBot = (props) => {
  const BotHeading = () => {
    const [animate, setAnimate] = useState(false);
    const tweetLoadAnim = useAnimation();
    const tweetLoaded = () => {};

    async function startAnimation() {
      await tweetLoadAnim.start({
        opacity: 1,
        scale: 1,
        transition: { duration: 0.2 },
      });
    }

    return (
      <div className="row">
        <div className="col-lg-8">
          <BlockCard block={Blocks.NFTSpotlightBot} />
          <AuthOnly>
            <motion.div animate={{ x: 0 }} initial={{ x: -1000 }}>
              <BotControls />
            </motion.div>
          </AuthOnly>
        </div>
        <div className="col-lg-4">
          <div className="spottie-tweet-example-card position-relative">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={tweetLoadAnim}
            >
              <div className="bot-example-card-badge">
                Spotlight Bot Example Tweet
              </div>
              <TwitterTweetEmbed
                onLoad={function noRefCheck() {
                  console.log(`setShowExampleTweet()`);
                  startAnimation();
                }}
                tweetId={"1640485657175814144"}
                options={{ theme: "dark" }}
              />
            </motion.div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {/* <button disabled={isLoading} onClick={() => signMessage()}>
        Sign message
      </button>
      {isSuccess && <div>Signature: {data}</div>}
      {isError && <div>Error signing message</div>} */}
      <PageHeaderBar
        pages={[
          { title: "Blocks", url: `/blocks` },
          { title: "NFT Spotlight Bot", url: `/blocks/spotlight` },
        ]}
      />
      <BotHeading />
    </Fragment>
  );
};

export default SpotlightBot;
