import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Papa from "papaparse";

import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import loadingCircleImg from "../../../../images/misc/loading-circle-green.svg";
import landmarkImg from "../../../../images/landmark/logo-text-dark.png";
import ApiService from "../../../../services/ApiService";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { utils } from "ethers";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { FileCopyOutlined as FileCopyOutlinedIcon } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { darken } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TypeAnimation } from "react-type-animation";
import { copyTextToClipboard, timeNow, useDocumentTitle } from "../../../utils";
import { ThemeContext } from "../../../../context/ThemeContext";
import {
  addCommas,
  epochToDateString,
  epochToDateTimeString,
  stringToRGB,
  timeAgo,
  toTitleCase,
} from "../../../../utils";

import SeamlessBotImg from "../../../../images/misc/seamless-bot-logo.png";
import BlueLoading from "../../../../images/misc/blue-goo-loading.svg";
import { Parser } from "@json2csv/plainjs";
// import { timeNow, useDocumentTitle } from "../../../utils";

const SeamlessAccounts = [
  "ihaq@landmarkventures.com",
  "ibrown@landmarkventures.com",
  "dsoukup@landmarkventures.com",
  "Sniessl@landmarkventures.com",
  "ptemplet@landmarkventures.com",
];

function mapEmails(arr, emailKey, setEmailKey = null) {
  const emailsSet = new Set();

  arr.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (
        typeof obj[key] === "string" &&
        /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b/.test(obj[key])
      ) {
        const email = obj[key].toLowerCase().trim();
        emailsSet.add(email);
        if (setEmailKey && emailKey !== key) {
          setEmailKey(key);
        }
      }
    });
  });

  return Array.from(emailsSet);
}

const SeamlessBotForm = ({ password }) => {
  const [originalData, setOriginalData] = useState([]);
  const [emailKey, setEmailKey] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [csvFileName, setCsvFileName] = useState("");
  const [masterCsvData, setMasterCsvData] = useState([]);
  const [masterCsvFileName, setMasterCsvFileName] = useState("");

  const [masterLoading, setMasterLoading] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [duplicateCount, setDuplicateCount] = useState(0);

  const [linkedInUsername, setLinkedInUsername] = useState(
    "mjonezdev@gmail.com"
  );
  const [linkedInPassword, setLinkedInPassword] = useState("landmarkadventure");
  const [searchUrl, setSearchUrl] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const [resultsEmail, setResultsEmail] = useState("");
  const [seamlessAccount, setSeamlessAccount] = useState(SeamlessAccounts[0]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setCsvFileName(file.name);

    Papa.parse(file, {
      complete: (result) => {
        const emailsFromCsv = mapEmails(result.data, emailKey, setEmailKey);
        setOriginalData(result.data);
        setCsvData(emailsFromCsv);
        console.log(`csvData`, emailsFromCsv);
      },
      header: true,
    });
  };

  const handleMasterFileUpload = (e) => {
    const file = e.target.files[0];
    setMasterCsvFileName(file.name);

    setMasterLoading(true);

    setTimeout(() => {
      Papa.parse(file, {
        complete: (result) => {
          const emailsFromCsv = mapEmails(result.data);
          setMasterCsvData(emailsFromCsv);
          console.log(`masterCsvData`, emailsFromCsv);
          setMasterLoading(false);
        },
        header: true,
      });
    }, 500);
  };

  const handleRunBotBtn = async () => {
    if (!linkedInUsername) {
      toast.error("Invalid Email");
      return;
    }
    if (!linkedInPassword) {
      toast.error("Invalid Password");
      return;
    }
    if (!searchTitle) {
      toast.error("Invalid Search Title");
      return;
    }
    if (!searchUrl) {
      toast.error("Invalid Search URL");
      return;
    }
    if (!seamlessAccount) {
      toast.error("Invalid Seamless Account");
      return;
    }
    if (!resultsEmail) {
      toast.error("Invalid Results Email");
      return;
    }
    if (!isRunBtnEnabled) {
      return;
    }
    try {
      const api = new ApiService({ botsApiUrl: true });
      const { success, error } = await api.post(
        `landmark/seamlessbot/createSeamlessBotTask`,
        {
          taskType: "sales",
          password,
          linkedInUsername,
          linkedInPassword,
          searchUrl,
          searchTitle,
          seamlessAccount,
          resultsEmail,
        }
      );
      if (!success) {
        toast.error(`${error}`);
        return false;
      } else {
        toast.success(`${success}`);
        toast.success(`Results will be emailed to ${resultsEmail}`);
        // clear fields
        setSearchTitle("");
        setSearchUrl("");
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  let isRunBtnEnabled = false;

  if (
    linkedInUsername &&
    linkedInPassword &&
    searchTitle &&
    searchUrl &&
    resultsEmail &&
    seamlessAccount
  ) {
    isRunBtnEnabled = true;
  }

  const handleSetLinkedInUsername = (email) => {
    setLinkedInUsername(email);
    setResultsEmail(email);
  };

  // const downloadFinalData = () => {
  //   const fileName = `deduped-emails-${timeNow()}.csv`;
  //   try {
  //     const parser = new Parser();
  //     const csv = parser.parse(finalData);
  //     const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  //     const link = document.createElement("a");
  //     if (link.download !== undefined) {
  //       const url = URL.createObjectURL(blob);
  //       link.setAttribute("href", url);
  //       link.setAttribute("download", fileName);
  //       link.style.visibility = "hidden";
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       toast.success("File Downloaded");
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  return (
    <>
      <div className="bot-downtime-msg">
        03/13/24 - Bot is experiencing downtime due to LinkedIn/Seamless
        changing the way contacts are displayed
      </div>
      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
        <Card className="mb-3 h-fc bot-table">
          <div className="logo-container">
            <img src={SeamlessBotImg} className="img-fluid" />
          </div>
          <div className="row">
            <>
              <div className="seamless-bot-form-wrapper">
                <div className="form-section text-left">
                  <div className="events-owners-subheading text-center">
                    Enter your LinkedIn credentials
                    <br />
                    (account must have Sales access)
                  </div>
                  <div className="form-group mb-3 col-12">
                    <label className="form-label">
                      Email <span className="example-text"></span>
                    </label>
                    <input
                      type="text"
                      value={linkedInUsername}
                      className="form-control"
                      onChange={(e) =>
                        handleSetLinkedInUsername(e.target.value)
                      }
                      autoComplete="email"
                    />
                  </div>
                  <div className="form-group mb-3 col-12">
                    <label className="form-label">
                      Password <span className="example-text"></span>
                    </label>
                    <input
                      type="password"
                      value={linkedInPassword}
                      className="form-control"
                      onChange={(e) => setLinkedInPassword(e.target.value)}
                      autoComplete="linkedInPassword"
                    />
                  </div>
                  <div className="events-owners-subheading text-center">
                    Enter Sales Search URL
                    <br />
                    <i>
                      (e.g. https://www.linkedin.com/sales/search/people...)
                    </i>
                  </div>
                  <div className="form-group mb-3 col-12">
                    <label className="form-label">
                      Search URL <span className="example-text"></span>
                    </label>
                    <input
                      type="text"
                      value={searchUrl}
                      className="form-control"
                      onChange={(e) => setSearchUrl(e.target.value)}
                      autoComplete="salesUrl"
                    />
                    <br></br>
                    <label className="form-label">
                      Search Title <i>(e.g. Marketing CEO connections)</i>
                      <br></br>
                      <i>
                        (Put whatever you want here, for your reference only,
                        does not affect search results)
                      </i>{" "}
                      <span className="example-text"></span>
                    </label>
                    <input
                      type="text"
                      value={searchTitle}
                      className="form-control"
                      onChange={(e) => setSearchTitle(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-3 col-12">
                    <label className="form-label">
                      Seamless Account <span className="example-text"></span>
                    </label>
                    <select
                      className="form-control lh-1"
                      onChange={(e) => setSeamlessAccount(e.target.value)}
                      value={seamlessAccount}
                    >
                      {SeamlessAccounts &&
                        SeamlessAccounts.map((account, key) => (
                          <option key={`owned_id_${key}`} value={account}>
                            {account}
                          </option>
                        ))}
                    </select>
                    <br></br>
                    <div className="form-section text-left">
                      <div className="events-owners-subheading text-center">
                        Send Results to
                      </div>
                      <label className="form-label">Email</label>
                      <input
                        type="text"
                        value={resultsEmail}
                        className="form-control"
                        onChange={(e) => setResultsEmail(e.target.value)}
                        autoComplete="email"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      className={`run-btn btn btn-primary ${
                        isRunBtnEnabled ? "" : "disabled"
                      }`}
                      onClick={handleRunBotBtn}
                    >
                      {`RUN BOT`}
                      <i className="event-owner-add fa-sharp fa-solid fa-robot ml-05"></i>
                    </button>
                  </div>
                </div>
              </div>
            </>
          </div>
        </Card>
      </motion.div>
    </>
  );
};

const PasswordPrompt = ({ password, setPassword, submitPassword }) => {
  const handleInputChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submitPassword(password);
  };

  return (
    <div className="d-flex">
      <input
        onChange={handleInputChange}
        type="password"
        className="form-control w-100 mt-1 inline-input"
        placeholder="Password"
        value={password}
        autoComplete="password"
      ></input>
      <div
        className="btn btn-primary light btn-xs mt-1 me-1 w-fc inline-btn"
        onClick={(e) => handleSubmit(e)}
      >
        Submit
      </div>
    </div>
  );
};

const Dashboard = (props) => {
  const { changeBackground } = useContext(ThemeContext);
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // set light theme
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);

  async function submitPassword(password) {
    if (!password) {
      toast.error("Invalid Password");
      return;
    }
    try {
      const api = new ApiService();
      const { success, error } = await api.post(`landmark/events/auth`, {
        password,
      });
      if (!success) {
        toast.error(`${error}`);
        setIsAuthenticated(false);
        return false;
      } else {
        toast.success(`${success}`);
        setIsAuthenticated(true);
        return true;
      }
    } catch (error) {
      console.error(error);
      setIsAuthenticated(false);
      return false;
    }
  }

  useDocumentTitle("LinkedIn Sales Nav Bot - Landmark Ventures");

  return (
    <Fragment>
      <div className="global-dash-wrapper seamless-bot">
        {/* <button disabled={isLoading} onClick={() => signMessage()}>
        Sign message
      </button>
      {isSuccess && <div>Signature: {data}</div>}
      {isError && <div>Error signing message</div>} */}
        <div className="d-flex align-items-center justify-content-center">
          <img src={landmarkImg} className="img-fluid landmark-img" />
        </div>
        <PageHeaderBar
          pages={[
            { title: "Landmark", url: `` },
            { title: "LinkedIn Sales Nav Bot", url: `/landmark/salesnavbot` },
          ]}
        />
        {/* show dashboard */}
        {isAuthenticated ? (
          <>
            <SeamlessBotForm password={password} />
          </>
        ) : null}
        {/* show password entry */}
        {!isAuthenticated ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div className="not-authed-msg">
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={["Enter password to access tool", 4600]}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <PasswordPrompt
                password={password}
                setPassword={setPassword}
                submitPassword={submitPassword}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default Dashboard;
