import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import loadingCircleImg from "../../../../images/misc/loading-circle-green.svg";
import landmarkImg from "../../../../images/landmark/logo-text-dark.png";
import ApiService from "../../../../services/ApiService";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import "react-datepicker/dist/react-datepicker.css";
import { utils } from "ethers";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { FileCopyOutlined as FileCopyOutlinedIcon } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { darken } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TypeAnimation } from "react-type-animation";
import {
  copyTextToClipboard,
  timeNow,
  useDocumentTitle,
  epochToDateObj,
  epochFromDate,
  uniqueGuid,
} from "../../../utils";
import { ThemeContext } from "../../../../context/ThemeContext";
import {
  epochToDateString,
  epochToDateTimeString,
  stringToRGB,
  toTitleCase,
} from "../../../../utils";

const OptionForm = ({ option, password, handleUpdateOption }) => {
  const [title, setTitle] = useState(option ? option.title : "");
  const [description, setDescription] = useState(
    option ? option.description : ""
  );
  const [location, setLocation] = useState(option ? option.location : "");
  const [startDate, setStartDate] = useState(
    option ? epochToDateObj(option.startTime) : epochToDateObj(timeNow())
  );
  const [endDate, setEndDate] = useState(
    option
      ? epochToDateObj(option.endTime)
      : epochToDateObj(timeNow() + 60 * 90)
  );

  useEffect(() => {
    registerLocale("en-US", enUS);
  }, []);

  useEffect(() => {
    // new option created
    if (!option || !option.title) {
      setTitle("");
      setDescription("");
      setLocation("");
      setStartDate(epochToDateObj(timeNow()));
      setEndDate(epochToDateObj(timeNow() + 60 * 90));
    }
    // existing option obj
    else {
      setTitle(option.title);
      setDescription(option.description);
      setLocation(option.location);
      setStartDate(epochToDateObj(option.startTime));
      setEndDate(epochToDateObj(option.endTime));
    }
    console.log(`option`, option);
  }, [option]);

  const handleSetStartDate = (date) => {
    console.log(`handleSetStartDate() - date`, date);
    setStartDate(date);
  };

  const handleSetEndDate = (date) => {
    console.log(`handleSetStartDate() - date`, date);
    setEndDate(date);
  };

  const handleSaveOption = () => {
    const updatedOption = {
      title,
      description,
      location,
      startTime: epochFromDate(startDate),
      endTime: epochFromDate(endDate),
    };
    if (option && (option.optionId || option.tempOptionId)) {
      // set optionId so handleUpdateOption knows we are editing not creating
      updatedOption.optionId = option.optionId;
      updatedOption.tempOptionId = option.tempOptionId;
    }
    console.log(`handleUpdateOption() - option`, option);
    console.log(`handleUpdateOption() - updatedOption`, updatedOption);
    handleUpdateOption(updatedOption);
  };

  const newOptionMode =
    !option || (!option.optionId && !option.tempOptionId) ? true : false; // user is creating a new option or editing existing

  return (
    <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
      <div className="option-form">
        <div className="row">
          <div className="form-group mb-3 col-md-6">
            <label className="form-label">
              Title <span className="example-text">(e.g. VIP Brunch)</span>
            </label>
            <input
              type="text"
              value={title}
              className="form-control"
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group mb-3 col-md-6">
            <label className="form-label">
              Location{" "}
              <span className="example-text">(e.g. The Palazzo Hotel)</span>
            </label>
            <input
              type="text"
              value={location}
              className="form-control"
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>
          <div className="form-group mb-3 col-md-12">
            <label className="form-label">
              Description{" "}
              <span className="example-text">
                (e.g. Delicious vegan brunch and networking meetup for VIPs)
              </span>
            </label>
            <input
              type="text"
              value={description}
              className="form-control"
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="form-group mb-3 col-md-6">
            <label className="form-label">
              Start Time{" "}
              <span className="example-text">(e.g. August 10th 17:30)</span>
            </label>
            <DatePicker
              selected={startDate}
              onChange={(date) => handleSetStartDate(date)}
              showTimeSelect
              locale="en-US"
              // showTimeInput
              timeIntervals={15}
              dateFormat="Pp"
            />
          </div>
          <div className="form-group mb-3 col-md-6">
            <label className="form-label">
              End Time{" "}
              <span className="example-text">(e.g. August 10th 19:00)</span>
            </label>
            <DatePicker
              selected={endDate}
              onChange={(date) => handleSetEndDate(date)}
              locale="en-US"
              showTimeSelect
              // showTimeInput
              timeIntervals={15}
              dateFormat="Pp"
            />
          </div>
          <div className="form-group mb-3 col-md-12">
            {newOptionMode && (
              <button
                className={`create-event-btn btn btn-sm btn-primary`}
                onClick={handleSaveOption}
              >
                {`Save New Option`}
                <i className="event-owner-add fa-sharp fa-solid fa-check ml-05"></i>
              </button>
            )}
            {!newOptionMode && (
              <button
                className={`create-event-btn btn btn-sm btn-primary`}
                onClick={handleSaveOption}
              >
                {`Save Changes`}
                <i className="event-owner-add fa-sharp fa-solid fa-check ml-05"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const CampaignForm = ({
  selectedCampaign,
  password,
  getCampaigns,
  exitCreateAndEditMode,
}) => {
  const [title, setTitle] = useState(
    selectedCampaign ? selectedCampaign.title : ""
  );
  const [description, setDescription] = useState(
    selectedCampaign ? selectedCampaign.description : ""
  );

  async function addOrEditCampaign(campaign, editMode = false) {
    try {
      const api = new ApiService({ password });
      const url = editMode
        ? "landmark/campaigns/editCampaign"
        : "landmark/campaigns/addCampaign";
      const eventsResponse = await api.post(url, {
        campaign,
      });
      console.log(`addOrEditCampaign() - ${url} - response`, eventsResponse);
      const { success, error } = eventsResponse;
      if (!success) {
        toast.error("An error occurred");
      } else if (error) {
        toast.error(error);
      } else {
        toast.success(success);
        // refresh events and exit form
        getCampaigns();
        exitCreateAndEditMode();
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSaveEvent = () => {
    // scrape details from state
    const campaign = {
      title,
      description,
    };
    // saving an existing event - use edit endpoint
    if (selectedCampaign && selectedCampaign.campaignId) {
      campaign.campaignId = selectedCampaign.campaignId;
      // edit event
      addOrEditCampaign(campaign, true);
    } else {
      // add event
      addOrEditCampaign(campaign, false);
    }
  };

  useEffect(() => {
    // new event created
    if (!selectedCampaign || !selectedCampaign.campaignId) {
      setTitle("");
      setDescription("");
    }
    // existing event obj
    else {
      setTitle(selectedCampaign.title);
      setDescription(selectedCampaign.description);
    }
  }, [selectedCampaign]);

  const saveBtnText =
    selectedCampaign === null ? "Save Campaign" : "Save Changes";

  return (
    <div className="event-form">
      <div className="row">
        <div className="form-group mb-3 col-md-12">
          <label className="form-label">
            Title{" "}
            {/* Title <span className="example-text">(e.g. Blackhat)</span> */}
          </label>
          <input
            type="text"
            value={title}
            className="form-control"
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-group mb-3 col-md-12">
          <label className="form-label">Description </label>
          <input
            type="text"
            value={description}
            className="form-control"
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div>
      <button
        className={`create-event-btn btn btn-sm btn-primary mb-3`}
        onClick={handleSaveEvent}
      >
        {saveBtnText}
        <i className="event-owner-add fa-sharp fa-solid fa-check ml-05"></i>
      </button>
    </div>
  );
};

const AddEditEvent = ({ campaigns, getCampaigns, password }) => {
  const [showForm, setShowForm] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const toggleSetShowForm = () => {
    setShowForm(!showForm);
  };

  const handleSetCreateMode = () => {
    setEditMode(false);
    setCreateMode(true);
    setSelectedCampaign(null);
  };

  const handleSetEditMode = () => {
    setCreateMode(false);
    setEditMode(true);
  };

  const exitCreateAndEditMode = () => {
    setCreateMode(false);
    setEditMode(false);
    setSelectedCampaign(null);
  };

  return (
    <>
      <div className="add-edit-btn-container">
        {!showForm && (
          <>
            <button
              className={`create-event-btn btn btn-sm btn-primary`}
              onClick={handleSetCreateMode}
            >
              {`Create New Campaign`}
              <i className="event-owner-add fa-sharp fa-solid fa-plus ml-05"></i>
            </button>
            <button
              className={`create-event-btn btn btn-sm btn-primary`}
              onClick={handleSetEditMode}
            >
              {`Edit Existing Campaign`}
              <i className="event-owner-add fa-sharp fa-solid fa-pencil ml-05"></i>
            </button>
          </>
        )}
      </div>
      {editMode ? (
        <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
          <Card className="mb-3 h-fc bot-table">
            <div className="row">
              <div className="outputHeader">Edit Campaign</div>
              <>
                <div className="events-owners-subheading">
                  Select Campaign to Edit
                </div>
                <div
                  className="event-owners-wrapper"
                  style={{ marginBottom: "12px" }}
                >
                  {campaigns.map((event) => {
                    const selectedClass =
                      selectedCampaign &&
                      event.campaignId === selectedCampaign.campaignId
                        ? "selected"
                        : "";
                    return (
                      <div key={event.campaignId}>
                        <div
                          className={`event-owner-container selectable ${selectedClass}`}
                          onClick={() => setSelectedCampaign(event)}
                        >
                          <img
                            src={event.imageUrl}
                            className="img-fluid event-img"
                          />
                          <div className="event-title">{event.title}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {selectedCampaign ? (
                  <>
                    <CampaignForm
                      selectedCampaign={selectedCampaign}
                      password={password}
                      getCampaigns={getCampaigns}
                      exitCreateAndEditMode={exitCreateAndEditMode}
                    />
                  </>
                ) : null}
              </>
            </div>
          </Card>
        </motion.div>
      ) : null}
      {createMode ? (
        <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
          <Card className="mb-3 h-fc bot-table">
            <div className="row">
              <div className="outputHeader">Create Campaign</div>
              <>
                {/* <div className="events-owners-subheading">
                </div> */}
                <>
                  <CampaignForm
                    selectedCampaign={selectedCampaign}
                    password={password}
                    getCampaigns={getCampaigns}
                    exitCreateAndEditMode={exitCreateAndEditMode}
                  />
                </>
              </>
            </div>
          </Card>
        </motion.div>
      ) : null}
    </>
  );
};

export default AddEditEvent;
