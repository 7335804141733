import { lazy, Suspense, useEffect, useState } from "react";

/// Components
import Index from "./jsx";
import { connect, useDispatch } from "react-redux";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
// action
import { checkAutoLogin } from "./services/AuthService";
import { Toaster } from "react-hot-toast";
// import { isAuthenticated } from './store/selectors/AuthSelectors';
import { Analytics } from "@vercel/analytics/react";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal, useWeb3ModalTheme, useWeb3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { mainnet, polygon } from "wagmi/chains";
import { useAccount, useSignMessage } from "wagmi";
import { AlchemyProvider } from "./jsx/Providers/AlchemyProvider";
import { AuthProvider } from "./jsx/Providers/AuthProvider";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";
import w3mLogo from "./images/logo/bb-walletconnect-logo.png";

/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

const chains = [mainnet, polygon];
const projectId = "4badc3514fbbf177dcbd14358ea582bc";

const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  provider,
});
const ethereumClient = new EthereumClient(wagmiClient, chains);

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

function App(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //    checkAutoLogin(dispatch, navigate);
  // }, []);

  // const { theme, setTheme } = useWeb3ModalTheme()
  // // Set modal theme
  // setTheme({
  //   themeMode: 'light',
  //   themeVariables: {
  //     // '--w3m-font-family': 'Roboto, sans-serif',
  //     // '--w3m-accent-color': '#362465'
  //   }
  // })

  let routeblog = (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/page-register" element={<SignUp />} />
      <Route path="/page-forgot-password" element={<ForgotPassword />} />
    </Routes>
  );
  if (props.isAuthenticated) {
    console.log(`App render`);
    return (
      <>
        <WagmiConfig client={wagmiClient}>
          <AuthProvider>
            <AlchemyProvider>
              <Toaster
                toastOptions={{
                  style: {
                    background: "#1f262b",
                    color: "#fff",
                  },
                }}
              />
              <Suspense
                fallback={
                  <div id="preloader">
                    <div className="sk-three-bounce">
                      <div className="sk-child sk-bounce1"></div>
                      <div className="sk-child sk-bounce2"></div>
                      <div className="sk-child sk-bounce3"></div>
                    </div>
                  </div>
                }
              >
                <Index />
              </Suspense>
            </AlchemyProvider>
          </AuthProvider>
        </WagmiConfig>
        <Web3Modal
          themeMode="dark"
          themeVariables={{
            "--w3m-accent-color": "#00e3b4",
            "--w3m-background-color": "#00e3b4",
            "--w3m-accent-fill-color": "#000",
            "--w3m-logo-image-url": w3mLogo,
            "--w3m-font-family": "Chakra Petch, sans-serif",
          }}
          projectId={projectId}
          ethereumClient={ethereumClient}
        />
        <Analytics />
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routeblog}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // isAuthenticated: isAuthenticated(state),
    isAuthenticated: true,
  };
};

//export default connect((mapStateToProps)(App));
export default withRouter(connect(mapStateToProps)(App));
