import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { Stepper, Step } from "react-form-stepper";
import { Link } from "react-router-dom";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import toast from "react-hot-toast";
import { Row, Col, Card, Table, Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { AlchemyContext } from "../../../Providers/AlchemyProvider";
import { TwitterTweetEmbed, TwitterShareButton } from "react-twitter-embed";
import blueWaveAnim from "../../../../images/misc/green-wave-anim.svg";
import BlockEventsTable from "../BlockEventsTable";

import {
  calculateTwitterCharacters,
  convertStringToHtml,
  convertStringToTwitterHtml,
  fileExtensionIsImage,
  getRandomIntInRange,
  makeReplacementsToString,
  stripNonNumeric,
  stripNonTwitterHandleChars,
  timeUntil,
  truncateAddress,
} from "../../../../utils";

import avatar1 from "../../../../images/avatar/1.jpg";
import AuthOnly from "../../AuthOnly";
import ApiService from "../../../../services/ApiService";
import { AuthContext } from "../../../Providers/AuthProvider";
import { isValidEthAddress } from "../../../utils";
import TwitterAuthSelectDropdown from "../TwitterAuthSelectDropdown";
import EmojiPicker from "../EmojiPicker";

import { Blocks } from "../blocks";
import BlockCard from "../BlockCard";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import { TypeAnimation } from "react-type-animation";
import { MultiSelect } from "primereact/multiselect";

const dailyTimeOptions = [
  {
    name: "00:00",
    value: "0",
  },
  {
    name: "01:00",
    value: "1",
  },
  {
    name: "02:00",
    value: "2",
  },
  {
    name: "03:00",
    value: "3",
  },
  {
    name: "04:00",
    value: "4",
  },
  {
    name: "05:00",
    value: "5",
  },
  {
    name: "06:00",
    value: "6",
  },
  {
    name: "07:00",
    value: "7",
  },
  {
    name: "08:00",
    value: "8",
  },
  {
    name: "09:00",
    value: "9",
  },
  {
    name: "10:00",
    value: "10",
  },
  {
    name: "11:00",
    value: "11",
  },
  {
    name: "12:00",
    value: "12",
  },
  {
    name: "13:00",
    value: "13",
  },
  {
    name: "14:00",
    value: "14",
  },
  {
    name: "15:00",
    value: "15",
  },
  {
    name: "16:00",
    value: "16",
  },
  {
    name: "17:00",
    value: "17",
  },
  {
    name: "18:00",
    value: "18",
  },
  {
    name: "19:00",
    value: "19",
  },
  {
    name: "20:00",
    value: "20",
  },
  {
    name: "21:00",
    value: "21",
  },
  {
    name: "22:00",
    value: "22",
  },
  {
    name: "23:00",
    value: "23",
  },
];

const automationIntervalOptions = [
  {
    name: "24 hours",
    value: "24 hours",
  },
  {
    name: "48 hours",
    value: "48 hours",
  },
  {
    name: "72 hours",
    value: "72 hours",
  },
  {
    name: "Weekly",
    value: "Weekly",
  },
];
const BotContext = createContext({});
//const [selectedCollections, setSelectedCollections] = useState(null);

const UserList = ({ alchemySDK }) => {
  const [bot, setBot] = useContext(BotContext);

  const [currentUserList, setCurrentUserList] = useState(
    bot?.twitterHandleList || []
  );
  const [currentInputName, setCurrentInputName] = useState("");
  const [userListErrorMessage, setUserListErrorMessage] = useState("");

  const [hasUserHitLimit, setHasUserHitLimit] = useState(false);

  let storedUsername = "";

  let customMessage =
    bot?.contractAddress === "0xb159f1a0920a7f1d336397a52d92da94b1279838"
      ? "Add a user to become a ROYAL"
      : "Add a user to throw some bananas";
  let brandingImage =
    bot?.contractAddress === "0xb159f1a0920a7f1d336397a52d92da94b1279838"
      ? "https://i.seadn.io/gae/pD7CaZQGBaVf-IwBi4KQdkFDlM_2bXyTgDXJCHp0Y98Ca1fNgm266WzrOCNZFT_DMs4zat7Zu8tDolatTuINQOb1jMjfhEYiXSnaoQ?auto=format&w=256"
      : "https://i.seadn.io/gcs/files/9e95b0ff4591b3bc5da1a0bf78d03088.gif?auto=format&w=256";
  let brandingHeaderImage =
    bot?.contractAddress === "0xb159f1a0920a7f1d336397a52d92da94b1279838"
      ? "https://i.seadn.io/gae/X_WXSCwdam99GpBb02pW4v7Ic3MXnrM3bSvCcYp5uLLdleyv4jG53RHyPjBW9fEZZfZoRr7sKd9iykSHQBhy-UfhbDIzK-jMz_z3AA?auto=format&w=3840"
      : "https://i.seadn.io/gae/3T2A9ZAKKL3DU78upgMC2sWBRmg6RYZFrgSnPPvXzMfPReyuJ0uSUzMW08JbSaVNzx0P3L5HtFnI4NcJ_fyoHpKn_uaET2PSssneRQ?auto=format&w=3840";

  const attemptAddHandle = (handle, key) => {
    console.log(`attemptAddHandle() - handle`, handle);
    console.log(`attemptAddHandle() - key`, key);
    if (handle === "$HACKY_SOLUTION$") {
      handle = currentInputName;
    }
    if (!hasUserHitLimit) {
      // Error validation
      if (handle.length >= 15) {
        setUserListErrorMessage("Handle must be less than 15 characters...");
      } else {
        setUserListErrorMessage("");
      }
      if (key === "Enter") {
        if (handle.length < 1) {
          setUserListErrorMessage("Handle must be greater than 1 character...");
        }
        if (handle.length >= 1 && handle.length <= 15) {
          let isDuplicate = checkForDuplicate(handle);
          console.log(isDuplicate);
          if (!isDuplicate) {
            let userList = currentUserList;
            userList.push({
              twitterHandle: handle,
              status: "unverified",
            });
            setCurrentUserList(userList);
            setCurrentInputName("");
            setUserListErrorMessage("");
            setBot({
              ...bot,
              twitterHandleList: userList,
            });
          } else {
            setUserListErrorMessage("Handle already in list...");
          }
        }
      }
      if (key === " ") {
        console.log("SPACE " + handle);
        setCurrentInputName(handle.split(" ")[0]);
      }
    } else {
      setUserListErrorMessage("Handle limit reached...");
    }
  };

  const userListEnterListener = (event) => {
    const handle = event.target.value;
    const key = event.key;
    attemptAddHandle(handle, key);
  };

  useEffect(() => {
    console.log("Total in List - " + bot?.twitterHandleList.length);
    if (bot?.twitterHandleList.length >= 20) {
      setHasUserHitLimit(true);
    }
  }, [bot?.twitterHandleList]);

  function checkForDuplicate(username) {
    let isDuplicate = false;
    currentUserList.map(function (v) {
      if (v.twitterHandle.toLowerCase() === username.toLowerCase()) {
        isDuplicate = true;
      }
    });
    return isDuplicate;
    ///currentUserList.includes(username.toLowerCase())
  }
  const deleteTwitterHandle = (twitterHandle) => {
    console.log(twitterHandle);
    const updatedList = currentUserList.filter(
      (x) => x.twitterHandle != twitterHandle
    );
    setCurrentUserList(updatedList);
    setBot({
      ...bot,
      twitterHandleList: updatedList,
    });
  };

  const userListUploadFile = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.accept = ".txt";

    input.onchange = (e) => {
      // getting a hold of the file reference
      var file = e.target.files[0];

      // setting up the reader
      var reader = new FileReader();
      reader.readAsText(file, "UTF-8");

      // here we tell the reader what to do when it's done reading...
      reader.onload = (readerEvent) => {
        var content = readerEvent.target.result; // this is the content!

        let contentArray = [];
        // Check if its a comma seperated list or a new line list
        const isCommaSeperated = content.includes(",");
        if (isCommaSeperated) {
          contentArray = content.split(",");
        } else {
          contentArray = content.split("\r\n");
        }
        console.log(contentArray);
        let errorCount = 0;
        let duplicateCount = 0;
        contentArray.forEach((element) => {
          // Check total limit has not been reached
          if (!hasUserHitLimit) {
            // Check if its within limits
            if (element.length > 4 && element.length < 15) {
              // Check if its a duplicate
              if (checkForDuplicate(element)) {
                duplicateCount++;
              } else {
                let userList = currentUserList;
                userList.push({
                  twitterHandle: element,
                  status: "unverified",
                });
                setCurrentUserList(userList);
                setBot({
                  ...bot,
                  twitterHandleList: currentUserList,
                });
              }
            } else {
              errorCount++;
            }
          } else {
            errorCount++;
          }
        });
        toast.success(
          "Total Added - " +
            (content.split("\n").length - errorCount - duplicateCount) +
            " Total Duplicates - " +
            duplicateCount +
            " Total Errors - " +
            errorCount
        );
      };
    };

    input.click();
  };

  const verifyTwitterHandles = async () => {
    console.log("verifyTwitterHandles()");
    const api = new ApiService();
    const response = await api.post(`blocks/rewardsBot/verifyTwitterHandles`, {
      bot,
      twitterHandleList: currentUserList, // so that the bot always has the up to date list
      userId: bot.userId,
    });
    console.log(`verifyTwitterHandles complete`, response.bot);
    if (response.bot) {
      setBot(response.bot);
      setCurrentUserList(response.bot.twitterHandleList);
    }
  };

  useEffect(() => {
    console.log(bot);
    customMessage =
      bot?.contractAddress === "0xb159f1a0920a7f1d336397a52d92da94b1279838"
        ? "Add a user to become a ROYAL"
        : "Add a user to throw some bananas";
  }, [bot?.contractAddress]);

  const handleInputNameChange = (event) => {
    const inputName = stripNonTwitterHandleChars(event.target.value.trim());
    if (currentInputName !== inputName) {
      setCurrentInputName(inputName);
    }
  };

  return (
    <div>
      <motion.div
        animate={{
          scale: [0, 1.07, 1],
          opacity: [0, 1],
        }}
      >
        <div className="userListControl">
          <img src={brandingImage} className="userControlBranding"></img>
          <div className="row">
            <div className="col-12 mb-5 pt-3 pl-2 pr-2 text-center">
              <h2 className="heading">Reward Recipients</h2>
              <div className="row mt-3 d-flex align-items-center justify-content-center">
                <div className="input-group max-w-300px">
                  <span className="input-group-text">@</span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Twitter handle"
                    onKeyUp={userListEnterListener}
                    value={currentInputName}
                    onChange={handleInputNameChange}
                  ></input>
                </div>
                <div
                  className="btn btn-primary btn-xs me-1 w-fc"
                  onClick={() => attemptAddHandle("$HACKY_SOLUTION$", "Enter")}
                >
                  Add Handle
                </div>
              </div>
              {/* no upload because buggy */}
              <div style={{ marginTop: "16px", marginBottom: "16px" }}>OR</div>
              <div className="text-center d-flex justify-content-center">
                <div
                  className="btn btn-primary btn-xs me-2 d-flex w-fc"
                  onClick={userListUploadFile}
                >
                  <i
                    className="fa-solid fa-upload"
                    style={{ marginRight: "7px", transform: "translateY(3px)" }}
                  ></i>
                  Upload Handle List
                </div>
              </div>
              <div
                style={{ marginTop: "10px" }}
                className="userListErrorMessage"
              >
                {userListErrorMessage}
              </div>
            </div>
          </div>
          {currentUserList.length > 0 && (
            <div className="container mt-0">
              <div className="userListTotalUsers">
                <span>Total Users in List: {currentUserList.length}</span>
                <button
                  className="btn btn-primary"
                  onClick={verifyTwitterHandles}
                >
                  Verify List
                </button>
              </div>
            </div>
          )}
          <div className="userListContainer">
            {currentUserList.length === 0 && (
              <div className="no-userBots-msg">
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={[
                    "Add a user to get started",
                    2600,
                    "Add a user to get going",
                    2600,
                    "Add a user to start rewarding",
                    2600,
                    customMessage,
                    2600,
                  ]}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </div>
            )}

            {currentUserList.length > 0 && (
              <div className="container max-w-700px">
                <div className="row">
                  <div className="col-12">
                    <div className="twitter-handles-container">
                      {currentUserList.map((user, key) => {
                        const verifiedClass = "userListNames " + user.status;
                        return (
                          <div
                            className="userListNameContainer hvr-shrink"
                            onClick={() =>
                              deleteTwitterHandle(user.twitterHandle)
                            }
                            key={`user_${key}`}
                          >
                            <div className={verifiedClass}>
                              <div className="handle not-selectable">
                                @{user.twitterHandle}
                              </div>
                              {/* below shows on hover */}
                              <div className="delete not-selectable">
                                <i className="fa fa-trash"></i>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="rewardsBotWarningFooter">
            Only verified Twitter handles will feature
          </div>
        </div>
      </motion.div>
    </div>
  );
};

const TweetDesigner = ({ alchemySDK }) => {
  const [bot, setBot] = useContext(BotContext);

  const [tweetHeader, setTweetHeader] = useState(bot?.tweetHeader || "");
  const [tweetFooter, setTweetFooter] = useState(bot?.tweetFooter || "");
  const [selectedUser, setSelectedUser] = useState(
    bot?.twitterHandleList.filter((x) => x.status === "verified")[0]
      .twitterHandle
  );

  const [tweetShowTraits, setTweetShowTraits] = useState(
    bot?.tweetShowTraits || true
  );
  const [tweetTraitsToShow, setTweetTraitsToShow] = useState(
    bot?.tweetTraitsToShow || "3"
  );
  const [tokenRarity, setTokenRarity] = useState(null);
  const [tokenMedia, setTokenMedia] = useState(null);

  const mediaIsImage = tokenMedia
    ? fileExtensionIsImage(tokenMedia.format)
    : true; // image by default

  const generateRandomUser = async () => {
    if (!bot) {
      return;
    }
    const allUsers = bot?.twitterHandleList.filter(
      (x) => x.status === "verified"
    );
    console.log(allUsers);
    const min = 0;
    const max = parseInt(allUsers.length) - 1;
    const random = getRandomIntInRange(min, max);
    console.log(allUsers[random].twitterHandle);
    setSelectedUser(allUsers[random].twitterHandle);
  };

  const handleTweetHeaderEmojiClick = (emoji) => {
    handleTweetHeaderChange(`${tweetHeader}${emoji.native}`);
  };

  const handleTweetFooterEmojiClick = (emoji) => {
    handleTweetFooterChange(`${tweetFooter}${emoji.native}`);
  };

  const handleTweetHeaderChange = (event) => {
    console.log(bot?.tweetHeader);
    const newTweetHeader = event.target ? event.target.value : event; // so we can feed emoji into this function
    if (bot.tweetHeader !== newTweetHeader) {
      setBot({
        ...bot,
        tweetHeader: newTweetHeader,
      });
    }
    setTweetHeader(newTweetHeader);
  };

  const handleTweetFooterChange = (event) => {
    const newtweetFooter = event.target ? event.target.value : event; // so we can feed emoji into this function
    if (bot.tweetFooter !== newtweetFooter) {
      setBot({
        ...bot,
        tweetFooter: newtweetFooter,
      });
    }
    setTweetFooter(newtweetFooter);
  };
  const handleProjectAddress = (projectAddress) => {
    return projectAddress === "0xb159f1a0920a7f1d336397a52d92da94b1279838"
      ? 'RSOP "ROYAL"'
      : 'OCM "RISE"';
  };
  const tweetReplacements = {
    "[project]":
      bot?.contractAddress === "0xb159f1a0920a7f1d336397a52d92da94b1279838"
        ? "$ROYAL from @RoyalSocietyOP"
        : "!RISE from @OnChainMonkey",
    "[twitterHandle]": "@" + selectedUser,
  };

  const traitsText = () => {
    if (!tweetShowTraits || !tokenRarity || tokenRarity.length === 0) {
      return ``;
    }
    let traitCount = tokenRarity.length; // default (all)
    if (tweetTraitsToShow && tweetTraitsToShow !== "All") {
      traitCount = parseInt(tweetTraitsToShow);
    }
    const traitsArr = tokenRarity.slice(0, traitCount);
    return `${traitsArr
      .map(
        (element) =>
          `${element.traitType}: ${element.value} (${(
            element.prevalence * 100
          ).toFixed(2)}%)`
      )
      .join("\n")}`;
  };

  const traitsStr = traitsText();
  const traitsHtml = convertStringToHtml(traitsStr);
  const tweetHeaderStr =
    tweetHeader && tweetHeader.length
      ? `\n${makeReplacementsToString(tweetHeader, tweetReplacements)}`
      : "";
  const traitsFinalStr =
    traitsStr.length > 0
      ? `${tweetHeaderStr.length > 0 ? `\n` : ``}${traitsStr}`
      : "";
  const tweetFooterStr =
    tweetFooter && tweetFooter.length ? `\n${tweetFooter}` : "";
  const tweetContent = `${tweetHeaderStr}${traitsFinalStr}${tweetFooterStr}`;
  const characterCount = calculateTwitterCharacters(`${tweetContent}`);
  // console.log(`tweetContent`, tweetContent);

  const SaveNftFileButton = ({ tokenMedia }) => {
    if (!tokenMedia || !tokenMedia.gateway) {
      return;
    }

    return (
      <div>
        <motion.div
          animate={{
            scale: [0, 1.07, 1],
            opacity: [0, 1],
          }}
        >
          <div className="download-btn d-flex align-items-center w-fc ">
            <div className=" hvr-shrink">
              <a href={tokenMedia.gateway} target="_blank" rel="noreferrer">
                <i className="fa fa-file-arrow-down mr-05"></i>Save Image
              </a>
            </div>
          </div>
        </motion.div>
      </div>
    );
  };

  const TwitterShareButtonComponent = ({
    tweetContent,
    url = `https://betterblocks.io`,
  }) => {
    return (
      <div className="twitter-share-btn-sm">
        <motion.div
          animate={{
            scale: [0, 1.07, 1],
            opacity: [0, 1],
          }}
        >
          <TwitterShareButton
            onLoad={function noRefCheck() {}}
            options={{
              // size: "large",
              text: tweetContent,
              // via: 'saurabhnemade'
            }}
            url={url}
          />
        </motion.div>
      </div>
    );
  };

  // const handleSaveNftFile = () => {
  //   const url = tokenMedia.gateway;
  //   if (!url || !tokenMedia || !collectionInfo) {
  //     return;
  //   }
  //   const collectionSymbol = collectionInfo.symbol;
  //   const extension = tokenMedia.format;
  //   const fileName = `${collectionSymbol}-${tokenId}.${extension}`;
  //   downloadFileFromUrl(url, fileName);
  // };

  return (
    <motion.div //updated the div tag
      animate={{ x: 0 }}
      initial={{ x: -150 }}
    >
      <div className="row tweet-designer">
        <div className="col-12 mb-5 pt-3 pl-2 pr-2">
          <h2 className="heading">Tweet Designer</h2>
          <div className="row mt-6 d-flex align-items-center justify-content-center">
            <div className="input-group max-w-500px">
              <span className="input-group-text">User</span>
              <select
                className="form-control lh-1"
                onChange={(e) => setSelectedUser(e.target.value)}
                value={selectedUser}
              >
                {bot?.twitterHandleList &&
                  bot?.twitterHandleList.map((item, key) => (
                    <option key={`owned_id_${key}`} value={item.twitterHandle}>
                      {item.twitterHandle}
                    </option>
                  ))}
              </select>
            </div>
            <div
              className="btn btn-primary light btn-xs mb-1 me-1 w-fc"
              onClick={() => generateRandomUser()}
            >
              Random User
            </div>
          </div>
        </div>
        <div className="col-md-6 z-100">
          <div className="basic-form">
            <form>
              <div className="form-group mb-3">
                <div className="d-flex justify-content-between position-relative">
                  <label>Tweet Header:</label>
                  <EmojiPicker onEmojiSelect={handleTweetHeaderEmojiClick} />
                </div>
                <textarea
                  className="form-txtarea form-control"
                  rows="4"
                  id="tweetHeader"
                  onChange={handleTweetHeaderChange}
                  value={bot?.tweetHeader}
                ></textarea>
              </div>
              <div className="form-group mb-3">
                <div className="d-flex justify-content-between position-relative">
                  <label>Tweet Footer:</label>
                  <EmojiPicker onEmojiSelect={handleTweetFooterEmojiClick} />
                </div>
                <textarea
                  className="form-txtarea form-control"
                  rows="4"
                  id="tweetFooter"
                  onChange={handleTweetFooterChange}
                  value={bot?.tweetFooter}
                ></textarea>
                <div className="character-count d-flex align-items-center">
                  Characters:{" "}
                  <span
                    className={`char-val ${
                      characterCount > 280 ? " col-danger" : ""
                    }`}
                  >
                    {characterCount}
                  </span>{" "}
                  / 280
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex align-items-end justify-content-between">
            <label>Tweet Preview:</label>
            <div className="d-flex align-items-center">
              <SaveNftFileButton tokenMedia={tokenMedia} />
              <TwitterShareButtonComponent tweetContent={tweetContent} />
            </div>
          </div>
          <div className="tweet-preview">
            <div className="head-container d-flex align-items-center">
              <div className="d-flex align-items-center">
                <img
                  src={avatar1}
                  className="me-2 br-50  border-green"
                  width="28"
                />
                <div className="user-info">
                  <div className="name">Example User</div>
                  <div className="username">@ExampleUser</div>
                </div>
              </div>
              <div className="icon">
                <i className="fa fa-brands fa-twitter col-twitter-blue"></i>
              </div>
            </div>
            <div className="body-container">
              <>
                <div>
                  {convertStringToTwitterHtml(tweetHeader, tweetReplacements)}
                </div>
                {traitsHtml && <div>{traitsHtml}</div>}
                <div>{tweetFooter}</div>
                <br />
                <AnimatePresence>
                  <motion.div
                    animate={{
                      scale: [0, 1.07, 1],
                      opacity: [0, 1],
                    }}
                    exit={{
                      scale: [1, 1.07, 0],
                      rotate: [0, 3, -90],
                    }}
                  >
                    {tokenMedia && mediaIsImage && (
                      <img className="img-fluid" src={tokenMedia.gateway} />
                    )}
                    {tokenMedia && !mediaIsImage && (
                      <video
                        autoPlay
                        muted
                        controls={false}
                        className="img-fluid"
                      >
                        <source src={tokenMedia.gateway} type="video/mp4" />
                      </video>
                    )}
                  </motion.div>
                </AnimatePresence>
              </>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const BotSelector = () => {
  const [bot, setBot] = useContext(BotContext);
  const { address } = useContext(AuthContext);

  function setContractAddress(contractAddress) {
    setBot({
      ...bot,
      contractAddress: contractAddress,
    });
  }

  return (
    <motion.div //updated the div tag
      animate={{ x: 0 }}
      initial={{ x: -150 }}
    >
      <div className="row">
        <div className="w-100 rewardsBotSelector">
          <label className="text-label">Select Project</label>
          <button
            className="btn btn-primary w-fc m-auto mt-3"
            onClick={() =>
              setContractAddress("0xb159f1a0920a7f1d336397a52d92da94b1279838")
            }
          >
            RSOP "ROYAL"
          </button>
          <button
            className="btn btn-primary w-fc m-auto mt-3 mb-1"
            onClick={() =>
              setContractAddress("0x960b7a6bcd451c9968473f7bbfd9be826efd549a")
            }
          >
            OCM "RISE"
          </button>
        </div>
      </div>
    </motion.div>
  );
};

const AutomationControls = ({ twitterAuths }) => {
  const [bot, setBot] = useContext(BotContext);

  const [automationEnabled, setAutomationEnabled] = useState(
    bot?.automationEnabled || false
  );
  const [automationInterval, setAutomationInterval] = useState(
    bot?.automationInterval || "24 hours"
  );
  const [automationDailyTime, setAutomationDailyTime] = useState(
    bot?.automationDailyTime || "13:00"
  );
  const [automationTwitterAuthId, setAutomationTwitterAuthId] = useState(
    bot?.automationTwitterAuthId || null
  );
  // const [automationIdSelectionMode, setAutomationIdSelectionMode] = useState(
  //   bot?.automationIdSelectionMode || "Random"
  // );

  const handleAuthSelect = (auth) => {
    const newAuthId = auth.twitterAuthId;
    if (bot.automationTwitterAuthId !== newAuthId) {
      setBot({
        ...bot,
        automationTwitterAuthId: newAuthId,
      });
    }
    setAutomationTwitterAuthId(newAuthId);
  };

  const handleSetDailyTime = (event) => {
    const newDailyTime = event.target.value;
    if (bot.automationDailyTime !== newDailyTime) {
      setBot({
        ...bot,
        automationDailyTime: newDailyTime,
      });
    }
    setAutomationDailyTime(newDailyTime);
  };

  const handleSetAutomationInterval = (event) => {
    const newAutomationInterval = event.target.value;
    if (bot.automationInterval !== newAutomationInterval) {
      setBot({
        ...bot,
        automationInterval: newAutomationInterval,
      });
    }
    setAutomationInterval(newAutomationInterval);
  };

  const handleSetAutomationEnabled = (isEnabled) => {
    if (bot.automationEnabled !== isEnabled) {
      setBot({
        ...bot,
        automationEnabled: isEnabled,
      });
    }
    setAutomationEnabled(isEnabled);
  };

  return (
    <motion.div //updated the div tag
      animate={{ x: 0 }}
      initial={{ x: -150 }}
    >
      <div className="row tweet-automation position-relative z-100">
        <div className="col-12 mb-5 pt-3 pl-2 pr-2">
          <h2 className="heading">Tweet Automation</h2>
          <div className="row mt-3 d-flex align-items-center justify-content-center">
            <div className="col-12 mb-2">
              <div className="max-w-1000px text-center m-auto">
                Keep flexing by scheduling daily automated tweets to showcase
                random NFTs from your wallet.
                <br></br>It's as simple as set and forget - we'll take care of
                everything else.
              </div>
              {twitterAuths.length === 0 && (
                <motion.div
                  animate={{
                    scale: [0, 1.07, 1],
                    opacity: [0, 1],
                  }}
                >
                  <div className="col-12">
                    <div className="not-authed-twitter">
                      <div>
                        You must have an authenticated Twitter account to use
                        this feature{" "}
                      </div>
                      <div>
                        Visit the{" "}
                        {/* <a target="_blank" href="/settings">
                          settings
                        </a>{" "} */}
                        <Link className="col-g" to={"/settings"}>
                          settings
                        </Link>{" "}
                        page to add an account
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </div>
          </div>
        </div>
        {twitterAuths.length > 0 && (
          <motion.div
            animate={{
              scale: [0, 1.07, 1],
              opacity: [0, 1],
            }}
          >
            <div className="row">
              <div className="col-md-6 z-100">
                <div className="basic-form">
                  <form>
                    <div className="form-group mb-3 pb-1">
                      <label>Twitter account to post from:</label>
                      <TwitterAuthSelectDropdown
                        twitterAuths={twitterAuths}
                        onSelect={handleAuthSelect}
                        selectedTwitterAuth={twitterAuths.find(
                          (auth) =>
                            auth.twitterAuthId === automationTwitterAuthId
                        )}
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-6">
                <label>Time of day (UTC) to post the Tweet</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">
                    <i className="fa fa-clock col-g" />{" "}
                  </span>
                  <select
                    defaultValue={dailyTimeOptions[0].name}
                    className="form-control rmono"
                    id="val-dailyTime"
                    name="automationDailyTime"
                    onChange={handleSetDailyTime}
                    value={automationDailyTime}
                  >
                    {dailyTimeOptions.map((dt, key) => {
                      return <option key={`dt_${key}`}>{dt.name}</option>;
                    })}
                  </select>
                </div>
                <label>Time between Tweets</label>
                <div className="input-group mb-3">
                  <span className="input-group-text">
                    <i className="fa fa-clock col-g" />{" "}
                  </span>
                  <select
                    defaultValue={automationIntervalOptions[0].name}
                    className="form-control rmono"
                    id="val-interval"
                    name="automationInterval"
                    onChange={handleSetAutomationInterval}
                    value={automationInterval}
                  >
                    {automationIntervalOptions.map((dt, key) => {
                      return <option key={`dt_${key}`}>{dt.name}</option>;
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-center text-center pb-2">
                  <AnimatePresence>
                    {automationEnabled && (
                      <motion.div
                        animate={{
                          scale: [0, 1.07, 1],
                          opacity: [0, 1],
                        }}
                      >
                        <div className="d-flex flex-column">
                          <div className="automation-status-msg enabled">
                            Automation Enabled
                          </div>
                          <img
                            className="automation-status-img"
                            src={blueWaveAnim}
                            width={100}
                          />
                          <div className="hvr-shrink">
                            <button
                              type="button"
                              className="btn btn-danger btn-xs"
                              onClick={() => handleSetAutomationEnabled(false)}
                            >
                              Pause
                            </button>
                          </div>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <AnimatePresence>
                    {!automationEnabled && (
                      <motion.div
                        animate={{
                          scale: [0, 1.07, 1],
                          opacity: [0, 1],
                        }}
                      >
                        <div className="d-flex flex-column">
                          <div className="automation-status-msg disabled">
                            Automation Paused
                          </div>
                          {!automationTwitterAuthId && (
                            <div className="automation-status-msg">
                              Select Twitter account to post from
                            </div>
                          )}
                          {automationTwitterAuthId && (
                            <div className="hvr-shrink">
                              <button
                                type="button"
                                className="btn btn-success btn-sm"
                                onClick={() => handleSetAutomationEnabled(true)}
                              >
                                Enable
                              </button>
                            </div>
                          )}
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

const CollectionImage = ({ collectionImage }) => {
  const { ensAvatar } = useContext(AuthContext);

  return (
    <>
      <AnimatePresence>
        {collectionImage && (
          <motion.div
            animate={{
              scale: [0, 1.07, 1],
              rotate: [-90, 3, 0],
              opacity: [0, 1],
            }}
            exit={{
              scale: [1, 1.07, 0],
              rotate: [0, 3, -90],
            }}
          >
            <img
              className="img-fluid mr-05 br-50  border-green"
              width={42}
              src={collectionImage}
            />
          </motion.div>
        )}
      </AnimatePresence>
      {!collectionImage && ensAvatar && (
        <img
          className="img-fluid mr-05 br-50 border-green"
          width={42}
          src={ensAvatar}
        />
      )}
    </>
  );
};

const BotControls = () => {
  const [bot, setBot] = useState(null);
  const [selectedBotId, setSelectedBotId] = useState(null);
  const [formStep, setFormStep] = useState(0);
  const { isAuth, userId, address } = useContext(AuthContext);
  const { alchemySDK } = useContext(AlchemyContext);
  const [selectedCollections, setSelectedCollections] = useState(null);
  const [userBots, setUserBots] = useState([]);
  const [userBotsLoading, setUserBotsLoading] = useState(false);
  const [twitterAuths, setTwitterAuths] = useState([]);
  const timeoutIdRef = useRef(null);
  const [blockEvents, setBlockEvents] = useState([]);

  useEffect(() => {
    // Clear the timeout when the component unmounts
    return () => clearTimeout(timeoutIdRef.current);
  }, []);

  const saveBot = async () => {
    console.log(`saveBot`, bot);
    delayedSaveBot(); // waits 1.9 sec to exec (so new request isn't overloaded with fast input changes)
  };

  const actualSaveBot = async () => {
    try {
      const api = new ApiService();
      const response = await api.post(`blocks/rewardsBot/saveBot`, {
        userId,
        bot,
      });
      console.log(`saveBot()`, response);
      if (response.error || !response.userBots) {
        toast.error(response.error);
      } else {
        toast.success("Changes saved");
        setUserBots(response.userBots);
      }
    } catch (error) {
      console.error(error);
    } finally {
      // setUserBotsLoading(false);
    }
  };

  // waits 1.9 secs to display 'Changes Saved' toast
  const delayedSaveBot = () => {
    // Clear any existing timeouts
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    // Set a timeout to call a function after 1.9 seconds
    const newTimeoutId = setTimeout(async () => {
      actualSaveBot();
    }, 1900);

    // Update the timeout ID ref
    timeoutIdRef.current = newTimeoutId;
  };

  useEffect(() => {
    if (bot && Object.keys(bot).length > 0) {
      const existing = userBots.find(
        (b) => b.rewardsBotId === bot.rewardsBotId
      );
      // only save if bot has changed from stored ones
      if (existing && existing !== bot) {
        saveBot();
      }
      // fetch any missing collection info
      // if (isValidEthAddress(bot.contractAddress) && !bot.collectionName) {
      //   fetchCollectionInfo();
      // }
    }
  }, [bot]);

  const fetchCollectionInfo = () => {
    if (!bot || !bot.contractAddress) {
      console.log(
        `fetchCollectionInfo() - returning because (!bot || !bot.contractAddress)`
      );
      return;
    }
    console.log(`fetchCollectionInfo()`);
    // valid eth address - refresh collection info
    if (isValidEthAddress(bot.contractAddress)) {
      alchemySDK.nft.getContractMetadata(bot.contractAddress).then((data) => {
        if (data && data.openSea) {
          // valid collection
          console.log(`collectionInfo`, data);
          setBot({
            ...bot,
            walletAddress: address,
          });
        }
        // no collection info for contract
        else {
          // only set to null if bot has these values set
          if (bot.walletAddress) {
            setBot({
              ...bot,
              walletAddress: null,
            });
          }
        }
      });
    }
    // invalid eth address
    else {
      // only set to null if bot has these values set
      if (bot.collectionName) {
        setBot({
          ...bot,
          collectionImage: null,
          collectionName: null,
          collectionSymbol: null,
          collectionTokenType: null,
          collectionTotalSupply: null,
        });
      }
    }
  };

  const fetchTwitterAuths = () => {
    const api = new ApiService();
    api.get(`user/twitter/getAuths/${userId}`).then((data) => {
      setTwitterAuths(data);
      console.log(`twitterAuths`, data);
    });
  };

  useEffect(() => {
    if (isAuth && userId && !twitterAuths.length) {
      fetchTwitterAuths();
    }
  }, [userId, isAuth, twitterAuths.length]);

  const handleSelectBot = (bot) => {
    console.log("setting bot", bot);
    if (bot.rewardsBotId === selectedBotId) {
      // bot already selected so unselect
      setBot(null);
      setSelectedBotId(null);
    } else {
      // select bot
      setBot(bot);
      setSelectedBotId(bot.rewardsBotId);
    }
  };

  const fetchUserBots = async () => {
    setUserBotsLoading(true);
    try {
      const api = new ApiService();
      const response = await api.get(`blocks/rewardsBot/getBots/${userId}`);
      console.log(`fetchUserBots()`, response);
      setUserBots(response);
    } catch (error) {
      console.error(error);
    } finally {
      setUserBotsLoading(false);
    }
  };

  useEffect(() => {
    if (!isAuth || !userId) {
      setUserBots([]);
      return;
    }
    fetchUserBots();
  }, [isAuth, userId, address]);

  const fetchBlockEvents = async () => {
    try {
      const api = new ApiService();
      const response = await api.get(`blocks/rewardsBot/getEvents/${userId}`);
      console.log(`fetchBlockEvents()`, response);
      setBlockEvents(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isAuth || !userId) {
      setBlockEvents([]);
      return;
    }
    fetchBlockEvents();
  }, [isAuth, userId]);

  const addNewBot = async () => {
    try {
      const api = new ApiService();
      const response = await api.post(`blocks/rewardsBot/addBot`, { userId });
      console.log(`addNewBot()`, response);
      if (response.error || !response.userBots) {
        toast.error(response.error);
      } else {
        toast.success("New bot created");
        setUserBots(response.userBots);
        // select new bot
        setBot(response.bot);
        setSelectedBotId(response.bot.rewardsBotId);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setUserBotsLoading(false);
    }
  };

  const confirmDeleteBotAlert = (botId) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "Any bots using this Twitter will be disabled",
      showCancelButton: true,
      confirmButtonColor: "#EB5757",
      cancelButtonColor: "#adadad",
      confirmButtonText: "Delete Bot",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBot(botId);
      }
    });
  };

  const handleProjectAddress = (projectAddress) => {
    return projectAddress === "0xb159f1a0920a7f1d336397a52d92da94b1279838"
      ? 'RSOP "ROYAL"'
      : 'OCM "RISE"';
  };

  useEffect(() => {
    // set form stage based on whether wallet address is set
    if (bot && bot.contractAddress) {
      setTimeout(() => {
        // slight delay for ux
        setFormStep(1); // go to the tweet designer stage
      }, 500);
    } else {
      setFormStep(0); // go to the collection edit
      // if (bot && !bot.collectionName) {
      //   fetchCollectionInfo(); // fetch missing collection info
      // }
    }
  }, [bot?.contractAddress]);

  const deleteBot = async (botId) => {
    try {
      const api = new ApiService();
      const response = await api.post(`blocks/rewardsBot/deleteBot`, {
        userId,
        botId,
      });
      console.log(`deleteBot() - ${botId}`, response);
      if (response.error || !response.userBots) {
        toast.error(response.error);
      } else {
        toast.success("Bot deleted");
        // unselect bot
        setBot(null);
        setSelectedBotId(null);
        // set new user bots
        setUserBots(response.userBots);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setUserBotsLoading(false);
    }
  };

  const UserBots = () => {
    return (
      <Col lg={12}>
        <Card className="mb-3 h-fc bot-table">
          <Card.Header>
            <Card.Title>Your Bots</Card.Title>
            <div className="hvr-shrink">
              <button className="btn btn-primary" onClick={addNewBot}>
                <span className="me-2">
                  {" "}
                  <i className="fa fa-plus"></i>{" "}
                </span>
                NEW BOT
              </button>
            </div>
          </Card.Header>
          <Card.Body>
            {userBots.length === 0 && (
              <div className="no-userBots-msg">
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={[
                    "You have no bots",
                    2600,
                    "You are botless",
                    2600,
                    "The total supply of your bots is 0",
                    2600,
                    "You are in a bot-free zone",
                    2600,
                    "Bot inventory critically low",
                    2600,
                    "Zero bots, infinite potential",
                    2600,
                    "Fresh out of bots",
                    2600,
                    "Bot count: 0",
                    2600,
                    "Bots? Not here",
                    2600,
                    "You still have no bots",
                    2600,
                    "Bots. Get some.",
                    2600,
                  ]}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </div>
            )}
            {userBots.length > 0 && (
              <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <strong>Project</strong>
                    </th>
                    <th>
                      <strong>Users in List</strong>
                    </th>
                    <th>
                      <strong>Automation</strong>
                    </th>
                    <th>
                      <strong>Next Scheduled Tweet</strong>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {userBots.map((bot, key) => {
                    return (
                      <tr
                        className={
                          selectedBotId && selectedBotId === bot.rewardsBotId
                            ? `selected`
                            : ``
                        }
                        key={`userBot_${key}`}
                        onClick={() => handleSelectBot(bot)}
                      >
                        <td>
                          <div className="d-flex align-items-center">
                            {bot.contractAddress && (
                              <>
                                {bot.collectionImage ? (
                                  <img
                                    src={bot.collectionImage}
                                    className="me-2 br-50 border-green"
                                    width="28"
                                    alt=""
                                  />
                                ) : null}
                                <span className="w-space-no">
                                  {" "}
                                  {handleProjectAddress(bot.contractAddress)}
                                </span>
                              </>
                            )}
                            {!bot.contractAddress && (
                              <span className="w-space-no">Not Set</span>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            {bot?.twitterHandleList && (
                              <span className="w-space-no">
                                {bot.twitterHandleList.length}
                              </span>
                            )}
                            {!bot?.twitterHandleList && (
                              <span className="w-space-no">Not Set</span>
                            )}
                          </div>
                        </td>
                        <td>
                          {bot.automationEnabled && (
                            <div className="d-flex align-items-center">
                              <i className="fa fa-circle text-success me-1"></i>{" "}
                              Enabled
                            </div>
                          )}
                          {!bot.automationEnabled && (
                            <div className="d-flex align-items-center">
                              <i className="fa fa-circle text-danger me-1"></i>{" "}
                              Paused
                            </div>
                          )}
                        </td>
                        <td>
                          {bot.automationEnabled && (
                            <span>
                              {timeUntil({
                                secondsUntil:
                                  bot.automationDailyTimeSecondsUntil,
                              })}
                            </span>
                          )}
                          {!bot.automationEnabled && <span>N/A</span>}
                        </td>
                        <td>
                          <div className="d-flex">
                            {/* <Link
                href="#"
                className="btn btn-primary shadow btn-xs sharp me-1"
              >
                <i className="fas fa-pencil-alt"></i>
              </Link> */}
                            <div className="hvr-shrink">
                              <div
                                onClick={() =>
                                  confirmDeleteBotAlert(bot.rewardsBotId)
                                }
                                className="btn btn-danger btn-xs sharp"
                              >
                                <i className="fa fa-trash"></i>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
      </Col>
    );
  };
  return (
    <BotContext.Provider value={[bot, setBot]}>
      <UserBots />
      <BotEditor
        formStep={formStep}
        setFormStep={setFormStep}
        alchemySDK={alchemySDK}
        twitterAuths={twitterAuths}
        fetchCollectionInfo={fetchCollectionInfo}
      />
      <BlockEventsTable events={blockEvents} />
    </BotContext.Provider>
  );
};

const BotEditor = ({
  formStep,
  setFormStep,
  alchemySDK,
  twitterAuths,
  fetchCollectionInfo,
  setSelectedCollections,
  selectedCollections,
}) => {
  const [bot, setBot] = useContext(BotContext);
  const [walletAddress, setWalletAddress] = useState(bot?.walletAddress || "");

  console.log(`BotEditor Refresh - bot`, bot);

  useEffect(() => {}, [bot]);

  // const handlbotEdit = (event) => {
  //   setData({
  //     ...bot,
  //     [event.target.name]: event.target.value,
  //   });
  // }
  // accompanied w/ :
  // <input name="name" value={data.name} onChange={handleChange} />

  return (
    <AnimatePresence>
      {bot !== null && (
        <motion.div
          animate={{
            x: [-500, 0],
            // scale: [0, 1],
            opacity: [0, 1],
          }}
        >
          <div className="card mb-3 h-fc">
            <div className="card-header d-flex justify-content-start">
              <CollectionImage collectionImage={bot?.collectionImage} />
              <h4 className="card-title">Edit Bot</h4>
            </div>
            <div className="card-body">
              <div className="form-wizard">
                <Stepper className="nav-wizard mb-1" activeStep={formStep}>
                  <Step
                    label="Project Selector"
                    className="nav-link"
                    onClick={() => setFormStep(0)}
                  />
                  <Step
                    label="User List"
                    className="nav-link"
                    onClick={() => setFormStep(1)}
                  />
                  <Step
                    label="Tweet Designer"
                    className="nav-link"
                    onClick={() => setFormStep(2)}
                  />
                  <Step
                    label="Automation"
                    className="nav-link"
                    onClick={() => setFormStep(3)}
                  />
                </Stepper>

                {formStep === 0 && (
                  <>
                    <BotSelector />
                    {bot?.contractAddress ? (
                      <div className="text-end toolbar toolbar-bottom p-2">
                        <div className="hvr-shrink">
                          <button
                            className="btn btn-primary sw-btn-next"
                            onClick={() => setFormStep(1)}
                          >
                            User List{" "}
                            <i className="fa fa-arrow-right ml-05"></i>
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </>
                )}
                {formStep === 1 && (
                  <>
                    <UserList alchemySDK={alchemySDK} />

                    <div className="text-end toolbar toolbar-bottom p-2 pl-0 pr-0 d-flex justify-content-between">
                      <div className="hvr-shrink">
                        <button
                          className="btn btn-secondary sw-btn-prev"
                          onClick={() => {
                            setFormStep(0);
                          }}
                        >
                          <i className="fa fa-arrow-left mr-05"></i> Project
                          Selector
                        </button>
                      </div>
                      {bot?.twitterHandleList &&
                      bot?.twitterHandleList.length > 0 ? (
                        <div className="d-flex">
                          <div className="hvr-shrink">
                            <button
                              className="btn btn-secondary sw-btn-next me-1"
                              onClick={() => {
                                setFormStep(2);
                              }}
                            >
                              Tweet Designer{" "}
                              <i className="fa fa-arrow-right ml-05"></i>
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
                {formStep === 2 && (
                  <>
                    <TweetDesigner alchemySDK={alchemySDK} />
                    <div className="text-end toolbar toolbar-bottom p-2 pl-0 pr-0 d-flex justify-content-between">
                      <div className="hvr-shrink">
                        <button
                          className="btn btn-secondary sw-btn-prev"
                          onClick={() => {
                            setFormStep(1);
                          }}
                        >
                          <i className="fa fa-arrow-left mr-05"></i> Collection
                        </button>
                      </div>
                      <div className="d-flex">
                        <div className="hvr-shrink">
                          <button
                            className="btn btn-secondary sw-btn-next me-1"
                            onClick={() => {
                              setFormStep(3);
                            }}
                          >
                            Automation{" "}
                            <i className="fa fa-arrow-right ml-05"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {formStep === 3 && (
                  <>
                    <AutomationControls twitterAuths={twitterAuths} />
                    <div className="text-end toolbar toolbar-bottom p-2 pl-0 pr-0 d-flex justify-content-between">
                      <div className="hvr-shrink">
                        <button
                          className="btn btn-secondary sw-btn-prev"
                          onClick={() => {
                            setFormStep(2);
                          }}
                        >
                          <i className="fa fa-arrow-left mr-05"></i> Tweet
                          Designer
                        </button>
                      </div>
                      {/* <div className="d-flex">
                        <div className="hvr-shrink">
                          <button
                            className="btn btn-success sw-btn-prev me-1 mr-05"
                            onClick={() => {}}
                          >
                            Save <i className="fa fa-check ml-05"></i>
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const RewardsBot = (props) => {
  const BotHeading = () => {
    const [animate, setAnimate] = useState(false);
    const tweetLoadAnim = useAnimation();
    const tweetLoaded = () => {};

    async function startAnimation() {
      await tweetLoadAnim.start({
        opacity: 1,
        scale: 1,
        transition: { duration: 0.2 },
      });
    }

    const GetRandomTweet = () => {
      const number = Math.floor(Math.random() * 2);
      return number === 0 ? "1655931180674342914" : "1655929438951948288";
    };

    return (
      <div className="rewardsBot row">
        <div className="col-lg-8">
          <BlockCard block={Blocks.RewardsBot} />
          <AuthOnly>
            <motion.div animate={{ x: 0 }} initial={{ x: -1000 }}>
              <BotControls />
            </motion.div>
          </AuthOnly>
        </div>
        <div className="col-lg-4">
          <div className="spottie-tweet-example-card position-relative">
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={tweetLoadAnim}
            >
              <div className="bot-example-card-badge">
                Rewards Bot Example Tweet
              </div>
              <TwitterTweetEmbed
                onLoad={function noRefCheck() {
                  console.log(`setShowExampleTweet()`);
                  startAnimation();
                }}
                tweetId={GetRandomTweet()}
                options={{ theme: "dark" }}
              />
            </motion.div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {/* <button disabled={isLoading} onClick={() => signMessage()}>
        Sign message
      </button>
      {isSuccess && <div>Signature: {data}</div>}
      {isError && <div>Error signing message</div>} */}
      <PageHeaderBar
        pages={[
          { title: "Blocks", url: `/blocks` },
          { title: "Rewards", url: `/blocks/rewards` },
        ]}
      />
      <BotHeading />
    </Fragment>
  );
};

export default RewardsBot;
