import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

function TwitterAuthSelectDropdown({
  twitterAuths,
  onSelect,
  selectedTwitterAuth,
}) {
  const [selectedAuth, setSelectedAuth] = useState(selectedTwitterAuth || null);
  const handleSelect = (eventKey) => {
    const selected = twitterAuths.find(
      (auth) => auth.twitterAuthId === eventKey
    );
    setSelectedAuth(selected);
    onSelect(selected);
  };

  return (
    <Dropdown onSelect={handleSelect} className="twitter-auth-select-dropdown">
      <Dropdown.Toggle
        className="basic-dropdown"
        variant="primary"
        id="dropdown-basic"
      >
        {selectedAuth ? (
          <>
            {selectedAuth.profileName}{" "}
            <span>{`(@${selectedAuth.profileUsername})`}</span>
          </>
        ) : (
          "Select Account"
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {twitterAuths.map((auth) => (
          <Dropdown.Item key={auth.twitterAuthId} eventKey={auth.twitterAuthId}>
            <div className="d-flex align-items-center twitter-auth-dropdown-item">
              <img
                className="br-50  border-green mr-1"
                src={auth.profileImg}
                alt={auth.profileName}
              />
              <div className="d-flex flex-column">
                <div className="name">{auth.profileName}</div>
                <span className="username">@{auth.profileUsername}</span>
              </div>
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default TwitterAuthSelectDropdown;
