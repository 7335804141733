import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import { AuthContext } from "../../Providers/AuthProvider";
import ApiService from "../../../services/ApiService";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import { motion } from "framer-motion";

function TwitterAuth() {
  const { isAuth, userId } = useContext(AuthContext);
  const [twitterAuths, setTwitterAuths] = useState([]);
  const [contactModal, setContactModal] = useState();
  const [authUrl, setAuthUrl] = useState("");
  const [pin, setPin] = useState("");

  const handlePinChange = (event) => {
    setPin(event.target.value.trim());
  };

  const deleteTwitterAuth = (twitterAuthId) => {
    const api = new ApiService();
    api
      .post(`user/twitter/deleteAuth`, { userId, twitterAuthId })
      .then((data) => {
        if (data.success) {
          toast.success("Twitter account deleted");
          fetchTwitterAuths();
        } else {
          toast.error("An error occurred");
        }
      });
  };

  const confirmDeleteTwitterAlert = (twitterAuthId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Any bots using this Twitter will be paused",
      showCancelButton: true,
      confirmButtonColor: "#EB5757",
      cancelButtonColor: "#adadad",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTwitterAuth(twitterAuthId);
      }
    });
  };

  useEffect(() => {
    // pin set - attempt to authenticate
    if (pin && pin.length === 7) {
      const api = new ApiService();
      api
        .post(`user/twitter/generateAuthTokens`, { userId, pin })
        .then((data) => {
          if (data.success) {
            toast.success("Twitter account authenticated");
            setContactModal(false);
            fetchTwitterAuths();
            // clear modal inputs
            setAuthUrl("");
            setPin("");
          } else {
            toast.error("Unable to authenticate account");
          }
        });
    }
  }, [pin]);

  // on load
  useEffect(() => {
    fetchAuthUrl();
  }, []);

  function fetchAuthUrl() {
    try {
      const api = new ApiService();
      // generate auth url
      api.get(`user/twitter/generateAuthUrl/${userId}`).then((data) => {
        if (data.url) {
          setAuthUrl(data.url);
        } else {
          toast.error("Unable to generate a twitter url");
        }
      });
    } catch (error) {}
  }

  const fetchTwitterAuths = () => {
    const api = new ApiService();
    api.get(`user/twitter/getAuths/${userId}`).then((data) => {
      setTwitterAuths(data);
    });
  };

  useEffect(() => {
    if (isAuth && userId && !twitterAuths.length) {
      fetchTwitterAuths();
    }
  }, [userId, isAuth, twitterAuths.length]);

  if (!isAuth || !userId) {
    return null;
  }

  return (
    <motion.div
      animate={{
        scale: [0, 1],
        opacity: [0, 1],
      }}
      className="col-xl-6 col-lg-6 col-md-12"
    >
      <div>
        <div className="card h-fc settings-twitter-accounts">
          <div className="card-header border-0 pb-0 d-block">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h3 className="heading">
                  <i className="fa fa-brands fa-twitter col-twitter-blue"></i>{" "}
                  Twitter Accounts
                </h3>
                <span>
                  You have{" "}
                  <span className="font-w600">{twitterAuths.length}</span>{" "}
                  authenticated account{twitterAuths.length === 1 ? "" : "s"}
                </span>
              </div>
              <Link to={"#"} onClick={() => setContactModal(true)}>
                <div className="icon-box icon-box-sm bg-primary hvr-shrink">
                  <i className="fa fa-plus color-b" />
                </div>
              </Link>
            </div>
          </div>
          <div className="card-body pt-4 pb-0 dz-scroll">
            <div className="contacts-list">
              {twitterAuths.map((item, index) => (
                <div
                  className="d-flex justify-content-between mb-3 mt-3 pb-3"
                  key={index}
                >
                  <div className="d-flex align-items-center">
                    <img
                      src={item.profileImg}
                      alt=""
                      className="avatar br-50  border-green"
                    />
                    <div className="ms-3">
                      <h5 className="mb-1">{item.profileName}</h5>
                      <span className="fs-14 text-muted">
                        @{item.profileUsername}
                      </span>
                    </div>
                  </div>
                  <Link
                    to={"#"}
                    onClick={() =>
                      confirmDeleteTwitterAlert(item.twitterAuthId)
                    }
                  >
                    <div className="icon-box icon-box-sm bg-primary-light hvr-shrink color-r-h">
                      <i className="fa fa-trash"></i>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal fade twitter-auth-modal"
        centered
        show={contactModal}
        onHide={setContactModal}
        onShow={() => {
          if (!authUrl || authUrl === "") {
            fetchAuthUrl();
          }
        }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">
              <i className="fa fa-brands fa-twitter col-twitter-blue"></i>{" "}
              Authenticate Twitter Account
            </h3>
            <button
              type="button"
              className="btn-close"
              onClick={() => setContactModal(false)}
            ></button>
          </div>
          <div className="modal-body">
            {/* <div className="instructions-text">
              Click the button below to authenticate and retrieve a pin
              <div className="hvr-shrink">
                <a
                  href="https://developer.twitter.com/en/portal/petition/essential/basic-info"
                  rel="nofollow"
                  target="_blank"
                >
                  <i class="fa fa-link"></i>Here
                </a>
              </div>
            </div> */}
            {/* <div className="instructions-text">
              Enter the pin below
              <div className="hvr-shrink">
                <a
                  href="https://developer.twitter.com/en/docs/apps/app-permissions"
                  rel="nofollow"
                  target="_blank"
                >
                  <i class="fa fa-link"></i>Here
                </a>
              </div>
            </div> */}
            {/* <div className="instructions-text">
              3. Enter App Key and App Secret:
              <br />
            </div>
            <label className="form-label d-block">
              Enter App Key{" "}
              {appKey.length === 25 && (
                <span>
                  <i className="fa fa-check col-green ml-1"></i>
                </span>
              )}
            </label>
            <input
              type="text"
              className="form-control w-100"
              placeholder="Twitter App Key"
              value={appKey}
              onChange={handleAppKeyChange}
            />
            <label className="form-label d-block mt-3">
              Enter App Secret{" "}
              {appSecret.length === 50 && (
                <span>
                  <i className="fa fa-check col-green ml-1"></i>
                </span>
              )}
            </label>
            <input
              type="text"
              className="form-control w-100"
              placeholder="Twitter App Secret"
              value={appSecret}
              onChange={handleAppSecretChange}
            /> */}
            <>
              {authUrl && (
                <>
                  {/* url display */}
                  <div className="twitter-auth-url-container">
                    <div className="twitter-auth-instructions">
                      Click the button below and retrieve a pin
                    </div>
                    <div className="hvr-shrink">
                      <a
                        href={authUrl}
                        target="_blank"
                        onClick={() => {
                          window.open(
                            authUrl,
                            "newwindow",
                            "width=800,height=830"
                          );
                          return false;
                        }}
                      >
                        <button type="button" className="me-2 btn btn-info">
                          Authorize Twitter
                        </button>
                      </a>
                    </div>
                  </div>

                  {/* pin input */}
                  <label className="form-label d-block mt-3">
                    Enter Pin{" "}
                    {pin.length === 7 && (
                      <span>
                        <i className="fa fa-check col-green ml-1"></i>
                        {/* <motion.div
                          animate={{
                            scale: [0, 1.07, 1],
                            rotate: [-90, 3, 0],
                            opacity: [0, 1],
                          }}
                        >
                        </motion.div> */}
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    className="form-control w-100"
                    placeholder="Pin"
                    value={pin}
                    onChange={handlePinChange}
                  />
                </>
              )}
            </>
          </div>
          <div className="modal-footer">
            <div className="hvr-shrink">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setContactModal(false)}
              >
                Cancel
              </button>
            </div>
            {/* <button type="button" className="btn btn-primary">
              Save changes
            </button> */}
          </div>
        </div>
      </Modal>
    </motion.div>
  );
}

export default TwitterAuth;
