import { useContext } from "react";
import { AuthContext } from "../Providers/AuthProvider";
import { TypeAnimation } from "react-type-animation";
import { useAccount } from "wagmi";

function AuthOnly(props) {
  const { children } = props;
  const { isAuth } = useContext(AuthContext);
  const { address, isConnected } = useAccount();
  console.log(`authonly props`, props);
  return (
    <>
      {isAuth && children}
      {!isAuth && !(props && props.hideMsg) && (
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <div className="not-authed-msg">
              <TypeAnimation
                // Same String at the start will only be typed once, initially
                sequence={[
                  "Connect wallet to use app",
                  4600,
                  "Connect wallet to build dreams",
                  2600,
                  "Connect wallet for digital happiness",
                  2600,
                  "Connect wallet to gm frens",
                  2600,
                  "Connect wallet to make it",
                  2600,
                ]}
                speed={40} // Custom Speed from 1-99 - Default Speed: 40
                wrapper="span" // Animation will be rendered as a <span>
                repeat={Infinity} // Repeat this Animation Sequence infinitely
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AuthOnly;
