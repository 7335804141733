import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import CodeEditor from "@uiw/react-textarea-code-editor";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import loadingCircleImg from "../../../../images/misc/loading-circle-green.svg";
import landmarkImg from "../../../../images/landmark/logo-text-dark.png";
import ApiService from "../../../../services/ApiService";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { utils } from "ethers";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import { ExportJsonCsv } from "react-export-json-csv";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { FileCopyOutlined as FileCopyOutlinedIcon } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { darken } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TypeAnimation } from "react-type-animation";
import {
  areSetsEqual,
  copyTextToClipboard,
  useDocumentTitle,
} from "../../../utils";
import { ThemeContext } from "../../../../context/ThemeContext";
import {
  addOneMonth,
  arraysAreEqual,
  epochToDateString,
  epochToDateTimeString,
  stringToRGB,
  toTitleCase,
} from "../../../../utils";

const MasterEventsTable = ({
  password,
  employee,
  quickbooksCustomers,
  opportunityId,
  invoiceOpportunity,
  addQuickbooksCustomerSalesforceMapping,
}) => {
  const [selectedQuickbooksCustomerName, setSelectedQuickbooksCustomerName] =
    useState("");
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const opportunityUrl = `https://landmarkventures.lightning.force.com/lightning/r/Opportunity/${opportunityId}/view`;

  const selectQuickbooksCustomer = (quickbooksCustomerName) => {
    setSelectedQuickbooksCustomerName(quickbooksCustomerName);
  };

  const confirmBtnClicked = async () => {
    if (!selectedQuickbooksCustomerName) {
      toast.error(`Please select a Quickbooks Customer`);
      return;
    } else {
      const customerId = quickbooksCustomers.find(
        (qc) => qc.displayName === selectedQuickbooksCustomerName
      ).customerId;
      const success = await addQuickbooksCustomerSalesforceMapping(
        customerId,
        invoiceOpportunity.clientName
      );
      if (success) {
        // show success msg
        setShowSuccessMsg(true);
      }
    }
  };

  if (!quickbooksCustomers || !quickbooksCustomers.length) {
    return (
      <div className="error-msg">
        Could not get Quickbooks Customers from server (if this problem persists
        please notify{" "}
        <a href="mailto:mjones@landmarkventures.com">
          mjones@landmarkventures.com
        </a>
        )
      </div>
    );
  }

  if (!opportunityId || !opportunityId.length) {
    return <div className="error-msg">Opportunity ID missing from URL</div>;
  }

  if (!invoiceOpportunity) {
    return (
      <div className="error-msg">
        Could not get Invoice Opportunity from server (if this problem persists
        please notify{" "}
        <a href="mailto:mjones@landmarkventures.com">
          mjones@landmarkventures.com
        </a>
        )
      </div>
    );
  }

  return (
    <>
      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
        <Card className="mb-3 h-fc bot-table">
          {/* <div className="event-image-img">
              <img src={selectedInvoice.imageUrl} className="img-fluid" />
            </div> */}
          <div className="row">
            <div className="outputHeader">Set Quickbooks Customer</div>
            <>
              <></>
              <div className="outputBody">
                <div className="table-wrapper">
                  {
                    <div className="text-center mb-3">
                      <div className="qb-settings">
                        <div className="qb-settings-group">
                          <label className="form-label">
                            <span className="example-text">
                              In Salesforce, the{` `}
                              <a
                                target={"_blank"}
                                className="inv-link"
                                href={opportunityUrl}
                              >
                                opportunity
                              </a>
                              {` `}client is{` `}
                              <span className="client-name">
                                {invoiceOpportunity.clientName}
                              </span>
                            </span>
                            <br />
                            <div className="example-text">
                              <b>
                                Please select the corresponding Quickbooks
                                Customer:
                              </b>
                            </div>
                          </label>
                          {showSuccessMsg ? (
                            <>
                              <div className="error-msg thank-you-msg">
                                Thank you!
                              </div>
                            </>
                          ) : (
                            <div className="qb-customer-select-container">
                              <select
                                defaultValue={"All"}
                                className="quickbooks-select"
                                onChange={(e) =>
                                  selectQuickbooksCustomer(e.target.value)
                                }
                                value={selectedQuickbooksCustomerName}
                              >
                                <option>{``}</option>
                                {quickbooksCustomers.map((c) => (
                                  <option>{c.displayName}</option>
                                ))}
                              </select>
                              <button
                                className={`create-event-btn btn btn-sm btn-primary email-options-btn`}
                                onClick={confirmBtnClicked}
                              >
                                {`Confirm`}
                                {/* <i className="event-owner-add fa-sharp fa-solid fa-user ml-05"></i> */}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </>
          </div>
        </Card>
      </motion.div>
    </>
  );
};

const PasswordPrompt = ({ password, setPassword, submitPassword }) => {
  const handleInputChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submitPassword(password);
  };

  return (
    <div className="d-flex">
      <input
        onChange={handleInputChange}
        type="password"
        className="form-control w-100 mt-1 inline-input"
        placeholder="Password"
        value={password}
      ></input>
      <div
        className="btn btn-primary light btn-xs mt-1 me-1 w-fc inline-btn"
        onClick={(e) => handleSubmit(e)}
      >
        Submit
      </div>
    </div>
  );
};

const EmployeeInfo = ({
  landmarkEmployee,
  handleOnClick = () => {},
  disableHover = false,
}) => {
  if (!landmarkEmployee) {
    return null;
  }
  const { firstName, lastName, email, employeeId } = landmarkEmployee;
  const disableHoverClass = disableHover ? "disable-hover" : "";
  return (
    <div
      className={`account ${disableHoverClass}`}
      onClick={() => handleOnClick(landmarkEmployee)}
    >
      <div
        className="user-icon"
        style={{
          backgroundColor: stringToRGB(employeeId),
        }}
      >
        <PersonIcon className="icon" />
      </div>
      <div>
        <div className="name">
          {firstName} {lastName}
        </div>
        <div className="email">{email}</div>
      </div>
    </div>
  );
};

const EmployeeSignIn = ({ password, signInAsEmployee }) => {
  const [employees, setEmployees] = useState([]);
  const [showNewAccount, setShowNewAccount] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  async function getEmployees() {
    try {
      const api = new ApiService({ password });
      const employeesResponse = await api.get(
        `landmark/employees/getEmployees`
      );
      console.log(`employees`, employeesResponse);
      setEmployees(employeesResponse);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getEmployees();
  }, []);

  const btnDisabled =
    firstName.length && lastName.length && email.length ? false : true;
  const btnDisabledClass = btnDisabled ? "disabled" : "";

  const handleSignUpBtnClicked = async () => {
    if (btnDisabled) {
      return;
    }
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/employees/addEmployee/`,
        {
          firstName,
          lastName,
          email,
        }
      );
      if (!success) {
        toast.error(`An error occurred: ${error}`);
        return false;
      } else {
        // toast.success(`Account Created`);
        const employee = success;
        signInAsEmployee(employee);
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <div className="landmark-sign-in">
      <div className="card">
        <div className="card-body">
          <div className="header-text">Log in as</div>
          <div className="existing-accounts-container">
            {employees.map((employee, i) => (
              <EmployeeInfo
                key={i}
                landmarkEmployee={employee}
                handleOnClick={signInAsEmployee}
              />
            ))}
          </div>
        </div>
      </div>
      {!showNewAccount && (
        <div
          className="new-account-btn"
          onClick={() => setShowNewAccount(true)}
        >
          <div className="hvr-shrink text">
            First time here? Create New Account
          </div>
        </div>
      )}
      {showNewAccount && (
        <motion.div
          animate={{
            scale: [0, 1.04, 1],
          }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <div className="card">
            <div className="card-body">
              <div className="header-text">New Account</div>
              <div className="settings-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        value={firstName}
                        className="form-control"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        value={lastName}
                        className="form-control"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="form-label">Email</label>
                    <div className="input-group input-primary">
                      <input
                        type="text"
                        value={email}
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <span className="input-group-text">
                        @landmarkventures.com
                      </span>
                    </div>
                  </div>
                  <div className="sign-up-btn-container">
                    <button
                      className={`sign-up-btn btn btn-primary btn-sm ${btnDisabledClass}`}
                      type="submit"
                      onClick={handleSignUpBtnClicked}
                    >
                      Sign up
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

const getQueryParams = (search) => {
  return new URLSearchParams(search);
};

const Dashboard = (props) => {
  const { changeBackground } = useContext(ThemeContext);
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [employee, setEmployee] = useState(null);
  const [invoiceOpportunity, setInvoiceOpportunity] = useState(null);
  const [quickbooksCustomers, setQuickbooksCustomers] = useState([]);

  const query = getQueryParams(window.location.search);
  const opportunityId = query.get("opportunity");

  // set light theme
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);

  async function getQuickbooksCustomers() {
    try {
      const api = new ApiService({ password });
      const response = await api.get(
        `landmark/invoicemanager/getQuickbooksCustomers`
      );
      console.log(`quickbooksCustomers`, response);
      setQuickbooksCustomers(response);
    } catch (error) {
      console.error(error);
    }
  }

  async function getInvoiceOpportunity() {
    if (opportunityId && opportunityId.length) {
      console.log(`getInvoiceOpportunity() - ${opportunityId}`);
      try {
        const api = new ApiService({ password });
        const response = await api.get(
          `landmark/invoicemanager/getInvoiceOpportunity/${opportunityId}`
        );
        console.log(`invoiceOpportunity`, response);
        setInvoiceOpportunity(response);
      } catch (error) {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    if (opportunityId && isAuthenticated) {
      getInvoiceOpportunity();
    }
  }, [opportunityId, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      getQuickbooksCustomers();
    }
  }, [isAuthenticated]);

  async function addQuickbooksCustomerSalesforceMapping(
    customerId,
    salesforceMapping
  ) {
    try {
      const api = new ApiService({ password });
      const { success, error } = await api.post(
        `landmark/invoicemanager/addQuickbooksCustomerSalesforceMapping`,
        { customerId, salesforceMapping }
      );
      console.log(
        `addQuickbooksCustomerSalesforceMapping() - customerId: ${customerId} salesforceMapping: ${salesforceMapping}`
      );
      if (!success) {
        toast.error(`${error}`);
        return false;
      } else {
        toast.success(`${success}`);
        // getInvoices();
        return true;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function submitPassword(password) {
    if (!password) {
      toast.error("Invalid Password");
      return;
    }
    try {
      const api = new ApiService();
      const { success, error } = await api.post(`landmark/campaigns/auth`, {
        password,
      });
      if (!success) {
        toast.error(`${error}`);
        setIsAuthenticated(false);
        return false;
      } else {
        toast.success(`${success}`);
        setIsAuthenticated(true);
        return true;
      }
    } catch (error) {
      console.error(error);
      setIsAuthenticated(false);
      return false;
    }
  }
  useDocumentTitle("Invoice Customer Dashboard - Landmark Ventures");

  // async function editInvoice(invoice) {
  //   try {
  //     // Create a shallow copy of the invoice object without the 'invoices' field
  //     const editedInvoice = { ...invoice, invoices: undefined };
  //     const api = new ApiService({ password });
  //     const url = "landmark/invoices/editInvoice";
  //     const response = await api.post(url, {
  //       invoice: editedInvoice,
  //     });
  //     console.log(`editInvoice() - ${url} - response`, response);
  //     // refresh invoices
  //     refreshInvoice(invoice.invoiceId);
  //     return response;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  return (
    <Fragment>
      <div className="global-dash-wrapper invoice-dash">
        {/* <button disabled={isLoading} onClick={() => signMessage()}>
        Sign message
      </button>
      {isSuccess && <div>Signature: {data}</div>}
      {isError && <div>Error signing message</div>} */}
        <div className="d-flex align-items-center justify-content-center">
          <img src={landmarkImg} className="img-fluid landmark-img" />
        </div>
        <PageHeaderBar
          pages={[
            { title: "Landmark", url: `` },
            {
              title: "Invoice Customer Dashboard",
              url: `/landmark/invoicecustomer/dashboard`,
            },
          ]}
        />
        {/* show dashboard */}
        {isAuthenticated ? (
          <>
            <MasterEventsTable
              password={password}
              employee={employee}
              quickbooksCustomers={quickbooksCustomers}
              opportunityId={opportunityId}
              invoiceOpportunity={invoiceOpportunity}
              addQuickbooksCustomerSalesforceMapping={
                addQuickbooksCustomerSalesforceMapping
              }
            />
          </>
        ) : null}
        {/* show password entry */}
        {!isAuthenticated ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div className="not-authed-msg">
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={["Enter password to access dashboard", 4600]}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <PasswordPrompt
                password={password}
                setPassword={setPassword}
                submitPassword={submitPassword}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default Dashboard;
