import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";

import { motion, AnimatePresence, useAnimation } from "framer-motion";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Modal,
  Button,
} from "react-bootstrap";

import { TypeAnimation } from "react-type-animation";
import { timeAgo } from "../../../utils";

import discordHookImg from "../../../images/misc/discord-hook.png";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { truncateAddress } from "../../utils";

const INITIAL_EVENTS_TO_DISPLAY_COUNT = 5;

const BlockEventsTable = ({ events }) => {
  const [viewTweetModal, setViewTweetModal] = useState(false);
  const [viewTweet, setViewTweet] = useState(null);
  const [loadingTweet, setLoadingTweet] = useState(false);
  const [eventsToDisplay, setEventsToDisplay] = useState(
    INITIAL_EVENTS_TO_DISPLAY_COUNT
  );
  const tweetLoadAnim = useAnimation();

  async function startAnimation() {
    await tweetLoadAnim.start({
      opacity: 1,
      scale: 1,
      transition: { duration: 0.2 },
    });
    setLoadingTweet(false);
  }

  // jsx for different types of blocks
  const tables = {
    // sales bot event display
    SalesBot: {
      header: (
        <>
          <tr>
            <th>
              <strong>Collection</strong>
            </th>
            <th>
              <strong>Discord</strong>
            </th>
            <th>
              <strong>Tweet</strong>
            </th>
            <th>
              <strong>Time</strong>
            </th>
          </tr>
        </>
      ),
      body: (
        <>
          {/* limit to eventsToDisplay events */}
          {events.slice(0, eventsToDisplay).map((e, key) => {
            const block = e.salesBot;
            if (!block) {
              return;
            }
            const hasDiscordWebhook = e.discord && e.discord.discordWebhook;
            const hasTweet = e.tweet && e.tweet.url;
            return (
              <tr key={`blockEvent_${key}`} className="cursor-default">
                <td>
                  <div className="d-flex align-items-center">
                    {block.collectionName && (
                      <>
                        <img
                          src={block.collectionImage}
                          className="me-2 br-50  border-green"
                          width="28"
                          alt=""
                        />{" "}
                        <span className="w-space-no">
                          {block.collectionName}
                        </span>
                      </>
                    )}
                    {!block.collectionName && (
                      <span className="w-space-no">Not Set</span>
                    )}
                  </div>
                </td>
                <td>
                  {hasDiscordWebhook && (
                    <div className="d-flex align-items-center twitter-auth-dropdown-item">
                      <img
                        className="br-50  border-green mr-1"
                        src={discordHookImg}
                        alt="Discord Webhook Logo"
                        width="28"
                      />
                      <div className="d-flex flex-column">
                        <div className="name">
                          {e.discord.discordWebhook.channelName}
                          {` (${e.discord.discordWebhook.serverName})`}
                        </div>
                      </div>
                    </div>
                  )}
                  {!hasDiscordWebhook && <span>N/A</span>}
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    {hasTweet && (
                      <div className="hvr-shrink">
                        <div
                          className="badge bg-info mt-04 cursor-pointer"
                          onClick={() => {
                            setViewTweet(e.tweet);
                            setLoadingTweet(true);
                            setViewTweetModal(true);
                          }}
                        >
                          View Tweet
                        </div>
                      </div>
                    )}
                    {!hasTweet && <span>N/A</span>}
                  </div>
                </td>
                <td>
                  <span>{timeAgo(e.eventTime)}</span>
                </td>
              </tr>
            );
          })}
        </>
      ),
    },
    // flex bot event display
    FlexBot: {
      header: (
        <>
          <tr>
            <th>
              <strong>Wallet</strong>
            </th>
            <th>
              <strong>Collection</strong>
            </th>
            <th>
              <strong>Token ID</strong>
            </th>
            <th>
              <strong>Tweet</strong>
            </th>
            <th>
              <strong>Time</strong>
            </th>
          </tr>
        </>
      ),
      body: (
        <>
          {/* limit to eventsToDisplay events */}
          {events.slice(0, eventsToDisplay).map((e, key) => {
            const block = e.flexBot;
            if (!block) {
              return;
            }
            const hasDiscordWebhook = e.discord && e.discord.discordWebhook;
            const hasTweet = e.tweet && e.tweet.url;
            return (
              <tr key={`blockEvent_${key}`} className="cursor-default">
                <td>
                  <div className="d-flex align-items-center">
                    {block.walletAddress && (
                      <>
                        <span className="w-space-no">
                          {truncateAddress(block.walletAddress)}
                        </span>
                      </>
                    )}
                    {!block.walletAddress && (
                      <span className="w-space-no">Not Set</span>
                    )}
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    {e.collectionInfo && e.collectionInfo.address ? (
                      <>
                        <img
                          src={e.collectionInfo.image}
                          className="me-2 br-50  border-green"
                          width="28"
                          alt=""
                        />{" "}
                        <span className="w-space-no">
                          {e.collectionInfo.name}
                        </span>
                      </>
                    ) : null}
                    {!e.collectionInfo || !e.collectionInfo.address ? (
                      <span className="w-space-no">Not Set</span>
                    ) : null}
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    {e.tokenId !== null && (
                      <>
                        <span className="w-space-no">{e.tokenId}</span>
                      </>
                    )}
                    {e.tokenId === null && (
                      <span className="w-space-no">Not Set</span>
                    )}
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    {hasTweet && (
                      <div className="hvr-shrink">
                        <div
                          className="badge bg-info mt-04 cursor-pointer"
                          onClick={() => {
                            setViewTweet(e.tweet);
                            setLoadingTweet(true);
                            setViewTweetModal(true);
                          }}
                        >
                          View Tweet
                        </div>
                      </div>
                    )}
                    {!hasTweet && <span>N/A</span>}
                  </div>
                </td>
                <td>
                  <span>{timeAgo(e.eventTime)}</span>
                </td>
              </tr>
            );
          })}
        </>
      ),
    },
    // spotlight bot event display
    SpotlightBot: {
      header: (
        <>
          <tr>
            <th>
              <strong>Collection</strong>
            </th>
            <th>
              <strong>Token ID</strong>
            </th>
            <th>
              <strong>Tweet</strong>
            </th>
            <th>
              <strong>Time</strong>
            </th>
          </tr>
        </>
      ),
      body: (
        <>
          {/* limit to eventsToDisplay events */}
          {events.slice(0, eventsToDisplay).map((e, key) => {
            const block = e.spotlightBot;
            if (!block) {
              return;
            }
            const hasDiscordWebhook = e.discord && e.discord.discordWebhook;
            const hasTweet = e.tweet && e.tweet.url;
            return (
              <tr key={`blockEvent_${key}`} className="cursor-default">
                <td>
                  <div className="d-flex align-items-center">
                    {block.collectionName ? (
                      <>
                        <img
                          src={block.collectionImage}
                          className="me-2 br-50  border-green"
                          width="28"
                          alt=""
                        />{" "}
                        <span className="w-space-no">
                          {block.collectionName}
                        </span>
                      </>
                    ) : null}
                    {!block.collectionName ? (
                      <span className="w-space-no">N/A</span>
                    ) : null}
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    {e.tokenId && e.tokenId.length > 0 ? (
                      <>
                        <span className="w-space-no">{e.tokenId}</span>
                      </>
                    ) : null}
                    {!e.tokenId || e.tokenId.length === 0 ? (
                      <span className="w-space-no">N/A</span>
                    ) : null}
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    {hasTweet && (
                      <div className="hvr-shrink">
                        <div
                          className="badge bg-info mt-04 cursor-pointer"
                          onClick={() => {
                            setViewTweet(e.tweet);
                            setLoadingTweet(true);
                            setViewTweetModal(true);
                          }}
                        >
                          View Tweet
                        </div>
                      </div>
                    )}
                    {!hasTweet && <span>N/A</span>}
                  </div>
                </td>
                <td>
                  <span>{timeAgo(e.eventTime)}</span>
                </td>
              </tr>
            );
          })}
        </>
      ),
    },
    // rewards bot event display
    RewardsBot: {
      header: (
        <>
          <tr>
            <th>
              <strong>Collection</strong>
            </th>
            <th>
              <strong>Twitter User</strong>
            </th>
            <th>
              <strong>Tweet</strong>
            </th>
            <th>
              <strong>Time</strong>
            </th>
          </tr>
        </>
      ),
      body: (
        <>
          {/* limit to eventsToDisplay events */}
          {events.slice(0, eventsToDisplay).map((e, key) => {
            const block = e.rewardsBot;
            if (!block) {
              return;
            }
            const hasTweet = e.tweet && e.tweet.url;
            return (
              <tr key={`blockEvent_${key}`} className="cursor-default">
                <td>
                  <div className="d-flex align-items-center">
                    {block.collectionName ? (
                      <>
                        <img
                          src={block.collectionImage}
                          className="me-2 br-50  border-green"
                          width="28"
                          alt=""
                        />{" "}
                        <span className="w-space-no">
                          {block.collectionName}
                        </span>
                      </>
                    ) : null}
                    {!block.collectionName ? (
                      <span className="w-space-no">N/A</span>
                    ) : null}
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    {hasTweet && e.userReceiving ? (
                      <>
                        <span className="w-space-no">@{e.userReceiving}</span>
                      </>
                    ) : null}
                    {!hasTweet || !e.userReceiving ? (
                      <span className="w-space-no">N/A</span>
                    ) : null}
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    {hasTweet && (
                      <div className="hvr-shrink">
                        <div
                          className="badge bg-info mt-04 cursor-pointer"
                          onClick={() => {
                            setViewTweet(e.tweet);
                            setLoadingTweet(true);
                            setViewTweetModal(true);
                          }}
                        >
                          View Tweet
                        </div>
                      </div>
                    )}
                    {!hasTweet && <span>N/A</span>}
                  </div>
                </td>
                <td>
                  <span>{timeAgo(e.eventTime)}</span>
                </td>
              </tr>
            );
          })}
        </>
      ),
    },
  };

  const getTableHeader = () => {
    // salesBot
    if (events.length && events[0].salesBot) {
      return tables.SalesBot.header;
    }
    // flexBot
    if (events.length && events[0].flexBot) {
      return tables.FlexBot.header;
    }
    // salesBot
    if (events.length && events[0].spotlightBot) {
      return tables.SpotlightBot.header;
    }
    // rewardsBot
    if (events.length && events[0].rewardsBot) {
      return tables.RewardsBot.header;
    }
    return null;
  };

  const getTableBody = () => {
    // salesBot
    if (events.length && events[0].salesBot) {
      return tables.SalesBot.body;
    }
    // flexBot
    if (events.length && events[0].flexBot) {
      return tables.FlexBot.body;
    }
    // salesBot
    if (events.length && events[0].spotlightBot) {
      return tables.SpotlightBot.body;
    }
    // salesBot
    if (events.length && events[0].rewardsBot) {
      return tables.RewardsBot.body;
    }
    return null;
  };

  const showLoadMoreBtn = events && events.length > eventsToDisplay;

  const handleLoadMoreBtnClicked = () => {
    setEventsToDisplay(eventsToDisplay + INITIAL_EVENTS_TO_DISPLAY_COUNT);
  };

  return (
    <>
      {/* <!-- Modal --> */}
      <Modal className="fade" show={viewTweetModal}>
        <Modal.Header>
          <Modal.Title>
            {" "}
            <h3 className="modal-title">
              <i className="fa fa-brands fa-twitter col-twitter-blue"></i> View
              Tweet
            </h3>
          </Modal.Title>
          <Button
            onClick={() => setViewTweetModal(false)}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          {viewTweet && (
            <>
              <div className="view-tweet-url-container">
                <a
                  href={viewTweet.url}
                  target="_blank"
                  className="text-center col-g"
                >
                  {viewTweet.url}
                </a>
              </div>
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                animate={tweetLoadAnim}
              >
                <TwitterTweetEmbed
                  onLoad={function noRefCheck() {
                    startAnimation();
                  }}
                  tweetId={viewTweet.id_str ? viewTweet.id_str : viewTweet.id}
                  options={{ theme: "dark" }}
                />
              </motion.div>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Col lg={12}>
        <Card className="mb-3 h-fc bot-table">
          <Card.Header>
            <Card.Title>Your Recent Events</Card.Title>
          </Card.Header>
          <Card.Body>
            {events.length === 0 && (
              <div className="no-userBots-msg">
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={["Not much has happened yet", 7000]}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </div>
            )}
            {events.length > 0 && (
              <>
                <Table responsive>
                  <thead>{getTableHeader()}</thead>
                  <tbody>{getTableBody()}</tbody>
                </Table>
                {showLoadMoreBtn && (
                  <div className="d-flex align-items-center justify-content-center">
                    <div
                      className="btn btn-primary light btn-xs mb-1 me-1 w-fc"
                      onClick={handleLoadMoreBtnClicked}
                    >
                      Load more
                    </div>
                  </div>
                )}
              </>
            )}
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default BlockEventsTable;
