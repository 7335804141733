import React, { useState, useContext } from "react";
import { AuthContext } from "../../Providers/AuthProvider";
import { Link } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import AuthOnly from "../AuthOnly";
import { motion } from "framer-motion";

import PageTitle from "../../layouts/PageTitle";
//images
import profile from "./../../../images/profile/pic1.jpg";
import pic1 from "./../../../images/profile/small/pic1.jpg";
import pic4 from "./../../../images/profile/small/pic4.jpg";

import { truncateAddress, epochToDate } from "../../utils";
import TwitterAuth from "./TwitterAuth";
import PageHeaderBar from "../../layouts/PageHeaderBar";
import DiscordWebhooks from "./DiscordWebhooks";

const Settings = () => {
  const { isAuth, auth, address, ensName, ensAvatar } = useContext(AuthContext);
  const [contactModal, setContactModal] = useState();
  // This is load more function

  return (
    <>
      <PageHeaderBar
        pages={[
          { title: "Settings", url: `/settings` },
          { title: "Your Settings", url: `/settings` },
        ]}
      />
      <AuthOnly>
        <div className="row">
          <div className="col-xl-12">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
                  <div className="card justify-content-center">
                    <div className="card-body d-flex">
                      <div className="d-block">
                        <motion.div
                          animate={{
                            scale: [0, 1.07, 1],
                            rotate: [-90, 3, 0],
                            opacity: [0, 1],
                          }}
                        >
                          <img
                            src={ensAvatar}
                            className="avatar avatar-xxl br-50 border-green"
                            alt=""
                          />
                        </motion.div>
                      </div>
                      <div className="w-100 ps-4 d-flex align-items-center">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="">
                            <h4 className="font-w700">
                              {truncateAddress(address)}
                            </h4>
                            {ensName && <h5> {ensName} </h5>}
                            {auth && auth.createdTime && (
                              <span>
                                {" "}
                                User since{" "}
                                {epochToDate(auth.createdTime, {
                                  removeDay: true,
                                })}{" "}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
              <TwitterAuth />
              <DiscordWebhooks />
            </div>
          </div>
        </div>
      </AuthOnly>
    </>
  );
};
export default Settings;
