import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Papa from "papaparse";

import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import loadingCircleImg from "../../../../images/misc/loading-circle-green.svg";
import landmarkImg from "../../../../images/landmark/logo-text-dark.png";
import ApiService from "../../../../services/ApiService";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { utils } from "ethers";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { FileCopyOutlined as FileCopyOutlinedIcon } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { darken } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TypeAnimation } from "react-type-animation";
import { copyTextToClipboard, timeNow, useDocumentTitle } from "../../../utils";
import { ThemeContext } from "../../../../context/ThemeContext";
import {
  addCommas,
  epochToDateString,
  epochToDateTimeString,
  stringToRGB,
  timeAgo,
  toTitleCase,
} from "../../../../utils";

import SeamlessBotImg from "../../../../images/misc/keyword-bot-logo-twitter.png";
import BlueLoading from "../../../../images/misc/blue-goo-loading.svg";
import { Parser } from "@json2csv/plainjs";
// import { timeNow, useDocumentTitle } from "../../../utils";

const SeamlessAccounts = [
  "ihaq@landmarkventures.com",
  "ibrown@landmarkventures.com",
  "dsoukup@landmarkventures.com",
  "Sniessl@landmarkventures.com",
  "ptemplet@landmarkventures.com",
];

const BatchIntervals = [
  "6 hours",
  "12 hours",
  "24 hours",
  "48 hours",
  "72 hours",
  "Weekly",
];

function mapEmails(arr, emailKey, setEmailKey = null) {
  const emailsSet = new Set();

  arr.forEach((obj) => {
    Object.keys(obj).forEach((key) => {
      if (
        typeof obj[key] === "string" &&
        /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b/.test(obj[key])
      ) {
        const email = obj[key].toLowerCase().trim();
        emailsSet.add(email);
        if (setEmailKey && emailKey !== key) {
          setEmailKey(key);
        }
      }
    });
  });

  return Array.from(emailsSet);
}

const SeamlessBotForm = ({ password }) => {
  const [originalData, setOriginalData] = useState([]);
  const [emailKey, setEmailKey] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [csvFileName, setCsvFileName] = useState("");
  const [masterCsvData, setMasterCsvData] = useState([]);
  const [masterCsvFileName, setMasterCsvFileName] = useState("");

  const [masterLoading, setMasterLoading] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [duplicateCount, setDuplicateCount] = useState(0);

  const [keyword, setKeyword] = useState("");
  const [tweetLimit, setSearchDepth] = useState(50);
  const [batchMode, setBatchMode] = useState(false);
  const [batchRunInterval, setBatchRunInterval] = useState("24 hours");
  const [resultsEmail, setResultsEmail] = useState("");
  const [tweetSearches, setTweetSearches] = useState([]);

  async function fetchTweetSearches() {
    try {
      const api = new ApiService({ password, botsApiUrl: false });
      const taskArr = await api.get(`landmark/tweetSearch/getAllTweetSearches`);
      setTweetSearches(taskArr);
      console.log(`fetchTweetSearches()`, taskArr);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchTweetSearches();
  }, []);

  async function stopRecurringTaskBtn(tweetSearchId) {
    try {
      const api = new ApiService({ botsApiUrl: false });
      const { success, error } = await api.post(
        `landmark/tweetSearch/stopRecurringTask`,
        {
          tweetSearchId,
          password,
        }
      );
      if (!success) {
        toast.error(`${error}`);
        return false;
      } else {
        toast.success(`${success}`, { duration: 6000 });
        // refresh list
        fetchTweetSearches();
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async function toggleSetRecurringTaskActive(tweetSearchId) {
    const task = tweetSearches.find((t) => t.tweetSearchId === tweetSearchId);
    let isActive;
    // already active
    if (task.batchActive) {
      isActive = false; // setting active to false
    }
    // not active (paused)
    else {
      isActive = true; // setting active to true
    }
    try {
      const api = new ApiService({ botsApiUrl: false });
      const { success, error } = await api.post(
        `landmark/tweetSearch/setRecurringTaskActive`,
        {
          tweetSearchId,
          isActive,
          password,
        }
      );
      if (!success) {
        toast.error(`${error}`);
        return false;
      } else {
        toast.success(`${success}`, { duration: 6000 });
        // refresh list
        fetchTweetSearches();
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setCsvFileName(file.name);

    Papa.parse(file, {
      complete: (result) => {
        const emailsFromCsv = mapEmails(result.data, emailKey, setEmailKey);
        setOriginalData(result.data);
        setCsvData(emailsFromCsv);
        console.log(`csvData`, emailsFromCsv);
      },
      header: true,
    });
  };

  const handleMasterFileUpload = (e) => {
    const file = e.target.files[0];
    setMasterCsvFileName(file.name);

    setMasterLoading(true);

    setTimeout(() => {
      Papa.parse(file, {
        complete: (result) => {
          const emailsFromCsv = mapEmails(result.data);
          setMasterCsvData(emailsFromCsv);
          console.log(`masterCsvData`, emailsFromCsv);
          setMasterLoading(false);
        },
        header: true,
      });
    }, 500);
  };

  const handleRunBotBtn = async () => {
    // if (!linkedInUsername) {
    //   toast.error("Invalid Email");
    //   return;
    // }
    // if (!linkedInPassword) {
    //   toast.error("Invalid Password");
    //   return;
    // }
    // if (!searchTitle) {
    //   toast.error("Invalid Search Title");
    //   return;
    // }
    if (!keyword) {
      toast.error("Invalid Search URL");
      return;
    }
    // if (!resultsEmail) {
    //   toast.error("Invalid Results Email");
    //   return;
    // }
    if (!isRunBtnEnabled) {
      return;
    }
    try {
      const api = new ApiService({ botsApiUrl: false });
      const { success, tweetSearch, error } = await api.post(
        `landmark/tweetSearch/createTweetSearch`,
        {
          taskType: "recent",
          password,
          keyword,
          tweetLimit,
          batchMode,
          batchRunInterval,
          // resultsEmail,
        }
      );
      if (!success) {
        toast.error(`${error}`);
        return false;
      } else {
        toast.success(`${success}`, { duration: 6000 });
        console.log(`tweetSearch`, tweetSearch);
        // toast.success(`Results will be emailed to ${resultsEmail}`, {
        //   duration: "6000",
        // });
        // clear fields
        // setSearchTitle("");
        setKeyword("");
        fetchTweetSearches();
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  let isRunBtnEnabled = false;

  if (
    // linkedInUsername &&
    // linkedInPassword &&
    // searchTitle &&
    keyword &&
    // resultsEmail &&
    tweetLimit
  ) {
    isRunBtnEnabled = true;
  }

  // const downloadFinalData = () => {
  //   const fileName = `deduped-emails-${timeNow()}.csv`;
  //   try {
  //     const parser = new Parser();
  //     const csv = parser.parse(finalData);
  //     const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  //     const link = document.createElement("a");
  //     if (link.download !== undefined) {
  //       const url = URL.createObjectURL(blob);
  //       link.setAttribute("href", url);
  //       link.setAttribute("download", fileName);
  //       link.style.visibility = "hidden";
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       toast.success("File Downloaded");
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const toggleSelectBatchMode = (event) => {
    const checked = event.target.checked;
    if (checked) {
      setBatchMode(true);
    } else {
      setBatchMode(false);
    }
  };

  const downloadFile = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      // Create a link element and trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      // Extract filename from the URL
      const urlParts = fileUrl.split("/");
      const filename = urlParts[urlParts.length - 1];
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
        <Card className="mb-3 h-fc bot-table">
          <div className="logo-container">
            <img src={SeamlessBotImg} className="img-fluid" />
          </div>
          <div className="sub-logo-text-container">
            <div>
              • Searches for Tweets containing a keyword
              <br />
              • Extracts user information for all the Tweet authors
              <br />• Can be scheduled to run at intervals
            </div>
          </div>
          <div className="row">
            <>
              <div className="seamless-bot-form-wrapper">
                <div className="form-section text-left">
                  {/* <div className="events-owners-subheading text-center">
                    Enter your LinkedIn credentials
                    <br />
                    (account must have Sales access)
                  </div>
                  <div className="form-group mb-3 col-12">
                    <label className="form-label">
                      Email <span className="example-text"></span>
                    </label>
                    <input
                      type="text"
                      value={linkedInUsername}
                      className="form-control"
                      onChange={(e) =>
                        handleSetLinkedInUsername(e.target.value)
                      }
                      autoComplete="email"
                    />
                  </div>
                  <div className="form-group mb-3 col-12">
                    <label className="form-label">
                      Password <span className="example-text"></span>
                    </label>
                    <input
                      type="password"
                      value={linkedInPassword}
                      className="form-control"
                      onChange={(e) => setLinkedInPassword(e.target.value)}
                      autoComplete="linkedInPassword"
                    />
                  </div> */}
                  <div className="events-owners-subheading text-center">
                    Enter Tweet Keyword to Search
                    <br />
                    <i>(e.g. CES2024)</i>
                  </div>
                  <div className="form-group mb-3 col-12">
                    <label className="form-label">
                      Keyword <span className="example-text"></span>
                    </label>
                    <input
                      type="text"
                      value={keyword}
                      className="form-control"
                      onChange={(e) => setKeyword(e.target.value)}
                      autoComplete="keyword"
                    />
                    <br></br>
                    <label className={`form-label`}>
                      Tweet Limit<br></br>
                      <span className="example-text">
                        How many tweets to return (10 - 100)
                      </span>
                      <br />
                      <span className="example-text">
                        Note: Our API is limited to 10,000 tweets per month, so
                        please don't search for more than you need
                      </span>
                    </label>
                    <input
                      type="number"
                      value={tweetLimit}
                      className={`form-control`}
                      onChange={(e) => setSearchDepth(e.target.value)}
                      min="10" // Set the minimum value (adjust as needed)
                      max="100" // Set the maximum value (adjust as needed)
                      step="10" // Set the increment value (adjust as needed)
                      // disabled={batchMode}
                    />

                    <br></br>
                    <label className="form-label">
                      Recurring Mode<br></br>
                      <span className="example-text">
                        (Performs a deep search of posts and updates the list
                        every interval, building a list of contacts over time)
                      </span>
                    </label>
                    <div className="checkbox-container">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheckBox2"
                        required
                        defaultChecked={false}
                        checked={batchMode}
                        onChange={(event) => toggleSelectBatchMode(event)}
                      />
                      <div
                        className={`checkbox-label ${batchMode ? "on" : "off"}`}
                      >
                        Recurring Mode {batchMode ? "On" : "Off"}
                      </div>
                    </div>
                    {batchMode ? (
                      <>
                        <br></br>
                        <label
                          className="form-label"
                          style={{ marginTop: "8px" }}
                        >
                          Recurring Search Interval{" "}
                          <span className="example-text"></span>
                        </label>
                        <select
                          className="form-control lh-1"
                          onChange={(e) => setBatchRunInterval(e.target.value)}
                          value={batchRunInterval}
                        >
                          {BatchIntervals &&
                            BatchIntervals.map((interval, key) => (
                              <option
                                key={`batch_interval_id_${key}`}
                                value={interval}
                              >
                                {interval}
                              </option>
                            ))}
                        </select>
                      </>
                    ) : null}

                    <br></br>
                    {/* <label className="form-label">
                      Search Title <i>(e.g. Marketing CEO connections)</i>{" "}
                      <span className="example-text"></span>
                    </label>
                    <input
                      type="text"
                      value={searchTitle}
                      className="form-control"
                      onChange={(e) => setSearchTitle(e.target.value)}
                    /> */}
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      className={`run-btn btn btn-primary ${
                        isRunBtnEnabled ? "" : "disabled"
                      }`}
                      onClick={handleRunBotBtn}
                    >
                      {`RUN BOT`}
                      <i className="event-owner-add fa-sharp fa-solid fa-robot ml-05"></i>
                    </button>
                  </div>
                </div>
              </div>
            </>
          </div>
        </Card>
        {tweetSearches && tweetSearches.length ? (
          <div className="incomplete-tasks-section">
            <div className="header-text">Tweet Searches</div>
            <div className="tasks-container">
              {tweetSearches.map((task) => {
                return (
                  <div className="task-container">
                    <div className="info">
                      <div>
                        Keyword:{" "}
                        <span className="highlighted">"{task.keyword}"</span>
                      </div>
                      <div>
                        Tweets Found:{" "}
                        <span className="highlighted">
                          {task.tweets.length}
                        </span>
                      </div>
                      <div>
                        Tweet Authors:{" "}
                        <span className="highlighted">
                          {task.tweetAuthors.length}
                        </span>
                      </div>
                      <div>
                        Search Time:{" "}
                        <span className="highlighted">
                          {epochToDateTimeString(task.lastRunTime)}
                        </span>
                      </div>
                      {task.batchMode && (
                        <div>
                          Search Interval:{" "}
                          <span className="highlighted">
                            {task.batchRunInterval}
                          </span>
                        </div>
                      )}
                      {task.errorLog && task.errorLog.length ? (
                        <div className="error-container">
                          <div className="left">Errors: </div>
                          <div className="right">
                            {task.errorLog.map((err, errKey) => (
                              <div
                                key={errKey}
                                className="error-msg highlighted"
                              >
                                {epochToDateTimeString(err.errorTime)}
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="controls">
                      {task.batchMode && !task.completed ? (
                        <>
                          <button
                            className={`run-btn btn btn-primary task-btn`}
                            onClick={() =>
                              toggleSetRecurringTaskActive(task.tweetSearchId)
                            }
                          >
                            <i
                              className={`event-owner-add fa-sharp fa-solid fa-${
                                task.batchActive ? "pause" : "play"
                              }`}
                            ></i>
                          </button>
                          <button
                            className={`run-btn btn btn-primary task-btn stop`}
                            onClick={() =>
                              stopRecurringTaskBtn(task.tweetSearchId)
                            }
                          >
                            <i className="event-owner-add fa-sharp fa-solid fa-stop"></i>
                          </button>
                        </>
                      ) : null}
                      <button
                        className={`run-btn btn btn-primary task-btn download`}
                        onClick={() => downloadFile(task.fileUrls[0])}
                      >
                        <i className="event-owner-add fa-sharp fa-solid fa-download"></i>
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </motion.div>
    </>
  );
};

const PasswordPrompt = ({ password, setPassword, submitPassword }) => {
  const handleInputChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submitPassword(password);
  };

  return (
    <div className="d-flex">
      <input
        onChange={handleInputChange}
        type="password"
        className="form-control w-100 mt-1 inline-input"
        placeholder="Password"
        value={password}
        autoComplete="password"
      ></input>
      <div
        className="btn btn-primary light btn-xs mt-1 me-1 w-fc inline-btn"
        onClick={(e) => handleSubmit(e)}
      >
        Submit
      </div>
    </div>
  );
};

const Dashboard = (props) => {
  const { changeBackground } = useContext(ThemeContext);
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // set light theme
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);

  async function submitPassword(password) {
    if (!password) {
      toast.error("Invalid Password");
      return;
    }
    try {
      const api = new ApiService();
      const { success, error } = await api.post(`landmark/events/auth`, {
        password,
      });
      if (!success) {
        toast.error(`${error}`);
        setIsAuthenticated(false);
        return false;
      } else {
        toast.success(`${success}`, { duration: 5000 });
        setIsAuthenticated(true);
        return true;
      }
    } catch (error) {
      console.error(error);
      setIsAuthenticated(false);
      return false;
    }
  }

  useDocumentTitle("Seamless Bot - Landmark Ventures");

  return (
    <Fragment>
      <div className="global-dash-wrapper seamless-bot">
        {/* <button disabled={isLoading} onClick={() => signMessage()}>
        Sign message
      </button>
      {isSuccess && <div>Signature: {data}</div>}
      {isError && <div>Error signing message</div>} */}
        <div className="d-flex align-items-center justify-content-center">
          <img src={landmarkImg} className="img-fluid landmark-img" />
        </div>
        <PageHeaderBar
          pages={[
            { title: "Landmark", url: `` },
            { title: "Tweet Search Dashboard", url: `/landmark/tweetsearch` },
          ]}
        />
        {/* show dashboard */}
        {isAuthenticated ? (
          <>
            <SeamlessBotForm password={password} />
          </>
        ) : null}
        {/* show password entry */}
        {!isAuthenticated ? (
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div className="not-authed-msg">
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={["Enter password to access tool", 4600]}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <PasswordPrompt
                password={password}
                setPassword={setPassword}
                submitPassword={submitPassword}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default Dashboard;
