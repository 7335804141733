import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import { AuthContext } from "../../Providers/AuthProvider";
import ApiService from "../../../services/ApiService";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import { motion } from "framer-motion";

import discordHookImg from "../../../images/misc/discord-hook.png";
import { epochToDateString } from "../../../utils";

function DiscordWebhooks() {
  const { isAuth, userId } = useContext(AuthContext);
  const [discordWebhooks, setDiscordWebhooks] = useState([]);
  const [contactModal, setContactModal] = useState();
  const [webhookUrl, setWebhookUrl] = useState("");
  const [serverName, setServerName] = useState("");
  const [channelName, setChannelName] = useState("");

  const handleChannelNameChange = (event) => {
    setChannelName(event.target.value);
  };

  const handleServerNameChange = (event) => {
    setServerName(event.target.value);
  };

  const handleWebhookUrlChange = (event) => {
    setWebhookUrl(event.target.value.trim());
  };

  const handleSaveWebhook = () => {
    const api = new ApiService();
    api
      .post(`user/discord/webhook/add`, {
        userId,
        webhookUrl,
        channelName,
        serverName,
      })
      .then((data) => {
        if (data.discordWebhook) {
          toast.success("Discord Webhook added");
          setDiscordWebhooks(data.userDiscordWebhooks);
          setContactModal(false);
        } else {
          toast.error("An error occurred");
        }
      });
  };

  const deleteDiscordWebhook = (discordWebhookId) => {
    const api = new ApiService();
    api
      .post(`user/discord/webhook/delete`, { userId, discordWebhookId })
      .then((data) => {
        if (data.success) {
          toast.success("Discord Webhook deleted");
          fetchDiscordWebhooks();
        } else {
          toast.error("An error occurred");
        }
      });
  };

  const confirmDeleteDiscordWebhookAlert = (discordWebhookId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Any bots using this Discord Webhook will be paused",
      showCancelButton: true,
      confirmButtonColor: "#EB5757",
      cancelButtonColor: "#adadad",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDiscordWebhook(discordWebhookId);
      }
    });
  };

  // useEffect(() => {
  //   // pin set - attempt to authenticate
  //   if (pin && pin.length === 7) {
  //     const api = new ApiService();
  //     api
  //       .post(`user/twitter/generateAuthTokens`, { userId, pin })
  //       .then((data) => {
  //         if (data.success) {
  //           toast.success("Twitter account authenticated");
  //           setContactModal(false);
  //           fetchDiscordWebhooks();
  //           // clear modal inputs
  //           setAuthUrl("");
  //           setPin("");
  //         } else {
  //           toast.error("Unable to authenticate account");
  //         }
  //       });
  //   }
  // }, [pin]);

  const fetchDiscordWebhooks = () => {
    const api = new ApiService();
    api.get(`user/discord/webhook/get/${userId}`).then((data) => {
      setDiscordWebhooks(data);
    });
  };

  useEffect(() => {
    if (isAuth && userId && !discordWebhooks.length) {
      fetchDiscordWebhooks();
    }
  }, [userId, isAuth, discordWebhooks.length]);

  if (!isAuth || !userId) {
    return null;
  }

  const webhookUrlIsValid = webhookUrl.startsWith(
    "https://discord.com/api/webhooks/"
  );

  return (
    <motion.div
      animate={{
        scale: [0, 1],
        opacity: [0, 1],
      }}
      className="col-xl-6 col-lg-6 col-md-12"
    >
      <div>
        <div className="card h-fc settings-twitter-accounts">
          <div className="card-header border-0 pb-0 d-block">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h3 className="heading">
                  <i className="fa fa-brands fa-discord col-discord-purple"></i>{" "}
                  Discord Webhooks
                </h3>
                <span>
                  You have{" "}
                  <span className="font-w600">{discordWebhooks.length}</span>{" "}
                  Webhook{discordWebhooks.length === 1 ? "" : "s"}
                </span>
              </div>
              <Link to={"#"} onClick={() => setContactModal(true)}>
                <div className="icon-box icon-box-sm bg-primary hvr-shrink">
                  <i className="fa fa-plus color-b" />
                </div>
              </Link>
            </div>
          </div>
          <div className="card-body pt-4 pb-0 dz-scroll">
            <div className="contacts-list">
              {discordWebhooks.map((item, index) => (
                <div
                  className="d-flex justify-content-between mb-3 mt-3 pb-3"
                  key={index}
                >
                  <div className="d-flex align-items-center">
                    <img
                      src={discordHookImg}
                      alt=""
                      className="avatar br-50  border-green"
                    />
                    <div className="ms-3">
                      <h5 className="mb-1">{item.channelName}</h5>
                      <span className="fs-14 text-muted">
                        {item.serverName}
                      </span>
                      {/* <br></br>
                      <span className="fs-14 text-muted">
                        Created: {epochToDateString(item.createdTime)}
                      </span> */}
                    </div>
                  </div>
                  <Link
                    to={"#"}
                    onClick={() =>
                      confirmDeleteDiscordWebhookAlert(item.discordWebhookId)
                    }
                  >
                    <div className="icon-box icon-box-sm bg-primary-light hvr-shrink color-r-h">
                      <i className="fa fa-trash"></i>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal fade twitter-auth-modal"
        centered
        show={contactModal}
        onHide={setContactModal}
        onShow={() => {}}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">
              <i className="fa fa-brands fa-discord col-discord-purple"></i> Add
              a Discord Webhook
            </h3>
            <button
              type="button"
              className="btn-close"
              onClick={() => setContactModal(false)}
            ></button>
          </div>
          <div className="modal-body">
            <div className="instructions-text">
              1. Open the Discord channel you want to post content from (you
              must have server permission to manage the channel)
              <br />
              2. From the channel menu, select <b>Edit channel</b>
              <br />
              3. Select <b>Integrations</b>
              <br />
              4. Select <b>Create Webhook</b>
              <br />
              5. Choose a name for the bot that will post the message
              <br />
              6. Click on <b>Copy Webhook URL</b>
              <br />
              7. Save changes and paste the copied URL below
              <br />
            </div>
            <label className="form-label d-block">
              Enter Webhook URL{" "}
              {webhookUrlIsValid && (
                <span>
                  <i className="fa fa-check col-green ml-1"></i>
                </span>
              )}
              {webhookUrl.length > 0 && !webhookUrlIsValid ? (
                <span>
                  <i className="fa fa-x col-red ml-1"></i>
                </span>
              ) : null}
            </label>
            <input
              type="text"
              className="form-control w-100"
              placeholder="Discord Webhook URL"
              value={webhookUrl}
              onChange={handleWebhookUrlChange}
            />
            {webhookUrlIsValid && (
              <>
                <label className="form-label d-block mt-3">
                  Channel Name (for your notes only){" "}
                  {channelName.length ? (
                    <span>
                      <i className="fa fa-check col-green ml-1"></i>
                    </span>
                  ) : null}
                </label>
                <input
                  type="text"
                  className="form-control w-100"
                  placeholder="e.g. Bored Ape Sales"
                  value={channelName}
                  onChange={handleChannelNameChange}
                />
                <label className="form-label d-block mt-3">
                  Server Name (for your notes only){" "}
                  {serverName.length ? (
                    <span>
                      <i className="fa fa-check col-green ml-1"></i>
                    </span>
                  ) : null}
                </label>
                <input
                  type="text"
                  className="form-control w-100"
                  placeholder="e.g. BAYC Server"
                  value={serverName}
                  onChange={handleServerNameChange}
                />
              </>
            )}
          </div>
          <div className="modal-footer">
            <div className="hvr-shrink">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setContactModal(false)}
              >
                Cancel
              </button>
            </div>
            {webhookUrlIsValid && channelName.length && serverName.length ? (
              <div className="hvr-shrink">
                <button
                  type="button"
                  className="btn btn-discord"
                  onClick={handleSaveWebhook}
                >
                  Save Webhook
                </button>
              </div>
            ) : null}
            {/* <button type="button" className="btn btn-primary">
              Save changes
            </button> */}
          </div>
        </div>
      </Modal>
    </motion.div>
  );
}

export default DiscordWebhooks;
