import React, { useState, useEffect } from "react";
//import {Tab, Nav} from 'react-bootstrap';
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import BlockCard from "./BlockCard";
import { Blocks, Categories } from "./blocks";
import PageHeaderBar from "../../layouts/PageHeaderBar";

const BlocksIndex = () => {
  const blocksArr = Object.values(Blocks).filter((b) => b.display);
  const categoriesArr = Object.values(Categories);

  const [popular, setPopular] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [activeCategory, setActiveCategory] = useState(0);

  useEffect(() => {
    fetchPopular();
  }, []);
  function fetchPopular() {
    setPopular(blocksArr);
    setFiltered(blocksArr);
  }

  useEffect(() => {
    if (activeCategory === 0) {
      setFiltered(blocksArr);
      return;
    }
    const filtered = popular.filter((block) =>
      block.categories.includes(activeCategory)
    );
    setFiltered(filtered);
  }, [activeCategory]);
  //console.log(filtered);
  //console.log('filtered');
  return (
    <>
      <PageHeaderBar
        pages={[
          { title: "Blocks", url: `/blocks` },
          { title: "All", url: `/blocks` },
        ]}
      />
      <div className="row">
        <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
          <div className="col-xl-12">
            <div className="card Infra">
              <div className="card-header border-0">
                <div className="site-filters clearfix center m-b40">
                  <ul className="filters" data-bs-toggle="buttons">
                    <li
                      data-filter=""
                      className={`btn ${activeCategory === 0 ? "active" : ""}`}
                    >
                      <Link
                        to={"#"}
                        className="site-button"
                        onClick={() => setActiveCategory(0)}
                      >
                        Show All
                      </Link>
                    </li>
                    {categoriesArr.map((category, index) => (
                      <li
                        data-filter=".infra"
                        className={`btn ${
                          category === activeCategory ? "active" : ""
                        }`}
                        key={index}
                      >
                        <Link
                          to={"#"}
                          className="site-button"
                          onClick={() => setActiveCategory(category)}
                        >
                          {category.title}
                        </Link>{" "}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
        <div className="col-xl-12">
          <div className="row">
            {/* <div className="col-xl-2 col-lg-4">
              <div className="card check-filter">
                <div className="card-body site-filters clearfix">
                  <ul className="filters d-grid" data-bs-toggle="radio">
                    <li data-filter="" className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        defaultChecked
                        onClick={() => setActiveCategory(0)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        All
                      </label>
                    </li>
                    <li data-filter=".rated" className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        onClick={() => setActiveCategory(1)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        Rated
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="col-xl-12">
              <div className="block-index-type-title">Bots</div>
              <ul
                //layout
                id="masonry"
                className="row"
                //transition={{ duration: 0.3 }}
              >
                <AnimatePresence>
                  {filtered
                    .filter((b) => b.type === "Bot")
                    .map((block, index) => {
                      return (
                        <motion.li
                          layout
                          initial={{ scale: 0, opacity: 0 }}
                          animate={{ scale: 1, opacity: 1 }}
                          exit={{ scale: 0, opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          className="col-xl-6 col-lg-6 col-md-12 infra rated px-3"
                          key={index}
                          //transition={{ duration: 0.5 }}
                        >
                          <Link to={block.url}>
                            <div className="hvr-shrink w-100">
                              <BlockCard
                                block={block}
                                compactMode={true}
                                withoutMotion={true}
                              />
                            </div>
                          </Link>
                        </motion.li>
                      );
                    })}
                </AnimatePresence>
              </ul>
            </div>
            <div className="col-xl-12">
              <div className="block-index-type-title">Tools</div>
              <ul
                //layout
                id="masonry"
                className="row"
                //transition={{ duration: 0.3 }}
              >
                <AnimatePresence>
                  {filtered
                    .filter((b) => b.type === "Tool")
                    .map((block, index) => {
                      return (
                        <motion.li
                          layout
                          initial={{ scale: 0, opacity: 0 }}
                          animate={{ scale: 1, opacity: 1 }}
                          exit={{ scale: 0, opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          className="col-xl-6 col-lg-6 col-md-12 infra rated px-3"
                          key={index}
                          //transition={{ duration: 0.5 }}
                        >
                          <Link to={block.url}>
                            <div className="hvr-shrink w-100">
                              <BlockCard
                                block={block}
                                compactMode={true}
                                withoutMotion={true}
                              />
                            </div>
                          </Link>
                        </motion.li>
                      );
                    })}
                </AnimatePresence>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlocksIndex;
