import React, { createContext, useState, useEffect, useMemo } from "react";
import { Alchemy, Network } from "alchemy-sdk";
// create context
const AlchemyContext = createContext();

const AlchemyProvider = ({ children }) => {
  const [alchemySDK, setAlchemySDK] = useState(null);

  // const alchemyKey = process.env.ALCHEMY_KEY;
  // the value that will be given to the context
  // const { chainId } = useWeb3React();
  
  useEffect(() => {
    const alchemyKey = `ljL7AH9-WbE7vwIghPAhPN7OF0MfhmcH`;
    if (!alchemyKey) {
      return;
    }

    const settings = {
      apiKey: alchemyKey,
      network: Network.ETH_MAINNET,
    };

    setAlchemySDK(new Alchemy(settings));
  }, []);

  const value = useMemo(
    () => ({ alchemySDK, setAlchemySDK }), 
    [alchemySDK]
  );

  // if (chainId === undefined) {
  //   return <>{children}</>;
  // }

  if (!alchemySDK) {
    return <div>Alchemy key is missing</div>;
  }

  return (
    <AlchemyContext.Provider value={value}>
      {children}
    </AlchemyContext.Provider>
  );
};

export { AlchemyContext, AlchemyProvider };