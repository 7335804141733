import React, {
  Fragment,
  useRef,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import loadingCircleImg from "../../../../images/misc/loading-circle-green.svg";
import landmarkImg from "../../../../images/landmark/logo-text-dark.png";
import ApiService from "../../../../services/ApiService";
import PageHeaderBar from "../../../layouts/PageHeaderBar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import "react-datepicker/dist/react-datepicker.css";
import { utils } from "ethers";
import { MaterialReactTable } from "material-react-table";
import { ExportToCsv } from "export-to-csv";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { FileCopyOutlined as FileCopyOutlinedIcon } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { darken } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { TypeAnimation } from "react-type-animation";
import {
  copyTextToClipboard,
  timeNow,
  useDocumentTitle,
  epochToDateObj,
  epochFromDate,
  uniqueGuid,
} from "../../../utils";
import { ThemeContext } from "../../../../context/ThemeContext";
import {
  epochToDateString,
  epochToDateTimeString,
  stringToRGB,
  toTitleCase,
} from "../../../../utils";
import imageUploadLoader from "../../../../images/misc/loader-light-blue-bg.gif";

const timezoneOptions = ["PT"];

const OptionForm = ({ option, options, password, handleUpdateOption }) => {
  const [title, setTitle] = useState(option ? option.title : "");
  const [description, setDescription] = useState(
    option ? option.description : ""
  );
  const [location, setLocation] = useState(option ? option.location : "");
  const [startDate, setStartDate] = useState(
    option ? epochToDateObj(option.startTime) : epochToDateObj(timeNow())
  );
  const [endDate, setEndDate] = useState(
    option
      ? epochToDateObj(option.endTime)
      : epochToDateObj(timeNow() + 60 * 90)
  );

  const [shortTitle, setShortTitle] = useState(option ? option.shortTitle : "");
  const [subLocation, setSubLocation] = useState(
    option ? option.subLocation : ""
  );
  const [timeString, setTimeString] = useState(option ? option.timeString : "");
  const [dateString, setDateString] = useState(option ? option.dateString : "");
  const [timezone, setTimezone] = useState(
    option ? option.timezone : timezoneOptions[0]
  );
  const [logos, setLogos] = useState(option ? option.logos : []);

  const [loading, setLoading] = useState(false);

  let allExistingOptionLogos = [];
  if (options && options.length) {
    const allOptionLogos = {};
    // const otherEvents = options && options.length
    //   ? events.filter((e) => e.eventId !== selectedEvent.eventId)
    //   : events;
    options.forEach((o) => {
      o.logos.forEach((l) => {
        allOptionLogos[l] = l;
      });
    });
    allExistingOptionLogos = Object.values(allOptionLogos).filter(
      (l) => !logos.some((cl) => l === cl)
    ); // filter to exclude already added logos
  }

  useEffect(() => {
    registerLocale("en-US", enUS);
  }, []);

  useEffect(() => {
    // new option created
    if (!option || !option.title) {
      setTitle("");
      setDescription("");
      setLocation("");
      setStartDate(epochToDateObj(timeNow()));
      setEndDate(epochToDateObj(timeNow() + 60 * 90));
      setShortTitle("");
      setSubLocation("");
      setTimeString("");
      setDateString("");
      setTimezone(timezoneOptions[0]);
      setLogos([]);
    }
    // existing option obj
    else {
      setTitle(option.title);
      setDescription(option.description);
      setLocation(option.location);
      setStartDate(epochToDateObj(option.startTime));
      setEndDate(epochToDateObj(option.endTime));
      setShortTitle(option.shortTitle);
      setSubLocation(option.subLocation);
      setTimeString(option.timeString);
      setDateString(option.dateString);
      setTimezone(option.timezone);
      setLogos(option.logos);
    }
    console.log(`option`, option);
  }, [option]);

  const handleSetStartDate = (date) => {
    console.log(`handleSetStartDate() - date`, date);
    setStartDate(date);
  };

  const handleSetEndDate = (date) => {
    console.log(`handleSetStartDate() - date`, date);
    setEndDate(date);
  };

  const handleSaveOption = () => {
    const updatedOption = {
      title,
      description,
      location,
      startTime: epochFromDate(startDate),
      endTime: epochFromDate(endDate),
      shortTitle,
      subLocation,
      timeString,
      dateString,
      timezone,
      logos,
    };
    if (option && (option.optionId || option.tempOptionId)) {
      // set optionId so handleUpdateOption knows we are editing not creating
      updatedOption.optionId = option.optionId;
      updatedOption.tempOptionId = option.tempOptionId;
    }
    console.log(`handleUpdateOption() - option`, option);
    console.log(`handleUpdateOption() - updatedOption`, updatedOption);
    handleUpdateOption(updatedOption);
  };

  // Helper functions for dynamic arrays
  const addArrayItem = (setter, array, newItem) => {
    setter([...array, newItem]);
  };

  const removeArrayItem = (setter, array, index) => {
    const updatedArray = array.filter((_, i) => i !== index);
    setter(updatedArray);
  };

  // Handle image upload
  const handleImageUpload = async (event, setter, options = {}) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true); // Start loading

    const formData = new FormData();
    formData.append("image", file);
    formData.append("options", JSON.stringify(options));

    try {
      const api = new ApiService({ password });
      const response = await api.post("landmark/upload/image", formData);
      if (response.success && response.imageUrl) {
        setter((prev) => [...prev, response.imageUrl]);
        toast.success("Image uploaded successfully");
      } else {
        toast.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Error uploading image");
    } finally {
      setLoading(false); // End loading
    }
  };

  const newOptionMode =
    !option || (!option.optionId && !option.tempOptionId) ? true : false; // user is creating a new option or editing existing

  return (
    <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
      <div className="option-form">
        <div className="row">
          <div className="form-group mb-3 col-md-6">
            <label className="form-label">
              Title{" "}
              <span className="example-text">(e.g. Welcome to CES 2024)</span>
            </label>
            <input
              type="text"
              value={title}
              className="form-control"
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group mb-3 col-md-6">
            <label className="form-label">
              Small Title{" "}
              <span className="example-text">(e.g. Happy Hour)</span>
            </label>
            <input
              type="text"
              value={shortTitle}
              className="form-control"
              onChange={(e) => setShortTitle(e.target.value)}
            />
          </div>
          <div className="form-group mb-3 col-md-6">
            <label className="form-label">
              Location{" "}
              <span className="example-text">(e.g. The Palazzo Hotel)</span>
            </label>
            <input
              type="text"
              value={location}
              className="form-control"
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>
          <div className="form-group mb-3 col-md-6">
            <label className="form-label">
              Sub Location{" "}
              <span className="example-text">(e.g. Rooftop Bar)</span>
            </label>
            <input
              type="text"
              value={subLocation}
              className="form-control"
              onChange={(e) => setSubLocation(e.target.value)}
            />
          </div>
          <div className="form-group mb-3 col-md-12">
            <label className="form-label">
              Description{" "}
              <span className="example-text">
                (e.g. Delicious vegan brunch and networking meetup for VIPs)
              </span>
            </label>
            <textarea
              type="text"
              rows="3"
              value={description}
              className="form-control"
              onChange={(e) => setDescription(e.target.value)}
              style={{ lineHeight: "1.4", height: "90px" }}
            />
          </div>
          <div className="form-group mb-3 col-md-12">
            <label className="form-label">
              Timezone{" "}
              <span className="example-text">
                (e.g. {timezoneOptions[0]}) (contact mjones if you need a
                different timezone)
              </span>
            </label>
            <select
              defaultValue={timezoneOptions[0]}
              className="form-control"
              onChange={(e) => setTimezone(e.target.value)}
              value={timezone}
            >
              {timezoneOptions.map((tz) => {
                return <option>{tz}</option>;
              })}
            </select>
          </div>
          <div className="form-group mb-3 col-md-6">
            <label className="form-label">
              Start Time <br />
              <span className="example-text">(e.g. 01/10/2024, 12:00 AM)</span>
            </label>
            <DatePicker
              selected={startDate}
              onChange={(date) => handleSetStartDate(date)}
              showTimeSelect
              locale="en-US"
              // showTimeInput
              timeIntervals={15}
              dateFormat="Pp"
              className="form-control"
            />
          </div>
          <div className="form-group mb-3 col-md-6">
            <label className="form-label">
              End Time <br />
              <span className="example-text">(e.g. 01/10/2024, 2:00 AM)</span>
            </label>
            <DatePicker
              selected={endDate}
              onChange={(date) => handleSetEndDate(date)}
              locale="en-US"
              showTimeSelect
              // showTimeInput
              timeIntervals={15}
              dateFormat="Pp"
              className="form-control"
            />
          </div>
          <div className="form-group mb-3 col-md-6">
            <label className="form-label">
              Display Date <br />
              <span className="example-text">
                (e.g. Tuesday, January 9, 2024)
              </span>
            </label>
            <input
              type="text"
              value={dateString}
              className="form-control"
              onChange={(e) => setDateString(e.target.value)}
            />
          </div>
          <div className="form-group mb-3 col-md-6">
            <label className="form-label">
              Display Time <br />
              <span className="example-text">(e.g. 4:00 - 6:00pm PT)</span>
            </label>
            <input
              type="text"
              value={timeString}
              className="form-control"
              onChange={(e) => setTimeString(e.target.value)}
            />
          </div>
          <div className="form-group mb-3 col-md-12">
            <label className="form-label">Logo Images</label>
            <div className="existing-logos-container">
              <label className="form-label">
                Click to add a previously used logo:
              </label>
              <div className="existing-logos-outer">
                {allExistingOptionLogos.map((hl, index) => {
                  return (
                    <div
                      onClick={() => {
                        setLogos([...logos, hl]); // add the new logo object
                      }}
                      key={index}
                      className="existing-logo"
                    >
                      <img
                        src={hl}
                        alt={`Header Logo ${index + 1}`}
                        className="img-fluid"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            {logos.map((image, index) => (
              <div key={index} className="d-flex align-items-center mb-2">
                <img
                  src={image}
                  alt={`Logo ${index + 1}`}
                  className="img-thumbnail"
                  style={{ maxWidth: "344px", width: "344px" }}
                />
                <button
                  className="btn btn-danger ml-2"
                  onClick={() => removeArrayItem(setLogos, logos, index)}
                >
                  Remove
                </button>
              </div>
            ))}
            {loading && (
              <img
                src={imageUploadLoader}
                alt="Uploading..."
                width="100"
                className="img-thumbnail"
              />
            )}
            <input
              type="file"
              accept="image/*"
              onChange={(e) =>
                handleImageUpload(e, setLogos, {
                  compress: true,
                })
              }
              className="d-none"
              id="subEventsLogoUpload"
            />
            <button
              className="btn btn-secondary btn-sm mt-2"
              onClick={() =>
                document.getElementById("subEventsLogoUpload").click()
              }
            >
              Upload Logo
              <i className="event-owner-add fa-sharp fa-solid fa-plus ml-05"></i>
            </button>
          </div>
          <div className="divider"></div>
          <div className="form-group mb-3 col-md-12">
            {newOptionMode && (
              <button
                className={`create-event-btn btn btn-sm btn-primary`}
                onClick={handleSaveOption}
              >
                {`Save New Option`}
                <i className="event-owner-add fa-sharp fa-solid fa-check ml-05"></i>
              </button>
            )}
            {!newOptionMode && (
              <button
                className={`create-event-btn btn btn-sm btn-primary`}
                onClick={handleSaveOption}
              >
                {`Save Changes`}
                <i className="event-owner-add fa-sharp fa-solid fa-check ml-05"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const EventForm = ({ selectedEvent, password, getEvents, resetModes }) => {
  const [title, setTitle] = useState(selectedEvent ? selectedEvent.title : "");
  const [startDate, setStartDate] = useState(
    selectedEvent ? selectedEvent.startDate : ""
  );
  const [endDate, setEndDate] = useState(
    selectedEvent ? selectedEvent.endDate : ""
  );
  const [location, setLocation] = useState(
    selectedEvent ? selectedEvent.location : ""
  );
  const [imageUrl, setImageUrl] = useState(
    selectedEvent ? selectedEvent.imageUrl : ""
  );
  const [tags, setTags] = useState(
    selectedEvent ? selectedEvent.tags : ["global"]
  );
  const [options, setOptions] = useState(
    selectedEvent ? selectedEvent.options : []
  );
  const [selectedOption, setSelectedOption] = useState(null);

  const [loading, setLoading] = useState(false);

  async function addOrEditEvent(event, editMode = false) {
    try {
      const api = new ApiService({ password });
      const url = editMode
        ? "landmark/events/editEvent"
        : "landmark/events/addEvent";
      const eventsResponse = await api.post(url, {
        event,
      });
      console.log(`addOrEditEvent() - ${url} - response`, eventsResponse);
      const { success, error } = eventsResponse;
      if (!success) {
        toast.error("An error occurred");
      } else if (error) {
        toast.error(error);
      } else {
        toast.success(success);
        // refresh events
        getEvents();
        resetModes(); // exit the edit/create form
      }
    } catch (error) {
      console.error(error);
    }
  }
  // Handle image upload
  const handleImageUpload = async (event, setter, options = {}) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true); // Start loading

    const formData = new FormData();
    formData.append("image", file);
    formData.append("options", JSON.stringify(options));

    try {
      const api = new ApiService({ password });
      const response = await api.post("landmark/upload/image", formData);
      if (response.success && response.imageUrl) {
        setter(response.imageUrl);
        toast.success("Image uploaded successfully");
      } else {
        toast.error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Error uploading image");
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleSaveEvent = () => {
    // scrape details from state
    const event = {
      title,
      location,
      startDate,
      endDate,
      imageUrl,
      tags,
      options,
    };
    // saving an existing event - use edit endpoint
    if (selectedEvent && selectedEvent.eventId) {
      event.eventId = selectedEvent.eventId;
      // edit event
      addOrEditEvent(event, true);
    } else {
      // add event
      addOrEditEvent(event, false);
    }
  };

  useEffect(() => {
    // new event created
    if (!selectedEvent || !selectedEvent.eventId) {
      setTitle("");
      setStartDate("");
      setEndDate("");
      setLocation("");
      setImageUrl("");
      setTags(["global"]);
      setOptions([]);
      setSelectedOption(null);
    }
    // existing event obj
    else {
      setTitle(selectedEvent.title);
      setStartDate(selectedEvent.startDate);
      setEndDate(selectedEvent.endDate);
      setLocation(selectedEvent.location);
      setImageUrl(selectedEvent.imageUrl);
      setTags(selectedEvent.tags);
      setOptions(selectedEvent.options);
      setSelectedOption(null);
    }
  }, [selectedEvent]);

  // called when user has saved an option
  const handleUpdateOption = (option) => {
    // option already exists
    if (option.optionId || option.tempOptionId) {
      const updatedOptions = options.map((o) => {
        if (
          (option.optionId && o.optionId && option.optionId === o.optionId) ||
          (option.tempOptionId &&
            o.tempOptionId &&
            option.tempOptionId === o.tempOptionId)
        ) {
          return { ...o, ...option }; // overwrite o's fields with option's fields
        }
        return o;
      });
      console.log(
        `handleUpdateOption() - setOptions() - updated option`,
        updatedOptions
      );
      setOptions(updatedOptions);
      setSelectedOption(null);
      toast.success("Option Updated");
    }
    // option is new - push to existing arr
    else {
      const updatedOptions = options;
      option.tempOptionId = uniqueGuid(); // so that we discern from a new option and a saved option in the option ui
      updatedOptions.push(option);
      console.log(
        `handleUpdateOption() - setOptions() - new option`,
        updatedOptions
      );
      setOptions(updatedOptions);
      setSelectedOption(null);
      toast.success("Option Added");
    }
  };

  const handleTagChange = (str) => {
    const newTags = str.split(",").map((s) => s.trim().replace(/\s/g, ""));
    const compareArrays = (a, b) => {
      return JSON.stringify(a) === JSON.stringify(b);
    };
    // tags array has changed - update state
    if (!compareArrays(tags, newTags)) {
      setTags(newTags);
    }
  };

  const newOptionMode =
    selectedOption !== null &&
    !selectedOption.optionId &&
    !selectedOption.tempOptionId
      ? true
      : false; // user is creating a new option or editing existing

  const saveBtnText = selectedEvent === null ? "Save Event" : "Save Changes";

  return (
    <div className="event-form">
      <div className="row">
        <div className="form-group mb-3 col-md-6">
          <label className="form-label">
            Title <span className="example-text">(e.g. Blackhat)</span>
          </label>
          <input
            type="text"
            value={title}
            className="form-control"
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-group mb-3 col-md-6">
          <label className="form-label">
            Location <span className="example-text">(e.g. Las Vegas, NV)</span>
          </label>
          <input
            type="text"
            value={location}
            className="form-control"
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
        <div className="form-group mb-3 col-md-6">
          <label className="form-label">
            Start Date <span className="example-text">(e.g. August 10)</span>
          </label>
          <input
            type="text"
            value={startDate}
            className="form-control"
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="form-group mb-3 col-md-6">
          <label className="form-label">
            End Date <span className="example-text">(e.g. August 14)</span>
          </label>
          <input
            type="text"
            value={endDate}
            className="form-control"
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className="form-group mb-3 col-md-12">
          <label className="form-label">
            Logo <span className="example-text">(for internal use only)</span>
          </label>

          {imageUrl ? (
            <div className="d-flex align-items-center mb-2">
              <img
                src={imageUrl}
                alt={`Event Logo`}
                className="img-thumbnail"
                style={{ maxWidth: "120px" }}
              />
              <button
                className="btn btn-danger ml-2"
                onClick={() => setImageUrl("")}
              >
                Remove
              </button>
            </div>
          ) : null}
          {loading && (
            <img
              src={imageUploadLoader}
              alt="Uploading..."
              width="100"
              className="img-thumbnail"
            />
          )}

          {!imageUrl ? (
            <>
              {" "}
              <input
                type="file"
                accept="image/*"
                onChange={(e) =>
                  handleImageUpload(e, setImageUrl, {
                    compress: true,
                  })
                }
                className="d-none"
                id="eventLogoUpload"
              />
              <button
                className="btn btn-secondary btn-sm mt-2"
                onClick={() =>
                  document.getElementById("eventLogoUpload").click()
                }
              >
                Upload Logo
                <i className="event-owner-add fa-sharp fa-solid fa-plus ml-05"></i>
              </button>
            </>
          ) : null}

          {/* <input
            type="text"
            value={imageUrl}
            className="form-control"
            onChange={(e) => setImageUrl(e.target.value)}
          /> */}
        </div>
        <div className="form-group mb-3 col-md-12">
          <label className="form-label">
            Tags determine which page(s) your Event is displayed on. For example
            a tag of <b>industrialtech</b> will display the event on{" "}
            <a
              href="https://industrialtech.landmarkventures.com/"
              target="_blank"
            >
              industrialtech.landmarkventures.com
            </a>
            . If your event has it's own dedicated website then use the tag{" "}
            <b>global</b>
            <br></br>
            <span className="example-text">
              Multiple tags should be separated by commas (e.g.
              global,industrialtech)
            </span>
          </label>
          <input
            type="text"
            value={tags.join(",")}
            className="form-control"
            onChange={(e) => handleTagChange(e.target.value)}
          />
        </div>
        <div className="form-group mb-3 col-md-12">
          <div className="options-wrapper">
            <label className="form-label">
              Event options which the attendee can request to attend<br></br>
              <span className="example-text">
                (e.g. VIP Brunch or Cocktail Reception)
              </span>
            </label>
            <label className="form-label">
              Click on an existing option to edit it or add a new option.
            </label>
            <div className="edit-options-container">
              {options.map((o) => {
                const selClass = o === selectedOption ? "selected" : "";
                return (
                  <div
                    className={`option-container ${selClass}`}
                    onClick={() => {
                      console.log("selectedOption", o);
                      setSelectedOption(o);
                    }}
                  >
                    <div className="title">{o.title}</div>
                    <div className="location">{o.description}</div>
                  </div>
                );
              })}
              <div
                className={`option-container new text-center ${
                  newOptionMode ? "selected" : ""
                }`}
                onClick={() => {
                  console.log("selectedOption", {});
                  setSelectedOption({});
                }}
              >
                <div className="title text-center">
                  New Option{" "}
                  <i className="event-owner-add fa-sharp fa-solid fa-plus ml-05"></i>
                </div>
              </div>
            </div>
            {selectedOption !== null ? (
              <OptionForm
                option={selectedOption}
                options={options}
                password={password}
                handleUpdateOption={handleUpdateOption}
              />
            ) : null}
          </div>
        </div>
      </div>
      <button
        className={`create-event-btn btn btn-sm btn-primary mb-3`}
        onClick={handleSaveEvent}
      >
        {saveBtnText}
        <i className="event-owner-add fa-sharp fa-solid fa-check ml-05"></i>
      </button>
    </div>
  );
};

const AddEditEvent = ({ password }) => {
  const [events, setEvents] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  async function getEvents() {
    try {
      const api = new ApiService({ password });
      const tag = "global";
      const eventsResponse = await api.get(`landmark/events/getEvents/${tag}`);
      console.log(`getEvents()`, eventsResponse);
      setEvents(eventsResponse);
      setSelectedEvent(null);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getEvents();
  }, []);

  const toggleSetShowForm = () => {
    setShowForm(!showForm);
  };

  const handleSetCreateMode = () => {
    setEditMode(false);
    setCreateMode(true);
    setSelectedEvent(null);
  };

  const handleSetEditMode = () => {
    setCreateMode(false);
    setEditMode(true);
  };

  const resetModes = () => {
    setCreateMode(false);
    setEditMode(false);
    setSelectedEvent(null);
  };

  return (
    <>
      <div className="add-edit-btn-container">
        {!showForm && (
          <>
            <button
              className={`create-event-btn btn btn-sm btn-primary`}
              onClick={handleSetCreateMode}
            >
              {`Create New Event`}
              <i className="event-owner-add fa-sharp fa-solid fa-plus ml-05"></i>
            </button>
            <button
              className={`create-event-btn btn btn-sm btn-primary`}
              onClick={handleSetEditMode}
            >
              {`Edit Existing Event`}
              <i className="event-owner-add fa-sharp fa-solid fa-pencil ml-05"></i>
            </button>
          </>
        )}
      </div>
      {editMode ? (
        <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
          <Card className="mb-3 h-fc bot-table">
            <div className="row">
              <div className="outputHeader">Edit Event</div>
              <>
                <div className="events-owners-subheading">
                  Select Event to Edit
                </div>
                <div
                  className="event-owners-wrapper"
                  style={{ marginBottom: "12px" }}
                >
                  {events.map((event) => {
                    const selectedClass =
                      selectedEvent && event.eventId === selectedEvent.eventId
                        ? "selected"
                        : "";
                    return (
                      <div key={event.eventId}>
                        <div
                          className={`event-owner-container selectable ${selectedClass}`}
                          onClick={() => setSelectedEvent(event)}
                        >
                          <img
                            src={event.imageUrl}
                            className="img-fluid event-img"
                          />
                          <div className="event-title">{event.title}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {selectedEvent ? (
                  <>
                    <EventForm
                      selectedEvent={selectedEvent}
                      password={password}
                      getEvents={getEvents}
                      resetModes={resetModes}
                    />
                  </>
                ) : null}
              </>
            </div>
          </Card>
        </motion.div>
      ) : null}
      {createMode ? (
        <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
          <Card className="mb-3 h-fc bot-table">
            <div className="row">
              <div className="outputHeader">Create Event</div>
              <>
                {/* <div className="events-owners-subheading">
                </div> */}
                <>
                  <EventForm
                    selectedEvent={selectedEvent}
                    password={password}
                    getEvents={getEvents}
                    resetModes={resetModes}
                  />
                </>
              </>
            </div>
          </Card>
        </motion.div>
      ) : null}
    </>
  );

  return (
    <>
      <motion.div animate={{ x: 0 }} initial={{ x: 150 }}>
        <Card className="mb-3 h-fc bot-table">
          <div className="row">
            <div className="outputHeader">Events</div>
            <>
              <div className="events-owners-subheading">
                Select Event to view RSVPs
              </div>
              <div
                className="event-owners-wrapper"
                style={{ marginBottom: "12px" }}
              >
                {events.map((event) => {
                  const selectedClass =
                    selectedEvent && event.eventId === selectedEvent.eventId
                      ? "selected"
                      : "";
                  return (
                    <div key={event.eventId}>
                      <div
                        className={`event-owner-container selectable ${selectedClass}`}
                        onClick={() => setSelectedEvent(event)}
                      >
                        <img
                          src={event.imageUrl}
                          className="img-fluid event-img"
                        />
                        <div className="event-title">{event.title}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          </div>
        </Card>
      </motion.div>
    </>
  );
};

export default AddEditEvent;
